import styled from 'styled-components'
import { Theme } from '@web-apps/ui-shared'

export const StyledLabel = styled.label<{ $withCheckmark: boolean }>`
  position: relative;
  display: block;
  padding-left: ${({ $withCheckmark }) => ($withCheckmark ? '36px' : '0')};
  cursor: pointer;
  user-select: none;
  margin-bottom: 0;
`

export const StyledCheckmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  border: 1px solid ${Theme.Colors.typography.light};
  background-color: ${Theme.Colors.background.base};
  border-radius: 50%;

  &:after {
    content: '';
    position: absolute;
    display: none;
    top: 3px;
    left: 3px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: ${Theme.Colors.typography.light};
  }
`

export const StyledInputRadio = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  &:checked ~ ${StyledCheckmark} {
    transition: all 0.25s ease-in-out;
  }

  &:checked ~ ${StyledCheckmark}:after {
    display: block;
  }
`
