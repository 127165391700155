import { useState, FC, PropsWithChildren } from 'react'
import { ShareLinkContext, ShareLinkType } from '@web-apps/ui-shared'

export const ShareLinkContextWrapper: FC<PropsWithChildren> = ({
  children,
}) => {
  const [shareLinkContextData, setShareLink] = useState<ShareLinkType>({
    url: '',
    container: document.body,
  })

  return (
    <ShareLinkContext.Provider
      value={{ ...shareLinkContextData, setShareLink }}
    >
      {children}
    </ShareLinkContext.Provider>
  )
}
