import { useEffect, useCallback, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { useDraftSection } from '../../../../containers/creator/MyPage/hooks/preview.hooks'
import { editDraftContentSection } from '../../../../store/creator/preview.slice'

import { Confirm } from '@web-apps/ui-shared'
import {
  getSectionImage,
  useDeleteCreatorPageSectionMutation,
  useEditCreatorPageSectionAtomicMutation,
} from '@web-apps/feature-creator-page'
import {
  ANALYTICS_CATEGORY,
  ContentSectionFormDataType,
  ContentSectionType,
} from '@web-apps/utils-types'
import { ROUTES } from '../../../../routes/RouteEnums'
import { SectionContentForm } from './SectionContentForm.component'
import { useCreatorPage } from '../../../../utils/hooks/useCreatorPage.hooks'
import { ToastShareWrapper } from '../../../../components'
import { creatorUrlBuilders } from '@web-apps/utils-shared'

export const EditSectionContent = () => {
  const { t } = useTranslation(['app', 'creator'])
  const navigate = useNavigate()
  const { id } = useParams<{ id: string }>()
  const [isConfirmOpen, toggleConfirm] = useState(false)
  const updatePreview = useDraftSection(id, editDraftContentSection, true)
  const creatorPage = useCreatorPage()

  const section = creatorPage?.sections.find(
    (item): item is ContentSectionType => item._links.self.id === id
  )

  const [
    editSection,
    {
      isSuccess: isEditSuccess,
      isError: isEditError,
      isLoading: isEditLoading,
    },
  ] = useEditCreatorPageSectionAtomicMutation()

  const [
    deleteSection,
    {
      isSuccess: isDeleteSuccess,
      isError: isDeleteError,
      isLoading: isDeleteLoading,
    },
  ] = useDeleteCreatorPageSectionMutation()

  useEffect(() => {
    if (isDeleteSuccess) {
      navigate(ROUTES.MY_PAGE)
      toast.success(
        t('creator:toast.delete_success', {
          section: '$t(creator:sections.content)',
        })
      )
    }

    if (isEditSuccess) {
      navigate(ROUTES.MY_PAGE)
      toast.success(
        <ToastShareWrapper
          href={creatorUrlBuilders.creatorUrlBuilder({
            creatorSlug: creatorPage._links.self.name,
          })}
          message={t('creator:toast.edit_success', {
            section: '$t(creator:sections.content)',
          })}
          analyticsEventProps={{
            actionNamePrefix: 'edit_text_section',
            category: ANALYTICS_CATEGORY.CONTENT,
          }}
        />
      )
    }
  }, [isEditSuccess, isDeleteSuccess, creatorPage, t, navigate])

  useEffect(() => {
    if (isEditError || isDeleteError)
      toast.error(
        t('creator:toast.edit_error', {
          section: '$t(creator:sections.content)',
        })
      )
  }, [isEditError, isDeleteError, section, t])

  const handleSubmit = useCallback(
    ({ imageData, ...sectionData }: ContentSectionFormDataType) =>
      section &&
      editSection({
        _links: section._links,
        sectionData,
        imageData,
      }),
    [editSection, section]
  )

  if (!section) return null

  return (
    <>
      <SectionContentForm
        handleSubmit={handleSubmit}
        handleDelete={() => toggleConfirm(!isConfirmOpen)}
        updatePreview={updatePreview}
        isLoading={isDeleteLoading || isEditLoading}
        initialValues={{
          label: section.label,
          text: section.text,
          type: section.type,
          styles: section.styles,
          published: section.published,
          imageData: getSectionImage(section),
        }}
      />
      {isConfirmOpen && (
        <Confirm
          onDismiss={() => toggleConfirm(false)}
          title={t('creator:confirm.title')}
          buttons={[
            {
              label: t('creator:confirm.cancel'),
              onClick: () => toggleConfirm(false),
              inverse: true,
            },
            {
              label: t('creator:confirm.delete'),
              onClick: () => {
                if (section) {
                  deleteSection({ path: section._links.self.href })
                  toggleConfirm(false)
                }
              },
            },
          ]}
        />
      )}
    </>
  )
}
