import styled, { css } from 'styled-components'

import { BREAKPOINTS, Card, Flex, media, Theme } from '@web-apps/ui-shared'

export const StyledPayoutsPanelCard = styled(Card)`
  display: flex;
  flex-direction: column;

  ${media.from(
    BREAKPOINTS.sm,
    `
    flex-direction: row;
    justify-content: space-around;
  `
  )}
`

export const StyleBalancePanel = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const StyledProgressBarContainer = styled.div`
  width: 100%;
  max-width: 200px;

  padding-top: 8px;
`

export const StyledSeparator = styled.div`
  height: 1px;
  width: 100%;
  margin-block: 24px;

  background-color: ${Theme.Colors.background.alternate};

  ${media.from(
    BREAKPOINTS.sm,
    `
      width: 1px;
      height: unset;
      margin-block: unset;
      margin-inline: 24px;
  `
  )}
`

export const StyledStatusPanel = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  text-align: center;

  ${media.from(
    BREAKPOINTS.sm,
    `
      align-items: flex-start;
      text-align: left;
    `
  )}
`

export const StyledStatusHeader = styled.div<{ $hasBackground?: boolean }>`
  display: flex;
  gap: 8px;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;

  ${({ $hasBackground }) =>
    $hasBackground &&
    css`
      padding: 8px 16px;
      border-radius: 8px;
      background: ${Theme.Colors.background.global};
      ${media.from(
        BREAKPOINTS.sm,
        `
            padding: 8px;
          `
      )};
    `};

  ${media.from(
    BREAKPOINTS.sm,
    `
      justify-content: unset;
    `
  )}
`

export const StyledHeaderActions = styled(Flex)`
  justify-content: center;

  ${media.from(
    BREAKPOINTS.sm,
    `
      justify-content: unset;
    `
  )}
`
