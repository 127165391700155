import {
  ImageFormType,
  ImageNamesEnum,
  ImageResponseAPIType,
  ImageResponseType,
} from '@web-apps/utils-types'

export const updateImages = (
  items: ImageResponseAPIType | undefined = [],
  itemNames: ImageNamesEnum[],
  newItem?: ImageFormType
): ImageResponseType[] => {
  const itemsArray = Array.isArray(items) ? items : [items]
  if (!newItem)
    return itemsArray.filter((item) => !itemNames.includes(item.name))

  const { imageFile, ...rest } = newItem
  const newImage = rest as ImageResponseType
  const currentIndex = itemsArray.findIndex(
    (item) => item.name === newItem.name
  )

  if (currentIndex === -1) {
    return [...itemsArray, newImage]
  }

  return itemsArray.map((item, index) => {
    if (currentIndex === index) return newImage

    return item
  })
}
