import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  computeCommissionToShowFromAggregate,
  DateHelpers,
} from '@web-apps/utils-shared'
import { Box, Flex, TitledMediaCard, Typography } from '@web-apps/ui-shared'
import ReactGA from 'react-ga4'
import { useLocation, useNavigate } from 'react-router-dom'
import { ANALYTICS_CATEGORY, Brand } from '@web-apps/utils-types'
import { TrafficSourceBadge } from '../../../../../../components'
import { useFlag } from '@unleash/proxy-client-react'
import {
  FLAG_BRAND_MODAL,
  FLAG_BRANDS_CAMPAIGN_DATA,
  FLAG_COMMISSION_SOURCE_SPLITTING,
} from '../../../../../../utils/constants/flags.constants'
import ReactTooltip, { TooltipProps } from 'react-tooltip'

type Props = {
  brand: Brand
}

type BadgeProps = {
  id: string
  title: string
  description?: string
  place?: TooltipProps['place']
}
const BadgeTooltip = ({
  id,
  title,
  description,
  place = 'right',
}: BadgeProps) => (
  <ReactTooltip id={id} multiline effect="solid" place={place} type="light">
    <Box w={240}>
      <Box mb={4}>
        <Typography variant="hint" fontWeight="bold">
          {title}
        </Typography>
      </Box>
      {description && <Typography variant="hint">{description}</Typography>}
    </Box>
  </ReactTooltip>
)

export const BrandCard = ({ brand }: Props) => {
  const { t } = useTranslation(['app', 'brands'], { useSuspense: false })
  const navigate = useNavigate()
  const location = useLocation()
  const isCommissionSourceSplittingEnabled = useFlag(
    FLAG_COMMISSION_SOURCE_SPLITTING
  )
  const isBrandModalEnabled = useFlag(FLAG_BRAND_MODAL)
  const isBrandsCampaignDataEnabled = useFlag(FLAG_BRANDS_CAMPAIGN_DATA)
  const { name, websiteURL, logoURL, commission, id, campaign } = brand
  const commissionInfo = computeCommissionToShowFromAggregate(commission)
  const badges = useMemo(() => {
    if (!isBrandsCampaignDataEnabled) return []

    const badges = []

    if (campaign?.clicksLimit) {
      const key = `${id || name}_max_clicks`
      badges.push({
        key,
        side: 'left',
        text: t('brands:badges.clicks_limit.title', {
          amount: campaign.clicksLimit.maxClicks,
        }),
        progress: campaign.clicksLimit.currentClicksFraction,
        tooltip: (
          <BadgeTooltip
            id={key}
            title={t('brands:badges.clicks_limit.tooltip.title', {
              maxAmount: campaign.clicksLimit.maxClicks,
            })}
            description={t('brands:badges.clicks_limit.tooltip.description', {
              amountLeft:
                campaign.clicksLimit.clicksLeft > 0
                  ? campaign.clicksLimit.clicksLeft
                  : 0,
            })}
          />
        ),
      })
    }
    if (campaign?.earningsLimit) {
      const key = `${id || name}_earnings_limit`
      badges.push({
        key,
        side: 'left',
        text: t('brands:badges.earnings_limit.title', {
          maxEarnings: campaign.earningsLimit.maxEarnings.formattedText,
        }),
        progress: campaign.earningsLimit.currentEarningFraction,
        tooltip: (
          <BadgeTooltip
            id={key}
            title={t('brands:badges.earnings_limit.tooltip.title', {
              maxEarnings: campaign.earningsLimit.maxEarnings.formattedText,
            })}
            description={t('brands:badges.earnings_limit.tooltip.description', {
              amountLeft: campaign.earningsLimit.amountLeft,
            })}
          />
        ),
      })
    }
    if (campaign?.periodLimit) {
      const key = `${id || name}_period_limit`
      badges.push({
        key,
        side: 'left',
        text: t('brands:badges.period_limit.title', {
          date: DateHelpers.format(campaign.periodLimit.validUntil, 'D MMM'),
        }),
        progress: campaign.periodLimit.timeLeftFraction,
        tooltip: (
          <BadgeTooltip
            id={key}
            title={t('brands:badges.period_limit.tooltip.title', {
              date: DateHelpers.format(
                campaign.periodLimit.validUntil,
                'YYYY MMM D, HH:mm'
              ),
            })}
            description={t('brands:badges.period_limit.tooltip.description', {
              timeLeft: campaign.periodLimit.timeLeft,
            })}
          />
        ),
      })
    }
    if (campaign?.title) {
      const key = `${id || name}_badge_info`
      badges.push({
        key,
        side: 'right',
        text: campaign.title,
        progress: 1,
        tooltip: (
          <BadgeTooltip
            id={key}
            place="top"
            title={campaign.title}
            description={campaign.text}
          />
        ),
      })
    }

    return badges
  }, [campaign, id, name, isBrandsCampaignDataEnabled, t])

  return (
    <TitledMediaCard
      key={websiteURL}
      badges={badges}
      width="164px"
      height="96px"
      image={
        logoURL
          ? {
              src: logoURL,
              alt: name,
            }
          : undefined
      }
      {...(isBrandModalEnabled
        ? {
            as: 'button',
            type: 'button',
            onClick: () => {
              ReactGA.event({
                action: 'brand_collab_page:brand_card:click',
                category: ANALYTICS_CATEGORY.AFFILIATES,
                label: name,
              })
              navigate(`/brands/${id}${location.search}`)
            },
          }
        : {
            as: 'a',
            target: '_blank',
            href: websiteURL,
            onClick: () => {
              ReactGA.event({
                action: 'brand_collab_page:brand_card:click',
                category: ANALYTICS_CATEGORY.AFFILIATES,
                label: name,
              })
            },
          })}
      {...(isCommissionSourceSplittingEnabled
        ? {
            imageReplacementTitle: name,
            title: (
              <Flex
                style={{ minHeight: '88px' }}
                justify="center"
                direction="column"
              >
                <TrafficSourceBadge centered sources={commission?.sources} />
              </Flex>
            ),
            disableSeparator: true,
            footerStyles: {
              padding: '0 8px 16px',
            },
          }
        : {
            title: name,
            description: t(`brands:commission.${commissionInfo.type}`, {
              commission: commissionInfo.commissionText,
            }),
          })}
      variant="custom"
    />
  )
}
