import { useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

export const useAdminLogin = () => {
  const [searchParams] = useSearchParams()
  const [isAdminLogin, setIsAdminLogin] = useState(
    localStorage.getItem('isAdminLogin') === 'true'
  )

  useEffect(() => {
    if (searchParams.has('as-admin')) {
      localStorage.setItem('isAdminLogin', 'true')
      setIsAdminLogin(true)
    }
  }, [searchParams, setIsAdminLogin])

  return [isAdminLogin]
}
