import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  ContactListFormType,
  ContentSectionFormDataType,
  ContentSectionType,
  CreatorPageType,
  CustomizationFormProperties,
  CustomizationPropsType,
  ExternalLinkMode,
  ExternalLinkSection,
  ImageFormType,
  ImageNamesEnum,
  ProductRecommendationsSectionType,
  SectionBasePropertiesType,
  SectionType,
  SectionTypeEnum,
  SectionVariantEnum,
  SocialLinkType,
  SocialsPlacement,
} from '@web-apps/utils-types'
import { updateImages } from '../../utils/helpers/preview.slice.helpers'

type InitialStateType = {
  data: CreatorPageType | null
  currentSectionId: string | null
}

const initialState: InitialStateType = {
  data: null,
  currentSectionId: null,
}

const previewSlice = createSlice({
  name: 'creatorPagePreview',
  initialState,
  reducers: {
    setInitialState(state, { payload }: PayloadAction<CreatorPageType>) {
      state.data = payload
      state.currentSectionId = null
    },
    clearPreviewState(state) {
      state = initialState
    },
    addDraftSection(state, { payload }: PayloadAction<string>) {
      state.currentSectionId = payload
      state.data?.sections.splice(0, 0, {
        label: '',
        type: SectionTypeEnum.EXTERNAL_LINK,
        mode: ExternalLinkMode.LINK,
        styles: {
          variant: SectionVariantEnum.PLAIN,
        },
        published: true,
        _links: {
          about: {
            href: '',
          },
          self: {
            href: '',
            id: payload,
          },
          parent: {
            href: '',
            id: '',
          },
          author: {
            href: '',
            id: '',
            name: '',
          },
          'self/edit-image': { href: 'https://zezam.io' },
        },
      })
    },
    editDraftExternalSection(
      state,
      {
        payload: { id, data },
      }: PayloadAction<{
        id: string
        data: {
          label: string
          tagline?: string
          href?: string
          published: boolean
          imageData?: ImageFormType
          styles: SectionBasePropertiesType['styles']
        }
      }>
    ) {
      const draftSection = state.data?.sections.find(
        (section) => section._links.self.id === id
      ) as SectionType

      if (draftSection) {
        Object.assign(draftSection, {
          label: data.label,
          tagline: data.tagline,
          styles: data.styles,
          published: data.published,
          _links: {
            ...draftSection._links,
            ...(data.href && {
              about: {
                href: data.href,
                id: '',
              },
            }),
            self: {
              href: '',
              id,
            },
            'self/image': updateImages(
              draftSection._links['self/image'],
              [ImageNamesEnum.DEFAULT, ImageNamesEnum.SECTION],
              data.imageData
            ),
          },
        })
      }
    },
    editDraftContentSection(
      state,
      {
        payload,
      }: PayloadAction<{ id: string; data: ContentSectionFormDataType }>
    ) {
      const draftSection = state.data?.sections.find(
        (section) => section._links.self.id === payload.id
      ) as ContentSectionType

      if (draftSection) {
        Object.assign(draftSection, payload.data)
        draftSection._links['self/image'] = updateImages(
          draftSection._links['self/image'],
          [ImageNamesEnum.DEFAULT, ImageNamesEnum.SECTION],
          payload.data.imageData
        )
      }
    },
    editDraftEmbedExternalSection(
      state,
      {
        payload: { id, data },
      }: PayloadAction<{
        id: string
        data: {
          href: string
          published: boolean
          label?: string
          tagline?: string
          imageData?: ImageFormType
          styles: SectionBasePropertiesType['styles']
        }
      }>
    ) {
      const draftSection = state.data?.sections.find(
        (section) => section._links.self.id === id
      ) as ExternalLinkSection

      if (draftSection) {
        Object.assign(draftSection, {
          label: data.label,
          tagline: data.tagline,
          styles: data.styles,
          mode: data.href ? ExternalLinkMode.EMBED : ExternalLinkMode.LINK,
          published: data.published,
          _links: {
            ...draftSection._links,
            ...(data.href && {
              about: {
                href: data.href,
                id: '',
              },
            }),
            self: {
              href: '',
              id,
            },
            'self/image': updateImages(
              draftSection._links['self/image'],
              [ImageNamesEnum.DEFAULT, ImageNamesEnum.SECTION],
              data.imageData
            ),
          },
        })
      }
    },
    editDraftContactListSection(
      state,
      {
        payload: { id, data },
      }: PayloadAction<{ id: string; data: Partial<ContactListFormType> }>
    ) {
      const draftSection = state.data?.sections.find(
        (section) => section._links.self.id === id
      )

      if (draftSection) {
        Object.assign(draftSection, data)
        draftSection._links['self/image'] = updateImages(
          draftSection._links['self/image'],
          [ImageNamesEnum.DEFAULT, ImageNamesEnum.SECTION],
          data.imageData
        )
      }
    },
    editSectionsOrder(state, { payload }: PayloadAction<SectionType[]>) {
      if (state.data) state.data.sections = payload
    },
    editAppearance(
      state,
      {
        payload,
      }: PayloadAction<{
        styles: CustomizationPropsType
        backgroundImage?: ImageFormType
        profileImage?: ImageFormType
        about: string
        name: string
      }>
    ) {
      if (state.data) {
        state.data.styles = {
          ...state.data.styles,
          ...payload.styles,
        }

        state.data.name = payload.name
        state.data.tagline = payload.about

        state.data._links['self/image'] = updateImages(
          state.data._links['self/image'],
          [ImageNamesEnum.BACKGROUND],
          payload.backgroundImage
        )
        state.data._links['self/image'] = updateImages(
          state.data._links['self/image'],
          [ImageNamesEnum.DEFAULT, ImageNamesEnum.AVATAR],
          payload.profileImage
        )
      }
    },
    editSocialLinks(
      state,
      {
        payload,
      }: PayloadAction<{
        items: SocialLinkType[]
        placement: SocialsPlacement
      }>
    ) {
      if (state.data) {
        state.data.social_links = payload.items
        state.data.styles[CustomizationFormProperties.SOCIALS_PLACEMENT] =
          payload.placement
      }
    },
    editCurrentSectionId(state, { payload }: PayloadAction<string>) {
      state.currentSectionId = payload
    },
    editDraftProductRecommendationsSection(
      state,
      {
        payload: { id, data },
      }: PayloadAction<{
        id: string
        data: ProductRecommendationsSectionType & { imageData?: ImageFormType }
      }>
    ) {
      const draftSection = state.data?.sections.find(
        (section) => section._links.self.id === id
      )

      if (draftSection) {
        Object.assign(draftSection, data)
        draftSection._links['self/image'] = updateImages(
          draftSection._links['self/image'],
          [ImageNamesEnum.DEFAULT, ImageNamesEnum.SECTION],
          data.imageData
        )
      }
    },
  },
})

export const {
  addDraftSection,
  editDraftContentSection,
  editDraftEmbedExternalSection,
  editDraftExternalSection,
  editDraftContactListSection,
  editSectionsOrder,
  editAppearance,
  editSocialLinks,
  editCurrentSectionId,
  clearPreviewState,
  setInitialState,
  editDraftProductRecommendationsSection,
} = previewSlice.actions
export const PreviewReducer = previewSlice.reducer
