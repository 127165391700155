import { SectionType } from '@web-apps/utils-types'
import { useCreatorPage } from '../../../../utils/hooks/useCreatorPage.hooks'

type UseIsSectionRedirectCheckerProps = {
  section?: SectionType
}

export const useIsSectionRedirectChecker = ({
  section,
}: UseIsSectionRedirectCheckerProps) => {
  const creatorPage = useCreatorPage()

  return {
    isSectionRedirect: section
      ? creatorPage?.redirect?.section._links.self.id === section._links.self.id
      : false,
  }
}
