import { useEffect, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'
import styled from 'styled-components'

import { AuthPageWrapper, useGetSession } from '@web-apps/feature-auth'
import { Box, Flex, LoadingSpinner, Theme } from '@web-apps/ui-shared'

import { ROUTES } from '../../../routes/RouteEnums'
import { getEnvironmentConfigs } from '../../../services/utility'
import { usePostRegistrationMutation } from '../../../services/auth'
import { useGetUserAccountQuery } from '../../../services/user.api'
import { useGetAPIConfigsQuery } from '../../../services/api'

const StyledSuccessText = styled.p`
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: ${Theme.Colors.typography.label};
`

export const PostRegisterPage = () => {
  const { t } = useTranslation(['auth'])
  const navigate = useNavigate()
  const { search } = useLocation()
  const { session, isSessionLoading } = useGetSession()
  const userId = session?.identity?.metadata_public?.user_id
  const { data: userAccount } = useGetUserAccountQuery(
    { userId: userId || '' },
    { skip: !session || isSessionLoading }
  )

  const cookiesDomain = getEnvironmentConfigs().cookieDomain

  const searchParams = useMemo(() => new URLSearchParams(search), [search])

  const { data: apiConfigs } = useGetAPIConfigsQuery()

  const [
    runPostRegistrationFlow,
    {
      isSuccess: isPostRegistrationSuccess,
      isError: isPostRegistrationError,
      data: postRegistrationData,
    },
  ] = usePostRegistrationMutation()

  const redirectHome = useCallback(() => {
    navigate(ROUTES.MY_PAGE)
  }, [navigate])

  useEffect(() => {
    if (!userId) return

    if (userAccount) {
      runPostRegistrationFlow({
        creatorPageEndpoint: userAccount.creatorPageEndpoint,
      })
    }
  }, [
    userAccount,
    userId,
    searchParams,
    runPostRegistrationFlow,
    cookiesDomain,
    apiConfigs,
  ])

  useEffect(() => {
    if (isPostRegistrationSuccess) {
      setTimeout(redirectHome, 2000)
    }

    if (isPostRegistrationError) redirectHome()
  }, [
    isPostRegistrationSuccess,
    isPostRegistrationError,
    postRegistrationData,
    redirectHome,
  ])

  return (
    <div>
      <AuthPageWrapper
        homePage={getEnvironmentConfigs().homePage}
        partner={searchParams.get('partner')}
      >
        <StyledSuccessText>
          {t('auth:register.upload_profile_description')}
        </StyledSuccessText>
        <Box mb={40} mt={40}>
          <Flex justify="center">
            <LoadingSpinner size={40} color={Theme.Colors.typography.regular} />
          </Flex>
        </Box>
      </AuthPageWrapper>
    </div>
  )
}
