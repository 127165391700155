import { useEffect, useMemo } from 'react'
import { toast } from 'react-toastify'
import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import {
  Login,
  AuthFormWrapper,
  AuthPageWrapper,
  UseFormLink,
  REDIRECT_PARAM_KEY,
} from '@web-apps/feature-auth'
import { Box } from '@web-apps/ui-shared'

import { ROUTES } from '../../../routes/RouteEnums'
import { getEnvironmentConfigs } from '../../../services/utility'

export const LoginPage = () => {
  const { t } = useTranslation(['auth'])

  const { search } = useLocation()
  const searchParams = useMemo(() => new URLSearchParams(search), [search])

  useEffect(() => {
    localStorage.removeItem('isSeamlessAuth')
  }, [])

  const onError = (error: string) => toast.error(error)

  return (
    <div>
      <AuthPageWrapper
        homePage={getEnvironmentConfigs().homePage}
        partner={searchParams.get('partner')}
      >
        <AuthFormWrapper title={t('auth:login.title')}>
          <Login
            // we need a manual redirect because in email login flow there's no redirect
            onLogin={() =>
              (window.location.href =
                searchParams.get(REDIRECT_PARAM_KEY) || '/')
            }
            onError={onError}
            returnToUrl={ROUTES.MY_PAGE}
            resetPasswordLink={ROUTES.RESET_PASSWORD}
          />
          <Box mt={16}>
            <UseFormLink>
              <Link to={ROUTES.RESET_PASSWORD + (search || '')}>
                {t('auth:login.forgot_password')}
              </Link>
            </UseFormLink>
          </Box>
        </AuthFormWrapper>

        <Box mt={16}>
          <UseFormLink withBoldLink>
            {t('auth:login.dont_have_an_account')}{' '}
            <Link to={ROUTES.REGISTER + (search || '')}>
              {t('auth:login.create_account')}
            </Link>
          </UseFormLink>
        </Box>
      </AuthPageWrapper>
    </div>
  )
}
