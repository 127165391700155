import styled from 'styled-components'

import { media, BREAKPOINTS, Theme } from '@web-apps/ui-shared'

type ActionProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  text: string
}

type ActionsBannerProps = {
  text: string
  primaryAction: ActionProps
  secondaryAction?: ActionProps
  show?: boolean
}

const StyledContainer = styled.div`
  position: fixed;
  z-index: 9999;

  bottom: 16px;
  left: 16px;
  right: 16px;

  background: #303030;
  padding: 8px 8px 8px 16px;
  border-radius: 8px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  color: #ffffff;

  button {
    border: none;
    padding: 8px 16px;
  }

  ${media.from(
    BREAKPOINTS.md,
    `
      position: sticky;
      margin-inline: clamp(16px, 20%, 120px);
      min-width: 260px;
    `
  )}
`

const StyledActionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  button {
    flex: 1;
  }

  ${media.from(
    BREAKPOINTS.md,
    `
      flex-wrap: nowrap;
    `
  )}
`

const StyledPrimaryActionButton = styled.button`
  background: ${Theme.Colors.typography.inverse};
  border-radius: 8px;
  color: ${Theme.Colors.typography.regular};

  &:disabled {
    background: ${Theme.Colors.typography.inactive};
  }
`

const StyledSecondaryActionButton = styled.button`
  background: inherit;
  color: inherit;
`

export const ActionsBanner = ({
  text,
  primaryAction,
  secondaryAction,
  show = false,
}: ActionsBannerProps) => {
  const { text: primaryActionText, ...primaryActionButtonProps } = primaryAction
  const { text: secondaryActionText, ...secondaryActionButtonProps } =
    secondaryAction || {}

  if (show)
    return (
      <StyledContainer>
        {text}
        <StyledActionsContainer>
          {secondaryAction && (
            <StyledSecondaryActionButton {...secondaryActionButtonProps}>
              {secondaryActionText}
            </StyledSecondaryActionButton>
          )}
          <StyledPrimaryActionButton {...primaryActionButtonProps}>
            {primaryActionText}
          </StyledPrimaryActionButton>
        </StyledActionsContainer>
      </StyledContainer>
    )

  return null
}
