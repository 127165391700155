import { ToastContainer as ToastProvider, Zoom } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'

export const ToastContainer = () => {
  return (
    <ToastProvider
      transition={Zoom}
      theme="dark"
      limit={2}
      position="bottom-center"
      autoClose={10000}
      newestOnTop={false}
      closeOnClick={false}
      rtl={false}
      draggable={false}
      pauseOnFocusLoss
      pauseOnHover
    />
  )
}
