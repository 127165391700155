import styled from 'styled-components'
import { Card, Theme } from '@web-apps/ui-shared'

export const StyledExistingGridCard = styled(Card)`
  padding: 16px;
`

export const StyledOverlayMoreItems = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

  padding: 0;

  background: ${Theme.Colors.typography.regular};
  opacity: 0.6;

  border-radius: ${Theme.Card.borderRadiusInPx}px;

  color: ${Theme.Colors.typography.inverse};
  line-height: 24px;
  font-size: 14px;
  font-weight: 700;
`

export const StyledProductItemCard = styled(Card)`
  width: 48px;
  height: 48px;
  padding: 0;
  position: relative;

  img {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    width: 100%;
    height: 100%;

    border-radius: ${Theme.Card.borderRadiusInPx}px;

    object-fit: cover;
  }
`
