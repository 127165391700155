import React, { useEffect } from 'react'
import { AffiliateTooltipState } from '../../../MyPage.types'
import { FormArgsType } from '@web-apps/ui-shared'
import { CommissionSource } from '@web-apps/utils-types'
import { TrafficSourceBadge } from '../../../../../../components'

type Props = {
  matches?: AffiliateTooltipState['matches']
  formReturnProps: FormArgsType<any>
  sources?: CommissionSource[]
}

// This component is used only to match legacy logic with "matches" that uses to find the path for creation
// It always use the first match-name to find the right ulr link in parent component for affiliate link
export const TrafficSourceSelector = ({
  matches,
  formReturnProps: { setValue },
  sources,
}: Props) => {
  useEffect(() => {
    if (matches) setValue('commissionMatchName', matches[0].name)
    return () => setValue('commissionMatchName', '')
  }, [setValue, matches])

  return <TrafficSourceBadge sources={sources} />
}
