import { createContext } from 'react'
import { initialValuesForCustomization } from '@web-apps/feature-creator-page'
import { CreatorPageType } from '@web-apps/utils-types'

export const CreatorPageContext = createContext<
  CreatorPageType & {
    queryStatus?: {
      isLoading?: boolean
      isFetching?: boolean
      isError?: boolean
    }
  }
>({
  name: '',
  tagline: '',
  social_links: [],
  sections: [],
  styles: initialValuesForCustomization,
  settings: {
    show_classic_footer: true,
    show_sticky_gradient_footer: true,
  },
  _links: {
    self: {
      href: '',
      id: '',
      name: '',
    },
    author: {
      href: '',
    },
    'self/edit-image': { href: '' },
  },
})
