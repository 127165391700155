import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import { useFlag } from '@unleash/proxy-client-react'

import {
  Box,
  ButtonVariant,
  Card,
  Flex,
  Form,
  FormArgsType,
  Icon,
  IconButton,
  IconCatalogPaths,
  MAX_TITLE_LENGTH,
  StyledNonSelectableText,
  TextEllipsis,
  TextField,
  TextFieldSize,
  Theme,
  Typography,
  UrlCopyField,
} from '@web-apps/ui-shared'
import {
  computeCommissionToShowFromAggregate,
  creatorUrlBuilders,
  DateHelpers,
} from '@web-apps/utils-shared'
import { AffiliateLinkType } from '@web-apps/utils-types'

import { COPY_BUTTON_MESSAGES_KEYS } from '../../../../../../../../components/CopyButton/CopyButton'
import { FLAG_COMMISSION_SOURCE_SPLITTING } from '../../../../../../../../utils/constants/flags.constants'

import {
  StyledAffiliateLinkActionButtonContainer,
  StyledAffiliateLinkDescriptionContainer,
  StyledAddButton,
  StyledButton,
  StyledImage,
} from './AffiliateLinkItem.styles'

export type AffiliateLinkItemProps = {
  affiliateLinkData: AffiliateLinkType
  onShare: (
    affiliateLinkData: AffiliateLinkType,
    shareButtonElement: HTMLButtonElement
  ) => void
  onAddToMyPage: (affiliateLinkData: AffiliateLinkType) => void
  onEditSubmit: (
    newAffiliateLinkData: AffiliateLinkType,
    prevAffiliateLinkData: AffiliateLinkType
  ) => void
  isFetching?: boolean
}

/**
 * Component to focus title input when form is rendered using setFocus from react-hook-form:
 *  https://react-hook-form.com/api/useform/setfocus
 */
const FormFocusOnRender = ({
  setFocus,
}: {
  setFocus: FormArgsType['setFocus']
}) => {
  useEffect(() => {
    setFocus('title')
  }, [setFocus])

  return null
}

export const AffiliateLinkItem = ({
  affiliateLinkData,
  onShare,
  onAddToMyPage,
  onEditSubmit,
  isFetching,
}: AffiliateLinkItemProps) => {
  const { t } = useTranslation(['brands', 'creator'])
  const isCommissionSourceSplittingEnabled = useFlag(
    FLAG_COMMISSION_SOURCE_SPLITTING
  )
  const shareRef = useRef<HTMLButtonElement>(null)

  const [isEditModeActive, setEditModeActive] = useState(false)

  const commissionInfo =
    affiliateLinkData.commission &&
    computeCommissionToShowFromAggregate(affiliateLinkData.commission)

  return (
    <li>
      <Card>
        <Flex gap={24} direction="column">
          <Flex gap={8}>
            {isFetching ? (
              <Skeleton width={48} height={48} />
            ) : (
              <StyledImage
                src={
                  affiliateLinkData.imageUrl ||
                  IconCatalogPaths.ProductImagePlaceholder
                }
                alt={
                  affiliateLinkData.title ||
                  affiliateLinkData.brandName ||
                  undefined
                }
              />
            )}

            <StyledAffiliateLinkDescriptionContainer
              direction="column"
              justify="center"
            >
              {isEditModeActive ? (
                <Form
                  initialWatchableFields={['title']}
                  initialValues={{ title: affiliateLinkData.title }}
                  validations={{
                    descriptors: {
                      title: {
                        required: t('app:field_is_required'),
                      },
                    },
                  }}
                  onSubmit={(values) => {
                    onEditSubmit(
                      { ...affiliateLinkData, title: values.title },
                      affiliateLinkData
                    )

                    // using an optimistic approach
                    setEditModeActive(false)
                  }}
                >
                  {({ getValues, setFocus }) => {
                    const submitDisabled = !getValues('title')
                    return (
                      <Flex gap={8} align="center">
                        <FormFocusOnRender setFocus={setFocus} />
                        <TextField
                          name="title"
                          placeholder={t(
                            'brands:affiliate_links_list.no_title_placeholder'
                          )}
                          fieldSize={TextFieldSize.NANO}
                          maxLength={MAX_TITLE_LENGTH}
                        />
                        <IconButton
                          icon="PlainRemove"
                          iconProps={{
                            width: '16px',
                            height: '16px',
                            fillColor: Theme.Colors.typography.regular,
                          }}
                          onClick={() => setEditModeActive(false)}
                        />
                        <IconButton
                          type="submit"
                          icon="PlainCheck"
                          disabled={submitDisabled}
                          iconProps={{
                            width: '16px',
                            height: '16px',
                            fillColor: Theme.Colors.typography.regular,
                          }}
                        />
                      </Flex>
                    )
                  }}
                </Form>
              ) : isFetching ? (
                <Skeleton count={1} />
              ) : (
                <Flex gap={8} align="center">
                  <Typography
                    as="div"
                    translate="no"
                    variant="note"
                    style={{ minWidth: 0 }}
                    fontWeight={affiliateLinkData.title ? 600 : undefined}
                    color={affiliateLinkData.title ? undefined : 'inactive'}
                    fontStyle={affiliateLinkData.title ? undefined : 'italic'}
                  >
                    <TextEllipsis>
                      {affiliateLinkData.title ||
                        affiliateLinkData.brandName ||
                        t('brands:affiliate_links_list.no_title_placeholder')}
                    </TextEllipsis>
                  </Typography>

                  <IconButton
                    icon="EditPen"
                    iconProps={{
                      width: '16px',
                      height: '16px',
                      fillColor: Theme.Colors.typography.regular,
                    }}
                    onClick={() => setEditModeActive(true)}
                  />
                </Flex>
              )}
              <Box mb={4}>
                <StyledNonSelectableText>
                  <Typography variant="hint" color="inactive">
                    <TextEllipsis>{affiliateLinkData.originalUrl}</TextEllipsis>
                  </Typography>
                </StyledNonSelectableText>
              </Box>
              {!isCommissionSourceSplittingEnabled && commissionInfo && (
                <Typography variant="hint" as="span">
                  <span role="img" aria-label="$">
                    💰
                  </span>{' '}
                  {t(`brands:commission.${commissionInfo.type}`, {
                    commission: commissionInfo.commissionText,
                  })}
                </Typography>
              )}
            </StyledAffiliateLinkDescriptionContainer>
          </Flex>
          <UrlCopyField
            name={`url-${affiliateLinkData.id}`}
            url={creatorUrlBuilders.generateUtmShareURL({
              originalURL: affiliateLinkData.affiliateUrl,
            })}
            messages={{
              buttonText: t(COPY_BUTTON_MESSAGES_KEYS.buttonText),
              copiedTooltipText: t(COPY_BUTTON_MESSAGES_KEYS.copiedTooltipText),
            }}
          />
          <StyledAffiliateLinkActionButtonContainer>
            <StyledButton
              ref={shareRef}
              clickHandler={() =>
                shareRef.current && onShare(affiliateLinkData, shareRef.current)
              }
              inverse
            >
              <Flex gap={12} justify="center" align="center">
                <Icon.Share />
                {t('creator:share_page_button')}
              </Flex>
            </StyledButton>
            <StyledAddButton
              variant={ButtonVariant.OUTLINE}
              clickHandler={() => onAddToMyPage(affiliateLinkData)}
            >
              <Flex gap={8} justify="center" align="center">
                <Icon.PlusCircle />
                {t('brands:product_dialog.add_to_my_page_button')}
              </Flex>
            </StyledAddButton>
          </StyledAffiliateLinkActionButtonContainer>
          <Typography variant="hint" color="inactive">
            {t('brands:affiliate_links_list.affiliate_link_created_at', {
              date: DateHelpers.formatDateToText(affiliateLinkData.createdAt),
            })}
          </Typography>
        </Flex>
      </Card>
    </li>
  )
}
