import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactGA from 'react-ga4'
import { toast } from 'react-toastify'

import {
  Box,
  Button,
  ButtonVariant,
  Dialog,
  DialogProps,
  Flex,
  Icon,
  IconButton,
  Theme,
  Typography,
} from '@web-apps/ui-shared'
import {
  AffiliateLinkConversionData,
  ANALYTICS_CATEGORY,
  Brand,
} from '@web-apps/utils-types'

import { ProductForm } from '../../../../MyPage/SectionProductRecommendationsForm/ProductForm.component'
import { ProductFormType } from '../../../../MyPage/SectionProductRecommendationsForm/SectionProductionRecommendations.types'
import { useAccountDetails } from '../../../../../../utils/hooks/useAccountDetails.hooks'
import { useCreateAffiliateLinkAtomicMutation } from '../../../../../../services/affiliates.api'
import { AddToMyPage } from '../../../components/AddToMyPage'
import { ToastShareWrapper } from '../../../../../../components'

import { StyledWebsiteContainer } from './AddProductDialog.styles'

type AddProductDialogProps = Omit<
  DialogProps,
  'title' | 'children' | 'isOpen'
> & {
  selectedBrand?: Brand
  onSuccess: () => void
  onError: () => void
}

export const AddProductDialog = ({
  selectedBrand,
  onSuccess,
  onError,
  ...dialogProps
}: AddProductDialogProps) => {
  const { t } = useTranslation(['brands'])

  const { pageId } = useAccountDetails()

  const [
    requestAffiliateLinkCreation,
    {
      data: affiliateLinkCreationData,
      isLoading: isCreateAffiliateLinkLoading,
      isError: isCreateAffiliateLinkError,
    },
  ] = useCreateAffiliateLinkAtomicMutation()

  const dialogRef = useRef<HTMLDivElement>(null)
  const submittedFormIdRef = useRef<'affiliate-link' | 'section'>()
  const [productData, setProductData] = useState<{
    product: ProductFormType
    links: {
      createAffiliateLink: string
      addAsSection: string
      addToGrid: string
    }
  }>()

  const onSubmit = (
    { commissionMatchName, ...productData }: ProductFormType,
    inspectResponse: AffiliateLinkConversionData | undefined
  ) => {
    const match = inspectResponse?.matches.find(
      (item) => item.name === commissionMatchName
    )

    if (
      !inspectResponse ||
      !match ||
      !match.createApiEndpoint ||
      !match.createSectionApiEndpoint ||
      !inspectResponse.api.createContainerApiEndpoint
    ) {
      return toast.error('app:general_error')
    }

    if (submittedFormIdRef.current === 'affiliate-link') {
      requestAffiliateLinkCreation({
        uri: productData.href,
        createAffiliateLinkEndpoint: match.createApiEndpoint,
        pageId,
        pageTitle: productData.title,
        imageUrl: productData.image?.href ? productData.image.href : undefined,
        imageData: productData.image?.wasUserUpload
          ? productData.image
          : undefined,
      })
    } else if (submittedFormIdRef.current === 'section') {
      setProductData({
        product: productData,
        links: {
          createAffiliateLink: match.createApiEndpoint,
          addAsSection: match.createSectionApiEndpoint,
          addToGrid: inspectResponse.api.createContainerApiEndpoint,
        },
      })
    }
  }

  /* clearing it when the dialog is changed */
  useEffect(() => {
    if (selectedBrand) {
      submittedFormIdRef.current = undefined
      setProductData(undefined)
    }
  }, [selectedBrand])

  /* push the dialog scroll up when showing grids overlay */
  useEffect(() => {
    if (productData && dialogRef.current) {
      dialogRef.current.scrollTo({ top: 0 })
    }
  }, [productData])

  useEffect(() => {
    if (isCreateAffiliateLinkError) onError()
  }, [isCreateAffiliateLinkError, onError])

  useEffect(() => {
    if (affiliateLinkCreationData) {
      onSuccess()
      toast.success(
        <ToastShareWrapper
          message={t('brands:link_converter.dialog.sub-title')}
          href={affiliateLinkCreationData.affiliateUrl}
          analyticsEventProps={{
            actionNamePrefix: 'affiliates:creation:conversion_dialog',
            category: ANALYTICS_CATEGORY.AFFILIATES,
          }}
        />
      )
    }
  }, [affiliateLinkCreationData, onSuccess, t])

  return (
    <Dialog
      ref={dialogRef}
      title={
        productData
          ? ''
          : selectedBrand?.name || t('brands:product_dialog.fallback_title')
      }
      ariaLabel={selectedBrand?.name || t('brands:product_dialog.arial_label')}
      isOpen={Boolean(selectedBrand)}
      showHeaderBorder={false}
      showCloseButton={!productData}
      leftHeaderDecorator={
        productData ? (
          <IconButton
            title="<-"
            icon="ArrowBack"
            onClick={() => setProductData(undefined)}
            iconProps={{ fillColor: Theme.Colors.typography.light }}
          />
        ) : undefined
      }
      {...dialogProps}
    >
      {selectedBrand && (
        <>
          <div style={{ display: productData ? 'none' : 'block' }}>
            <StyledWebsiteContainer>
              <Flex direction="column" gap={16}>
                <Typography>
                  {t('brands:product_dialog.description')}
                </Typography>

                <Flex direction="column" gap={8}>
                  <Typography color="inactive" variant="hint">
                    {t('brands:product_dialog.website_label')}
                  </Typography>
                  <a
                    href={selectedBrand.websiteURL}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => {
                      ReactGA.event({
                        action: 'brand_collab_page:brand_website:click',
                        category: ANALYTICS_CATEGORY.AFFILIATES,
                        label: selectedBrand.websiteURL,
                      })
                    }}
                  >
                    <Flex gap={12} align="center">
                      <Typography variant="h4" as="span">
                        {selectedBrand.websiteURL}
                      </Typography>
                      <Icon.LinkExternal />
                    </Flex>
                  </a>
                </Flex>
              </Flex>
            </StyledWebsiteContainer>

            <Box mt={24}>
              <ProductForm
                productBrand={selectedBrand}
                affiliateConverterPath={
                  selectedBrand.affiliateConverter?.[0].href
                }
                initialValues={{
                  href: '',
                  title: '',
                }}
                onlyAffiliateAllowed
                handleSubmit={onSubmit}
                validationDescriptorsOverrides={{
                  title: {
                    required: t('app:field_is_required'),
                  },
                  image: undefined,
                }}
                renderActionsFooter={(formInfo) => (
                  <Flex direction="column" gap="16">
                    <Button
                      width="100%"
                      isLoading={
                        formInfo.isLoading || isCreateAffiliateLinkLoading
                      }
                      disabled={!formInfo.isLinkConverted}
                      clickHandler={() => {
                        submittedFormIdRef.current = 'section'
                        ReactGA.event({
                          action:
                            'brand_collab_page:add_to_my_page_button:click',
                          category: ANALYTICS_CATEGORY.AFFILIATES,
                          label: selectedBrand.name,
                        })
                      }}
                    >
                      <Flex gap={12} justify="center" align="center">
                        <Icon.PlusCircle
                          width={20}
                          height={20}
                          fillColor={
                            formInfo.isLinkConverted
                              ? Theme.Colors.typography.inverse
                              : Theme.Colors.typography.inactive
                          }
                        />
                        {t('brands:product_dialog.add_to_my_page_button')}
                      </Flex>
                    </Button>
                    <Button
                      width="100%"
                      disabled={!formInfo.isLinkConverted}
                      isLoading={
                        formInfo.isLoading || isCreateAffiliateLinkLoading
                      }
                      variant={ButtonVariant.OUTLINE}
                      clickHandler={() => {
                        submittedFormIdRef.current = 'affiliate-link'
                        ReactGA.event({
                          action: 'brand_collab_page:add_affiliate_link:click',
                          category: ANALYTICS_CATEGORY.AFFILIATES,
                          label: selectedBrand.name,
                        })
                      }}
                    >
                      <Flex gap={12} justify="center" align="center">
                        <Icon.Convert
                          width={20}
                          height={20}
                          fillColor={
                            formInfo.isLinkConverted
                              ? Theme.Colors.typography.title
                              : Theme.Colors.typography.inactive
                          }
                        />
                        {t('brands:product_dialog.convert_share_button')}
                      </Flex>
                    </Button>
                  </Flex>
                )}
              />
            </Box>
          </div>

          {productData && (
            <AddToMyPage
              product={productData.product}
              onSuccess={onSuccess}
              onError={onError}
              api={{
                createAffiliateLink: productData.links.createAffiliateLink,
                addToGrid: productData.links.addToGrid,
                addAsSection: productData.links.addAsSection,
              }}
            />
          )}
        </>
      )}
    </Dialog>
  )
}
