import React, { ButtonHTMLAttributes } from 'react'

import { Icon } from '../../atoms'
import { Theme } from '../../theme'
import { IconButton } from '../IconButton'
import { TextFieldProps, TextField } from '../TextField'

export type SearchFieldProps = Omit<
  TextFieldProps,
  'leftDecorator' | 'rightDecorator'
> & {
  onReset?: ButtonHTMLAttributes<HTMLButtonElement>['onClick']
  closeIconOverrideProps?: ButtonHTMLAttributes<HTMLButtonElement>
  searchIconOverrideProps?: ButtonHTMLAttributes<HTMLButtonElement>
}

export const SearchField = React.forwardRef<HTMLInputElement, SearchFieldProps>(
  (props, ref) => {
    return (
      <TextField
        {...props}
        ref={ref}
        leftDecorator={
          <Icon.Search {...(props.searchIconOverrideProps || {})} />
        }
        rightDecorator={
          props.value ? (
            <IconButton
              icon="Close"
              iconProps={{ fillColor: Theme.Colors.typography.regular }}
              onClick={props.onReset}
              {...(props.closeIconOverrideProps || {})}
            />
          ) : undefined
        }
      />
    )
  }
)
