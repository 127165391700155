import {
  StyledPanelWrapper,
  StyledSummariesContainer,
} from './AnalyticsMetricPanelsDashboard.styles'

import { MetricSummaryPanelProps } from '../MetricSummaryPanel/MetricSummaryPanel.types'
import { MetricSummaryPanel } from '..'

export type AnalyticsMetricPanelsDashboardProps = {
  panelsProps: Array<MetricSummaryPanelProps>
}

export const AnalyticsMetricPanelsDashboard = ({
  panelsProps,
}: AnalyticsMetricPanelsDashboardProps) => {
  return (
    <StyledSummariesContainer>
      {(panelsProps || []).map((panelProps) => (
        <StyledPanelWrapper key={panelProps.title}>
          <MetricSummaryPanel {...panelProps} />
        </StyledPanelWrapper>
      ))}
    </StyledSummariesContainer>
  )
}
