import React, { CSSProperties } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, ButtonSize, Box, Flex } from '@web-apps/ui-shared'
import { emptyFunctionForOptionals } from '@web-apps/utils-shared'

import { StyledHeaderButtonWrapper } from './FormActionsBar.styles'

type BoxProps = React.ComponentProps<typeof Box>

type Props = Omit<BoxProps, 'children'> & {
  primaryActionName?: string
  secondaryAction?: (event: React.MouseEvent<HTMLElement>) => void
  secondaryActionName?: string
  hideSecondaryAction?: boolean
  isLoading?: boolean
  disabled?: boolean
  hasHeaderSaveButton?: boolean
  justifyContent?: CSSProperties['justifyContent']
}

export const FormActionsBar = ({
  primaryActionName,
  secondaryAction = emptyFunctionForOptionals,
  secondaryActionName = '',
  hideSecondaryAction,
  isLoading,
  disabled = false,
  hasHeaderSaveButton,
  justifyContent = 'flex-end',
  ...boxProps
}: Props) => {
  const { t } = useTranslation('app')

  return (
    <Box mt={24} mb={48} {...boxProps}>
      <Flex gap={24} justify={justifyContent}>
        {!hideSecondaryAction && (
          <Button inverse clickHandler={secondaryAction} type="button">
            {secondaryActionName}
          </Button>
        )}
        <Button type="submit" isLoading={isLoading} disabled={disabled}>
          {primaryActionName || t('app:button_save')}
        </Button>
        {hasHeaderSaveButton && (
          <StyledHeaderButtonWrapper>
            <Button
              size={ButtonSize.SLIM}
              type="submit"
              isLoading={isLoading}
              disabled={disabled}
            >
              {primaryActionName || t('app:button_save')}
            </Button>
          </StyledHeaderButtonWrapper>
        )}
      </Flex>
    </Box>
  )
}
