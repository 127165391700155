import React from 'react'

import { PeriodsEnum } from './hooks/useSelectorParams'

import {
  CommissionsTimeseriesPoint,
  CreatorPageSimpleAnalyticsAPIPayload,
  ExternalLinkMode,
  LinkAPIType,
  SectionTypeEnum,
  SectionVariantEnum,
  SocialNetworksEnum,
} from '@web-apps/utils-types'
import { Icon } from '@web-apps/ui-shared'
import { DateHelpers, Money } from '@web-apps/utils-shared'

const DATE_TIME_FORMATTER_FOR_PERIOD: {
  [key in string]: (date: string) => string
} = {
  [PeriodsEnum.BY_MONTH]: DateHelpers.formatDateToDayMonth,
  [PeriodsEnum.CUSTOM]: DateHelpers.formatDateToDayMonth,
  [PeriodsEnum.DAYS_30]: DateHelpers.formatDateToDayMonth,
  [PeriodsEnum.DAYS_7]: DateHelpers.formatDateToDayMonth,
  [PeriodsEnum.MONTHS_12]: DateHelpers.formatDateMonthOnly,
  [PeriodsEnum.MONTHS_6]: DateHelpers.formatDateMonthOnly,
  [PeriodsEnum.REALTIME]: DateHelpers.formatDateToDayMonth,
}

const DATE_TIME_TOOLTIP_FORMATTER_FOR_PERIOD: {
  [key in string]: (date: string) => string
} = {
  [PeriodsEnum.BY_MONTH]: DateHelpers.formatDateToText,
  [PeriodsEnum.CUSTOM]: DateHelpers.formatDateToText,
  [PeriodsEnum.DAYS_30]: DateHelpers.formatDateToText,
  [PeriodsEnum.DAYS_7]: DateHelpers.formatDateToText,
  [PeriodsEnum.MONTHS_12]: DateHelpers.formatDateToMonthYear,
  [PeriodsEnum.MONTHS_6]: DateHelpers.formatDateToMonthYear,
  [PeriodsEnum.REALTIME]: DateHelpers.formatDateToText,
}

export const formatChartDate = (date: string, period: string): string => {
  const formatter =
    DATE_TIME_FORMATTER_FOR_PERIOD[period] || DateHelpers.formatDateToDayMonth

  return formatter(date)
}

export const formatChartTooltipDate = (
  date: string,
  period: string
): string => {
  const formatter =
    DATE_TIME_TOOLTIP_FORMATTER_FOR_PERIOD[period] ||
    DateHelpers.formatDateToDayMonth

  return formatter(date)
}

export const DEFAULT_ICON_FOR_SECTION_TYPE = Icon.Link
export const IconComponentsForSectionType: {
  [key in SectionTypeEnum]?: React.FC<React.PropsWithChildren<unknown>>
} = {
  [SectionTypeEnum.EXTERNAL_LINK]: DEFAULT_ICON_FOR_SECTION_TYPE,
}

export const advancedAnalyticsForbiddenMock: Omit<
  CreatorPageSimpleAnalyticsAPIPayload,
  'aggregate' | 'timeseries'
> = {
  social_link_clicks: [
    {
      type: SocialNetworksEnum.Instagram,
      events: 6,
    },
    {
      type: SocialNetworksEnum.TikTok,
      events: 4,
    },
    {
      type: SocialNetworksEnum.Twitter,
      events: 2,
    },
    {
      type: SocialNetworksEnum.YouTube,
      events: 1,
    },
  ],
  section_clicks: [
    {
      events: 6,
      section: {
        mode: ExternalLinkMode.LINK,
        styles: {
          variant: SectionVariantEnum.PLAIN,
        },
        type: SectionTypeEnum.EXTERNAL_LINK,
        published: true,
        label: 'zezam.io',
        _links: {
          about: {
            href: 'https://zezam.io',
          },
          parent: {
            id: '',
            href: '',
          },
          author: {
            id: 'zezam-io',
            href: 'https://zezam.io',
            name: 'zezam.io',
          },
          self: {
            id: 'zezam-io',
            href: 'https://zezam.io',
          },
          'self/edit-image': { href: 'https://zezam.io' },
        },
      },
    },
    {
      events: 8,
      section: {
        mode: ExternalLinkMode.LINK,
        styles: {
          variant: SectionVariantEnum.PLAIN,
        },
        type: SectionTypeEnum.EXTERNAL_LINK,
        label: 'zez.am',
        published: true,
        _links: {
          about: {
            href: 'https://zez.am',
          },
          parent: {
            id: '',
            href: '',
          },
          author: {
            id: 'zezam',
            href: 'https://zez.am',
            name: 'zezam',
          },
          self: {
            id: 'zezam',
            href: 'https://zez.am',
          },
          'self/edit-image': { href: 'https://zezam.io' },
        },
      },
    },
  ],
  sources: [
    {
      source: 'https://zezam.io',
      pageviews: 55,
      visitors: 40,
    },
    {
      source: 'https://zez.am/johndoe',
      pageviews: 3,
      visitors: 1,
    },
  ],
  countries: [
    {
      country: 'DE',
      pageviews: 5,
      visitors: 2,
    },
    {
      country: 'US',
      pageviews: 15,
      visitors: 8,
    },
    {
      country: 'PT',
      pageviews: 1,
      visitors: 1,
    },
    {
      country: 'IT',
      pageviews: 3,
      visitors: 1,
    },
  ],
}

export const toURLWithParams = ({
  apiLink,
  period,
}: {
  apiLink?: LinkAPIType
  period?: string
}) => {
  const urlObject = apiLink?.href ? new URL(apiLink.href) : undefined

  if (period && urlObject) {
    if (urlObject.searchParams.has('period')) {
      urlObject.searchParams.set('period', period)
    } else {
      urlObject.searchParams.append('period', period)
    }
  }

  return urlObject?.toString()
}

export const transformTimeseriesPointToChartData = (
  point: CommissionsTimeseriesPoint
) => {
  const expectedAmount =
    (point.stats.earnings.approved.amount || 0) +
    (point.stats.earnings.pending.amount || 0)

  return {
    name: point.date,
    clicks: point.stats.clicks,
    sales: point.stats.sales,
    commissions: expectedAmount,
    commissionsLabel: Money.formatPrice({
      amount: expectedAmount,
      currency: point.stats.earnings.approved.currency,
    }),
  }
}
