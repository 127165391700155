import React, { useCallback } from 'react'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

import { SectionType, SectionTypeEnum } from '@web-apps/utils-types'
import { creatorUrlBuilders } from '@web-apps/utils-shared'

import { ToastShareWrapper } from '../../../../../components'
import { useAccountDetails } from '../../../../../utils/hooks/useAccountDetails.hooks'
import { AddProductActionVariant } from '../../../MyPage/MyPage.types'

type UseNotificationProps = {
  onSuccess: () => void
  onError: () => void
}
export const useNotification = ({
  onSuccess,
  onError,
}: UseNotificationProps) => {
  const { slug } = useAccountDetails()
  const { t } = useTranslation(['brands'])

  const onAddProductSuccess = useCallback(
    (actionVariant: AddProductActionVariant, addedSection: SectionType) => {
      onSuccess()

      const sharedURL =
        addedSection.type === SectionTypeEnum.CONTAINER &&
        (addedSection.items || []).length
          ? addedSection.items[0]._links.alternate?.href ||
            addedSection.items[0]._links.about?.href
          : addedSection._links.alternate?.href ||
            addedSection._links.about?.href

      toast.success(
        <ToastShareWrapper
          href={creatorUrlBuilders.generateUtmShareURL({
            originalURL:
              sharedURL ||
              creatorUrlBuilders.creatorUrlBuilder({ creatorSlug: slug }),
          })}
          message={t(`brands:product_dialog.success_messages.${actionVariant}`)}
        />
      )
    },
    [slug, onSuccess, t]
  )

  const onAddProductError = useCallback(
    (actionVariant: AddProductActionVariant) => {
      onError()
      toast.error(t(`brands:product_dialog.error_messages.${actionVariant}`))
    },
    [t, onError]
  )

  return { onAddProductSuccess, onAddProductError }
}
