import styled from 'styled-components'

export const StyledIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 56px;
  height: 56px;
  border-radius: 8px;
  background: rgba(239, 164, 144, 0.2);
`
