import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import ReactGA from 'react-ga4'
import { useTranslation } from 'react-i18next'
import {
  useLocation,
  useNavigate,
  useParams,
  useMatch,
  useSearchParams,
} from 'react-router-dom'
import { useFlag } from '@unleash/proxy-client-react'

import {
  Backdrop,
  Box,
  Button,
  Flex,
  HorizontalButtonsFilter,
  HorizontalButtonsFilterVariant,
  Icon,
  SearchFieldList,
  Typography,
} from '@web-apps/ui-shared'
import { computeCommissionToShowFromAggregate } from '@web-apps/utils-shared'
import { ANALYTICS_CATEGORY, Brand } from '@web-apps/utils-types'

import { ROUTES } from '../../../../routes/RouteEnums'
import {
  useGetTopBrandsQuery,
  useGetBrandsQuery,
  useGetBrandQuery,
} from '../../../../services/affiliates.api'
import {
  BrandsListItemSkeleton,
  SkeletonWrapper,
} from '../../../../components/skeletons'

import { useAccountDetails } from '../../../../utils/hooks/useAccountDetails.hooks'
import { useGetBrandsFilters } from '../BrandCollaborations.hooks'
import { AddProductDialog } from './components/AddProductDialog'

import {
  StyledLayerBehindSearchResults,
  StyledNoResultsContainer,
  StyledTopBrandsContainer,
} from './BrandsPage.styles'
import {
  FLAG_BRAND_MODAL,
  FLAG_COMMISSION_SOURCE_SPLITTING,
} from '../../../../utils/constants/flags.constants'
import { BrandCard } from './components/BrandCard'
const MIN_SEARCH_TERM_LENGTH = 2

export const BrandsPage = () => {
  const { t } = useTranslation(['brands'], { useSuspense: false })
  const { brandId } = useParams<{ brandId: string }>()
  const {
    userId,
    region,
    apiConfigs: { endpointPaths },
  } = useAccountDetails()

  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const isProductDialogOpen = useMatch(ROUTES.BRAND_DIALOG_MODAL)
  const isCommissionSourceSplittingEnabled = useFlag(
    FLAG_COMMISSION_SOURCE_SPLITTING
  )
  const isBrandModalEnabled = useFlag(FLAG_BRAND_MODAL)
  const { filterParams, setFilterParams } = useGetBrandsFilters()
  const searchRef = useRef<HTMLInputElement>(null)
  const [searchText, setSearchText] = useState('')
  const [debouncedSearchText, setDebouncedSearchText] = useState('')
  const [isSearchResultsListExpanded, setSearchResultsListExpanded] =
    useState(false)

  const [searchedResults, setSearchedResults] = useState<Brand[]>()

  const onSearchResultsExpandedStateChange = useCallback(
    (isExpanded: boolean) => setSearchResultsListExpanded(isExpanded),
    []
  )

  const {
    isError: topBrandsQueryFailed,
    isFetching: isLoadingTopBrandsQuery,
    data: topBrands,
  } = useGetTopBrandsQuery(
    { ...filterParams, path: endpointPaths.topBrands },
    { skip: !region }
  )

  const {
    data: brandsData,
    originalArgs,
    isFetching: isSearchingBrands,
  } = useGetBrandsQuery(
    {
      searchTerm: debouncedSearchText,
      path: endpointPaths.brandsSearch,
    },
    { skip: !debouncedSearchText }
  )
  const {
    data: brandData,
    isFetching: isBrandLoading,
    isError: isBrandQueryFailed,
  } = useGetBrandQuery(
    {
      brandId: brandId as string,
      userId,
    },
    { skip: !brandId }
  )

  const filterData = useMemo(() => {
    const { cpaMetric, cpcMetric } = filterParams

    return [
      {
        key: 0,
        title: (
          <Flex gap={8}>
            <Flex>
              <Icon.CommissionPerClick />
              <Icon.Shop />
            </Flex>
            {t('app:header.brands.brands_page.filters.all')}
          </Flex>
        ),
        value: { cpaMetric: false, cpcMetric: false },
        isActive: !cpaMetric && !cpcMetric,
      },
      {
        key: 1,
        title: (
          <Flex gap={8}>
            <Icon.CommissionPerClick />
            {t('app:header.brands.brands_page.filters.per_click')}
          </Flex>
        ),
        value: { cpaMetric: false, cpcMetric: true },
        isActive: cpcMetric,
      },
      {
        key: 2,
        title: (
          <Flex gap={8}>
            <Icon.Shop />
            {t('app:header.brands.brands_page.filters.per_order')}
          </Flex>
        ),
        value: { cpaMetric: true, cpcMetric: false },
        isActive: cpaMetric,
      },
    ]
  }, [filterParams, t])

  const handleFilterChange = useCallback(
    (value: { cpaMetric: boolean; cpcMetric: boolean }) =>
      setFilterParams(value),
    [setFilterParams]
  )

  const brandResults = useMemo(
    () =>
      isSearchingBrands
        ? undefined
        : searchedResults?.map((b) => {
            const { name, id, commission, websiteURL } = b
            const commissionInfo =
              computeCommissionToShowFromAggregate(commission)

            return {
              key: isBrandModalEnabled ? id : websiteURL || id,
              value: name,
              ...(!isCommissionSourceSplittingEnabled && {
                subtitleText: t(`brands:commission.${commissionInfo.type}`, {
                  commission: commissionInfo.commissionText,
                }),
              }),
            }
          }),
    [
      searchedResults,
      isSearchingBrands,
      isBrandModalEnabled,
      isCommissionSourceSplittingEnabled,
      t,
    ]
  )

  /**
   * originalArgs need to be in array dependencies, because we want to update results even if current results does not change
   * for example if we remove originalArgs
   *  - search for asos
   *  - go back to clear all the text
   *  - search again for asos
   *  - brandsData will not change because it contains last request from asos search
   *  - having originalArgs will force it to re-update even for those cases
   **/
  useEffect(() => {
    setSearchedResults(brandsData?.brands)
  }, [brandsData, originalArgs])

  useEffect(() => {
    if (topBrandsQueryFailed) {
      toast.error(t('brands:errors.top_offers_query'))
    }

    if (isBrandQueryFailed) {
      toast.error(t('brands:errors.brand_query'))
    }
  }, [topBrandsQueryFailed, isBrandQueryFailed, t])

  const onDismissProductDialog = useCallback(() => {
    const from = searchParams.get('from')

    navigate(from ? from : `${ROUTES.BRANDS}${location.search}`)
  }, [navigate, location, searchParams])

  return (
    <>
      {isSearchResultsListExpanded && <StyledLayerBehindSearchResults />}
      {isBrandLoading && <Backdrop isLoading />}
      {isProductDialogOpen && !isBrandLoading && (
        <AddProductDialog
          selectedBrand={brandData}
          onDismiss={onDismissProductDialog}
          onError={onDismissProductDialog}
          onSuccess={onDismissProductDialog}
        />
      )}
      <SearchFieldList
        ref={searchRef}
        name="brands-search"
        placeholder={t('app:header.brands.brands_page.placeholder')}
        value={searchText}
        style={
          /* these override styles are needed to make sure input is in front of StyledLayerBehindSearchResults above */
          {
            position: 'relative',
            zIndex: 1,
          }
        }
        closeIconOverrideProps={{
          /* these override styles are needed to make sure input is in front of StyledLayerBehindSearchResults above */
          style: {
            zIndex: 2,
          },
        }}
        searchIconOverrideProps={{
          /* these override styles are needed to make sure input is in front of StyledLayerBehindSearchResults above */
          style: {
            zIndex: 2,
          },
        }}
        onOptionSelect={(item) => {
          setSearchedResults(undefined)
          ReactGA.event({
            action: 'brand_collab_page:brand_search_item:click',
            category: ANALYTICS_CATEGORY.AFFILIATES,
          })
          if (isBrandModalEnabled) {
            navigate(`/brands/${item.key}${location.search}`)
          } else {
            window.open(item.key, '_blank')
          }
        }}
        onChange={({ target: { value } }) => {
          setSearchText(value)
          setDebouncedSearchText('')
          setSearchedResults(undefined)
        }}
        debouncedMsc={250}
        debouncedCallback={(value) => {
          if (value.length >= MIN_SEARCH_TERM_LENGTH) {
            setDebouncedSearchText(value)
          }
        }}
        resultItems={brandResults}
        onReset={() => {
          setSearchText('')
          setSearchedResults(undefined)

          searchRef.current?.focus()
        }}
        onFocus={() => {
          ReactGA.event({
            action: 'brand_collab_page:search_brand_input:focus',
            category: ANALYTICS_CATEGORY.AFFILIATES,
          })
        }}
        noResultsInfo={
          <StyledNoResultsContainer>
            <Flex justify="center" align="center" direction="column">
              <Typography variant="h4">
                {t('brands:search.no_results.message')}
              </Typography>
              <Box mt={8}>
                <Typography variant="hint" color="inactive">
                  {t('brands:search.no_results.info')}
                </Typography>
              </Box>
            </Flex>
          </StyledNoResultsContainer>
        }
        onExpandedStateChange={onSearchResultsExpandedStateChange}
      />
      <div
        style={{
          opacity: isSearchResultsListExpanded ? 0.05 : 1,
        }}
      >
        <Box mt={48}>
          <Box mb={24}>
            <HorizontalButtonsFilter
              variant={HorizontalButtonsFilterVariant.LARGE}
              filterData={filterData}
              handleChange={handleFilterChange}
            />
          </Box>
          <Box
            data-testid="top-brands-container"
            onClick={() => {
              ReactGA.event({
                action: 'brand_collab_page:curated_brand:click',
                label: 'top',
                category: ANALYTICS_CATEGORY.AFFILIATES,
              })
            }}
          >
            <StyledTopBrandsContainer>
              <SkeletonWrapper
                Skeleton={BrandsListItemSkeleton}
                isLoading={isLoadingTopBrandsQuery}
                asList
                itemsAmount={topBrands?.length || 12}
                showEmptyState={topBrands && topBrands.length === 0}
              >
                {topBrands?.map((b) => (
                  <BrandCard brand={b} key={b.id} />
                ))}
              </SkeletonWrapper>
            </StyledTopBrandsContainer>
          </Box>
        </Box>
      </div>
    </>
  )
}
