import { useTranslation } from 'react-i18next'
import { Flex } from '@web-apps/ui-shared'
import {
  Footer,
  CreatorThemeWrapper,
  computeBackgroundInfoForStyles,
  initialValuesForCustomization,
} from '@web-apps/feature-creator-page'
import {
  CustomizationPropsType,
  CreatorPageType,
  CustomizationFormProperties,
} from '@web-apps/utils-types'
import { CreatorPageContainer } from '../CreatorPage'
import { StyledContainer } from './PreviewContent.styles'

type Props = {
  creatorPage: CreatorPageType
}

export const PreviewContent = ({ creatorPage }: Props) => {
  const { t } = useTranslation(['app'])
  const backgroundInfo = computeBackgroundInfoForStyles(creatorPage)
  const customization: CustomizationPropsType = {
    ...(Object.values(creatorPage.styles || {}).length
      ? creatorPage.styles
      : initialValuesForCustomization),
    // it is possible that background_type is not defined due to not being sent to API in earlier usage
    [CustomizationFormProperties.BACKGROUND_TYPE]:
      backgroundInfo.backgroundType,
  }

  return (
    <CreatorThemeWrapper customizationProps={customization}>
      <StyledContainer direction="column">
        <Flex flex={1}>
          <CreatorPageContainer creatorPage={creatorPage} />
        </Flex>
        <Footer
          title={t('app:footer_title')}
          url="https://zezam.io"
          showBanner={false}
          showClassic={true}
        />
      </StyledContainer>
    </CreatorThemeWrapper>
  )
}
