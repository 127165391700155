import { useState } from 'react'

type UsePasswordHiderResult = {
  isPasswordVisible: boolean
  togglePasswordVisibility: () => void
}

export const usePasswordHider = (): UsePasswordHiderResult => {
  const [isPasswordVisible, setPasswordVisible] = useState(false)

  return {
    isPasswordVisible,
    togglePasswordVisibility: () => {
      setPasswordVisible(
        (previousIsPasswordVisible) => !previousIsPasswordVisible
      )
    },
  }
}
