import { TFunction } from 'next-i18next'

import {
  Icon,
  richTextEditorSanitizer,
  ValidationSchema,
} from '@web-apps/ui-shared'
import { FormValidations } from '@web-apps/utils-shared'
import { colorsInitialValues } from '@web-apps/feature-creator-page'
import {
  ButtonShadowStyle,
  ColorOpacity,
  ButtonThumbnailPositionStyle,
  MyPageAppearanceForm,
  BackgroundTypeStyle,
  BackgroundType,
  ButtonBorderStyle,
  ButtonShapeStyle,
  SectionVariantEnum,
  CustomizationFormProperties,
  HeaderPosition,
  CustomizationPropsType,
  FontEnum,
  ImageFormType,
  ProfileImageShape,
  SectionCoverFormat,
} from '@web-apps/utils-types'
import { hexRegex } from '../../../../utils/constants/regex.constants'

import { ReactComponent as ButtonPillIcon } from '../../../../assets/icons/button-shape-pill.svg'
import { ReactComponent as ButtonRoundIcon } from '../../../../assets/icons/button-shape-round.svg'
import { ReactComponent as ButtonSquareIcon } from '../../../../assets/icons/button-shape-rectangle.svg'
import { ReactComponent as HeaderCenterIcon } from '../../../../assets/icons/header-style-center.svg'
import { ReactComponent as HeaderLeftIcon } from '../../../../assets/icons/header-style-left.svg'
import { ReactComponent as ProfileImageCircleIcon } from '../../../../assets/icons/profile-image-circle.svg'
import { ReactComponent as ProfileImageSquareIcon } from '../../../../assets/icons/profile-image-square.svg'
import { ReactComponent as ProfileImageDiamondIcon } from '../../../../assets/icons/profile-image-diamond.svg'
import { ReactComponent as ButtonPlainIcon } from '../../../../assets/icons/button-style-plain.svg'
import { ReactComponent as ButtonCoverIcon } from '../../../../assets/icons/button-style-cover.svg'
import { ReactComponent as ButtonCoverLandscapeIcon } from '../../../../assets/icons/button-cover-landscape.svg'
import { ReactComponent as ButtonCoverSquareIcon } from '../../../../assets/icons/button-cover-square.svg'
import { ReactComponent as ButtonCoverPortraitIcon } from '../../../../assets/icons/button-cover-portrait.svg'
import { ReactComponent as ButtonImageIcon } from '../../../../assets/icons/button-style-with-image.svg'
import { ReactComponent as ButtonShadowNoShadow } from '../../../../assets/icons/button-shadow-no-shadow.svg'
import { ReactComponent as ButtonShadowSoft } from '../../../../assets/icons/button-shadow-soft.svg'
import { ReactComponent as ButtonShadowHard } from '../../../../assets/icons/button-shadow-hard.svg'
import { ReactComponent as ButtonThumbnailRegular } from '../../../../assets/icons/button-thumbnail-regular.svg'
import { ReactComponent as ButtonThumbnailLeading } from '../../../../assets/icons/button-thumbnail-leading.svg'
import { ReactComponent as ButtonThumbnailStickOut } from '../../../../assets/icons/button-thumbnail-stick-out.svg'
import { ReactComponent as ButtonFillFilled } from '../../../../assets/icons/button-fill-filled.svg'
import { ReactComponent as ButtonFillOutlined } from '../../../../assets/icons/button-fill-outlined.svg'
import { StyledFontOptionIcon } from './MyPageAppearance.styles'

type RadioOptionsType = {
  label?: string
  value: string
  icon?: JSX.Element
}

type CustomizationConfigsType = {
  buttonBorderStyleValues: RadioOptionsType[]
  buttonVariantStyleValues: RadioOptionsType[]
  buttonCoverStyleValues: RadioOptionsType[]
  headerPositionStyleValues: RadioOptionsType[]
  buttonShapeStyleValues: RadioOptionsType[]
  backgroundStylesOptions: RadioOptionsType[]
  backgroundEffectsOptions: RadioOptionsType[]
  profileImageShapeOptions: RadioOptionsType[]
  buttonShadowStyleValues: RadioOptionsType[]
  buttonOpacityValues: RadioOptionsType[]
  buttonThumbnailPositionValues: RadioOptionsType[]
  fontOptions: RadioOptionsType[]
}

export const generateCustomisationData = (
  t: TFunction,
  showOldDesignOptions = true
): CustomizationConfigsType => {
  const buttonBorderStyleValues = [
    {
      label: t('creator:design_customisation.button_fill_style.filled'),
      value: ButtonBorderStyle.FILLED,
      icon: <ButtonFillFilled />,
    },
    {
      label: t('creator:design_customisation.button_fill_style.outline'),
      value: ButtonBorderStyle.OUTLINE,
      icon: <ButtonFillOutlined />,
    },
  ]

  const buttonShapeStyleValues = [
    {
      label: t('creator:design_customisation.button_form_style.pill'),
      value: ButtonShapeStyle.PILL,
      icon: <ButtonPillIcon />,
    },
    {
      label: t('creator:design_customisation.button_form_style.rounded'),
      value: ButtonShapeStyle.ROUNDED,
      icon: <ButtonRoundIcon />,
    },
    {
      label: t('creator:design_customisation.button_form_style.square'),
      value: ButtonShapeStyle.SQUARE,
      icon: <ButtonSquareIcon />,
    },
  ]

  const buttonVariantStyleValues = [
    {
      label: t('creator:button_visual_style_plain'),
      value: SectionVariantEnum.PLAIN,
      icon: <ButtonPlainIcon />,
    },
    {
      label: t('creator:button_visual_style_image'),
      value: SectionVariantEnum.IMAGE,
      icon: <ButtonImageIcon />,
    },
    {
      label: t('creator:button_visual_style_cover'),
      value: SectionVariantEnum.COVER,
      icon: <ButtonCoverIcon />,
    },
  ]

  const buttonCoverStyleValues = [
    {
      label: t('creator:button_visual_style_cover_format.types.landscape'),
      value: SectionCoverFormat.LANDSCAPE,
      icon: <ButtonCoverLandscapeIcon />,
    },
    {
      label: t('creator:button_visual_style_cover_format.types.square'),
      value: SectionCoverFormat.SQUARE,
      icon: <ButtonCoverSquareIcon />,
    },
    {
      label: t('creator:button_visual_style_cover_format.types.portrait'),
      value: SectionCoverFormat.PORTRAIT,
      icon: <ButtonCoverPortraitIcon />,
    },
  ]

  const buttonThumbnailPositionValues = [
    {
      label: t('creator:design_customisation.button_thumbnail_style.regular'),
      value: ButtonThumbnailPositionStyle.REGULAR,
      icon: <ButtonThumbnailRegular />,
    },
  ]

  const buttonShadowStyleValues = [
    {
      label: t('creator:design_customisation.button_shadow.no_shadow'),
      value: ButtonShadowStyle.NO_SHADOW,
      icon: <ButtonShadowNoShadow />,
    },
    {
      label: t('creator:design_customisation.button_shadow.soft'),
      value: ButtonShadowStyle.SOFT,
      icon: <ButtonShadowSoft />,
    },
  ]

  const buttonOpacityValues = [
    {
      label: t('creator:design_customisation.button_opacity.10'),
      value: ColorOpacity['10%'],
    },
    {
      label: t('creator:design_customisation.button_opacity.20'),
      value: ColorOpacity['20%'],
    },
    {
      label: t('creator:design_customisation.button_opacity.40'),
      value: ColorOpacity['40%'],
    },
    {
      label: t('creator:design_customisation.button_opacity.60'),
      value: ColorOpacity['60%'],
    },
    {
      label: t('creator:design_customisation.button_opacity.80'),
      value: ColorOpacity['80%'],
    },
    {
      label: t('creator:design_customisation.button_opacity.100'),
      value: ColorOpacity['100%'],
    },
  ]

  const headerPositionStyleValues = [
    {
      label: t('creator:design_customisation.header_alignment.center'),
      value: HeaderPosition.CENTER,
      icon: <HeaderCenterIcon />,
    },
  ]

  const profileImageShapeOptions = [
    {
      label: t('creator:design_customisation.profile_image_shape.circle'),
      value: ProfileImageShape.CIRCLE,
      icon: <ProfileImageCircleIcon />,
    },
  ]

  const backgroundStylesOptions = [
    {
      label: t('creator:customization_background_options.solid'),
      icon: <Icon.BgStyleSolid />,
      value: BackgroundType.Solid,
    },
    {
      label: t('creator:customization_background_options.gradient'),
      icon: <Icon.BgStyleGradient />,
      value: BackgroundType.Gradient,
    },
  ]

  const backgroundEffectsOptions = [
    {
      label: t('creator:customization_background_style_options.regular'),
      icon: <Icon.BgEffectNone />,
      value: BackgroundTypeStyle.REGULAR,
    },
    {
      label: t('creator:customization_background_style_options.faded_dark'),
      icon: <Icon.BgEffectFadedDark />,
      value: BackgroundTypeStyle.FADED_DARK,
    },
    {
      label: t('creator:customization_background_style_options.faded_light'),
      icon: <Icon.BgEffectFadedLight />,
      value: BackgroundTypeStyle.FADED_LIGHT,
    },
    {
      label: t('creator:customization_background_style_options.blurred_dark'),
      icon: <Icon.BgEffectBlurredDark />,
      value: BackgroundTypeStyle.BLURRED_DARK,
    },
    {
      label: t('creator:customization_background_style_options.blurred_light'),
      icon: <Icon.BgEffectBlurredLight />,
      value: BackgroundTypeStyle.BLURRED_LIGHT,
    },
  ]

  const fontOptions = [
    {
      icon: (
        <StyledFontOptionIcon $fontFamily={FontEnum.INTER}>
          {t('creator:font_options.aa') as string}
        </StyledFontOptionIcon>
      ),
      value: FontEnum.INTER,
    },
    {
      icon: (
        <StyledFontOptionIcon $fontFamily={FontEnum.ROBOTO_SLAB}>
          {t('creator:font_options.aa') as string}
        </StyledFontOptionIcon>
      ),
      value: FontEnum.ROBOTO_SLAB,
    },
    {
      label: '',

      icon: (
        <StyledFontOptionIcon $fontFamily={FontEnum.OSWALD}>
          {t('creator:font_options.aa') as string}
        </StyledFontOptionIcon>
      ),
      value: FontEnum.OSWALD,
    },
    {
      icon: (
        <StyledFontOptionIcon $fontFamily={FontEnum.KALAM}>
          {t('creator:font_options.aa') as string}
        </StyledFontOptionIcon>
      ),
      value: FontEnum.KALAM,
    },
    {
      icon: (
        <StyledFontOptionIcon $fontFamily={FontEnum.AMATIC_SC}>
          {t('creator:font_options.aa') as string}
        </StyledFontOptionIcon>
      ),
      value: FontEnum.AMATIC_SC,
    },
    {
      icon: (
        <StyledFontOptionIcon $fontFamily={FontEnum.PLAYFAIR_DISPLAY}>
          {t('creator:font_options.aa') as string}
        </StyledFontOptionIcon>
      ),
      value: FontEnum.PLAYFAIR_DISPLAY,
    },
  ]

  if (showOldDesignOptions) {
    buttonThumbnailPositionValues.push(
      ...[
        {
          label: t(
            'creator:design_customisation.button_thumbnail_style.leading'
          ),
          value: ButtonThumbnailPositionStyle.LEADING,
          icon: <ButtonThumbnailLeading />,
        },
        {
          label: t(
            'creator:design_customisation.button_thumbnail_style.stick_out'
          ),
          value: ButtonThumbnailPositionStyle.STICK_OUT,
          icon: <ButtonThumbnailStickOut />,
        },
      ]
    )
    buttonShadowStyleValues.push({
      label: t('creator:design_customisation.button_shadow.hard'),
      value: ButtonShadowStyle.HARD,
      icon: <ButtonShadowHard />,
    })
    headerPositionStyleValues.push({
      label: t('creator:design_customisation.header_alignment.left'),
      value: HeaderPosition.LEFT,
      icon: <HeaderLeftIcon />,
    })
    profileImageShapeOptions.push(
      ...[
        {
          label: t('creator:design_customisation.profile_image_shape.square'),
          value: ProfileImageShape.SQUARE,
          icon: <ProfileImageSquareIcon />,
        },
        {
          label: t('creator:design_customisation.profile_image_shape.diamond'),
          value: ProfileImageShape.DIAMOND,
          icon: <ProfileImageDiamondIcon />,
        },
      ]
    )
    backgroundStylesOptions.push(
      ...[
        {
          label: t('creator:customization_background_options.image'),
          icon: <Icon.BgStyleCover />,
          value: BackgroundType.Image,
        },
        {
          label: t('creator:customization_background_options.header'),
          icon: <Icon.BgStyleHeader />,
          value: BackgroundType.Header,
        },
      ]
    )
  }

  return {
    buttonBorderStyleValues,
    buttonVariantStyleValues,
    buttonCoverStyleValues,
    headerPositionStyleValues,
    profileImageShapeOptions,
    buttonShapeStyleValues,
    buttonThumbnailPositionValues,
    backgroundStylesOptions,
    backgroundEffectsOptions,
    buttonShadowStyleValues,
    buttonOpacityValues,
    fontOptions,
  }
}

export const validateRadioButtons = ({
  customizationData,
  customizationConfigs,
}: {
  customizationData: CustomizationPropsType
  customizationConfigs: Omit<
    CustomizationConfigsType,
    | 'backgroundStylesOptions'
    | 'backgroundEffectsOptions'
    | 'buttonShadowStyleValues'
    | 'buttonOpacityValues'
    | 'buttonThumbnailPositionValues'
    | 'buttonCoverStyleValues'
  >
}): CustomizationPropsType => {
  const validatedCustomizationData = { ...customizationData }

  // Since this property is placed in the different form we don't want to have it  in case it would be changed
  delete validatedCustomizationData[
    CustomizationFormProperties.SOCIALS_PLACEMENT
  ]

  // Forcing correct option if option defined is non-existent
  if (
    !customizationConfigs.headerPositionStyleValues
      .map((o) => o.value)
      .includes(
        validatedCustomizationData[CustomizationFormProperties.HEADER_POSITION]
      )
  ) {
    validatedCustomizationData[CustomizationFormProperties.HEADER_POSITION] =
      customizationConfigs.headerPositionStyleValues[0]?.value as HeaderPosition
  }

  if (
    !customizationConfigs.buttonShapeStyleValues
      .map((o) => o.value)
      .includes(
        validatedCustomizationData[CustomizationFormProperties.BUTTONS_SHAPE]
      )
  ) {
    validatedCustomizationData[CustomizationFormProperties.BUTTONS_SHAPE] =
      customizationConfigs.buttonShapeStyleValues[0]?.value as ButtonShapeStyle
  }

  if (
    !customizationConfigs.buttonBorderStyleValues
      .map((o) => o.value)
      .includes(
        validatedCustomizationData[
          CustomizationFormProperties.BUTTONS_BORDER_STYLE
        ]
      )
  ) {
    validatedCustomizationData[
      CustomizationFormProperties.BUTTONS_BORDER_STYLE
    ] = customizationConfigs.buttonBorderStyleValues[0]
      ?.value as ButtonBorderStyle
  }

  return validatedCustomizationData
}

export const generateFormValidations = ({ t }: { t: TFunction }) => {
  return {
    descriptors: {
      ...Object.keys(colorsInitialValues).reduce((acc, name) => {
        acc[name] = {
          required: t('app:field_is_required'),
          pattern: {
            value: hexRegex,
            message: t('app:invalid_color_required'),
          },
        }

        return acc
      }, {} as { [key: string]: ValidationSchema }),
      backgroundImage: {
        validate: (value: ImageFormType, formData: MyPageAppearanceForm) => {
          return formData[CustomizationFormProperties.BACKGROUND_TYPE] ===
            BackgroundType.Image
            ? !!(value?.href || value?.imageFile)
            : true
        },
      },
      name: {
        required: t('app:field_required'),
        validate: (fieldValue: string) => {
          return FormValidations.isEmpty(fieldValue)
            ? t('app:field_required')
            : true
        },
      },
      about: {
        validate: (fieldValue: string) => {
          if (
            FormValidations.getHtmlTextOnlyLength(fieldValue) >
            FormValidations.textareaMaxLength
          ) {
            return t('app:field_invalid_max_length')
          }

          return true
        },
      },
    },
  }
}

export const MAX_PROFILE_IMAGE_SIZE_IN_MEGABYTES = 5
export const MAX_PROFILE_IMAGE_SIZE_IN_BYTES =
  MAX_PROFILE_IMAGE_SIZE_IN_MEGABYTES * 1024 * 1024

// checking if rich text edit is empty https://stackoverflow.com/a/59333111/1085387
export const isAboutEmpty = (about: string) =>
  about.replace(/<(.|\n)*?>/g, '').trim().length === 0

export const normalizeAboutValue = (about: string) =>
  about
    ? isAboutEmpty(about)
      ? ''
      : richTextEditorSanitizer(about)
    : undefined
