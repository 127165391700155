import { useEffect, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'

type Props = {
  debounceMsc?: number
}

export const useWindowSize = (props?: Props) => {
  const debounceMsc = props?.debounceMsc || 250

  const [windowSize, setWindowSize] = useState<{
    width?: number
    height?: number
  }>({})

  const handleResize = useDebouncedCallback(() => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    })
  }, debounceMsc)

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [handleResize])

  return windowSize
}
