import React, { InputHTMLAttributes } from 'react'
import {
  StyledLabel,
  StyledInputRadio,
  StyledCheckmark,
} from './RadioInput.styles'

export type RadioInputType = InputHTMLAttributes<HTMLInputElement> & {
  label: string | React.ReactNode
  name: string
  withCheckmark?: boolean
}

export const RadioInput = React.forwardRef<HTMLInputElement, RadioInputType>(
  (
    { label, name, withCheckmark = true, ...otherProps }: RadioInputType,
    ref
  ) => {
    return (
      <StyledLabel $withCheckmark={withCheckmark}>
        <StyledInputRadio ref={ref} name={name} type="radio" {...otherProps} />
        {label}
        {withCheckmark && <StyledCheckmark />}
      </StyledLabel>
    )
  }
)
