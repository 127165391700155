import styled from 'styled-components'
import { Flex } from '@web-apps/ui-shared'

export const StyledContainer = styled(Flex)`
  width: 100%;
  height: 100%;

  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
`
