import styled from 'styled-components'

import {
  ImageErrorHandlerWrapper,
  Box,
  media,
  BREAKPOINTS,
} from '@web-apps/ui-shared'

import { StyledDraggableListItemContent } from '../../../components'

export const StyledProductsItem = styled.li`
  margin-bottom: 16px;
`

export const StyledProductImage = styled(ImageErrorHandlerWrapper)`
  width: 48px;
  height: 48px;
  border-radius: 8px;
  object-fit: cover;
`

export const StyledProductCardContent = styled(StyledDraggableListItemContent)`
  position: relative;
  gap: 8px;

  padding: 8px;
  padding-block-start: 16px;
`

export const StyledProductInfoContainer = styled.div`
  display: flex;
  flex-direction: column;

  min-width: 0;
  padding-right: 40px;

  ${media.from(
    BREAKPOINTS.lg,
    `
    padding-right: 104px;
  `
  )}
`

export const StyledConvertedTagWrapper = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
`
export const StyledConvertedTag = styled(Box)`
  ${media.to(
    BREAKPOINTS.md,
    `
    display: none;
  `
  )}
`

export const StyleConvertedTagMobile = styled.div`
  ${media.from(
    BREAKPOINTS.lg,
    `
    display: none;
  `
  )}
`
