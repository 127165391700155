import { useTranslation } from 'react-i18next'
import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ComposedChart,
} from 'recharts'

import { Card, Flex, Typography, Theme } from '@web-apps/ui-shared'

import {
  StyledChartContainer,
  StyledSquareLegendIcon,
  StyledTooltipStatsLineContainer,
} from './PageviewsChart.styles'
import { VisitsTimeseriesPoint } from '@web-apps/utils-types'

type Props = {
  labelFormatter: (label: string) => string
  tickFormatter: (label: string) => string
  data: VisitsTimeseriesPoint[]
}

const CustomTooltip = ({
  label,
  active,
  payload,
  labelFormatter,
}: {
  label?: string
  active?: boolean
  labelFormatter?: (label: string) => string
  payload?: Array<{
    name: string
    dataKey: 'visitors' | 'date'
    color: string
    payload: {
      date: string
      visitors: number
    }
  }>
}) => {
  if (active && label && payload) {
    return (
      <Card>
        <Flex direction="column" gap={4}>
          <Typography variant="hint" fontWeight="600">
            {labelFormatter ? labelFormatter(label) : label}
          </Typography>
          {payload.map(({ name, dataKey, color, payload }) => {
            return (
              <StyledTooltipStatsLineContainer key={dataKey}>
                <StyledSquareLegendIcon $color={color} />
                <div>
                  <Typography variant="hint" as="span">
                    {name}:{' '}
                  </Typography>
                  <Typography variant="hint" fontWeight="600" as="span">
                    {payload[dataKey]}
                  </Typography>
                </div>
              </StyledTooltipStatsLineContainer>
            )
          })}
        </Flex>
      </Card>
    )
  }

  return null
}

export const PageviewsChart = ({
  data,
  labelFormatter,
  tickFormatter,
}: Props) => {
  const { t } = useTranslation(['metrics'], { useSuspense: false })

  return (
    <StyledChartContainer>
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart
          data={data}
          margin={{ top: 5, bottom: 5, right: 10, left: -35 }}
        >
          <CartesianGrid
            vertical={false}
            stroke={Theme.Colors.background.alternate}
          />
          <XAxis
            width={20}
            dataKey="date"
            stroke={Theme.Colors.typography.inactive}
            tickFormatter={tickFormatter}
            fontSize={10}
          />
          <YAxis
            tickLine={false}
            axisLine={false}
            stroke={Theme.Colors.typography.inactive}
            fontSize={10}
            allowDecimals={false}
          />
          <Tooltip
            labelFormatter={labelFormatter}
            content={<CustomTooltip />}
          />
          <Bar
            isAnimationActive
            dataKey="pageviews"
            barSize={12}
            name={t('metrics:visitsChart.visitsLegend')}
            fill={Theme.Colors.typography.light}
            radius={[4, 4, 0, 0]}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </StyledChartContainer>
  )
}
