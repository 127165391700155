const RANDOM_SLUG_LENGTH = 8

const randomSlug = (length: number): string => {
  let slug = ''
  const allowedChars = 'abcdefghijklmnopqrstuvwxyz0123456789._-'
  const charactersLength = allowedChars.length

  for (let i = 0; i < length; i++) {
    slug += allowedChars.charAt(Math.floor(Math.random() * charactersLength))
  }

  return slug
}

export const generateRandomSlug = (): string => randomSlug(RANDOM_SLUG_LENGTH)
