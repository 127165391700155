import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { useDraftSection } from '../hooks/preview.hooks'
import { editDraftExternalSection } from '../../../../store/creator/preview.slice'
import { useCreatorPage } from '../../../../utils/hooks/useCreatorPage.hooks'
import { ANALYTICS_CATEGORY, ExternalLinkSection } from '@web-apps/utils-types'
import { useIsSectionRedirectChecker } from '../hooks/useIsSectionRedirectChecker'
import {
  getSectionImage,
  useDeleteCreatorPageSectionMutation,
  useEditSectionAtomicMutation,
} from '@web-apps/feature-creator-page'
import { ROUTES } from '../../../../routes/RouteEnums'
import { ToastShareWrapper } from '../../../../components'
import {
  creatorUrlBuilders,
  isImageDownloadApiError,
} from '@web-apps/utils-shared'
import { Confirm } from '@web-apps/ui-shared'
import { InspectModeEnum, SectionLinkForm } from '../components/SectionLinkForm'

export const EditExternalLinkSection = () => {
  const { t } = useTranslation(['app', 'creator'])
  const navigate = useNavigate()
  const { id } = useParams<{ id: string }>()
  const [isConfirmOpen, toggleConfirm] = useState(false)
  const updatePreview = useDraftSection(id, editDraftExternalSection, true)
  const creatorPage = useCreatorPage()

  const section = creatorPage?.sections.find(
    (item): item is ExternalLinkSection => item._links.self.id === id
  )

  const { isSectionRedirect } = useIsSectionRedirectChecker({ section })

  const [
    editSection,
    {
      isSuccess: isEditSuccess,
      isError: isEditError,
      error: editError,
      isLoading: isEditLoading,
    },
  ] = useEditSectionAtomicMutation()

  const [
    deleteSection,
    {
      isSuccess: isDeleteSuccess,
      isError: isDeleteError,
      isLoading: isDeleteLoading,
    },
  ] = useDeleteCreatorPageSectionMutation()

  useEffect(() => {
    if (isDeleteSuccess) {
      navigate(ROUTES.MY_PAGE)
      toast.success(
        t('creator:toast.delete_success', {
          section: '$t(creator:sections.link)',
        })
      )
    }

    if (isEditSuccess) {
      navigate(ROUTES.MY_PAGE)
      toast.success(
        <ToastShareWrapper
          message={t('creator:toast.edit_success', {
            section: '$t(creator:sections.link)',
          })}
          href={creatorUrlBuilders.creatorUrlBuilder({
            creatorSlug: creatorPage._links.self.name,
          })}
          analyticsEventProps={{
            actionNamePrefix: 'edit_link_section',
            category: ANALYTICS_CATEGORY.CONTENT,
          }}
        />
      )
    }
  }, [isEditSuccess, isDeleteSuccess, creatorPage, t, navigate])

  useEffect(() => {
    if (isEditError && isImageDownloadApiError(editError)) {
      toast.error(t('app:imageUploadError'))
      return
    }

    if (isEditError || isDeleteError)
      toast.error(
        t('creator:toast.edit_error', {
          section: '$t(creator:sections.link)',
        })
      )
  }, [isEditError, editError, isDeleteError, t])

  const handleSubmit = useCallback(
    ({
      isRedirect,
      imageData,
      ...sectionData
    }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
    any) => {
      if (!section) return

      // only delete if it is the current redirect and isRedirect is false
      // only update to be the redirect if it is not the current redirect and isRedirect is true
      let redirect: 'delete' | 'update' | undefined = undefined
      if (isSectionRedirect && !isRedirect) redirect = 'delete'
      if (!isSectionRedirect && isRedirect) redirect = 'update'

      editSection({
        path: section._links.self.href,
        sectionData,
        redirect,
        imageData,
      })
    },
    [editSection, section, isSectionRedirect]
  )

  const isLoading = isDeleteLoading || isEditLoading

  if (!section) return null

  return (
    <>
      <SectionLinkForm
        initialValues={{
          label: section.label,
          href: section._links?.about?.href || '',
          type: section.type,
          styles: section.styles,
          published: section.published,
          isRedirect: isSectionRedirect,
          tagline: section.tagline,
          imageData: getSectionImage(section),
        }}
        isLoading={isLoading}
        handleSubmit={handleSubmit}
        updatePreview={updatePreview}
        inspectMode={InspectModeEnum.IGNORE_INSPECT}
        handleDelete={() => toggleConfirm(!isConfirmOpen)}
      />
      {isConfirmOpen && (
        <Confirm
          onDismiss={() => toggleConfirm(false)}
          title={t('creator:confirm.title')}
          buttons={[
            {
              label: t('creator:confirm.cancel'),
              onClick: () => toggleConfirm(false),
              inverse: true,
            },
            {
              label: t('creator:confirm.delete'),
              onClick: () => {
                deleteSection({ path: section._links.self.href })
                toggleConfirm(false)
              },
            },
          ]}
        />
      )}
    </>
  )
}
