import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  Box,
  Button,
  ButtonVariant,
  Icon,
  Theme,
  Flex,
} from '@web-apps/ui-shared'
import { StyledFormLabel } from '../../../../../components/styles'

type Props = {
  handleDelete?: () => void
}

export const DeleteBlock = ({ handleDelete }: Props) => {
  const { t } = useTranslation(['creator'])

  if (!handleDelete) {
    return null
  }

  return (
    <Box mb={48}>
      <Button
        type="button"
        variant={ButtonVariant.PLAIN}
        clickHandler={handleDelete}
      >
        <Flex align="center" gap={8}>
          <Icon.DeleteBin fillColor={Theme.Colors.typography.danger} />
          <StyledFormLabel $danger>
            {t('creator:custom_link.button_delete_section')}
          </StyledFormLabel>
        </Flex>
      </Button>
    </Box>
  )
}
