import { useMemo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { computeCommissionToShowFromAggregate } from '@web-apps/utils-shared'
import {
  Flex,
  FormArgsType,
  ShowHideAnimationWrapper,
  Typography,
} from '@web-apps/ui-shared'

import { RadioInput } from '../../../../../../components'
import { AffiliateTooltipState } from '../../../MyPage.types'
import {
  StyledContainer,
  StyledCommissionTypeContainer,
  StyledIcon,
} from './CommissionTypeSelector.styles'

type Props = {
  matches?: AffiliateTooltipState['matches']
  formReturnProps: FormArgsType<any>
}

export const CommissionTypeSelector = ({
  matches = [],
  formReturnProps: { getValues, setValue, register },
}: Props) => {
  const { t } = useTranslation(['app', 'creator'], { useSuspense: false })
  const commissionTypes = useMemo(
    () => [...matches].sort((a) => (a.createApiEndpoint ? -1 : 1)),
    [matches]
  )
  const defaultIndex = useMemo(
    () => commissionTypes.findIndex((item) => !!item.createApiEndpoint),
    [commissionTypes]
  )
  const currentPath = getValues('path')
  const currentCommissionInfo = useMemo(
    () =>
      computeCommissionToShowFromAggregate(
        matches.find((item) => item.createApiEndpoint === currentPath)
          ?.commission
      ),
    [matches, currentPath]
  )

  useEffect(() => {
    return () => setValue('commissionMatchName', '')
  }, [setValue])

  return (
    <Flex direction="column" gap={8}>
      {commissionTypes.length > 1 && (
        <Typography variant="hint" fontWeight={600}>
          {t(
            'creator:affiliate_links.conversion_tooltip.select_commission_type'
          )}
        </Typography>
      )}
      <StyledContainer gap={8} wrap="wrap">
        {commissionTypes.map((item, index) => {
          const commissionInfo = computeCommissionToShowFromAggregate(
            item.commission
          )

          return (
            <RadioInput
              withCheckmark={false}
              key={item.name}
              defaultChecked={index === defaultIndex}
              label={
                <StyledCommissionTypeContainer align="center" gap={8}>
                  <StyledIcon />
                  <Typography fontWeight="600" variant="hint">
                    {t(
                      `creator:affiliate_links.conversion_tooltip.converted.description_1_${commissionInfo.type}`,
                      { commission: commissionInfo.commissionText }
                    )}
                  </Typography>
                </StyledCommissionTypeContainer>
              }
              value={item.name}
              {...register('commissionMatchName', {
                required: t('app:field_is_required'),
              })}
            />
          )
        })}
      </StyledContainer>
      <ShowHideAnimationWrapper state={currentPath || ''}>
        <Typography variant="hint" color="inactive">
          {t(
            `creator:affiliate_links.conversion_tooltip.converted.description_2_no_brand_${currentCommissionInfo.type}`
          )}
        </Typography>
      </ShowHideAnimationWrapper>
    </Flex>
  )
}
