import { useTranslation } from 'react-i18next'

import {
  CopyButtonProps,
  CopyButton as CopyButtonBase,
} from '@web-apps/ui-shared'

export const COPY_BUTTON_MESSAGES_KEYS = {
  buttonText: 'app:button_copy',
  copiedTooltipText: 'app:button_tooltip_copied',
}

export const CopyButton = (props: Omit<CopyButtonProps, 'messages'>) => {
  const { t } = useTranslation('app')

  return (
    <CopyButtonBase
      messages={{
        buttonText: t(COPY_BUTTON_MESSAGES_KEYS.buttonText),
        copiedTooltipText: t(COPY_BUTTON_MESSAGES_KEYS.copiedTooltipText),
      }}
      {...props}
    />
  )
}
