import styled from 'styled-components'
import { BREAKPOINTS, media } from '@web-apps/ui-shared'

export const StyledNoResultsContainer = styled.div`
  text-align: center;
`

export const StyledTopBrandsContainer = styled.div`
  display: grid;

  gap: 24px;
  grid-template-columns: repeat(1, 1fr);

  ${media.from(
    BREAKPOINTS.md,
    `
      grid-template-columns: repeat(3, 1fr);
    `
  )}
`

export const StyledLayerBehindSearchResults = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
`
