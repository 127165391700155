import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import cloneDeep from 'lodash.clonedeep'

import { Dialog } from '@web-apps/ui-shared'
import { ProductForm } from '../../ProductForm.component'
import { ProductFormType } from '../../SectionProductionRecommendations.types'
import {
  AffiliateLinkConversionData,
  ContainerSectionAPIPayloadType,
} from '@web-apps/utils-types'
import { useCreateAffiliateLinkMutation } from '../../../../../../services/affiliates.api'
import {
  useAddSectionAtomicMutation,
  useEditSectionAtomicMutation,
} from '@web-apps/feature-creator-page'
import { formatProductFormToApi } from '../../SectionProductRecommendationsForm.adapters'
import { isImageDownloadApiError } from '@web-apps/utils-shared'

type Props = {
  onClose: () => void
  product: ProductFormType
  productIndex?: number
  append: (data: ProductFormType) => void
  update: (index: number, data: ProductFormType) => void
  section?: ContainerSectionAPIPayloadType
}

export const NestedProductForm = ({
  productIndex,
  product,
  append,
  update,
  onClose,
  section,
}: Props) => {
  const { t } = useTranslation(['app', 'creator'], { useSuspense: false })
  const [isLoading, setIsLoading] = useState(false)
  const [createAffiliateLink, { isError: isCreateAffiliateLinkError }] =
    useCreateAffiliateLinkMutation()
  const [
    editItemSection,
    { isSuccess: isItemEdited, isError: isEditItemError, error: editItemError },
  ] = useEditSectionAtomicMutation()
  const [
    addSectionItem,
    { isSuccess: isItemAdded, isError: isItemAddError, error: addSectionError },
  ] = useAddSectionAtomicMutation()

  const handleAdd = useCallback(
    async (
      { commissionMatchName, ...data }: ProductFormType,
      inspectResponse?: AffiliateLinkConversionData
    ) => {
      let product = data

      if (commissionMatchName && inspectResponse) {
        const path = inspectResponse.matches.find(
          (item) => item.name === commissionMatchName
        )?.createApiEndpoint

        const affiliateLink = await createAffiliateLink({
          uri: data.href,
          title: data.title,
          path,
          stopInvalidatesTags: true,
        }).unwrap()

        product = {
          ...product,
          alternate: affiliateLink.affiliateUrl,
          commission: {
            ...affiliateLink.commission,
          },
          affiliateLinkId: affiliateLink.id,
        }
      }

      if (section) {
        const { imageData, ...sectionData } = formatProductFormToApi(product)

        await addSectionItem({
          sectionData: sectionData,
          imageData,
          path: section._links['creator_page_section/create'].href,
        })
      } else {
        append(product)
      }
    },
    [append, section, addSectionItem, createAffiliateLink]
  )
  const handleEdit = useCallback(
    async (data: ProductFormType) => {
      if (section) {
        const sectionToBeEdited = (section.items || []).find(
          (s) => s._links.self.id === data.id
        )
        if (!sectionToBeEdited) {
          toast.error(t('creator:product_recommendations.edit_error'))
          return false
        }

        const { imageData, image_source, ...sectionData } =
          formatProductFormToApi(data)
        await editItemSection({
          path: sectionToBeEdited._links.self.href,
          sectionData: sectionData,
          imageData,
        })
      } else {
        update(productIndex as number, data)
      }
    },
    [section, t, update, editItemSection, productIndex]
  )

  const handleSubmit = useCallback(
    async (
      data: ProductFormType,
      inspectResponse?: AffiliateLinkConversionData
    ) => {
      try {
        setIsLoading(true)
        if (productIndex !== undefined) {
          const cloned = cloneDeep(data)
          await handleEdit(cloned)
        } else {
          await handleAdd(data, inspectResponse)
        }
        setIsLoading(false)
        onClose()
      } catch (e) {
        onClose()
        toast.error(t('app:generic_error'))
      }
    },
    [handleAdd, handleEdit, productIndex, onClose, t]
  )

  useEffect(() => {
    if (isItemAdded || isItemEdited) {
      toast.success(
        t('creator:toast.edit_success', {
          section: '$t(creator:sections.product_recommendations)',
          count: Number.POSITIVE_INFINITY,
        })
      )
    }
  }, [isItemAdded, isItemEdited, t])

  useEffect(() => {
    if (isItemAddError || isCreateAffiliateLinkError) {
      toast.error(
        isImageDownloadApiError(addSectionError)
          ? t('app:imageUploadError')
          : t('creator:product_recommendations.edit_error')
      )
    }

    if (isEditItemError) {
      toast.error(
        isImageDownloadApiError(editItemError)
          ? t('app:imageUploadError')
          : t('creator:product_recommendations.edit_error')
      )
    }
  }, [
    isCreateAffiliateLinkError,
    isItemAddError,
    addSectionError,
    isEditItemError,
    editItemError,
    t,
  ])

  return (
    <Dialog
      isOpen={true}
      onDismiss={onClose}
      title={t(
        `creator:product_recommendations.product_form.${
          productIndex ? 'title_edit' : 'title_add'
        }`
      )}
    >
      <ProductForm
        initialValues={product}
        isURLReadOnly={productIndex !== undefined}
        isSubmitting={isLoading}
        handleSubmit={handleSubmit}
      />
    </Dialog>
  )
}
