import { useTranslation } from 'react-i18next'
import { useLocation, Link, Outlet } from 'react-router-dom'
import { useFlag } from '@unleash/proxy-client-react'

import { Tab, TabList, TabPanels, Tabs } from '@web-apps/ui-shared'
import {
  PageHeader,
  PageTitle,
  PageWrapper,
} from '../../../components/PageComponents'
import {
  FLAG_TRENDING_PRODUCTS_PAGE,
} from '../../../utils/constants/flags.constants'
import { ROUTES } from '../../../routes/RouteEnums'

export const BrandCollaborations = () => {
  const { t } = useTranslation(['app'], { useSuspense: false })
  const { pathname, search } = useLocation()
  const isTrendingProductsActive = useFlag(FLAG_TRENDING_PRODUCTS_PAGE)

  const TAB_INDEX_FOR_ROUTES: { [key: string]: number } = {
    [ROUTES.BRANDS]: 0,
  }

  if (isTrendingProductsActive) {
    TAB_INDEX_FOR_ROUTES[ROUTES.TRENDING_PRODUCTS] = 1
    TAB_INDEX_FOR_ROUTES[ROUTES.LINK_CONVERTER] = 2
  } else {
    TAB_INDEX_FOR_ROUTES[ROUTES.LINK_CONVERTER] = 1
  }

  const activeTabIndex = TAB_INDEX_FOR_ROUTES[pathname] || 0

  return (
    <>
      <PageHeader hasBorder={false}>
        <PageTitle title={t('app:header.brands.title')} />
      </PageHeader>
      <PageWrapper hasTabs style={{ flex: 1, position: 'relative' }}>
        <Tabs
          index={activeTabIndex}
          onChange={
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            () => {}
          }
        >
          <TabList>
            <Link to={`${ROUTES.BRANDS}${search}`}>
              <Tab
                isActive={activeTabIndex === 0}
                label={t('app:header.brands.tabs.brands')}
              />
            </Link>
            {isTrendingProductsActive && (
              <Link to={`${ROUTES.TRENDING_PRODUCTS}${search}`}>
                <Tab
                  isActive={
                    activeTabIndex ===
                    TAB_INDEX_FOR_ROUTES[ROUTES.TRENDING_PRODUCTS]
                  }
                  label={t('app:header.brands.tabs.products')}
                />
              </Link>
            )}
            <Link to={`${ROUTES.LINK_CONVERTER}${search}`}>
              <Tab
                isActive={
                  activeTabIndex ===
                  TAB_INDEX_FOR_ROUTES[ROUTES.LINK_CONVERTER]
                }
                label={t('app:header.brands.tabs.converter')}
              />
            </Link>
          </TabList>
          <TabPanels>
            <Outlet />
          </TabPanels>
        </Tabs>
      </PageWrapper>
    </>
  )
}
