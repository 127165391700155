import styled from 'styled-components'
import ReactSlider from 'react-slider'

import { BREAKPOINTS, media, Theme } from '@web-apps/ui-shared'

export const StyledButtonWrapper = styled.div`
  position: relative;
  width: calc(50% - 4px);

  ${media.from(
    BREAKPOINTS.sm,
    `
    width: 160px;
  `
  )}
`

export const StyledDivider = styled.div`
  width: 16px;
  height: 2px;
  background: ${Theme.Button.backgroundColor};
`

export const StyledSlider = styled(ReactSlider)`
  width: 100%;
  height: 24px;

  & .track-1 {
    top: 8px;
    height: 8px;
    border-radius: 24px;
    background: ${Theme.Button.backgroundColor};
  }
`

export const StyledSliderHandle = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 24px;
  background: ${Theme.Button.backgroundColor};
  cursor: grab;
`

export const StyledTrack = styled.div<{
  $state: { index: number }
}>`
  position: absolute;
  top: 8px;
  bottom: 0;
  height: 8px;
  width: 100%;
  border-radius: 24px;
  background: ${({ $state }) =>
    $state.index === 2
      ? Theme.Button.backgroundDisabledColor
      : $state.index === 1
      ? 'transparent'
      : Theme.Button.backgroundDisabledColor};
`
