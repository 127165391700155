import { ReactNode } from 'react'
import ReactGA from 'react-ga4'

import { ANALYTICS_CATEGORY } from '@web-apps/utils-types'

import { Flex, LoadingIndicator } from '../../../index'
import {
  StyledPopover,
  StyledOptionsItem,
  StyledOptionsWrapper,
} from './PopoverMenu.styles'

type OptionType = {
  key: string
  value: string
  title: string
  action: (option: OptionType) => void
  analyticsKey: string
  isActive?: boolean
}

type PopoverMenuProps = {
  onDismiss: () => void
  title: string
  isLoading?: boolean
  containerWidth?: number
  options?: OptionType[]
  children?: ReactNode
}

export const PopoverMenu = ({
  onDismiss,
  title,
  isLoading,
  options,
  children,
  containerWidth = 220,
}: PopoverMenuProps) => {
  return (
    <StyledPopover
      onClose={onDismiss}
      title={title}
      wrapperStyles={{
        maxWidth: 'initial',
        minWidth: 'initial',
        marginBottom: 0,
      }}
    >
      <StyledOptionsWrapper $containerWidth={containerWidth}>
        {isLoading ? (
          <Flex justify="space-around">
            <LoadingIndicator />
          </Flex>
        ) : options ? (
          <Flex direction="column" gap={16}>
            {options.map((option) => (
              <StyledOptionsItem
                key={option.key}
                $isActive={option.isActive}
                onClick={() => {
                  ReactGA.event({
                    action: `${option.analyticsKey}:click`,
                    category: ANALYTICS_CATEGORY.CONTENT,
                  })
                  option.action(option)
                }}
              >
                {option.title}
              </StyledOptionsItem>
            ))}
          </Flex>
        ) : (
          children
        )}
      </StyledOptionsWrapper>
    </StyledPopover>
  )
}
