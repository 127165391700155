import { getSocialData } from '@web-apps/utils-shared'
import { Icon, IconAssetsKeys } from '@web-apps/ui-shared'
import { SocialNetworksEnum } from '@web-apps/utils-types'

import { MenuButtonOptionProps } from '../../../../../containers/creator/MyPage/MyPage.types'

export const generateSocialLinksOptions = (
  usedSocials: string[] = []
): MenuButtonOptionProps[] =>
  Object.values(SocialNetworksEnum)
    .filter((value) => !usedSocials.includes(value))
    .map((id) => {
      const { icon, title } = getSocialData(id)

      return {
        id,
        IconComponent: Icon[icon as IconAssetsKeys],
        title: title,
        listItemProps: {
          hiddenBorders: true,
        },
      }
    }) as MenuButtonOptionProps[]
