import { useTranslation } from 'react-i18next'

import { Tag } from '@web-apps/ui-shared'

type AffiliateStatusTagProps = {
  status: string
}

export const AffiliateStatusTag = ({ status }: AffiliateStatusTagProps) => {
  const { t } = useTranslation(['creator'])
  switch (status) {
    case 'converted_static':
      return (
        <Tag
          variant="success"
          text={t('creator:affiliate_links.status_tag.converted')}
          showIcon
        />
      )
    case 'converted':
    case 'convertible':
      return (
        <Tag
          variant="success"
          text={t('creator:affiliate_links.status_tag.supported')}
          showIcon
        />
      )
    default:
      return null
  }
}
