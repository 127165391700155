import styled from 'styled-components'
import { BREAKPOINTS, media, Theme } from '@web-apps/ui-shared'

export const StyledContainer = styled.div<{ $isSafariIOS: boolean }>`
  ${({ $isSafariIOS }) => {
    return (
      $isSafariIOS &&
      `
        padding-bottom: 100px;
     `
    )
  }}
  margin-left: ${Theme.Sidebar.width}px;
  background-color: ${Theme.CreatorApp.backgroundColor};
  display: flex;
  flex-flow: column;
  min-height: calc(100vh - ${Theme.CreatorApp.header.height}px);
  position: relative;
  margin-top: 64px;
  padding-bottom: 64px;

  ${media.to(
    BREAKPOINTS.md,
    `
    height: calc(100vh - ${Theme.CreatorApp.header.height}px);
    overflow-y: auto;
    overflow-x: hidden;
    margin-left: 0;
    `
  )}
`
