import React, { useEffect, useState } from 'react'
import { RecoveryFlow, UpdateRecoveryFlowBody } from '@ory/client'
import { useTranslation } from 'next-i18next'

import { FormValidations } from '@web-apps/utils-shared'
import {
  Box,
  Button,
  Flex,
  Form,
  LoadingSpinner,
  StyledError,
  TextField,
  Theme,
} from '@web-apps/ui-shared'

import {
  useGetSession,
  useFormatLoginRegisterRecoveryFlowNodes,
  useGetRecoveryFlow,
} from '../../utils/hooks'
import { submitRecoverFlow } from '../../utils/flows'

type ResetPasswordProps = {
  onReset: () => void
  onError?: (error: string) => void
  redirectTo?: () => void
}
export const ResetPassword = ({ onReset, redirectTo }: ResetPasswordProps) => {
  const { session, isSessionLoading } = useGetSession()
  const { t } = useTranslation(['auth'])
  const { flow, isFlowLoading, error: flowError } = useGetRecoveryFlow()
  const { fields } = useFormatLoginRegisterRecoveryFlowNodes(flow)

  const [submitErrorMessage, setSubmitErrorMessage] = useState<string | null>(
    null
  )

  const onSubmit = async (values: UpdateRecoveryFlowBody) => {
    setSubmitErrorMessage(null)
    const response = await submitRecoverFlow(
      flow as RecoveryFlow,
      values,
      onReset
    )
    if (response?.error) setSubmitErrorMessage(response.error)
  }

  useEffect(() => {
    if (session?.active) redirectTo?.()
  }, [session?.active, redirectTo])

  if (isSessionLoading || isFlowLoading)
    return (
      <Flex align="center" direction="column">
        <Box py={24}>
          <LoadingSpinner size={42} color={Theme.Colors.background.dark} />
        </Box>
      </Flex>
    )

  const emailField = fields?.email?.length ? fields.email[0] : undefined

  return (
    <div>
      {emailField && (
        <Form
          onSubmit={onSubmit}
          validations={{
            descriptors: {
              [emailField.attributes.name]: {
                required: t('auth:errors.email_required'),
                pattern: {
                  value: FormValidations.emailRegex,
                  message: t('auth:errors.email_wrong_format'),
                },
              },
            },
          }}
        >
          {() => {
            return (
              <Box mb={24}>
                {[...fields.csrfToken, ...fields.method].map(
                  ({ attributes }) => (
                    <TextField
                      key={attributes.name}
                      name={attributes.name}
                      type="hidden"
                      value={attributes.value}
                    />
                  )
                )}

                {emailField && (
                  <Box mb={16}>
                    <TextField
                      name={emailField.attributes.name}
                      placeholder={t('auth:input_placeholders.email')}
                    />
                  </Box>
                )}

                <Button width="100%">
                  {t('auth:reset_password.reset_password_button')}
                </Button>
              </Box>
            )
          }}
        </Form>
      )}
      <Box my={24}>
        {(submitErrorMessage || flowError) && (
          <Flex align="center" justify="center">
            <Box mb={24}>
              <StyledError $size="regular">
                {t(`auth:errors.${submitErrorMessage || flowError}`)}
              </StyledError>
            </Box>
          </Flex>
        )}
      </Box>
    </div>
  )
}
