import { useRef, useState, useEffect } from 'react'

import {
  Backdrop,
  ClickOutside,
  Flex,
  Icon,
  PopoverHeader,
  Typography,
} from '@web-apps/ui-shared'
import { DateHelpers } from '@web-apps/utils-shared'

import { useSelectorParams } from '../../hooks/useSelectorParams'

import {
  StyledButton,
  StyledButtonWrapper,
  StyledContainer,
  StyledList,
  StyledPopover,
  StyledPopoverWrapper,
  StyledGroup,
  StyledLinkItem,
} from './PeriodSelector.styles'

export const PeriodSelector = ({
  dates: { start, end },
}: {
  dates: { start?: string; end?: string }
}) => {
  const optionsListRef = useRef<HTMLDivElement>(null)
  const [showOptions, setShowOptions] = useState(false)
  const buttonRef = useRef(null)
  const { optionGroups, label } = useSelectorParams()
  const [startDate, setStartDate] = useState<Date | undefined>()
  const [endDate, setEndDate] = useState<Date | undefined>()

  useEffect(() => {
    if (start) setStartDate(new Date(start))
    if (end) setEndDate(new Date(end))
  }, [start, end])

  return (
    <StyledContainer>
      <StyledButtonWrapper>
        <StyledButton
          ref={buttonRef}
          onClick={() => setShowOptions(!showOptions)}
        >
          <Flex direction="column">
            <Typography variant="h4" as="span">
              {startDate &&
                endDate &&
                `${DateHelpers.formatDateToText(
                  startDate
                )} - ${DateHelpers.formatDateToText(endDate)}`}
            </Typography>
            <Typography variant="note" color="inactive" as="span">
              {label}
            </Typography>
          </Flex>
          <Icon.Chevron />
        </StyledButton>
        {showOptions && (
          <>
            <Backdrop mobileOnly />
            <StyledPopover>
              <ClickOutside
                handleClickOutside={() => setShowOptions(false)}
                ignoreElementRef={buttonRef}
              >
                {/* as="div" would solve typescript complain, however, styled-components stop passing styles down when using as */}
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-ignore */}
                <StyledPopoverWrapper ref={optionsListRef}>
                  <PopoverHeader
                    text={'Date range'}
                    onClose={() => setShowOptions(false)}
                  />

                  <StyledList>
                    {optionGroups.map((group, index) => (
                      <StyledGroup key={index}>
                        {group.map((item) => (
                          <div key={item.id}>
                            <StyledLinkItem
                              to={item.to}
                              onClick={() => setShowOptions(false)}
                            >
                              {item.title}
                            </StyledLinkItem>
                          </div>
                        ))}
                      </StyledGroup>
                    ))}
                  </StyledList>
                </StyledPopoverWrapper>
              </ClickOutside>
            </StyledPopover>
          </>
        )}
      </StyledButtonWrapper>
    </StyledContainer>
  )
}
