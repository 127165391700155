import { useEffect, useRef } from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'

import {
  Box,
  ButtonVariant,
  DraggableCard,
  Flex,
  Icon,
  Switch,
  SwitchVariant,
  Tag,
  Typography,
  useShareLinkData,
} from '@web-apps/ui-shared'
import {
  creatorUrlBuilders,
  getEmbedCategoryTitle,
  nativeShareWrapper,
} from '@web-apps/utils-shared'
import { useToggleSectionPublishStateMutation } from '@web-apps/feature-creator-page'
import {
  ExternalLinkMode,
  SectionType,
  SectionTypeEnum,
} from '@web-apps/utils-types'

import {
  StyledDeleteButton,
  StyledDraggableListItemActionsContainer,
  StyledDraggableListItemContent,
  StyledDraggableListItemSubtitle,
  StyledDraggableListItemTitle,
  StyledEditButton,
  StyledHiddenDraggableContainer,
} from '../../../.'

import { useIsSectionRedirectChecker } from '../../../../hooks/useIsSectionRedirectChecker'
import { ROUTES } from '../../../../../../../routes/RouteEnums'

const getCategoryLabel = (section: SectionType, t: TFunction): string => {
  switch (section.type) {
    case SectionTypeEnum.AFFILIATE_LINK:
      return t('creator:sections.product_recommendations', { count: 1 })
    case SectionTypeEnum.CONTENT:
      return t('creator:sections.content')
    case SectionTypeEnum.EXTERNAL_LINK: {
      if (
        section.mode === ExternalLinkMode.EMBED &&
        section._links.about?.href
      ) {
        const { name, type } = getEmbedCategoryTitle(section._links.about.href)
        return t(`creator:sections.${type}`, { name })
      }

      if (section._links.affiliate?.href) {
        return t('creator:sections.product_recommendations', { count: 1 })
      }

      return t('creator:sections.link')
    }
    case SectionTypeEnum.CONTAINER: {
      return section.styles?.['is_product_recommendations']
        ? t('creator:sections.product_recommendations', {
            count: Number.POSITIVE_INFINITY,
          })
        : t('creator:sections.link')
    }
    default:
      return t('creator:sections.link')
  }
}

const getLabel = (section: SectionType): string => {
  if (section.type === SectionTypeEnum.CONTENT) {
    const div = document.createElement('div')
    div.innerHTML = section.text

    return div.textContent || div.innerText || ''
  }

  return section.label || ''
}

const getEditLink = (section: SectionType): string | null => {
  const id = section._links.self.id

  switch (section.type) {
    case SectionTypeEnum.AFFILIATE_LINK: {
      return `/my-page/affiliate-link/${id}`
    }
    case SectionTypeEnum.EXTERNAL_LINK: {
      if (section.mode === ExternalLinkMode.EMBED) {
        const { type } = getEmbedCategoryTitle(section._links.about?.href || '')

        return `/my-page/${type}/${id}`
      }

      return `/my-page/link/${id}`
    }
    case SectionTypeEnum.CONTENT: {
      return `/my-page/content-section/${id}`
    }
    case SectionTypeEnum.CONTAINER: {
      return section.styles?.['is_product_recommendations']
        ? `${ROUTES.MY_PAGE_ADD_PRODUCT_RECOMMENDATIONS}/${id}`
        : null
    }

    default: {
      return null
    }
  }
}

type SectionsListItemProps = {
  section: SectionType
  deleteHandler: (href: string) => void
  isDragging?: boolean
  isHidden?: boolean
}
export const SectionsListItem = ({
  section,
  deleteHandler,
  isDragging = false,
  isHidden = false,
}: SectionsListItemProps) => {
  const { t } = useTranslation(['creator', 'app'])
  const ref = useRef<HTMLDivElement>(null)
  const { setShareLink } = useShareLinkData()

  const publishLink = section._links.edit?.find(
    (link) => link.name === 'published'
  )
  const category = getCategoryLabel(section, t)
  const label = getLabel(section)
  const [togglePublishState, { isLoading, isError }] =
    useToggleSectionPublishStateMutation()

  const { isSectionRedirect } = useIsSectionRedirectChecker({ section })

  useEffect(() => {
    if (isError) {
      toast.error(
        t('creator:toast.edit_error', {
          section: `${section.label}`,
        })
      )
    }
  }, [isError, section.label, t])

  const editLink = getEditLink(section)

  return (
    <StyledHiddenDraggableContainer $isHidden={isHidden}>
      <DraggableCard isDragging={isDragging}>
        <StyledDraggableListItemContent>
          {category && (
            <Flex justify="space-between" align="center">
              <StyledDraggableListItemSubtitle>
                {category}
              </StyledDraggableListItemSubtitle>
              {isSectionRedirect && (
                <Tag variant="success" text={t('creator:redirect_indicator')} />
              )}
            </Flex>
          )}
          <StyledDraggableListItemTitle>
            <Flex justify="space-between" align="center">
              {label ? (
                <span translate="no">{label}</span>
              ) : (
                <Typography fontStyle="italic" color="inactive">
                  {t('creator:product_recommendations.no_title')}
                </Typography>
              )}
              {isSectionRedirect && !category && (
                <Tag variant="success" text={t('creator:redirect_indicator')} />
              )}
            </Flex>
          </StyledDraggableListItemTitle>
          <Box mt={12} mb={-8} ml={-4}>
            <Flex justify="space-between" align="center">
              <StyledDraggableListItemActionsContainer>
                {editLink && (
                  <StyledEditButton as={Link} to={editLink}>
                    <Flex align="center" gap={2}>
                      <Icon.EditPen width={16} height={16} />
                      {t('creator:button_edit')}
                    </Flex>
                  </StyledEditButton>
                )}
                {[
                  SectionTypeEnum.EXTERNAL_LINK,
                  SectionTypeEnum.AFFILIATE_LINK,
                ].includes(section.type) && (
                  <div ref={ref} style={{ position: 'relative' }}>
                    <StyledEditButton
                      variant={ButtonVariant.PLAIN}
                      type="button"
                      clickHandler={() => {
                        const url = section._links.alternate?.href
                          ? creatorUrlBuilders.generateUtmShareURL({
                              originalURL: section._links.alternate.href,
                            })
                          : (section._links.about?.href as string)
                        return nativeShareWrapper(url, () =>
                          setShareLink({
                            url:
                              section._links.alternate?.href ||
                              section._links.about?.href,
                            copyUrl: section._links.alternate?.href
                              ? creatorUrlBuilders.generateUtmShareURL({
                                  originalURL: section._links.alternate.href,
                                })
                              : undefined,
                            container: ref.current,
                          })
                        )
                      }}
                    >
                      <Flex align="center" gap={2} translate="no">
                        <Icon.Share width={16} height={16} />
                        {t('creator:button_share')}
                      </Flex>
                    </StyledEditButton>
                  </div>
                )}
                <StyledDeleteButton
                  variant={ButtonVariant.PLAIN}
                  type="button"
                  clickHandler={() => deleteHandler(section._links.self.href)}
                >
                  <Flex align="center" gap={2}>
                    <Icon.DeleteBin width={16} height={16} />
                    {t('creator:button_delete')}
                  </Flex>
                </StyledDeleteButton>
              </StyledDraggableListItemActionsContainer>

              <Switch
                name="published"
                onChange={(published) => {
                  if (publishLink?.href) {
                    togglePublishState({
                      path: publishLink.href,
                      data: { published },
                    })
                  }
                }}
                variant={SwitchVariant.SMALL}
                checked={section.published}
                disabled={!publishLink || isLoading}
              />
            </Flex>
          </Box>
        </StyledDraggableListItemContent>
      </DraggableCard>
    </StyledHiddenDraggableContainer>
  )
}
