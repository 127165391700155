import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import {
  Box,
  Button,
  ButtonSize,
  Flex,
  Form,
  Icon,
  TextField,
  Theme,
  Typography,
} from '@web-apps/ui-shared'
import {
  AffiliateLinkType,
  AffiliateProductsDataType,
  AffiliateProductType,
  ANALYTICS_CATEGORY,
  TrendingProductsFiltrationType,
} from '@web-apps/utils-types'

import {
  ProductsListItemSkeleton,
  SkeletonWrapper,
} from '../../../../components/skeletons'
import { useSearchTrendingProductsQuery } from '../../../../services/affiliates.api'
import { ToastShareWrapper } from '../../../../components'
import { useAccountDetails } from '../../../../utils/hooks/useAccountDetails.hooks'
import { AffiliateProductCard } from '../components/AffiliateProductCard'

import { Filtration } from './components'
import {
  StyledClearBtn,
  StyledContainer,
  StyledContent,
  StyledLoadMoreRow,
} from './TrendingPorductsPage.styles'

export const TrendingProductsPage = () => {
  const { t } = useTranslation(['app', 'brands'], { useSuspense: false })
  const { pageId } = useAccountDetails()

  const [requestParams, setRequestParams] = useState<{
    query?: string
    path?: string
    filters?: TrendingProductsFiltrationType
  }>({})
  const [products, setProductsList] = useState<
    AffiliateProductsDataType['products']
  >([])

  const { isError, isFetching, data } = useSearchTrendingProductsQuery(
    {
      query: requestParams?.query as string,
      filters: requestParams?.filters,
      path: requestParams?.path,
    },
    {
      skip: !requestParams?.path && !requestParams?.query,
      refetchOnMountOrArgChange: true,
    }
  )

  const handleConvertedProduct = useCallback(
    (affiliateLink: AffiliateLinkType) => {
      toast.success(
        <ToastShareWrapper
          message={t('brands:link_converter.dialog.sub-title')}
          href={affiliateLink.affiliateUrl}
          analyticsEventProps={{
            actionNamePrefix: 'link_conversion',
            category: ANALYTICS_CATEGORY.AFFILIATES,
          }}
        />
      )
    },
    [t]
  )

  useEffect(() => {
    if (data) {
      setProductsList((prev) => [...prev, ...(data.products || [])])
    }
  }, [data])

  useEffect(() => {
    if (isError) {
      toast.error(t('brands:errors.trending_products_query'))
    }
  }, [isError, t])

  return (
    <>
      <Typography variant="h3">
        {t('app:header.brands.products_page.title')}
      </Typography>
      <StyledContainer>
        <Box my={16}>
          <Form
            initialWatchableFields={['searchString']}
            initialValues={{ searchString: requestParams?.query || '' }}
            onSubmit={({ searchString }) => {
              if (searchString !== '' && searchString !== requestParams.query) {
                setProductsList([])
                setRequestParams((prev) => ({
                  ...prev,
                  query: searchString,
                  path: undefined,
                }))
              }
            }}
          >
            {({ setValue, setFocus, getValues }) => (
              <TextField
                placeholder={t(
                  'app:header.brands.products_page.input_placeholder'
                )}
                name="searchString"
                autoComplete="off"
                autoCorrect="off"
                rightDecorator={
                  <Flex align="center">
                    <StyledClearBtn
                      onClick={() => {
                        setRequestParams({})
                        setValue('searchString', '')
                        setFocus('searchString')
                      }}
                    >
                      {getValues('searchString') && (
                        <Icon.Close fillColor={Theme.Colors.background.dark} />
                      )}
                    </StyledClearBtn>
                    <Box ml={16}>
                      <Button
                        type="submit"
                        size={ButtonSize.SMALL}
                        isLoading={isFetching}
                      >
                        <Flex align="center">
                          <Box mr={4}>
                            <Icon.Search
                              width={24}
                              height={24}
                              fillColor={Theme.Colors.typography.inverse}
                            />
                          </Box>
                          {t('app:header.brands.products_page.search_button')}
                        </Flex>
                      </Button>
                    </Box>
                  </Flex>
                }
              />
            )}
          </Form>
        </Box>
        <Box mt={24} mb={16}>
          <Filtration
            onChange={(filters) => {
              setProductsList([])
              setRequestParams((prev) => ({
                ...prev,
                path: undefined,
                filters,
              }))
            }}
          />
        </Box>
        <StyledContent>
          <SkeletonWrapper
            asList
            itemsAmount={12}
            Skeleton={ProductsListItemSkeleton}
            isLoading={isFetching && !products.length}
            showEmptyState={data && data?.totalProducts === 0}
          >
            {products.length !== 0 && (
              <>
                {products.map((item: AffiliateProductType) => {
                  return (
                    <AffiliateProductCard
                      key={item.id}
                      pageId={pageId}
                      product={item}
                      onConverted={handleConvertedProduct}
                    />
                  )
                })}
                {data?.nextPage && (
                  <StyledLoadMoreRow justify="center">
                    <Button
                      inverse
                      clickHandler={() =>
                        data.nextPage &&
                        setRequestParams((prev) => ({
                          ...prev,
                          path: data.nextPage?.href,
                        }))
                      }
                      type="button"
                      isLoading={isFetching}
                    >
                      {t('app:load_more')}
                    </Button>
                  </StyledLoadMoreRow>
                )}
              </>
            )}
          </SkeletonWrapper>
        </StyledContent>
      </StyledContainer>
    </>
  )
}
