import styled from 'styled-components'

import { media, BREAKPOINTS, Theme, Flex } from '@web-apps/ui-shared'

export const StyledHeaderContentContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

export const StyledShareButtonContent = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`

export const StyledContainer = styled.div`
  width: 100%;
  position: relative;

  ${media.from(
    BREAKPOINTS.lg,
    `
      display: flex;
      flex-wrap: nowrap;
    `
  )}
`

export const StyledLeftCol = styled.div`
  display: flex;
  justify-content: space-around;
  flex: 1 0 100%;

  ${media.from(
    BREAKPOINTS.lg,
    `
      flex: 1 0 66.66%;
      max-width: 500px;
    `
  )}

  ${media.from(
    BREAKPOINTS.xl,
    `
      flex: 1 0 66.66%;
      max-width: 600px;
    `
  )}

  ${media.from(
    BREAKPOINTS.xxl,
    `
      max-width: initial;
    `
  )}
`

export const StyledRightCol = styled.div`
  flex: 1 0 33.33%;
  display: flex;
  justify-content: space-around;
`

export const StylePreviewWrapper = styled.div`
  width: 100%;
  padding: 0 8%;
  box-sizing: border-box;
`

export const StyledCreatorURLWrapper = styled.div`
  display: none;
  ${media.from(
    BREAKPOINTS.lg,
    `
      display: flex;
      align-items: center;
      gap: 16px;
      flex: 1;
      max-width: clamp(200px, 35vw, 464px);
    `
  )}
`

export const StyledPageActions = styled(Flex)`
  ${media.from(
    BREAKPOINTS.lg,
    `
      display: none
    `
  )}
`

export const StyledPreviewIconWrapper = styled.div`
  margin-right: 72px;

  ${media.from(
    BREAKPOINTS.lg,
    `
      display: none
    `
  )}
`

export const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;

  ${media.from(
    BREAKPOINTS.lg,
    `
      max-width: 720px;
    `
  )}
`

export const StyledContentWrapper = styled.div`
  position: relative;
`

export const StyledAddSectionButtonsContainer = styled.div`
  margin-block-end: 16px;
`

export const StyledMobileShareActionsContainer = styled(StyledPageActions)`
  gap: 8px;
  padding: 16px;
  background: ${Theme.Colors.background.base};
  margin: -30px -16px 24px;
  border-bottom: 1px solid ${Theme.Colors.line};
`
