import { useEffect } from 'react'
import { useRoutes } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { ErrorBoundary } from 'react-error-boundary'

import { LoadingIndicator, SuspenseWrapper } from '@web-apps/ui-shared'
import { isSafariIOS } from '@web-apps/utils-shared'
import { useGetCreatorPageQuery } from '@web-apps/feature-creator-page'
import { SessionType } from '@web-apps/feature-auth'

import {
  AddAudienceCountry,
  AddToHomeScreenTooltip,
  Sidebar,
  ErrorApp,
  AdminTooltip,
} from '../../components'

import { useGetUserAccountQuery } from '../../services/user.api'
import { AccountContext, CreatorPageContext } from '../../utils/contexts'
import { injectNonPublicRouteComponentsForRoles } from '../../routes/RoutesComponents'

import { StyledContainer } from './PageLayout.styles'
import { useGetAPIConfigsQuery } from '../../services/api'
import { useUnleashContext } from '@unleash/proxy-client-react'
import { DATE_OF_HIDDEN_DESIGN_OPTIONS } from '../../utils/constants/app.constants'

type Props = {
  userId: string
  session: SessionType
}

export const PageLayout = ({ userId, session }: Props) => {
  const { t } = useTranslation(['app'])
  const updateContext = useUnleashContext()
  const {
    data: APIConfigs,
    isLoading: isLoadingAPIConfigs,
    isError: isAPIConfigsError,
  } = useGetAPIConfigsQuery()

  const {
    data,
    isLoading: isAccountLoading,
    isError: isAccountError,
  } = useGetUserAccountQuery({
    userId,
  })
  const {
    data: creatorPage,
    isLoading: isCreatorPageLoading,
    isFetching: isFetchingCreatorPage,
    isError: isCreatorPageError,
  } = useGetCreatorPageQuery(
    { pageId: data?.pageId || '' },
    { skip: !data?.pageId }
  )

  const userRoles = session.identity.metadata_public.roles || []
  const RouteComponentsForRoles =
    injectNonPublicRouteComponentsForRoles(userRoles)
  const routes = useRoutes(RouteComponentsForRoles)

  useEffect(() => {
    if (data) {
      if (
        new Date(data.createdAt).getTime() >
        new Date(DATE_OF_HIDDEN_DESIGN_OPTIONS).getTime()
      )
        updateContext({
          userId: data.userId,
          properties: {
            userEmail: data.email,
            hideDesignOptions: 'true',
          },
        })
    }
  }, [data, updateContext])

  const hasError = isCreatorPageError || isAccountError || isAPIConfigsError
  useEffect(() => {
    if (hasError) toast.error(t('app:page_error'))
  }, [hasError, t])

  if (isAccountLoading || isCreatorPageLoading || isLoadingAPIConfigs) {
    return <LoadingIndicator size={100} isCentered />
  }

  if (!data || !creatorPage || !APIConfigs) return <ErrorApp />

  return (
    <ErrorBoundary fallback={<ErrorApp />}>
      <AccountContext.Provider
        value={{
          ...data,
          apiConfigs: APIConfigs,
        }}
      >
        <CreatorPageContext.Provider
          value={{
            ...creatorPage,
            queryStatus: {
              isLoading: isCreatorPageLoading,
              isFetching: isFetchingCreatorPage,
              isError: isCreatorPageError,
            },
          }}
        >
          <div>
            <AdminTooltip />
            {!data?.region && <AddAudienceCountry />}
            <AddToHomeScreenTooltip />
            <Sidebar />
            <StyledContainer $isSafariIOS={isSafariIOS()}>
              <SuspenseWrapper>{routes}</SuspenseWrapper>
            </StyledContainer>
          </div>
        </CreatorPageContext.Provider>
      </AccountContext.Provider>
    </ErrorBoundary>
  )
}
