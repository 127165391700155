import { useTranslation } from 'react-i18next'

import { CommissionAggregateType } from '@web-apps/utils-types'
import { computeCommissionToShowFromAggregate } from '@web-apps/utils-shared'
import { Flex, Typography } from '@web-apps/ui-shared'

type CommissionHintProps = {
  commission?: CommissionAggregateType
}

export const CommissionHint = ({ commission }: CommissionHintProps) => {
  const { t } = useTranslation(['brands'], { useSuspense: false })

  const commissionInfo = computeCommissionToShowFromAggregate(commission)

  return (
    <Flex align="center" gap={4}>
      <span role="img" aria-label="$">
        💰
      </span>
      <Typography variant="hint">
        {t(`brands:commission.${commission ? commissionInfo.type : 'none'}`, {
          commission: commission ? commissionInfo.commissionText : undefined,
        })}
      </Typography>
    </Flex>
  )
}
