import { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import ReactGA from 'react-ga4'
import { UaEventOptions } from 'react-ga4/types/ga4'

import {
  Button,
  ButtonSize,
  Flex,
  Icon,
  Theme,
  SvgIcon,
} from '@web-apps/ui-shared'
import { ANALYTICS_CATEGORY } from '@web-apps/utils-types'
import { MenuButtonOptionProps } from '../../MyPage.types'
import { ListItem } from '../'

import {
  StyledContainer,
  StyledList,
  StyledListItemButton,
  StyledPopover,
} from './MenuButton.styles'

type MenuButtonProps = {
  text: string
  options: MenuButtonOptionProps[]
  onOptionSelected?: (optionId: MenuButtonOptionProps) => void
  disabled?: boolean
  buttonGroupAnalyticsDetails?: UaEventOptions
  IconComponent?: React.ComponentType<React.PropsWithChildren<unknown>>
}

export const MenuButton = ({
  text,
  options,
  onOptionSelected,
  disabled = false,
  IconComponent = Icon.PlusCircle,
  buttonGroupAnalyticsDetails,
}: MenuButtonProps) => {
  const [showOptions, setShowOptions] = useState(false)
  const [optionSelected, setOptionSelected] = useState<MenuButtonOptionProps>()

  const buttonRef = useRef(null)
  const isShowingSubOptions = Boolean(optionSelected)

  return (
    <StyledContainer>
      <Button
        size={ButtonSize.SMALL}
        type="button"
        ref={buttonRef}
        clickHandler={() => {
          if (buttonGroupAnalyticsDetails)
            ReactGA.event(buttonGroupAnalyticsDetails)
          setShowOptions(!showOptions)
        }}
        disabled={disabled}
        width="100%"
      >
        <Flex gap={16} align="center" justify="center">
          <span>
            <SvgIcon
              color={
                disabled
                  ? Theme.Colors.typography.inactive
                  : Theme.Colors.typography.inverse
              }
            >
              <IconComponent />
            </SvgIcon>
          </span>
          <span>{text}</span>
        </Flex>
      </Button>

      {showOptions && (
        <StyledPopover
          wrapperStyles={{
            /* to leave room for our Add Section popover show all options, without scroll needed, on standard height for mobile */
            maxHeight: '664px',
          }}
          title={optionSelected?.title || text}
          onClose={() => {
            setShowOptions(false)
            setOptionSelected(undefined)
          }}
          onBackClick={() => {
            setOptionSelected(undefined)
          }}
          clickOutsideOverrideProps={{ ignoreElementRef: buttonRef }}
          withArrowBack={isShowingSubOptions}
          hideHeaderOnDesktop={!isShowingSubOptions}
        >
          <StyledList>
            {(optionSelected?.subOptions || options).map((o) => {
              const renderListItem = () => (
                <ListItem
                  {...(o?.listItemProps || {})}
                  leftDecorator={<o.IconComponent />}
                  title={o.title}
                  description={o.description}
                  tagText={o.tagText}
                  tagVariant={o.tagVariant}
                />
              )

              const onListItemClick = () => {
                ReactGA.event({
                  action: 'add_section:section_type:click',
                  label: o.title,
                  category: ANALYTICS_CATEGORY.CONTENT,
                })
                if (o.subOptions?.length) {
                  setOptionSelected(o)
                } else {
                  setShowOptions(false)
                }

                if (onOptionSelected) onOptionSelected(o)
              }

              return (
                <li key={o.id}>
                  {o.link ? (
                    <Link to={o.link} onClick={onListItemClick}>
                      {renderListItem()}
                    </Link>
                  ) : (
                    <StyledListItemButton onClick={onListItemClick}>
                      {renderListItem()}
                    </StyledListItemButton>
                  )}
                </li>
              )
            })}
          </StyledList>
        </StyledPopover>
      )}
    </StyledContainer>
  )
}
