import { useEffect, useState, useCallback, useMemo, useRef } from 'react'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

import { Box, ShareLinkContext, ShareLinkType } from '@web-apps/ui-shared'
import { api, DateHelpers } from '@web-apps/utils-shared'
import {
  AffiliateLinksListType,
  AffiliateLinkType,
} from '@web-apps/utils-types'

import {
  useGetAffiliateLinksQuery,
  useUpdateAffiliateLinkAtomicMutation,
  useUpdateAffiliateLinkMutation,
} from '../../../../../services/affiliates.api'
import { useAccountDetails } from '../../../../../utils/hooks/useAccountDetails.hooks'

import { Converter } from './Converter'
import { AffiliateLinksList } from './AffiliateLinksList'
import {
  mergeAffiliateLinksLists,
  updateAffiliateLinkInList,
} from './LinkConverterPage.utils'
import { useDispatch } from 'react-redux'

export const LinkConverterPage = () => {
  const { t } = useTranslation(['app', 'brands'])
  const { pageId } = useAccountDetails()
  const dispatch = useDispatch()

  const [shareLinkContextData, setShareLink] = useState<ShareLinkType>({
    url: '',
    container: document.body,
  })

  const [nextAPILink, setNextAPILink] = useState<string>()
  const [affiliateLinksList, setAffiliateLinksList] =
    useState<AffiliateLinksListType>()

  const affiliateLinksListRequestParams = useMemo(
    () =>
      nextAPILink
        ? { path: nextAPILink }
        : {
            pageId,
          },
    [nextAPILink, pageId]
  )

  const {
    data: affiliateLinksListPage,
    isLoading,
    isFetching,
    isError: affiliateLinksListFetchFailed,
  } = useGetAffiliateLinksQuery(affiliateLinksListRequestParams)

  const [updateAffiliateLink, { isError: isAffiliateLinkUpdateError }] =
    useUpdateAffiliateLinkMutation()
  const [updateAffiliateLinkAsync] = useUpdateAffiliateLinkAtomicMutation()

  const upsertAffiliateLink = useCallback(
    (affiliateLink: AffiliateLinkType) => {
      const createdAt = DateHelpers.dateInstanceBuilder(affiliateLink.createdAt)
      const fullYear = createdAt.year()
      const monthIndex = createdAt.month()

      setAffiliateLinksList(
        (prevList) =>
          prevList &&
          mergeAffiliateLinksLists(
            prevList,
            {
              linksByMonthDescSorted: [
                {
                  fullYear,
                  monthIndex,
                  linksDescSortedByDate: [affiliateLink],
                },
              ],
            },
            { preserveCurrentPaginationLinks: true }
          )
      )

      affiliateLinkAdded.current = true
    },
    []
  )

  const afterCreationHandling = async (affiliateLink: AffiliateLinkType) => {
    upsertAffiliateLink(affiliateLink)
    const result = await updateAffiliateLinkAsync({
      affiliateLink,
    }).unwrap()

    setAffiliateLinksList(
      (prevList) => prevList && updateAffiliateLinkInList(prevList, result)
    )
  }

  useEffect(() => {
    if (!affiliateLinksListPage) return

    setAffiliateLinksList((prevList) =>
      prevList
        ? mergeAffiliateLinksLists(prevList, affiliateLinksListPage)
        : affiliateLinksListPage
    )
  }, [affiliateLinksListPage])

  useEffect(() => {
    if (affiliateLinksListFetchFailed) {
      toast.error(t('brands:errors.affiliate_links_list_query'))
    }
  }, [affiliateLinksListFetchFailed, t])

  useEffect(() => {
    if (isAffiliateLinkUpdateError) {
      toast.error(t('app:generic_error'))
    }
  }, [isAffiliateLinkUpdateError, t])

  // In order to get the latest list fetched as we don't due to inserting items manually,
  // we need to invalidate the list on the leave page action.
  const affiliateLinkAdded = useRef(false)
  useEffect(() => {
    return () => {
      if (affiliateLinkAdded.current) {
        dispatch(api.util.invalidateTags(['AffiliateLinks']))
      }
    }
  }, [dispatch])

  return (
    <Box mb={48}>
      <Box mb={48}>
        <Converter onAffiliateLinkConverted={afterCreationHandling} />
      </Box>
      <ShareLinkContext.Provider
        value={{ ...shareLinkContextData, setShareLink }}
      >
        <AffiliateLinksList
          affiliateLinksListData={affiliateLinksList}
          onLoadMore={setNextAPILink}
          isLoading={isLoading}
          isFetching={isFetching}
          onAddingSection={(newAffiliateLinkData) => {
            upsertAffiliateLink(newAffiliateLinkData)
          }}
          onAffiliateLinkEditSubmit={async (
            newAffiliateLinkData,
            prevAffiliateLinkData
          ) => {
            upsertAffiliateLink(newAffiliateLinkData)

            if (newAffiliateLinkData.title) {
              const result = await updateAffiliateLink({
                linkId: newAffiliateLinkData.id,
                pageId,
                title: newAffiliateLinkData.title,
              })

              // rollback information in case of error
              if ('error' in result) upsertAffiliateLink(prevAffiliateLinkData)
            }
          }}
        />
      </ShareLinkContext.Provider>
    </Box>
  )
}
