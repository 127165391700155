import { useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { Link, useParams, useLocation } from 'react-router-dom'

import {
  AnalyticsMetricPanelsDashboard,
  Box,
  Card,
  Flex,
  Icon,
  IconCatalogPaths,
  LoadingIndicator,
  TextEllipsis,
  Typography,
  useShareLinkData,
  StyledNonSelectableText,
} from '@web-apps/ui-shared'
import {
  getAvatarImage,
  LEGACY_AFFILIATE_LINKS,
  useGetAffiliateLinkAnalyticsQuery,
  useGetAffiliateLinkQuery,
} from '@web-apps/feature-creator-page'
import {
  computeCommissionToShowFromAggregate,
  creatorUrlBuilders,
  imageUrlBuilder,
  nativeShareWrapper,
} from '@web-apps/utils-shared'
import { AffiliateLinkType } from '@web-apps/utils-types'
import { COPY_BUTTON_MESSAGES_KEYS } from '../../../../components/CopyButton/CopyButton'
import {
  PageHeader,
  PageTitle,
  PageWrapper,
  StyledTitleWrapper,
} from '../../../../components/PageComponents'
import { ROUTES } from '../../../../routes/RouteEnums'
import { useAccountDetails } from '../../../../utils/hooks/useAccountDetails.hooks'
import { useSearchPeriod } from '../hooks/useSelectorParams'
import { useCreatorPage } from '../../../../utils/hooks/useCreatorPage.hooks'
import {
  formatChartDate,
  formatChartTooltipDate,
  transformTimeseriesPointToChartData,
} from '../Analytics.utils'
import { PeriodSelector } from '../components/PeriodSelector'
import { AffiliateLinkAnalyticsChart } from '../components/AffiliateLinkAnalyticsChart'
import { useAffiliateLinkPanelProps } from './AffiliateLinkAnalyticsDetails.utils'

import {
  StyledAffiliateLinkImage,
  StyledHeaderContent,
  StyledHeaderDescrContent,
  StyledShareButton,
  StyledHeaderWrapper,
  StyledShareLinkPopup,
} from './AffiliateLinkAnalyticsDetails.styles'
import { useReturnLink } from '../../../../utils/hooks/useReturnLink.hooks'

export const AffiliateLinkAnalyticsDetails = () => {
  const { t } = useTranslation(
    ['app', 'metrics', 'errors', 'creator', 'brands'],
    { useSuspense: false }
  )

  const shareRef = useRef(null)
  const { pageId } = useAccountDetails()
  const creatorPage = useCreatorPage()
  const { setShareLink, url: shareUrl } = useShareLinkData()

  const { search } = useLocation()
  const params = useParams<{ linkId: string }>()
  const linkId = params.linkId as string
  const period = useSearchPeriod()
  const isLegacyLink = linkId === LEGACY_AFFILIATE_LINKS
  const [returnLink] = useReturnLink(`${ROUTES.ANALYTICS_EARNINGS}${search}`)

  const {
    data,
    isLoading: isAffiliateLinkLoading,
    isError: isAffiliateLinkError,
  } = useGetAffiliateLinkQuery(
    { pageId, linkId },
    { skip: !linkId || isLegacyLink }
  )
  const {
    data: chartData,
    isLoading: isAffiliateLinkAnalyticsLoading,
    isError: isAffiliateLinkAnalyticsError,
  } = useGetAffiliateLinkAnalyticsQuery({
    pageId,
    period,
    linkId: isLegacyLink ? undefined : linkId,
  })

  const affiliateLink: AffiliateLinkType | undefined = useMemo(() => {
    const imageUrl = imageUrlBuilder({
      image: getAvatarImage(creatorPage),
      width: 200,
    })
    const url = creatorUrlBuilders.creatorUrlBuilder({
      creatorSlug: creatorPage._links.self.name,
    })
    return isLegacyLink
      ? {
          id: LEGACY_AFFILIATE_LINKS,
          title: t('metrics:my_legacy_links'),
          createdAt: '',
          originalUrl: url,
          affiliateUrl: url,
          author: {
            name: creatorPage._links.author.title as string,
            avatarUrl: imageUrl,
          },
          imageUrl,
          api: {
            addAsSection: '',
            addToGrid: '',
          },
        }
      : data
  }, [isLegacyLink, data, creatorPage, t])

  const commissionInfo = computeCommissionToShowFromAggregate(
    affiliateLink?.commission
  )

  const metrics = useAffiliateLinkPanelProps(chartData?.aggregate, {
    withClicks: true,
    withSales: commissionInfo.type === 'cpa',
  })
  const isError = useMemo(
    () => isAffiliateLinkAnalyticsError || isAffiliateLinkError,
    [isAffiliateLinkAnalyticsError, isAffiliateLinkError]
  )
  const isLoading = useMemo(
    () => isAffiliateLinkAnalyticsLoading || isAffiliateLinkLoading,
    [isAffiliateLinkAnalyticsLoading, isAffiliateLinkLoading]
  )

  useEffect(() => {
    if (isError) {
      toast.error(t('brands:errors.affiliate_link_query'))
    }
  }, [isError, t])

  return (
    <>
      <PageHeader hasArrowBack>
        <StyledTitleWrapper>
          <Link to={returnLink}>
            <Icon.ArrowBack />
          </Link>
          <PageTitle title={t('metrics:affiliate_analytics_details.title')} />
        </StyledTitleWrapper>
      </PageHeader>
      <PageWrapper>
        {isLoading && <LoadingIndicator size={100} isCentered />}
        {affiliateLink && (
          <>
            <div>
              <Box mb={48}>
                <StyledHeaderWrapper align="start" justify="space-between">
                  <StyledHeaderContent align="center" gap={8}>
                    <StyledAffiliateLinkImage
                      alt={
                        affiliateLink.title ||
                        t('brands:affiliate_links_list.no_title_placeholder')
                      }
                      src={
                        affiliateLink.imageUrl ||
                        IconCatalogPaths.ProductImagePlaceholder
                      }
                    />
                    <StyledHeaderDescrContent>
                      <Typography
                        variant="subTitleBig"
                        as="span"
                        textTransform="none"
                        fontWeight={600}
                        style={{ display: 'flex' }}
                      >
                        <TextEllipsis>
                          {affiliateLink.title ||
                            affiliateLink.brandName ||
                            t(
                              'brands:affiliate_links_list.no_title_placeholder'
                            )}
                        </TextEllipsis>
                      </Typography>

                      <StyledNonSelectableText>
                        <Typography
                          variant="hint"
                          as="span"
                          color="inactive"
                          title={affiliateLink.originalUrl}
                          style={{ display: 'flex' }}
                        >
                          <TextEllipsis>
                            {affiliateLink.originalUrl}
                          </TextEllipsis>
                        </Typography>
                      </StyledNonSelectableText>
                      <Flex align="center" gap={2}>
                        <span
                          role="img"
                          aria-label="$"
                          style={{ lineHeight: '16px' }}
                        >
                          💰
                        </span>{' '}
                        <Typography variant="hint">
                          {t(`brands:commission.${commissionInfo.type}`, {
                            commission: commissionInfo.commissionText,
                          })}
                        </Typography>
                      </Flex>
                    </StyledHeaderDescrContent>
                  </StyledHeaderContent>

                  {!isLegacyLink && (
                    <StyledShareButton
                      ref={shareRef}
                      clickHandler={() => {
                        const url = creatorUrlBuilders.generateUtmShareURL({
                          originalURL: affiliateLink.affiliateUrl,
                        })

                        return nativeShareWrapper(url, () =>
                          setShareLink({
                            url,
                            container: shareRef.current,
                          })
                        )
                      }}
                      inverse
                    >
                      <Flex
                        gap={12}
                        justify="center"
                        align="center"
                        translate="no"
                      >
                        <Icon.Share />
                        {t('creator:share_page_button')}
                      </Flex>
                    </StyledShareButton>
                  )}
                </StyledHeaderWrapper>
              </Box>
              <Box mb={24}>
                <PeriodSelector
                  dates={{
                    start: chartData?.timeseries[0].date,
                    end: chartData?.timeseries[chartData.timeseries.length - 1]
                      .date,
                  }}
                />
              </Box>
            </div>
            <Box mb={24}>
              <Card>
                {chartData && (
                  <AffiliateLinkAnalyticsChart
                    tickFormatter={(label) => formatChartDate(label, period)}
                    labelFormatter={(label) =>
                      formatChartTooltipDate(label, period)
                    }
                    withClicks={true}
                    withSales={Boolean(commissionInfo.type === 'cpa')}
                    data={chartData.timeseries.map(
                      transformTimeseriesPointToChartData
                    )}
                  />
                )}
                {metrics && (
                  <AnalyticsMetricPanelsDashboard panelsProps={metrics} />
                )}
              </Card>
            </Box>
          </>
        )}
      </PageWrapper>
      {shareUrl && (
        <StyledShareLinkPopup
          title={t('app:share_popup.share_link')}
          messages={{
            t,
            namespace: 'app',
            copyButton: {
              buttonText: t(COPY_BUTTON_MESSAGES_KEYS.buttonText),
              copiedTooltipText: t(COPY_BUTTON_MESSAGES_KEYS.copiedTooltipText),
            },
          }}
          direction="left"
        />
      )}
    </>
  )
}
