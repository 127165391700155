import styled, { css } from 'styled-components'

import { Theme, Flex, TextEllipsis, Button } from '@web-apps/ui-shared'

export const StyledDeleteButton = styled(Button)`
  padding: 4px;
  border-radius: 8px;
  color: ${Theme.Colors.typography.inactive};

  svg,
  path {
    fill: ${Theme.Colors.typography.inactive};
  }

  &:hover {
    background-color: ${Theme.Colors.lightSand};
    color: ${Theme.Colors.typography.danger};

    svg path {
      fill: ${Theme.Colors.typography.danger};
    }
  }
`

export const StyledEditButton = styled(Button)`
  padding: 4px;
  border-radius: 8px;
  color: ${Theme.Colors.typography.inactive};

  svg,
  path {
    fill: ${Theme.Colors.typography.inactive};
  }

  &:hover {
    background-color: ${Theme.Colors.lightSand};
    color: ${Theme.Colors.typography.regular};

    svg path {
      fill: ${Theme.Colors.typography.regular};
    }
  }
`

export const StyledDraggableListItemContent = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: column;

  padding: 16px;

  /* Needed for ellipsis to have effect on children - https://stackoverflow.com/a/66689926/1085387 */
  min-width: 0;
`

export const StyledDraggableListItemSubtitle = styled(TextEllipsis)`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  color: ${Theme.Colors.typography.inactive};
`

export const StyledDraggableListItemTitle = styled(TextEllipsis)`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`

export const StyledDraggableListItemActionsContainer = styled(Flex)`
  gap: 8px;
  padding-inline-end: 16px;
  align-self: stretch;
  align-items: center;

  font-size: 12px;
  font-weight: 600;
  line-height: 24px;
`

export const StyledHiddenDraggableContainer = styled.div<{
  $isHidden?: boolean
}>`
  ${({ $isHidden }) =>
    $isHidden &&
    css`
      display: none;
    `}}
`
