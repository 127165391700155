import { useState, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useFieldArray } from 'react-hook-form'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import ReactTooltip from 'react-tooltip'
import { TransitionGroup } from 'react-transition-group'
import { toast } from 'react-toastify'

import { ContainerSectionAPIPayloadType } from '@web-apps/utils-types'
import {
  Box,
  Button,
  ButtonVariant,
  Confirm,
  DraggableCard,
  ErrorOption,
  Flex,
  FormArgsType,
  Icon,
  IconCatalogPaths,
  ShowHideAnimationWrapper,
  StyledError,
  StyledLabelText,
  Tag,
  Theme,
  Typography,
  useShareLinkData,
} from '@web-apps/ui-shared'
import { FormFieldBox } from '../../../../../../components'
import {
  ProductFormType,
  ProductRecommendationsFormType,
} from '../../SectionProductionRecommendations.types'
import {
  StyleConvertedTagMobile,
  StyledConvertedTag,
  StyledConvertedTagWrapper,
  StyledProductCardContent,
  StyledProductImage,
  StyledProductInfoContainer,
  StyledProductsItem,
} from './NestedSectionsControl.styles'
import {
  StyledDeleteButton,
  StyledDraggableListItemActionsContainer,
  StyledDraggableListItemTitle,
  StyledEditButton,
} from '../../../components'
import { AffiliateStatusTag } from '../../../components/AffiliateStatusTag'
import {
  computeCommissionToShowFromAggregate,
  creatorUrlBuilders,
  nativeShareWrapper,
} from '@web-apps/utils-shared'
import { NestedProductForm } from '../NestedProductForm'
import { useDeleteCreatorPageSectionMutation } from '@web-apps/feature-creator-page'
import { formatContainerToForm } from '../../SectionProductRecommendationsForm.adapters'
import { useFlag } from '@unleash/proxy-client-react'
import { FLAG_COMMISSION_SOURCE_SPLITTING } from '../../../../../../utils/constants/flags.constants'

type Props = {
  formReturnProps: FormArgsType<ProductRecommendationsFormType>
  isLoading: boolean
  section?: ContainerSectionAPIPayloadType
}

export const PRODUCTS_COUNT_LIMIT = 20

export const NestedSectionsControl = ({
  formReturnProps,
  isLoading,
  section,
}: Props) => {
  const { t } = useTranslation(['app', 'creator', 'brands'], {
    useSuspense: false,
  })
  const isCommissionSourceSplittingEnabled = useFlag(
    FLAG_COMMISSION_SOURCE_SPLITTING
  )
  const ref = useRef<{ [key: number]: HTMLDivElement | null }>({})
  const { setShareLink } = useShareLinkData()
  const [selectedData, setProductFormData] = useState<{
    product: ProductFormType
    index?: number
  } | null>(null)
  const [productToDelete, setProductToDelete] = useState<{
    index: number
    id?: string
  } | null>()
  const {
    control,
    reset,
    formState: { errors },
  } = formReturnProps
  const { fields, append, update, remove, move } = useFieldArray({
    control: control,
    name: 'products',
  })
  const [
    deleteItemSection,
    {
      isSuccess: isDeleteItemSuccess,
      isError: isDeleteItemError,
      isLoading: isDeleteItemLoading,
    },
  ] = useDeleteCreatorPageSectionMutation()

  const handleDelete = () => {
    if (productToDelete) {
      if (productToDelete.id && section) {
        const path = section.items.find(
          (item) => item._links.self.id === productToDelete.id
        )?._links.self.href
        path && deleteItemSection({ path })
      }

      remove(productToDelete.index)
      setProductToDelete(null)
    }
  }
  useEffect(() => {
    if (isDeleteItemSuccess) {
      toast.success(
        t('creator:toast.edit_success', {
          section: '$t(creator:sections.product_recommendations)',
          count: Number.POSITIVE_INFINITY,
        })
      )
    }

    if (isDeleteItemError) {
      toast.error(t('creator:product_recommendations.edit_error'))
    }
  }, [isDeleteItemSuccess, isDeleteItemError, t])

  useEffect(() => {
    if (section) reset(formatContainerToForm(section))
  }, [section, reset])

  const productsLimitReached = fields.length >= PRODUCTS_COUNT_LIMIT

  return (
    <>
      <FormFieldBox>
        <StyledLabelText>
          {t('creator:product_recommendations.field_label_items')}
        </StyledLabelText>

        <Box mt={16}>
          <Button
            width="100%"
            type="button"
            disabled={productsLimitReached}
            isLoading={isLoading || isDeleteItemLoading}
            clickHandler={() =>
              setProductFormData({ product: { title: '', href: '' } })
            }
          >
            <Flex gap={12} justify="center">
              <Icon.PlusCircle
                width={20}
                height={20}
                fillColor={Theme.Colors.typography.inverse}
              />
              <span>
                {productsLimitReached
                  ? t('creator:product_recommendations.add_product_limit', {
                      limit: PRODUCTS_COUNT_LIMIT,
                    })
                  : t('creator:product_recommendations.button_text_add_item')}
              </span>
            </Flex>
          </Button>
          {/* needed to use "as" because typescript is telling errors.products is an Array which is not true */}
          {(errors.products as ErrorOption)?.message && (
            <StyledError>
              {(errors.products as ErrorOption).message}
            </StyledError>
          )}
        </Box>
      </FormFieldBox>
      {fields.length > 0 && (
        <div data-ignore-by-form>
          <DragDropContext
            onDragEnd={(result) => {
              if (result.destination && result.source) {
                move(result.source.index, result.destination.index)
              }
            }}
          >
            <Droppable droppableId="product-droppable">
              {(provided) => (
                <ul {...provided.droppableProps} ref={provided.innerRef}>
                  <TransitionGroup component={null}>
                    {fields.map((p, index) => {
                      const commissionInfo =
                        computeCommissionToShowFromAggregate(p.commission)

                      return (
                        <ShowHideAnimationWrapper key={p.id} in>
                          <Draggable
                            isDragDisabled={isLoading}
                            key={p.id}
                            draggableId={p.id}
                            index={index}
                            shouldRespectForcePress
                          >
                            {(provided) => (
                              <StyledProductsItem
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <DraggableCard>
                                  <StyledProductCardContent>
                                    <Flex gap={12} align="center">
                                      <StyledProductImage
                                        alt={p.title}
                                        src={
                                          p.image?.href ||
                                          IconCatalogPaths.ProductImagePlaceholder
                                        }
                                      />
                                      <StyledProductInfoContainer>
                                        {p.title ? (
                                          <StyledDraggableListItemTitle>
                                            {p.title}
                                          </StyledDraggableListItemTitle>
                                        ) : (
                                          <Typography
                                            fontStyle="italic"
                                            color="inactive"
                                          >
                                            {t(
                                              'creator:product_recommendations.no_title'
                                            )}
                                          </Typography>
                                        )}
                                        {!isCommissionSourceSplittingEnabled && (
                                          <Box my={4}>
                                            <Flex align="center" gap={4}>
                                              <Typography
                                                lineHeight="16px"
                                                as="span"
                                              >
                                                💰
                                              </Typography>
                                              {p.commission ? (
                                                <Typography variant="hint">
                                                  {t(
                                                    `brands:commission.${commissionInfo.type}`,
                                                    {
                                                      commission:
                                                        commissionInfo.commissionText,
                                                    }
                                                  )}
                                                </Typography>
                                              ) : (
                                                <Typography
                                                  fontStyle="italic"
                                                  variant="hint"
                                                  color="inactive"
                                                >
                                                  {t('brands:commission.none')}
                                                </Typography>
                                              )}
                                            </Flex>
                                          </Box>
                                        )}
                                      </StyledProductInfoContainer>
                                      {p.alternate && (
                                        <StyledConvertedTagWrapper
                                          data-tip={true}
                                          data-for="converted-tag"
                                        >
                                          <ReactTooltip
                                            id="converted-tag"
                                            multiline
                                            effect="solid"
                                            place="right"
                                            type="light"
                                          >
                                            <Box w={160}>
                                              <Box mb={4}>
                                                <Typography
                                                  variant="hint"
                                                  fontWeight="bold"
                                                >
                                                  <Flex gap={4}>
                                                    <Icon.PlainCheck
                                                      fillColor={
                                                        Theme.Button
                                                          .backgroundColor
                                                      }
                                                    />
                                                    {t(
                                                      'creator:affiliate_links.status_tag.converted_description.title'
                                                    )}
                                                  </Flex>
                                                </Typography>
                                              </Box>
                                              <Typography variant="hint">
                                                {t(
                                                  'creator:affiliate_links.status_tag.converted_description.content'
                                                )}
                                              </Typography>
                                            </Box>
                                          </ReactTooltip>
                                          <StyleConvertedTagMobile>
                                            <Tag variant="success" showIcon />
                                          </StyleConvertedTagMobile>
                                          <StyledConvertedTag ml="auto">
                                            <AffiliateStatusTag status="converted_static" />
                                          </StyledConvertedTag>
                                        </StyledConvertedTagWrapper>
                                      )}
                                    </Flex>
                                    <Flex
                                      justify="space-between"
                                      align="center"
                                    >
                                      <StyledDraggableListItemActionsContainer>
                                        <StyledEditButton
                                          variant={ButtonVariant.PLAIN}
                                          type="button"
                                          clickHandler={() =>
                                            setProductFormData({
                                              product: p,
                                              index,
                                            })
                                          }
                                        >
                                          <Flex align="center" gap={2}>
                                            <Icon.EditPen
                                              width={16}
                                              height={16}
                                            />
                                            {t('creator:button_edit')}
                                          </Flex>
                                        </StyledEditButton>
                                        <div
                                          ref={(element) =>
                                            (ref.current[index] = element)
                                          }
                                          style={{
                                            position: 'relative',
                                          }}
                                        >
                                          <StyledEditButton
                                            variant={ButtonVariant.PLAIN}
                                            type="button"
                                            clickHandler={() => {
                                              const url =
                                                creatorUrlBuilders.generateUtmShareURL(
                                                  {
                                                    originalURL:
                                                      p.alternate || p.href,
                                                  }
                                                )
                                              return nativeShareWrapper(
                                                url,
                                                () =>
                                                  setShareLink({
                                                    url,
                                                    copyUrl: url,
                                                    container:
                                                      ref.current[index],
                                                  })
                                              )
                                            }}
                                          >
                                            <Flex
                                              align="center"
                                              gap={2}
                                              translate="no"
                                            >
                                              <Icon.Share
                                                width={16}
                                                height={16}
                                              />
                                              {t('creator:button_share')}
                                            </Flex>
                                          </StyledEditButton>
                                        </div>

                                        {fields.length > 1 && (
                                          <StyledDeleteButton
                                            variant={ButtonVariant.PLAIN}
                                            type="button"
                                            clickHandler={() =>
                                              setProductToDelete({
                                                index,
                                                id: p.id,
                                              })
                                            }
                                          >
                                            <Flex align="center" gap={2}>
                                              <Icon.DeleteBin
                                                width={16}
                                                height={16}
                                              />
                                              {t('creator:button_delete')}
                                            </Flex>
                                          </StyledDeleteButton>
                                        )}
                                      </StyledDraggableListItemActionsContainer>
                                    </Flex>
                                  </StyledProductCardContent>
                                </DraggableCard>
                              </StyledProductsItem>
                            )}
                          </Draggable>
                        </ShowHideAnimationWrapper>
                      )
                    })}
                  </TransitionGroup>
                  {provided.placeholder}
                </ul>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      )}
      {selectedData && (
        <NestedProductForm
          section={section}
          append={append}
          update={update}
          onClose={() => setProductFormData(null)}
          product={selectedData.product}
          productIndex={selectedData.index}
        />
      )}
      {productToDelete && (
        <Confirm
          onDismiss={() => setProductToDelete(null)}
          title={t(
            'creator:product_recommendations.delete_product_dialog_title'
          )}
          buttons={[
            {
              label: t('creator:confirm.cancel'),
              onClick: () => setProductToDelete(null),
              inverse: true,
            },
            {
              label: t('creator:confirm.delete'),
              onClick: handleDelete,
            },
          ]}
        />
      )}
    </>
  )
}
