import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { useFlag } from '@unleash/proxy-client-react'

import { Box, Flex, Icon, Typography } from '@web-apps/ui-shared'
import { FLAG_MAINTENANCE } from '../../utils/constants/flags.constants'
import {
  StyledWrapper,
  StyledContainer,
  StyledContent,
} from './MaintenanceWrapper.styles'

type Props = {
  children: ReactNode
}

export const MaintenanceWrapper = ({ children }: Props) => {
  const { t } = useTranslation(['app'])
  const isMaintenanceEnabled = useFlag(FLAG_MAINTENANCE)

  if (isMaintenanceEnabled) {
    return (
      <StyledWrapper>
        <StyledContainer>
          <Flex direction="column" align="center" justify="center">
            <Box mb={24}>
              <Icon.LogoFull width={180} height={30} />
            </Box>
            <StyledContent>
              <Typography variant="h2">{t('app:maintenance.title')}</Typography>
              <Typography>{t('app:maintenance.description')}</Typography>
            </StyledContent>
          </Flex>
        </StyledContainer>
      </StyledWrapper>
    )
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>
}
