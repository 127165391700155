import styled from 'styled-components'

import {
  BREAKPOINTS,
  media,
  Theme,
  PlainButton,
  Popover,
} from '@web-apps/ui-shared'

export const StyledSharePopover = styled(Popover)`
  ${media.from(
    BREAKPOINTS.sm,
    `
      position: fixed;
      top: ${Theme.CreatorApp.header.height}px;
      right: 48px;
      left: unset;
      width: 375px;
  `
  )}
`

export const StyledSection = styled(PlainButton)`
  display: block;
  width: 100%;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  padding-block: 16px;

  &:not(:last-child) {
    border-bottom: 1px solid ${Theme.Colors.border.regular};
  }
`
