import { useTranslation } from 'react-i18next'

import { FormSections } from './MyPageSettings.utils'
import { PageUrlSection } from './PageUrlSection'
import { SocialLinksSection } from './SocialLinksSection'
import { FormSection } from './FormSection'
import { FormIndex } from './FormIndex'
import { useAccountDetails } from '../../../../utils/hooks/useAccountDetails.hooks'
import { useCreatorPage } from '../../../../utils/hooks/useCreatorPage.hooks'

export const MyPageSettings = () => {
  const { t } = useTranslation(['app', 'creator'])
  const { userId } = useAccountDetails()
  const creatorPage = useCreatorPage()

  return (
    <>
      <FormIndex />
      <FormSection
        sectionId={FormSections.SOCIAL_LINKS}
        title={t('creator:settings_tab.social_links_title')}
      >
        {creatorPage && (
          <SocialLinksSection creatorPage={creatorPage} userNanoId={userId} />
        )}
      </FormSection>
      <FormSection
        sectionId={FormSections.MY_PAGE_URL}
        title={t('creator:settings_tab.my_page_url_title')}
      >
        {creatorPage && <PageUrlSection slug={creatorPage._links.self.name} />}
      </FormSection>
    </>
  )
}
