import styled from 'styled-components'
import { BREAKPOINTS, Flex, media } from '@web-apps/ui-shared'

export const StyledContainer = styled.div`
  position: relative;
  min-height: 64px;
`

export const StyledContent = styled.div`
  margin: 24px 0;
  min-height: 64px;
  display: grid;

  gap: 24px;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr)) [last];

  ${media.from(
    BREAKPOINTS.md,
    `
      grid-template-columns: repeat(auto-fill, minmax(220px, 1fr)) [last];
    `
  )}
`

export const StyledLoadMoreRow = styled(Flex)`
  grid-column: 1 / span last;
`

export const StyledClearBtn = styled.div`
  cursor: pointer;
`
