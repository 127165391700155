import { api } from '@web-apps/utils-shared'

type PostRegistrationArgs = {
  creatorPageEndpoint: string
}

export const authApi = api.injectEndpoints({
  endpoints: (builder) => ({
    registerUser: builder.mutation<
      void,
      {
        slug: string
        email: string
        provider?: string
        password?: string
        time_zone?: string
        page_import_id?: string
        is_metapic_referral?: boolean
      }
    >({
      query: (payload) => ({
        path: 'users',
        method: 'put',
        data: payload,
      }),
    }),
    postRegistration: builder.mutation<
      { checkout: string },
      PostRegistrationArgs
    >({
      async queryFn(
        { creatorPageEndpoint },
        _queryApi,
        _extraOptions,
        fetchWithBQ
      ) {
        const { error: creatorPageError } = await fetchWithBQ({
          path: creatorPageEndpoint,
          method: 'get',
        })

        if (creatorPageError) throw creatorPageError

        return { data: { checkout: '' } }
      },
    }),
  }),
})

export const { useRegisterUserMutation, usePostRegistrationMutation } = authApi
