import React from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

export const MetaHead = () => {
  const { t } = useTranslation('meta')

  return (
    <Helmet>
      <title translate="no">{t('meta:main_title')}</title>
      <meta
        translate="no"
        name="description"
        content={t('meta:main_description')}
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={`${process.env.PUBLIC_URL}/apple-touch-icon.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href={`${process.env.PUBLIC_URL}/favicon-32x32.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href={`${process.env.PUBLIC_URL}/favicon-16x16.png`}
      />
      <link
        rel="manifest"
        href={`${process.env.PUBLIC_URL}/site.webmanifest`}
      />
      <link
        rel="mask-icon"
        href={`${process.env.PUBLIC_URL}/safari-pinned-tab.svg`}
        color="#333333"
      />
      <link
        rel="shortcut icon"
        href={`${process.env.PUBLIC_URL}/favicon.ico`}
      />
      <meta
        name="msapplication-config"
        content={`${process.env.PUBLIC_URL}/browserconfig.xml`}
      />
      <meta name="msapplication-TileColor" content="#2b5797" />
      <meta name="theme-color" content="'#ffffff'" />
    </Helmet>
  )
}
