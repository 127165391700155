import { Typography, Box } from '@web-apps/ui-shared'

import { DEFAULT_FORM_FIELD_MARGIN_BOTTOM_IN_PX } from '../../../utils/constants/theme.constants'

type FormFieldBoxProps = React.ComponentProps<typeof Box> & {
  title?: string | React.ReactNode
}

export const FormFieldBox = ({
  mb = DEFAULT_FORM_FIELD_MARGIN_BOTTOM_IN_PX,
  title,
  ...boxProps
}: FormFieldBoxProps) => (
  <Box mb={mb} {...boxProps}>
    {title && (
      <Box mb={8}>
        <Typography variant="h4">{title}</Typography>
      </Box>
    )}
    {boxProps.children}
  </Box>
)
