import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { Box, Theme } from '@web-apps/ui-shared'

export const PayoutsHistorySkeleton = () => {
  return (
    <div>
      <Box p={8}>
        <Skeleton />
      </Box>
      {Array(5)
        .fill(0)
        .map((_, index) => (
          <div
            key={index}
            style={{ borderTop: `1px solid ${Theme.Colors.border.darker}` }}
          >
            <Box px={8} py={16} key={index}>
              <Skeleton />
            </Box>
          </div>
        ))}
    </div>
  )
}
