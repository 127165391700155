import React from 'react'
import { Link } from 'react-router-dom'
import { Box, Typography } from '@web-apps/ui-shared'

export const NotFound = () => {
  return (
    <Box p={48}>
      <Typography variant="h1">404 - Page not found</Typography>
      <Box my={24}>
        <Typography variant="h3">
          The page you were looking for was not found
        </Typography>
      </Box>
      <Link to="/">
        <Typography>&larr; Back to home</Typography>
      </Link>
    </Box>
  )
}
