import {
  MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import {
  Box,
  Button,
  ButtonVariant,
  Flex,
  Form,
  FormArgsType,
  Icon,
  MAX_TITLE_LENGTH,
  ShowHideAnimationWrapper,
  Switch,
  SwitchVariant,
  TextField,
  Theme,
} from '@web-apps/ui-shared'
import {
  AffiliateLinkConversionData,
  ExternalLinkSectionFormType,
  SectionTypeEnum,
} from '@web-apps/utils-types'
import { FormValidations } from '@web-apps/utils-shared'
import { useURLInputHandlers } from '../../hooks/useURLInputHandlers.hook'
import { ROUTES } from '../../../../../routes/RouteEnums'
import {
  StyledFormContainer,
  StyledFormLabel,
} from '../../../../../components/styles'
import { AffiliateTooltip } from '../AffiliateTooltip'
import { SectionLinkStyleFormBlock } from '../SectionLinkStyleFormBlock'
import { FormActionsBar, FormFieldBox } from '../../../../../components'
import { PublishedRedirectSectionControls } from '../PublishedRedirectSectionControls'
import { DeleteBlock } from '../DeleteBlock'
import { useInspectLink } from '../../hooks/useInspectLink.hook'

export enum InspectModeEnum {
  INSPECT, // The link will be inspected and only convertible links are allowed
  OPTIONAL_INSPECT, // The link will be inspected but user can save not convertible link
  IGNORE_INSPECT, // The inspect request will be ignored (edit mode)
}

type Props = {
  pageId?: string
  handleSubmit: (d: ExternalLinkSectionFormType) => void
  handleDelete?: () => void
  updatePreview: (form: { data: ExternalLinkSectionFormType }) => void
  initialValues: ExternalLinkSectionFormType
  isLoading: boolean
  inspectMode: InspectModeEnum
  commission?: AffiliateLinkConversionData['commission']
}

export const SectionLinkForm = ({
  initialValues,
  isLoading,
  handleSubmit,
  handleDelete,
  updatePreview,
  inspectMode,
  commission,
}: Props) => {
  const navigate = useNavigate()
  const subscription = useRef<ReturnType<FormArgsType['watch']>>()
  const { t } = useTranslation(['app', 'creator', 'brands'], {
    useSuspense: false,
  })
  const isNotEditable = !!commission
  const [
    inspect,
    {
      status,
      data: inspectResponse,
      isError,
      isLoading: isInspectionLoading,
      reset,
    },
  ] = useInspectLink({
    defaultStatus:
      inspectMode === InspectModeEnum.IGNORE_INSPECT ? 'ignored' : 'initial',
    ignoreMetapicLink: inspectMode === InspectModeEnum.OPTIONAL_INSPECT,
  })
  const [hasTagline, toggleTagline] = useState(!!initialValues.tagline)
  const [originalUrl, setOriginalUrl] = useState(initialValues.href)
  const isUrlNotConvertable = useMemo(() => {
    return (
      inspectMode === InspectModeEnum.INSPECT &&
      ((inspectResponse && !inspectResponse?.isConvertible) || isError)
    )
  }, [inspectResponse, isError, inspectMode])

  const handleCancel: MouseEventHandler<HTMLElement> = (event) => {
    event.preventDefault()
    navigate(ROUTES.MY_PAGE)
  }

  const onSubmit = useCallback(
    ({ commissionMatchName, ...data }: ExternalLinkSectionFormType) => {
      if (!commissionMatchName) {
        return handleSubmit({
          ...data,
          type: commission
            ? SectionTypeEnum.AFFILIATE_LINK
            : SectionTypeEnum.EXTERNAL_LINK,
        })
      }

      const commissionMatch = inspectResponse?.matches?.find(
        (item) => item.name === commissionMatchName
      )

      if (!commissionMatch) {
        toast.error('app:generic_error')
      } else {
        handleSubmit({
          ...data,
          path: commissionMatch.createSectionApiEndpoint,
        })
      }
    },
    [inspectResponse, handleSubmit, commission]
  )

  const { onPaste, onChangeBuilder, onBlurBuilder, onKeyPressBuilder } =
    useURLInputHandlers({
      onValueChange: async (uri) => {
        if (inspectMode !== InspectModeEnum.IGNORE_INSPECT) {
          if (FormValidations.isValidWebURL(uri)) {
            inspect(uri)
          } else {
            reset()
          }
        }
        setOriginalUrl(uri)
      },
    })

  useEffect(() => () => subscription?.current?.unsubscribe(), [])

  return (
    <Form
      initialWatchableFields={['published', 'href', 'commissionMatchName']}
      initialValues={initialValues}
      onSubmit={onSubmit}
      validations={{
        descriptors: {
          label: { required: t('app:field_is_required') },
          ...(!isNotEditable && {
            href: {
              required: t('app:field_is_required'),
              maxLength: {
                value: FormValidations.urlMaxLength,
                message: t('app:field_invalid_max_length'),
              },
              validate: (value) =>
                FormValidations.isValidURL(value)
                  ? true
                  : t('app:invalid_url_address'),
            },
          }),
        },
      }}
    >
      {(formProps) => {
        const {
          setValue,
          setFocus,
          getValues,
          control,
          watch,
          register,
          unregister,
        } = formProps
        const setURLValue = (value: string) => setValue('href', value)

        if (!subscription.current) {
          subscription.current = watch((data) => {
            updatePreview({ data })
          })
        }

        return (
          <>
            <StyledFormContainer>
              <TextField
                name="href"
                disabled={isNotEditable}
                leftDecorator={<Icon.Link />}
                maxLength={FormValidations.urlMaxLength}
                label={t('creator:custom_link.field_label_target_url')}
                onPaste={onPaste}
                onChange={onChangeBuilder(setURLValue)}
                onBlur={onBlurBuilder(setURLValue)}
                onKeyPress={onKeyPressBuilder(getValues('href'), setURLValue)}
                showErrorState={isUrlNotConvertable}
                showErrorIcon={!isUrlNotConvertable}
                rightDecorator={
                  isUrlNotConvertable ? (
                    <Button
                      variant={ButtonVariant.PLAIN}
                      clickHandler={() => {
                        setFocus('href')
                        setURLValue('')
                        reset()
                      }}
                    >
                      <Icon.Close fillColor={Theme.Colors.background.dark} />
                    </Button>
                  ) : undefined
                }
              />

              <ShowHideAnimationWrapper state={status}>
                <AffiliateTooltip
                  showButton
                  formReturnProps={formProps}
                  status={status}
                  commission={inspectResponse?.commission}
                  missedHints={inspectResponse?.missedHints}
                  matches={inspectResponse?.matches}
                />
              </ShowHideAnimationWrapper>
            </StyledFormContainer>
            <StyledFormContainer>
              <FormFieldBox
                mb={0}
                title={t('creator:custom_link.title_section_button_style')}
              >
                <SectionLinkStyleFormBlock
                  formReturnProps={formProps}
                  externalUrl={originalUrl}
                />
                <FormFieldBox mt={24}>
                  <TextField
                    name="label"
                    label={t('creator:custom_link.field_label_title')}
                    showAllowedChars
                    maxLength={MAX_TITLE_LENGTH}
                  />
                </FormFieldBox>
                <Flex justify="space-between" align="center">
                  <StyledFormLabel>
                    {t('creator:custom_link.field_label_tagline')}
                  </StyledFormLabel>
                  <Switch
                    name="uncontrolled_hasTagline"
                    variant={SwitchVariant.SMALL}
                    onChange={() => {
                      if (hasTagline) {
                        setValue('tagline', undefined)
                        unregister('tagline')
                      }
                      toggleTagline(!hasTagline)
                    }}
                    checked={hasTagline}
                  />
                </Flex>
                <ShowHideAnimationWrapper in={hasTagline}>
                  <Box mt={8}>
                    <TextField
                      showAllowedChars
                      name="tagline"
                      maxLength={100}
                      {...(hasTagline &&
                        register('tagline', {
                          required: t('app:field_is_required'),
                        }))}
                    />
                  </Box>
                </ShowHideAnimationWrapper>
              </FormFieldBox>
            </StyledFormContainer>
            <PublishedRedirectSectionControls
              formProps={{
                control,
                getValues,
                setValue,
              }}
            />
            <DeleteBlock handleDelete={handleDelete} />
            <FormActionsBar
              hasHeaderSaveButton
              disabled={isUrlNotConvertable}
              isLoading={isLoading || isInspectionLoading}
              secondaryAction={(e) => handleCancel(e)}
              secondaryActionName={t('app:button_discard')}
            />
          </>
        )
      }}
    </Form>
  )
}
