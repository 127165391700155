import { useTranslation } from 'react-i18next'

import {
  AnalyticsBarPanel,
  AnalyticsMetricPanelsDashboard,
  Card,
  Icon,
  IconAssetsKeys,
  StyledIndicatorApprovedClicks,
} from '@web-apps/ui-shared'
import { getSocialData, Localisation } from '@web-apps/utils-shared'
import { SocialNetworksEnum } from '@web-apps/utils-types'
import {
  DEFAULT_ICON_FOR_SECTION_TYPE,
  formatChartDate,
  formatChartTooltipDate,
  IconComponentsForSectionType,
} from '../../Analytics.utils'
import { StyledStatsDashboardContainer } from './PageAnalytics.styles'
import { useSearchPeriod } from '../../hooks/useSelectorParams'
import { PageviewsChart } from '../PageviewsChart'
import { useGetCreatorPageSimpleAnalyticsQuery } from '@web-apps/feature-creator-page'
import { useAccountDetails } from '../../../../../utils/hooks/useAccountDetails.hooks'
import {
  AnalyticsPageChartSkeleton,
  SkeletonWrapper,
} from '../../../../../components/skeletons'

export const PageAnalytics = () => {
  const { t } = useTranslation(['metrics'], { useSuspense: false })
  const period = useSearchPeriod()
  const { pageId } = useAccountDetails()

  const {
    data: analyticsData,
    isLoading: isAnalyticsDataLoading,
    isFetching,
  } = useGetCreatorPageSimpleAnalyticsQuery(
    {
      pageId: pageId || '',
      period,
      advanced: true,
    },
    {
      skip: !pageId,
    }
  )

  return (
    <>
      <SkeletonWrapper
        Skeleton={AnalyticsPageChartSkeleton}
        isLoading={isAnalyticsDataLoading}
      >
        <Card style={{ borderTop: 'none', borderRadius: '0 0 8px 8px' }}>
          {analyticsData && (
            <>
              <PageviewsChart
                data={analyticsData.timeseries}
                tickFormatter={(label) => formatChartDate(label, period)}
                labelFormatter={(label) =>
                  formatChartTooltipDate(label, period)
                }
              />
              <AnalyticsMetricPanelsDashboard
                panelsProps={[
                  {
                    title: t('metrics:metricPanels.pageviews'),
                    value: analyticsData.aggregate.pageviews,
                    customLeftIndicatorForTitle: (
                      <StyledIndicatorApprovedClicks />
                    ),
                  },
                ]}
              />
            </>
          )}
        </Card>
      </SkeletonWrapper>
      <StyledStatsDashboardContainer>
        <AnalyticsBarPanel
          isLoading={isFetching}
          title={t('metrics:sectionClicksPanel.title')}
          columns={[
            {
              title: t('metrics:sectionClicksPanel.identifierColumn'),
            },
            {
              title: t('metrics:sectionClicksPanel.valueColumn'),
            },
          ]}
          messages={{
            noData: t('metrics:noData'),
            callToActionInfo: t('metrics:advanced.callToActionInfo'),
            callToActionButtonText: t(
              'metrics:advanced.callToActionButtonText'
            ),
          }}
          stats={(analyticsData?.section_clicks || []).map(
            ({ events, section: { type, label, _links } }) => {
              const IconComponent =
                IconComponentsForSectionType[type] ||
                DEFAULT_ICON_FOR_SECTION_TYPE
              return {
                id: _links.self.id,
                icon: <IconComponent />,
                value: events,
                text: label || _links.about?.href || '',
              }
            }
          )}
        />

        <AnalyticsBarPanel
          isLoading={isFetching}
          title={t('metrics:socialClicksPanel.title')}
          columns={[
            {
              title: t('metrics:socialClicksPanel.identifierColumn'),
            },
            {
              title: t('metrics:socialClicksPanel.valueColumn'),
            },
          ]}
          messages={{
            noData: t('metrics:noData'),
            callToActionInfo: t('metrics:advanced.callToActionInfo'),
            callToActionButtonText: t(
              'metrics:advanced.callToActionButtonText'
            ),
          }}
          stats={(analyticsData?.social_link_clicks || []).map(
            ({ events, type }) => {
              const social = getSocialData(type as SocialNetworksEnum)
              const IconComponent = Icon[social.monoIcon as IconAssetsKeys]

              return {
                id: type,
                icon: <IconComponent />,
                value: events,
                text: social.title,
              }
            }
          )}
        />

        <AnalyticsBarPanel
          isLoading={isFetching}
          title={t('metrics:countriesPanel.title')}
          columns={[
            {
              title: t('metrics:countriesPanel.identifierColumn'),
            },
            {
              title: t('metrics:countriesPanel.valueColumn'),
            },
          ]}
          messages={{
            noData: t('metrics:noData'),
            callToActionInfo: t('metrics:advanced.callToActionInfo'),
            callToActionButtonText: t(
              'metrics:advanced.callToActionButtonText'
            ),
          }}
          stats={(analyticsData?.countries || []).map(
            ({ country, visitors }) => {
              const countryName = Localisation.getCountryNameFromCountryISOCode(
                {
                  countryISOCode: country,
                  notFoundMessage: t('metrics:countriesPanel.unknown'),
                }
              )

              return {
                id: country,
                value: visitors,
                text: countryName,
              }
            }
          )}
        />

        <AnalyticsBarPanel
          isLoading={isFetching}
          title={t('metrics:sourcesPanel.title')}
          columns={[
            {
              title: t('metrics:sourcesPanel.identifierColumn'),
            },
            {
              title: t('metrics:sourcesPanel.valueColumn'),
            },
          ]}
          messages={{
            noData: t('metrics:noData'),
            callToActionInfo: t('metrics:advanced.callToActionInfo'),
            callToActionButtonText: t(
              'metrics:advanced.callToActionButtonText'
            ),
          }}
          stats={(analyticsData?.sources || []).map(({ source, visitors }) => ({
            id: source,
            text: source,
            value: visitors,
          }))}
        />
      </StyledStatsDashboardContainer>
    </>
  )
}
