import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { TransitionGroup } from 'react-transition-group'

import { emptyFunctionForOptionals } from '@web-apps/utils-shared'
import {
  Backdrop,
  Confirm,
  ShowHideAnimationWrapper,
} from '@web-apps/ui-shared'
import { useDeleteCreatorPageSectionMutation } from '@web-apps/feature-creator-page'
import { SectionType, SectionTypeEnum } from '@web-apps/utils-types'

import { StyledContainer, StyleListItem } from './SectionsList.styles'
import { SectionsListItem } from './components/SectionsListItem'

type SectionsListProps = {
  sections: SectionType[]
  onSectionsOrderChanged: (orderedSections: SectionType[]) => void
  isFetchingCreatorPage?: boolean
}

export const SectionsList = ({
  sections,
  onSectionsOrderChanged = emptyFunctionForOptionals,
  isFetchingCreatorPage = false,
}: SectionsListProps) => {
  const { t } = useTranslation(['creator', 'app'])
  const [pathToDelete, setDeletePath] = useState<string | null>(null)
  const [deleteSection, { isLoading: isDeletingSection }] =
    useDeleteCreatorPageSectionMutation()

  return (
    <StyledContainer>
      {(isDeletingSection || isFetchingCreatorPage) && (
        <Backdrop isLoading delayMs={isDeletingSection ? 0 : 750} />
      )}

      <DragDropContext
        onDragEnd={(result) => {
          if (result.destination && result.source) {
            const nextSections = [...sections]
            const [movedItem] = nextSections.splice(result.source.index, 1)
            nextSections.splice(result.destination.index, 0, movedItem)

            onSectionsOrderChanged(nextSections)
          }
        }}
      >
        <Droppable droppableId="section-droppable">
          {(provided) => (
            <ul {...provided.droppableProps} ref={provided.innerRef}>
              <TransitionGroup component={null}>
                {sections.map((section, index) => {
                  const draggableId = section._links.self.id
                  return (
                    <ShowHideAnimationWrapper key={draggableId} in>
                      <Draggable
                        isDragDisabled={
                          isFetchingCreatorPage || isDeletingSection
                        }
                        key={draggableId}
                        draggableId={draggableId}
                        index={index}
                        shouldRespectForcePress
                      >
                        {(provided, snapshot) => (
                          <StyleListItem
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <SectionsListItem
                              section={section}
                              isDragging={snapshot.isDragging}
                              deleteHandler={setDeletePath}
                              isHidden={
                                section.type === SectionTypeEnum.CONTACT_LIST
                              }
                            />
                          </StyleListItem>
                        )}
                      </Draggable>
                    </ShowHideAnimationWrapper>
                  )
                })}
              </TransitionGroup>
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
      {!!pathToDelete && (
        <Confirm
          onDismiss={() => setDeletePath(null)}
          title={t('creator:confirm.title')}
          buttons={[
            {
              label: t('creator:confirm.cancel'),
              onClick: () => setDeletePath(null),
              inverse: true,
            },
            {
              label: t('creator:confirm.delete'),
              onClick: () => {
                deleteSection({ path: pathToDelete })
                setDeletePath(null)
              },
            },
          ]}
        />
      )}
    </StyledContainer>
  )
}
