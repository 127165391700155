import { Link } from 'react-router-dom'

import { Icon } from '@web-apps/ui-shared'

import { useAccountDetails } from '../../utils/hooks/useAccountDetails.hooks'

import { MenuItem, MenuItemAccount } from './MenuItem'
import { useSidebarMenuItems } from './Sidebar.hooks'

import { StyledComponentWrapper, StyledLogo } from './Sidebar.styles'

export const Sidebar = () => {
  const { avatar: avatarImageUrl } = useAccountDetails()
  const { items, myAccountItem, isSidebarHidden } = useSidebarMenuItems()

  return (
    <StyledComponentWrapper $isHidden={isSidebarHidden}>
      <StyledLogo>
        <Link to="/">
          <Icon.ZezamLogo width={30} height={30} />
        </Link>
      </StyledLogo>

      {items.map((item) => (
        <MenuItem key={item.id} item={item} />
      ))}

      <MenuItemAccount imageUrl={avatarImageUrl} menuItem={myAccountItem} />
    </StyledComponentWrapper>
  )
}
