import styled from 'styled-components'
import { Theme } from '@web-apps/ui-shared'

export const StyledColorInputContainer = styled.div`
  position: relative;
`

export const StyledLabel = styled.label`
  font-weight: 600;
`

export const StyledColorInput = styled.input`
  height: 48px;
  width: 100%;
  padding: 12px 16px 12px 64px;
  border: 1px solid ${Theme.Colors.line};
  border-radius: 8px;
`

export const StyledColorSquare = styled.div`
  width: 47px;
  height: 46px;
  cursor: pointer;
  position: absolute;
  top: 1px;
  left: 1px;
  background-color: ${Theme.Colors.background.base};
  border-right: 1px solid ${Theme.Colors.line};
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
`
export const StyledPopover = styled.div`
  position: absolute;
  z-index: 2;
`
