import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import {
  Typography,
  Dialog,
  Flex,
  Box,
  Icon,
  TextFieldSize,
  TextField,
  ButtonSize,
  Theme,
} from '@web-apps/ui-shared'
import { ItemParentLink } from '../Sidebar.types'

import {
  StyledMenuItemCoverDesktop,
  StyledMenuItemCoverMobile,
  StyledLabel,
  StyledMenuItemAccount,
  StyledMenuItemAccountImg,
  StyledSubMenuLink,
  StyledSubMenuWrapper,
  StyledItemGroupWrapper,
} from './MenuItem.styles'
import { CopyButton } from '../../../components'

import { useCreatorPage } from '../../../utils/hooks/useCreatorPage.hooks'
import ReactGA from 'react-ga4'
import { ANALYTICS_CATEGORY } from '@web-apps/utils-types'
import {
  creatorUrlBuilders,
  isCopyClipboardAvailable,
  Localisation,
} from '@web-apps/utils-shared'
import { ROUTES } from '../../../routes/RouteEnums'

type LanguageMenuType = {
  toggleMenu: (isTrue: boolean) => void
  changeLanguage: (id: string) => void
}
const LanguageMenu = ({ toggleMenu, changeLanguage }: LanguageMenuType) => {
  const { t, i18n } = useTranslation(['app'])

  const languageMenu = useMemo(
    () =>
      Object.keys(Localisation.languages).map((language) => ({
        id: Localisation.languages[language].value,
        title: Localisation.languages[language].label,
      })),
    []
  )

  return (
    <>
      <div onClick={() => toggleMenu(false)}>
        <Flex align="center" gap={12}>
          <Icon.ArrowBack />
          <Typography fontWeight="bold" variant="note">
            {t('app:sidebar.language')}
          </Typography>
        </Flex>
      </div>
      {languageMenu.map((item) => (
        <StyledSubMenuLink
          key={item.id}
          onClick={() => {
            changeLanguage(item.id)
          }}
          $isActive={i18n.language === item.id}
        >
          <Flex justify="space-between" gap={80} align="center">
            {item.title}
            {i18n.language === item.id && (
              <Icon.PlainCheck
                width={24}
                height={24}
                fillColor={Theme.Button.borderColor}
              />
            )}
          </Flex>
        </StyledSubMenuLink>
      ))}
    </>
  )
}

type AccountSubMenuType = {
  toggleMenu: (isTrue: boolean) => void
  toggleLanguageMenu: (isTrue: boolean) => void
}
const AccountSubMenu = ({
  toggleMenu,
  toggleLanguageMenu,
}: AccountSubMenuType) => {
  const navigate = useNavigate()
  const { t } = useTranslation(['app'])
  const isSeamlessLogin = localStorage.getItem('isSeamlessAuth')

  return (
    <>
      <StyledItemGroupWrapper>
        {!isSeamlessLogin && (
          <StyledSubMenuLink
            onClick={() => {
              navigate(ROUTES.PERSONAL_SETTINGS)
              ReactGA.event({
                action: `navigation:sub_item_settings:click`,
                category: ANALYTICS_CATEGORY.APPLICATION,
              })
              toggleMenu(false)
            }}
          >
            <Flex align="center">
              <Box w={34}>
                <Icon.Settings />
              </Box>
              <Typography variant="note">
                {t('app:sidebar.settings')}
              </Typography>
            </Flex>
          </StyledSubMenuLink>
        )}
        <StyledSubMenuLink
          onClick={() => {
            toggleLanguageMenu(true)
          }}
        >
          <Flex align="center">
            <Box w={34}>
              <Icon.Language />
            </Box>
            <Typography variant="note">{t('app:sidebar.language')}</Typography>
            <Box ml={48}>
              <Icon.ArrowRight />
            </Box>
          </Flex>
        </StyledSubMenuLink>
      </StyledItemGroupWrapper>

      <StyledSubMenuLink
        onClick={() => {
          navigate(ROUTES.LOGOUT)
          ReactGA.event({
            action: `navigation:sub_item_logout:click`,
            category: ANALYTICS_CATEGORY.APPLICATION,
          })
          toggleMenu(false)
        }}
      >
        <Flex align="center">
          <Box w={34}>
            <Icon.Logout />
          </Box>
          <Typography variant="note">{t('app:sidebar.logout')}</Typography>
        </Flex>
      </StyledSubMenuLink>
    </>
  )
}

export const MenuItemAccount = ({
  imageUrl,
  menuItem,
}: {
  imageUrl?: string
  menuItem: ItemParentLink
}) => {
  const { t, i18n } = useTranslation(['app'])

  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [isLanguageMenuOpen, setIsLanguageMenuOpen] = useState(false)

  const creatorPage = useCreatorPage()
  const creatorPublicURL = creatorUrlBuilders.creatorUrlBuilder({
    creatorSlug: creatorPage._links.self.name,
  })

  return (
    <>
      <StyledMenuItemAccount>
        <StyledMenuItemCoverMobile
          onClick={() => {
            setIsDialogOpen(true)
            ReactGA.event({
              action: `navigation:my_account:click`,
              category: ANALYTICS_CATEGORY.APPLICATION,
            })
          }}
        />
        <StyledMenuItemAccountImg
          $url={imageUrl}
          $isActive={menuItem.isActive}
        />
        <StyledLabel>
          <Typography variant="extraSmall">
            {t('app:sidebar.my_account')}
          </Typography>
        </StyledLabel>

        {/* This is the menu for desktop that is shown on hover */}

        <StyledMenuItemCoverDesktop>
          <StyledSubMenuWrapper $bottom={-10}>
            {isLanguageMenuOpen ? (
              <LanguageMenu
                toggleMenu={setIsLanguageMenuOpen}
                changeLanguage={(id) => {
                  i18n.changeLanguage(id)
                  localStorage.setItem(Localisation.OVERRIDE_LOCALE_COOKIE, id)
                  setIsLanguageMenuOpen(false)
                  setIsDialogOpen(false)
                }}
              />
            ) : (
              <>
                <Box mb={20}>
                  <Typography variant="subTitleBig" fontWeight="600">
                    {menuItem.title}
                  </Typography>
                </Box>
                <AccountSubMenu
                  toggleMenu={setIsDialogOpen}
                  toggleLanguageMenu={setIsLanguageMenuOpen}
                />
              </>
            )}
          </StyledSubMenuWrapper>
        </StyledMenuItemCoverDesktop>
      </StyledMenuItemAccount>

      {/* This is the menu for mobiles that is shown on click */}
      <Dialog
        isOpen={isDialogOpen}
        title={t('app:sidebar.my_account')}
        showHeaderBorder={false}
        onDismiss={() => setIsDialogOpen(false)}
      >
        {isLanguageMenuOpen ? (
          <LanguageMenu
            toggleMenu={setIsLanguageMenuOpen}
            changeLanguage={(id) => {
              i18n.changeLanguage(id)
              localStorage.setItem(Localisation.OVERRIDE_LOCALE_COOKIE, id)
              setIsLanguageMenuOpen(false)
              setIsDialogOpen(false)
            }}
          />
        ) : (
          <>
            <Box mb={24}>
              <TextField
                name="url"
                leftDecorator={<Icon.Link />}
                rightDecorator={
                  isCopyClipboardAvailable() ? (
                    <CopyButton
                      value={creatorPublicURL}
                      buttonSize={ButtonSize.SLIM}
                    />
                  ) : undefined
                }
                readOnly
                value={creatorPublicURL}
                fieldSize={TextFieldSize.REGULAR}
              />
            </Box>
            <AccountSubMenu
              toggleMenu={setIsDialogOpen}
              toggleLanguageMenu={setIsLanguageMenuOpen}
            />
          </>
        )}
      </Dialog>
    </>
  )
}
