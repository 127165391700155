import styled from 'styled-components'
import { Flex, IconCatalogPaths, Theme } from '@web-apps/ui-shared'

export const StyledCommissionTypeContainer = styled(Flex)`
  padding: 8px 16px;
  background: ${Theme.Colors.background.base};
  border: ${Theme.Colors.border.darker};
  border-radius: 8px;
  width: min-content;
  white-space: nowrap;
`

export const StyledIcon = styled.span`
  width: 24px;
  height: 24px;

  &:after {
    display: block;
    content: '💰';
    width: 24px;
    height: 24px;
    line-height: 24px;
    font-size: 24px;
  }
`

export const StyledContainer = styled(Flex)`
  input:checked + ${StyledCommissionTypeContainer} {
    background: rgba(79, 192, 26, 0.2);
    border: rgba(79, 192, 26, 0.2);

    ${StyledIcon} {
      &:after {
        content: ' ';
        background: url(${IconCatalogPaths.PlainCheck}) center;
        background-size: cover;
        width: 24px;
        height: 24px;
      }
    }
`
