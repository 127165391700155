import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { ChipFilter } from '@web-apps/ui-shared'

import { FormSections } from '../MyPageSettings.utils'

type FormSectionsListType = { [key: string]: string }

export const FormIndex = () => {
  const formSectionKeys = Object.keys(FormSections)
  const firstSection = (FormSections as FormSectionsListType)[
    formSectionKeys[0]
  ]

  const { t } = useTranslation(['creator'])
  const { hash = '' } = useLocation()

  const activeOptionId = hash.replace('#', '') || firstSection

  const options = Object.keys(FormSections).map((key) => {
    const formSectionId = (FormSections as FormSectionsListType)[key]
    return {
      id: formSectionId,
      label: t(`creator:settings_tab.${formSectionId}_title`),
      isActive: formSectionId === activeOptionId,
      as: 'a',
      href: `#${formSectionId}`,
    }
  })

  return <ChipFilter options={options} />
}
