import styled from 'styled-components'
import { Theme } from '@web-apps/ui-shared'

export const AFFILIATE_IMAGE_SIZE_PX = 64

export const StyledAffiliateLinkImage = styled.img<{ $isCover?: boolean }>`
  width: ${AFFILIATE_IMAGE_SIZE_PX}px;
  height: ${AFFILIATE_IMAGE_SIZE_PX}px;
  border: 1px solid ${Theme.Card.borderColor};
  border-radius: 8px;
  flex-shrink: 0;
  object-fit: ${({ $isCover }) => ($isCover ? 'cover' : 'contain')};
`
