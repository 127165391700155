import React, { useEffect } from 'react'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Money } from '@web-apps/utils-shared'
import {
  Box,
  InfoTooltip,
  MetricSummaryPanel,
  Theme,
} from '@web-apps/ui-shared'
import { useGetCreatorPageAffiliateAnalyticsQuery } from '@web-apps/feature-creator-page'

import {
  SkeletonWrapper,
  AnalyticsPagePeriodBlockSkeleton,
  AnalyticsPageTabSkeleton,
} from '../../../../components/skeletons'
import { useCreatorPage } from '../../../../utils/hooks/useCreatorPage.hooks'

import { PayoutsPanel } from '../components/PayoutsPanel'
import { PeriodSelector } from '../components/PeriodSelector'
import { EarningsAnalytics } from '../components/EarningsAnalytics'
import { useSearchPeriod } from '../hooks/useSelectorParams'
import { toURLWithParams } from '../Analytics.utils'

const StyledSummaryContainer = styled.div`
  padding-top: 24px;
  border-radius: 8px 8px 0 0;
  border-top: 1px solid ${Theme.Colors.border.darker};
  border-left: 1px solid ${Theme.Colors.border.darker};
  border-right: 1px solid ${Theme.Colors.border.darker};
  background: ${Theme.Colors.background.base};
`

export const OldAnalyticsPage = () => {
  const creatorPage = useCreatorPage()
  const { t } = useTranslation(['creator', 'app'], { useSuspense: false })

  const period = useSearchPeriod()

  const affiliateAnalyticsAPIPath = toURLWithParams({
    apiLink: creatorPage?._links.affiliate_analytics,
    period,
  })

  const {
    data: affiliatesAnalyticsData,
    isError: isAffiliateOverallAnalyticsError,
    isLoading: isAffiliateOverallAnalyticsLoading,
  } = useGetCreatorPageAffiliateAnalyticsQuery(
    { path: affiliateAnalyticsAPIPath as string },
    { skip: !affiliateAnalyticsAPIPath }
  )

  useEffect(() => {
    if (isAffiliateOverallAnalyticsError) {
      toast.error(t('creator:toast.page_error'))
    }
  }, [isAffiliateOverallAnalyticsError, t])
  return (
    <div>
      <Box mb={48}>
        <PayoutsPanel />
      </Box>
      <Box mb={24}>
        <SkeletonWrapper
          Skeleton={AnalyticsPagePeriodBlockSkeleton}
          isLoading={isAffiliateOverallAnalyticsLoading}
        >
          {affiliatesAnalyticsData && (
            <PeriodSelector
              dates={{
                start: affiliatesAnalyticsData?.timeseries[0].date,
                end: affiliatesAnalyticsData?.timeseries[
                  affiliatesAnalyticsData?.timeseries.length - 1
                ].date,
              }}
            />
          )}
        </SkeletonWrapper>
      </Box>
      <SkeletonWrapper
        Skeleton={AnalyticsPageTabSkeleton}
        isLoading={isAffiliateOverallAnalyticsLoading}
      >
        <StyledSummaryContainer>
          <MetricSummaryPanel
            title={t('app:header.analytics.tabs.estimated_earnings.title')}
            value={
              affiliatesAnalyticsData
                ? Money.formatPrice({
                    amount:
                      (affiliatesAnalyticsData.aggregate.earnings.approved
                        .amount || 0) +
                      (affiliatesAnalyticsData.aggregate.earnings.pending
                        .amount || 0),
                    currency:
                      affiliatesAnalyticsData.aggregate.earnings.approved
                        .currency,
                  })
                : '0'
            }
            customRightIndicatorForTitle={
              <InfoTooltip
                id="analytics-estimated-earnings-tooltip"
                title={t('app:header.analytics.tabs.estimated_earnings.title')}
                message={t(
                  'app:header.analytics.tabs.estimated_earnings.label'
                )}
                hint={t('app:header.analytics.tabs.estimated_earnings.hint')}
              />
            }
          />
        </StyledSummaryContainer>
        <EarningsAnalytics
          isLoading={isAffiliateOverallAnalyticsLoading}
          affiliatesAnalyticsData={affiliatesAnalyticsData}
        />
      </SkeletonWrapper>
    </div>
  )
}
