import { useState } from 'react'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes'

import {
  ApiErrorTypeEnum,
  FormValidations,
  isCriteriaMatchedForApiErrorResponse,
} from '@web-apps/utils-shared'
import {
  Form,
  TextField,
  Button,
  Typography,
  Flex,
  Box,
  Card,
  media,
  BREAKPOINTS,
  LoadingIndicator,
} from '@web-apps/ui-shared'
import {
  useGetCreatorPageCommerceSettingsQuery,
  useUpdateCreatorPageCommerceSettingsMutation,
} from '@web-apps/feature-creator-page'

import { useUpdateUserAccountMutation } from '../../../services/user.api'

import { FormFieldBox } from '../../../components'

import { AuthSettings } from '../../../components/AuthSettings'
import { useAccountDetails } from '../../../utils/hooks/useAccountDetails.hooks'

const StyledAccountSettingsContainer = styled.div`
  margin-block-end: 48px;
  ${media.from(
    BREAKPOINTS.sm,
    `
      margin-block-start: 16px;
  `
  )}
`

const AccountView = () => {
  const { ...accountProps } = useAccountDetails()
  const { t } = useTranslation(['personalSettings', 'app'])
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)

  const isRecoveryFlow = Boolean(searchParams.get('change-password'))

  const [isEmailAlreadyUsed, setEmailAlreadyUsed] = useState(false)
  const [passwordModal, setPasswordModal] = useState(isRecoveryFlow)

  const { data: commerceSettings, isLoading: isLoadingCommerceSettings } =
    useGetCreatorPageCommerceSettingsQuery({
      pageId: accountProps.pageId,
    })

  const [editCommerceSettings, { isLoading: isCommerceSettingsBeingUpdate }] =
    useUpdateCreatorPageCommerceSettingsMutation()

  const [updateUserAccount, { isLoading }] = useUpdateUserAccountMutation()

  if (isLoadingCommerceSettings)
    return <LoadingIndicator size={100} isCentered delayMs={750} />

  const initialValues = {
    email: accountProps.email || '',
    name: accountProps.name || '',
    defaultCurrency: commerceSettings?.defaultCurrency,
  }

  return (
    <div>
      <Form
        initialValues={initialValues}
        onSubmit={async ({ defaultCurrency, ...userAccountData }) => {
          const requestPromises: Promise<QueryReturnValue>[] = [
            updateUserAccount({
              userId: accountProps.userId,
              userAccountData,
            }),
          ]

          if (defaultCurrency) {
            requestPromises.push(
              editCommerceSettings({
                pageId: accountProps.pageId,
                data: {
                  defaultCurrency,
                },
              })
            )
          }

          const responses = await Promise.all(requestPromises)
          const updateAccountResponse = responses[0]
          const hasError = responses.some((r) => 'error' in r)

          if (hasError) {
            if (
              'error' in updateAccountResponse &&
              isCriteriaMatchedForApiErrorResponse(
                { status: 409, type: ApiErrorTypeEnum.UserEmailError },
                updateAccountResponse.error
              )
            ) {
              setEmailAlreadyUsed(true)
              toast.error(t('personalSettings:email_already_used'))
            } else {
              toast.error(t('personalSettings:account_settings_change_failure'))
            }
          } else {
            toast.success(t('personalSettings:account_settings_change_success'))
          }
        }}
        validations={{
          descriptors: {
            email: {
              required: t('app:field_is_required'),
              pattern: {
                value: FormValidations.emailRegex,
                message: t('personalSettings:valid_email_required'),
              },
            },
          },
        }}
      >
        {() => {
          return (
            <Flex direction="column">
              <StyledAccountSettingsContainer>
                <Card>
                  <Box mb={24}>
                    <Typography variant="h3">
                      {t('personalSettings:account_info')}
                    </Typography>
                  </Box>

                  <FormFieldBox>
                    <TextField
                      name="name"
                      label={t('personalSettings:name')}
                      labelDecorator={t(
                        'app:optional_form_field_label_decorator'
                      )}
                      maxLength={50}
                      showAllowedChars
                    />
                  </FormFieldBox>

                  <FormFieldBox>
                    <TextField
                      name="email"
                      label={t('personalSettings:email')}
                      maxLength={254}
                      errorMessages={
                        isEmailAlreadyUsed
                          ? [t('personalSettings:email_already_used')]
                          : undefined
                      }
                      onChange={() => {
                        setEmailAlreadyUsed(false)
                      }}
                    />
                  </FormFieldBox>

                  <Button
                    type="submit"
                    isLoading={isLoading || isCommerceSettingsBeingUpdate}
                  >
                    {t('app:button_save')}
                  </Button>
                </Card>
              </StyledAccountSettingsContainer>

              <Box mb={48}>
                <Card>
                  <Box mb={8}>
                    <Typography variant="h3">
                      {t('personalSettings:password_section_title')}
                    </Typography>
                  </Box>

                  <Typography variant="hint" color="inactive">
                    {t('personalSettings:password_section_description')}
                  </Typography>
                  <Box mt={24} mb={8}>
                    <Button
                      type="button"
                      clickHandler={() => {
                        setPasswordModal(!passwordModal)
                      }}
                    >
                      {t('personalSettings:password_section_button')}
                    </Button>
                  </Box>
                </Card>
              </Box>

              <Box mb={48}>
                <Card>
                  <Box mb={8}>
                    <Typography variant="h3">
                      {t('personalSettings:account_removal')}
                    </Typography>
                  </Box>

                  <Typography variant="hint" color="inactive">
                    {t('personalSettings:account_removal_note_paragraph_1')}
                  </Typography>

                  <Box mt={16}>
                    <Typography variant="hint" color="inactive">
                      {t('personalSettings:account_removal_note_paragraph_2')}
                    </Typography>
                  </Box>

                  <Box mt={8}>
                    <Typography
                      fontWeight="bold"
                      variant="hint"
                      color="inactive"
                    >
                      {t('personalSettings:account_removal_email_note')}
                    </Typography>
                  </Box>

                  <Box mt={24} mb={8}>
                    <Button
                      type="button"
                      clickHandler={() => {
                        const body = t(
                          'personalSettings:account_removal_email_body'
                        )
                        window.location.href = `mailto:contact@zezam.io?subject=Account removal&body=${body}`
                      }}
                    >
                      {t('personalSettings:get_in_contact')}
                    </Button>
                  </Box>
                </Card>
              </Box>
            </Flex>
          )
        }}
      </Form>

      {passwordModal && (
        <AuthSettings
          onDismiss={() => setPasswordModal(false)}
          onSuccess={() => {
            toast.success(
              t('personalSettings:authentication_settings_change_success')
            )
          }}
          isRecoveryFlow={isRecoveryFlow}
        />
      )}
    </div>
  )
}

export default AccountView
