import { StatsData } from './AnalyticsBarPanel.types'

export const calculatePercentageForStats = (
  indexStat: number,
  stats: StatsData[]
): number => {
  const statValue = stats[indexStat].value
  const total = stats
    .map(({ value }) => value)
    .reduce((previousValue, currentValue) => previousValue + currentValue, 0)

  if (!statValue) return 0
  return (statValue / total) * 100
}
