import {
  useEffect,
  useRef,
  useCallback,
  useLayoutEffect,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import {
  clearPreviewState,
  setInitialState,
} from '../../../../../store/creator/preview.slice'

import {
  BREAKPOINTS_SIZES,
  ButtonVariant,
  useWindowSize,
  Icon,
  Theme,
  LoadingIndicator,
  SuspenseWrapper,
} from '@web-apps/ui-shared'

import { ROUTES } from '../../../../../routes/RouteEnums'

import { PreviewContent } from './PreviewContent'

import {
  StyledPreview,
  StyledPreviewContainer,
  StyledMobilePreviewContainer,
  StyledMobileClose,
  StyledMobilePreview,
  StyledPreviewWrapper,
  StyledPreviewContainerScale,
} from './Preview.styles'
import { useCreatorPage } from '../../../../../utils/hooks/useCreatorPage.hooks'
import { GlobalState } from '../../../../../store'

type Props = {
  closeMobilePreview: () => void
  isMobilePreviewOpen: boolean
}

export const Preview = ({ isMobilePreviewOpen, closeMobilePreview }: Props) => {
  const size = useWindowSize()
  const dispatch = useDispatch()

  const containerRef = useRef<HTMLDivElement>(null)
  const wrapperRef = useRef<HTMLDivElement>(null)
  const wasPreviewResetRef = useRef(false)

  const creatorPage = useCreatorPage()
  const { data, currentSectionId } = useSelector(
    (state: GlobalState) => state.creator.preview
  )
  const [previewWidth, setPreviewWidth] = useState<number | undefined>()

  const resetPreview = useCallback(() => {
    if (creatorPage) {
      dispatch(setInitialState(creatorPage))
      wasPreviewResetRef.current = true
    }
  }, [creatorPage, dispatch])

  // in some situations those two effects could lead to preview being reset twice sequentially, only when component is mounted
  // maybe the best way would be to have each page decide when preview should be reset instead

  // next two effects are trying to keep state between changing tabs in My Page
  // however when save is clicked in each my page tab it would reset the preview because creator page was invalidated
  // and re-fetched.
  //  To solve that situation preview is reset only on mount and for specific pages when route is changed, except for my page routes

  // always reset preview on mount and when new info from creator page, only once
  useEffect(() => {
    if (!wasPreviewResetRef.current) {
      resetPreview()
    }
  }, [resetPreview])

  // reset on location change, excluding some cases
  const { pathname } = useLocation()
  useEffect(() => {
    // do not want to reset preview while moving between tabs in my page
    const pathsToExclude: string[] = [
      ROUTES.MY_PAGE,
      ROUTES.MY_PAGE_APPEARANCE,
      ROUTES.MY_PAGE_SETTINGS,
    ]

    if (!pathsToExclude.includes(pathname)) {
      resetPreview()
    }
  }, [resetPreview, pathname])

  useEffect(() => {
    return () => void dispatch(clearPreviewState())
  }, [dispatch])

  useEffect(() => {
    const container = containerRef.current
    let offset = 0

    if (!container) {
      return
    }

    if (currentSectionId) {
      const element = document.getElementById(currentSectionId)
      if (element && container.parentElement) {
        offset =
          element.offsetTop +
          element.clientHeight -
          container.parentElement.clientHeight / 2
      }
    }

    container.scrollTo({
      top: offset,
      behavior: 'smooth',
    })
  }, [currentSectionId])

  useEffect(() => {
    if (isMobilePreviewOpen && (size.width || 0) <= BREAKPOINTS_SIZES.from.xl) {
      window.document.body.style.overflowY = 'hidden'
    } else {
      window.document.body.style.overflowY = 'auto'
    }
  }, [isMobilePreviewOpen, size.width])

  useLayoutEffect(() => {
    setPreviewWidth(wrapperRef?.current?.offsetWidth)
  }, [wrapperRef, size])

  if (!data || !size.width) {
    return null
  }

  if (size.width >= BREAKPOINTS_SIZES.from.lg) {
    return (
      <StyledPreviewContainer ref={wrapperRef}>
        <StyledPreviewContainerScale
          $parentWrapperWidth={previewWidth}
          $pageHeight={size.height}
        >
          <StyledPreview>
            <SuspenseWrapper
              fallback={<LoadingIndicator size={64} isCentered />}
            >
              <StyledPreviewWrapper ref={containerRef}>
                <PreviewContent creatorPage={data} />
              </StyledPreviewWrapper>
            </SuspenseWrapper>
          </StyledPreview>
        </StyledPreviewContainerScale>
      </StyledPreviewContainer>
    )
  }

  return (
    <StyledMobilePreviewContainer $isOpen={isMobilePreviewOpen}>
      <StyledMobileClose
        clickHandler={closeMobilePreview}
        variant={ButtonVariant.PLAIN}
      >
        <Icon.Close fillColor={Theme.Colors.typography.regular} />
      </StyledMobileClose>
      <StyledMobilePreview>
        <SuspenseWrapper fallback={<LoadingIndicator size={64} isCentered />}>
          <PreviewContent creatorPage={data} />
        </SuspenseWrapper>
      </StyledMobilePreview>
    </StyledMobilePreviewContainer>
  )
}
