import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import {
  Form,
  FormFieldController,
  RichTextEditor,
  richTextEditorSanitizer,
  Box,
  Flex,
  FormArgsType,
  Switch,
  SwitchVariant,
  TextField,
  ShowHideAnimationWrapper,
} from '@web-apps/ui-shared'
import {
  StyledFormContainer,
  StyledSmallText,
  StyledSubTitle,
} from '../../../../components/styles'
import { FormActionsBar, FormFieldBox } from '../../../../components'
import { ROUTES } from '../../../../routes/RouteEnums'
import { FormValidations } from '@web-apps/utils-shared'
import {
  DeleteBlock,
  SectionExpandableStyleFormBlock,
  SectionStyleFormBlock,
} from '../components'
import {
  ContentSectionFormDataType,
  SectionExtension,
} from '@web-apps/utils-types'

type Props = {
  handleSubmit: (d: ContentSectionFormDataType) => void
  updatePreview: (form: { data: ContentSectionFormDataType }) => void
  handleDelete?: () => void
  isLoading: boolean
  initialValues?: ContentSectionFormDataType
}

export const SectionContentForm = ({
  initialValues,
  isLoading,
  handleSubmit,
  handleDelete,
  updatePreview,
}: Props) => {
  const subscription = useRef<ReturnType<FormArgsType['watch']>>()
  const navigate = useNavigate()
  const { t } = useTranslation(['app', 'creator'])

  const normalizeHtmlStringValue = (str = '') =>
    FormValidations.isHtmlStringEmpty(str) ? '' : richTextEditorSanitizer(str)

  const handleCancel = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    navigate(ROUTES.MY_PAGE)
  }

  useEffect(() => () => subscription?.current?.unsubscribe(), [])

  return (
    <Form
      initialValues={initialValues}
      onSubmit={(data) =>
        handleSubmit({
          ...data,
          text: normalizeHtmlStringValue(data.text),
        })
      }
      validations={{
        descriptors: {
          text: {
            validate: (fieldValue) => {
              if (!fieldValue) {
                return t('app:field_is_required')
              }

              if (
                FormValidations.getHtmlTextOnlyLength(fieldValue) >
                FormValidations.textareaMaxLength
              ) {
                return t('app:field_invalid_max_length')
              }

              return FormValidations.isHtmlStringEmpty(fieldValue)
                ? t('app:field_is_required')
                : true
            },
          },
        },
      }}
    >
      {(formProps) => {
        const {
          control,
          watch,
          getValues,
          formState: { errors },
        } = formProps
        if (!subscription.current) {
          subscription.current = watch((data) => {
            updatePreview({
              data: {
                ...(data as ContentSectionFormDataType),
                text: normalizeHtmlStringValue(data.text),
              },
            })
          })
        }

        return (
          <>
            <StyledFormContainer>
              <FormFieldController
                name="text"
                render={({ field }) => (
                  <RichTextEditor
                    errorMessages={
                      errors.text ? [errors.text.message] : undefined
                    }
                    maxLength={FormValidations.textareaMaxLength}
                    {...field}
                  />
                )}
                control={control}
                defaultValue={initialValues?.text}
              />
            </StyledFormContainer>
            <StyledFormContainer>
              <FormFieldBox
                mb={0}
                title={t('creator:custom_link.title_section_button_style')}
              >
                <SectionExpandableStyleFormBlock formReturnProps={formProps} />
                <ShowHideAnimationWrapper
                  in={
                    getValues('styles.extension') === SectionExtension.ACCORDION
                  }
                >
                  <SectionStyleFormBlock formReturnProps={formProps} />
                  <FormFieldBox mt={24}>
                    <FormFieldController
                      name="label"
                      rules={{ required: t('app:field_is_required') }}
                      render={({ field }) => (
                        <TextField
                          name={field.name}
                          label={t('creator:custom_link.field_label_title')}
                          value={field.value}
                          onChange={field.onChange}
                          showAllowedChars
                          errorMessages={
                            errors.label?.message
                              ? typeof errors.label.message === 'string'
                                ? [errors.label.message]
                                : errors.label.message
                              : []
                          }
                          maxLength={100}
                        />
                      )}
                      control={control}
                    />
                  </FormFieldBox>
                </ShowHideAnimationWrapper>
              </FormFieldBox>
            </StyledFormContainer>
            <StyledFormContainer>
              <FormFieldController
                render={({ field }) => {
                  return (
                    <Flex justify="space-between" align="flex-start">
                      <div>
                        <Box mb={4}>
                          <StyledSubTitle>
                            {t('creator:display_section.title')}
                          </StyledSubTitle>
                        </Box>
                        <StyledSmallText>
                          {t('creator:display_section.description')}
                        </StyledSmallText>
                      </div>
                      <Switch
                        name={field.name}
                        onChange={field.onChange}
                        checked={field.value}
                        variant={SwitchVariant.SMALL}
                      />
                    </Flex>
                  )
                }}
                control={control}
                name="published"
              />
            </StyledFormContainer>

            <DeleteBlock handleDelete={handleDelete} />
            <FormActionsBar
              hasHeaderSaveButton
              isLoading={isLoading}
              secondaryAction={(e) => handleCancel(e)}
              secondaryActionName={t('app:button_discard')}
            />
          </>
        )
      }}
    </Form>
  )
}
