import styled from 'styled-components'

import { Theme } from '@web-apps/ui-shared'

export const StyledWebsiteContainer = styled.div`
  padding: 16px;

  border-radius: 8px;
  background: ${Theme.Colors.lightSand};
`
