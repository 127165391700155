import { createRoot } from 'react-dom/client'
import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'
import { BrowserRouter } from 'react-router-dom'
import { Provider as ReduxProvider } from 'react-redux'
import * as Sentry from '@sentry/react'
import FlagProvider from '@unleash/proxy-client-react'

import { store } from './store'
import { ScrollToTop } from './routes/ScrollToTop'

import { flagsConfig } from './services/flags'
import './services/translation'

import { Auth } from './containers/auth'
import { MetaHead } from './components/MetaHead/MetaHead'
import GlobalStyle from './components/styles/GlobalStyle'
import { config } from './utils/helpers/config.helpers'

import './index.css'
import 'react-loading-skeleton/dist/skeleton.css'

import * as serviceWorker from './serviceWorker'
import {
  initConfig,
  initAPI,
  getHostForEnvironment,
} from '@web-apps/utils-shared'
import { initOry } from '@web-apps/feature-auth'
import { SuspenseWrapper } from '@web-apps/ui-shared'
import { getEnvironmentConfigs } from './services/utility'
import { initPlausible } from './services/plausible'
import { MaintenanceWrapper, ToastsWrapper } from './components'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: config.sentry.dsn,
    release: process.env.REACT_APP_VERSION,
    environment: config.sentry.environment,
    sampleRate: config.sentry.errorsSampleRate,
    tracesSampleRate: config.sentry.tracesSampleRate,

    // The list of errors was based on a suggestion here: https://docs.sentry.io/platforms/javascript/configuration/filtering/#decluttering-sentry
    ignoreErrors: [
      // TBU based on fantom errors
      'Network Error',
      // https://github.com/getsentry/sentry-javascript/issues/7993
      'webkit-masked-url',
    ],
    denyUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Chrome extensions
      /chrome-extension/i,
      /extensions\//i,
      /^chrome:\/\//i,
      /C:\\Users/i,
    ],
  })
}

initPlausible(getEnvironmentConfigs().creatorApp)

if (config.apiBaseUrl) initAPI({ baseURL: config.apiBaseUrl })

const root = createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <FlagProvider config={flagsConfig}>
    <ReduxProvider store={store}>
      <SuspenseWrapper>
        <MaintenanceWrapper>
          <MetaHead />
          <GlobalStyle />
          <BrowserRouter>
            <ScrollToTop />
            <Auth />
            <ToastsWrapper />
          </BrowserRouter>
        </MaintenanceWrapper>
      </SuspenseWrapper>
    </ReduxProvider>
  </FlagProvider>
)

if (config.iframelyKey && config.chargebeeSite && config.authUrl) {
  initConfig({
    IFRAMELY_KEY: config.iframelyKey,
    CHARGEBEE_SITE: config.chargebeeSite,
    AUTH_URL: config.authUrl,
    FACEBOOK_APP_ID: config.facebookAppId,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    HOSTNAME: getHostForEnvironment((window as any).env.ENVIRONMENT),
  })
  initOry()
}

serviceWorker.register()
