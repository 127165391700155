import { useLocation, useMatch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { ROUTES } from '../../../../routes/RouteEnums'

const SECTIONS_TAB_INDEX = 0
const APPEARANCE_TAB_INDEX = 1
const SETTINGS_TAB_INDEX = 2

export type MyPageRouteInfoProps = {
  title: string
  allowMyPageBack?: boolean
  showShareFeature?: boolean
  selectedTabIndex: 0 | 1 | 2
  hasBorder: boolean
}

export const useMyPageRouteInfo = (): MyPageRouteInfoProps => {
  const { t } = useTranslation(['creator'])
  const { pathname } = useLocation()
  const matchEditLinkRoute = useMatch(ROUTES.MY_PAGE_CUSTOM_LINK_EDIT)
  const matchEditAffiliateLinkRoute = useMatch(
    ROUTES.MY_PAGE_EDIT_AFFILIATE_LINK_SECTION
  )
  const matchEditContentRoute = useMatch(ROUTES.MY_PAGE_EDIT_CONTENT_SECTION)
  const matchEditVideoRoute = useMatch(ROUTES.MY_PAGE_VIDEO_EDIT)
  const matchEditMusicRoute = useMatch(ROUTES.MY_PAGE_MUSIC_EDIT)

  const matchEditProductRecommendationSectionRoute = useMatch(
    ROUTES.MY_PAGE_EDIT_PRODUCT_RECOMMENDATIONS
  )

  switch (true) {
    case ROUTES.MY_PAGE_ADD_VIDEO === pathname:
      return {
        title: t('creator:embed_section.video.title_add'),
        allowMyPageBack: true,
        selectedTabIndex: SECTIONS_TAB_INDEX,
        hasBorder: true,
      }
    case !!matchEditVideoRoute:
      return {
        title: t('creator:embed_section.video.title_edit'),
        allowMyPageBack: true,
        selectedTabIndex: SECTIONS_TAB_INDEX,
        hasBorder: true,
      }
    case ROUTES.MY_PAGE_ADD_MUSIC === pathname:
      return {
        title: t('creator:embed_section.music.title_add'),
        allowMyPageBack: true,
        selectedTabIndex: SECTIONS_TAB_INDEX,
        hasBorder: true,
      }
    case !!matchEditMusicRoute:
      return {
        title: t('creator:embed_section.music.title_edit'),
        allowMyPageBack: true,
        selectedTabIndex: SECTIONS_TAB_INDEX,
        hasBorder: true,
      }
    case ROUTES.MY_PAGE_CONTENT_SECTION === pathname:
      return {
        title: t('creator:content_section.title_add'),
        allowMyPageBack: true,
        selectedTabIndex: SECTIONS_TAB_INDEX,
        hasBorder: true,
      }
    case !!matchEditContentRoute:
      return {
        title: t('creator:content_section.title_edit'),
        allowMyPageBack: true,
        selectedTabIndex: SECTIONS_TAB_INDEX,
        hasBorder: true,
      }
    case !!matchEditLinkRoute:
      return {
        title: t('creator:custom_link.title_edit'),
        allowMyPageBack: true,
        selectedTabIndex: SECTIONS_TAB_INDEX,
        hasBorder: true,
      }
    case !!matchEditAffiliateLinkRoute:
      return {
        title: t('creator:affiliate_link.title_edit'),
        allowMyPageBack: true,
        selectedTabIndex: SECTIONS_TAB_INDEX,
        hasBorder: true,
      }
    case ROUTES.MY_PAGE_AFFILIATE_LINK_SECTION === pathname:
      return {
        title: t('creator:affiliate_link.title_add'),
        allowMyPageBack: true,
        selectedTabIndex: SECTIONS_TAB_INDEX,
        hasBorder: true,
      }
    case ROUTES.MY_PAGE_CUSTOM_LINK === pathname:
      return {
        title: t('creator:custom_link.title_add'),
        allowMyPageBack: true,
        selectedTabIndex: SECTIONS_TAB_INDEX,
        hasBorder: true,
      }
    case !!matchEditProductRecommendationSectionRoute:
      return {
        title: t('creator:product_recommendations.title_edit'),
        allowMyPageBack: true,
        selectedTabIndex: SECTIONS_TAB_INDEX,
        hasBorder: true,
      }
    case ROUTES.MY_PAGE_ADD_PRODUCT_RECOMMENDATIONS === pathname:
      return {
        title: t('creator:product_recommendations.title_add'),
        allowMyPageBack: true,
        selectedTabIndex: SECTIONS_TAB_INDEX,
        hasBorder: true,
      }
    case ROUTES.MY_PAGE_SETTINGS === pathname:
      return {
        title: t('creator:my_page_title'),
        showShareFeature: true,
        selectedTabIndex: SETTINGS_TAB_INDEX,
        hasBorder: false,
      }
    case ROUTES.MY_PAGE_APPEARANCE === pathname:
      return {
        title: t('creator:my_page_title'),
        showShareFeature: true,
        selectedTabIndex: APPEARANCE_TAB_INDEX,
        hasBorder: false,
      }
    case ROUTES.MY_PAGE === pathname:
    default:
      return {
        title: t('creator:my_page_title'),
        showShareFeature: true,
        selectedTabIndex: SECTIONS_TAB_INDEX,
        hasBorder: false,
      }
  }
}
