import { ComponentType } from 'react'
import { useTranslation } from 'react-i18next'
import { Flex } from '@web-apps/ui-shared'

const SkeletonList: React.FC<{ amount?: number; Item: ComponentType }> = ({
  Item,
  amount,
}) => {
  return (
    <>
      {Array(amount || 9)
        .fill(0)
        .map((_, index) => (
          <Item key={index} />
        ))}
    </>
  )
}

export type SkeletonWrapperProps = {
  Skeleton: ComponentType
  EmptyState?: ComponentType
  ErrorState?: ComponentType
  asList?: boolean
  itemsAmount?: number
  isLoading: boolean
  isError?: boolean
  showEmptyState?: boolean
}

export const SkeletonWrapper: React.FC<
  React.PropsWithChildren<SkeletonWrapperProps>
> = ({
  Skeleton,
  EmptyState,
  ErrorState,
  itemsAmount,
  asList,
  isLoading,
  isError,
  showEmptyState,
  children,
}) => {
  const { t } = useTranslation(['app'], { useSuspense: false })

  if (isLoading) {
    return asList ? (
      <SkeletonList Item={Skeleton} amount={itemsAmount} />
    ) : (
      <Skeleton />
    )
  }

  if (isError) {
    return ErrorState ? (
      <ErrorState />
    ) : (
      <Flex style={{ gridColumn: '1 / span last' }} justify="center">
        <p style={{ textAlign: 'center' }}>{t('app:content_error')}</p>
      </Flex>
    )
  }

  if (showEmptyState) {
    return EmptyState ? (
      <EmptyState />
    ) : (
      <Flex style={{ gridColumn: '1 / span last' }} justify="center">
        <p>{t('app:empty_list')}</p>
      </Flex>
    )
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>
}
