import { Flex, Typography } from '../../atoms'

import {
  StyleBarContainer,
  StyledBackgroundBar,
  StyledCompleteIndicatorContainer,
  StyledFilledBar,
} from './ProgressBar.styles'

type ProgressBarProps = {
  fillColor: string
  percentage: number
  completeIndicatorContent: React.ReactNode
}

export const ProgressBar = ({
  fillColor,
  percentage = 0,
  completeIndicatorContent,
}: ProgressBarProps) => {
  const isComplete = percentage >= 100
  let width = percentage
  if (width > 100) width = 100
  if (width < 0) width = 0

  return (
    <Flex align="center">
      <StyleBarContainer>
        <StyledBackgroundBar />
        <StyledFilledBar $width={`${width}%`} $fillColor={fillColor} />
      </StyleBarContainer>

      {completeIndicatorContent && (
        <Typography as="span" variant="hint">
          <StyledCompleteIndicatorContainer
            $fillColor={isComplete ? fillColor : undefined}
            $opacity={!isComplete ? 0.48 : undefined}
          >
            <span>{completeIndicatorContent}</span>
          </StyledCompleteIndicatorContainer>
        </Typography>
      )}
    </Flex>
  )
}
