import { useMemo } from 'react'
import { useTranslation, Trans } from 'react-i18next'

import {
  Box,
  Flex,
  FormArgsType,
  FormFieldController,
  Icon,
} from '@web-apps/ui-shared'
import { SectionExtension, SectionVariantEnum } from '@web-apps/utils-types'
import { RadioInputWithIcon } from '../../../../../components'

type Props = {
  // eslint-disable-next-line
  formReturnProps: FormArgsType<any>
  isMultiItemsIcon?: boolean
}

export const SectionExpandableStyleFormBlock = ({
  formReturnProps,
  isMultiItemsIcon,
}: Props) => {
  const { control, getValues, setValue } = formReturnProps
  const { t } = useTranslation(['app', 'creator'])
  const buttonExpandableVariants = useMemo(
    () => [
      {
        label: t('creator:expandable.embedded'),
        icon: isMultiItemsIcon ? (
          <Icon.NonExpandableMultiItems width={64} height={64} />
        ) : (
          <Icon.NonExpandableSingleItem width={64} height={64} />
        ),
        value: SectionExtension.NONE,
      },
      {
        label: <Trans i18nKey="creator:expandable.expandable_section" />,
        icon: <Icon.Expandable width={64} height={64} />,
        value: SectionExtension.ACCORDION,
      },
    ],
    [t, isMultiItemsIcon]
  )

  return (
    <div>
      <FormFieldController
        name="styles.extension"
        control={control}
        defaultValue={getValues('styles.extension') || SectionExtension.NONE}
        render={({ field }) => {
          return (
            <Box mb={16}>
              <Flex justify="center" gap={16}>
                {buttonExpandableVariants.map((item) => (
                  <RadioInputWithIcon
                    key={item.value}
                    name="styles.extension"
                    label={item.label}
                    value={item.value}
                    icon={item.icon}
                    checked={item.value === field.value}
                    onChange={(el) => {
                      field.onChange(el)
                      if (el.target.value === SectionExtension.NONE) {
                        setValue('styles.variant', SectionVariantEnum.PLAIN)
                        setValue('image', null)
                      }
                    }}
                    containerStyles={{ justifyContent: 'start' }}
                  />
                ))}
              </Flex>
            </Box>
          )
        }}
      />
    </div>
  )
}
