import { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, Route, Routes, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ReactGA from 'react-ga4'

import {
  creatorUrlBuilders,
  EmbedProvidersCategory,
  relativePath,
  nativeShareWrapper,
} from '@web-apps/utils-shared'
import { ROUTES } from '../../../routes/RouteEnums'
import {
  Button,
  ButtonSize,
  ButtonVariant,
  Icon,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  TextField,
  TextFieldSize,
  Box,
  Theme,
  ShareLinkContext,
  ShareLinkType,
  ShareLinkPopup,
  Flex,
} from '@web-apps/ui-shared'
import { ANALYTICS_CATEGORY } from '@web-apps/utils-types'

import { setInitialState } from '../../../store/creator/preview.slice'

import {
  PageHeader,
  PageTitle,
  PageWrapper,
  StyledTitleWrapper,
  SharePopup,
} from '../../../components'

import { useMyPageRouteInfo } from './hooks/useMyPageRouteInfo.hook'

import { MyPageSettings } from './MyPageSettings'
import { MyPageContent } from './MyPageContent'
import { AddSectionEmbed, EditSectionEmbed } from './SectionEmbedForm'
import { AddSectionContent, EditSectionContent } from './SectionContentForm'
import {
  AddSectionProductRecommendations,
  EditSectionProductRecommendations,
} from './SectionProductRecommendationsForm'
import { Preview } from './components/Preview'

import {
  StyledContainer,
  StyledContentContainer,
  StyledContentWrapper,
  StyledCreatorURLWrapper,
  StyledHeaderContentContainer,
  StyledPageActions,
  StyledShareButtonContent,
  StyledPreviewIconWrapper,
  StyledLeftCol,
  StyledRightCol,
  StylePreviewWrapper,
  StyledMobileShareActionsContainer,
} from './MyPage.styles'

import { MyPageAppearance } from './MyPageAppearance'
import { useCreatorPage } from '../../../utils/hooks/useCreatorPage.hooks'
import {
  AddAffiliateLinkSection,
  EditAffiliateLinkSection,
} from './SectionAffiliateLinkForm'
import {
  AddExternalLinkSection,
  EditExternalLinkSection,
} from './SectionExternalLinkForm'

export const MyPage = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['creator', 'auth', 'app'], {
    useSuspense: false,
  })
  const { pathname } = useLocation()

  const creatorPage = useCreatorPage()

  const [isMobilePreviewOpen, togglePreview] = useState(false)
  const [isSharePopupOpen, setIsSharePopupOpen] = useState(false)
  const [shareLinkContextData, setShareLink] = useState<ShareLinkType>({
    url: '',
    container: document.body,
  })
  const header = useMyPageRouteInfo()

  const hasTabs = useMemo(() => {
    return (
      [
        ROUTES.MY_PAGE,
        ROUTES.MY_PAGE_APPEARANCE,
        ROUTES.MY_PAGE_SETTINGS,
      ] as string[]
    ).includes(pathname)
  }, [pathname])

  const creatorPublicURL = creatorUrlBuilders.creatorUrlBuilder({
    creatorSlug: creatorPage._links.self.name,
  })

  useEffect(() => {
    if (creatorPage) {
      dispatch(setInitialState(creatorPage))
    }
  }, [creatorPage, dispatch])

  const handlePreviewClick = () => {
    togglePreview(true)
    if (isMobilePreviewOpen) {
      ReactGA.event({
        action: 'mobile_preview:show_btn:click',
        category: ANALYTICS_CATEGORY.APPLICATION,
      })
    }
  }
  const handleShareClick = () => {
    nativeShareWrapper(creatorPublicURL, () =>
      setIsSharePopupOpen(!isSharePopupOpen)
    )
  }
  /**
   * This is needed to avoid duplicating the layout with preview for different pages
   * and to avoid Preview component being unmounted and mounted when tab is changed
   * leading to a loading in the preview
   */
  const renderPageContainerLayoutWithJSX = (
    children: JSX.Element,
    options?: {
      showMobileActions?: boolean
    }
  ) => {
    return (
      <StyledContainer>
        <StyledLeftCol>
          <StyledContentContainer>
            {options?.showMobileActions && (
              <StyledMobileShareActionsContainer>
                <TextField
                  name="url"
                  rightDecorator={
                    <a href={creatorPublicURL} target="_blank" rel="noreferrer">
                      <Box p={2}>
                        <Icon.LinkExternal
                          width={12}
                          height={12}
                          fillColor={Theme.Button.backgroundColor}
                        />
                      </Box>
                    </a>
                  }
                  readOnly
                  style={{ color: Theme.Colors.typography.regular }}
                  value={creatorPublicURL}
                  fieldSize={TextFieldSize.SLIM}
                />
                <Button
                  size={ButtonSize.SLIM}
                  variant={ButtonVariant.PLAIN}
                  clickHandler={handleShareClick}
                  inverse
                >
                  <Box p={8}>
                    <Flex gap={8} translate="no">
                      <Icon.Share />
                      {t('creator:share_page_button')}
                    </Flex>
                  </Box>
                </Button>
              </StyledMobileShareActionsContainer>
            )}
            <StyledContentWrapper>{children}</StyledContentWrapper>
          </StyledContentContainer>
        </StyledLeftCol>
        <StyledRightCol>
          <StylePreviewWrapper>
            <Preview
              isMobilePreviewOpen={isMobilePreviewOpen}
              closeMobilePreview={() => togglePreview(false)}
            />
          </StylePreviewWrapper>
        </StyledRightCol>
      </StyledContainer>
    )
  }

  return (
    <>
      <PageHeader
        hasArrowBack={header.allowMyPageBack}
        hasBorder={header.hasBorder}
      >
        <StyledHeaderContentContainer>
          <StyledTitleWrapper>
            {header.allowMyPageBack && (
              <Link to={ROUTES.MY_PAGE}>
                <Icon.ArrowBack />
              </Link>
            )}
            <PageTitle title={header.title} />
          </StyledTitleWrapper>
          {header.showShareFeature && (
            <>
              <StyledCreatorURLWrapper>
                <TextField
                  name="url"
                  rightDecorator={
                    <a href={creatorPublicURL} target="_blank" rel="noreferrer">
                      <Box p={2}>
                        <Icon.LinkExternal
                          width={12}
                          height={12}
                          fillColor={Theme.Button.backgroundColor}
                        />
                      </Box>
                    </a>
                  }
                  readOnly
                  style={{ color: Theme.Colors.typography.regular }}
                  value={creatorPublicURL}
                  fieldSize={TextFieldSize.SLIM}
                />
                <Button
                  size={ButtonSize.SLIM}
                  clickHandler={handleShareClick}
                  inverse
                >
                  <StyledShareButtonContent translate="no">
                    <Icon.Share />
                    {t('creator:share_page_button')}
                  </StyledShareButtonContent>
                </Button>
              </StyledCreatorURLWrapper>
              <StyledPageActions>
                <Button
                  clickHandler={handlePreviewClick}
                  variant={ButtonVariant.PLAIN}
                >
                  <Box p={16} mr={-8}>
                    <Icon.PreviewEye />
                  </Box>
                </Button>
              </StyledPageActions>
            </>
          )}
          {header.allowMyPageBack && (
            <StyledPreviewIconWrapper>
              <Button
                clickHandler={() => togglePreview(true)}
                variant={ButtonVariant.PLAIN}
              >
                <Box p={16}>
                  <Icon.PreviewEye />
                </Box>
              </Button>
            </StyledPreviewIconWrapper>
          )}
        </StyledHeaderContentContainer>
      </PageHeader>
      <ShareLinkContext.Provider
        value={{ ...shareLinkContextData, setShareLink }}
      >
        <PageWrapper hasTabs={hasTabs} fullSize>
          <Routes>
            <Route
              path={relativePath(ROUTES.MY_PAGE_ADD_VIDEO, ROUTES.MY_PAGE)}
              element={renderPageContainerLayoutWithJSX(
                <AddSectionEmbed type={EmbedProvidersCategory.VIDEO} />
              )}
            />

            <Route
              path={relativePath(ROUTES.MY_PAGE_VIDEO_EDIT, ROUTES.MY_PAGE)}
              element={renderPageContainerLayoutWithJSX(
                <EditSectionEmbed type={EmbedProvidersCategory.VIDEO} />
              )}
            />

            <Route
              path={relativePath(ROUTES.MY_PAGE_ADD_MUSIC, ROUTES.MY_PAGE)}
              element={renderPageContainerLayoutWithJSX(
                <AddSectionEmbed type={EmbedProvidersCategory.MUSIC} />
              )}
            />

            <Route
              path={relativePath(ROUTES.MY_PAGE_MUSIC_EDIT, ROUTES.MY_PAGE)}
              element={renderPageContainerLayoutWithJSX(
                <EditSectionEmbed type={EmbedProvidersCategory.MUSIC} />
              )}
            />

            <Route
              path={relativePath(
                ROUTES.MY_PAGE_EDIT_AFFILIATE_LINK_SECTION,
                ROUTES.MY_PAGE
              )}
              element={renderPageContainerLayoutWithJSX(
                <EditAffiliateLinkSection />
              )}
            />

            <Route
              path={relativePath(
                ROUTES.MY_PAGE_AFFILIATE_LINK_SECTION,
                ROUTES.MY_PAGE
              )}
              element={renderPageContainerLayoutWithJSX(
                <AddAffiliateLinkSection />
              )}
            />

            <Route
              path={relativePath(
                ROUTES.MY_PAGE_CUSTOM_LINK_EDIT,
                ROUTES.MY_PAGE
              )}
              element={renderPageContainerLayoutWithJSX(
                <EditExternalLinkSection />
              )}
            />

            <Route
              path={relativePath(ROUTES.MY_PAGE_CUSTOM_LINK, ROUTES.MY_PAGE)}
              element={renderPageContainerLayoutWithJSX(
                <AddExternalLinkSection />
              )}
            />

            <Route
              path={relativePath(
                ROUTES.MY_PAGE_CONTENT_SECTION,
                ROUTES.MY_PAGE
              )}
              element={renderPageContainerLayoutWithJSX(<AddSectionContent />)}
            />

            <Route
              path={relativePath(
                ROUTES.MY_PAGE_EDIT_CONTENT_SECTION,
                ROUTES.MY_PAGE
              )}
              element={renderPageContainerLayoutWithJSX(<EditSectionContent />)}
            />

            <Route
              path={relativePath(
                ROUTES.MY_PAGE_EDIT_PRODUCT_RECOMMENDATIONS,
                ROUTES.MY_PAGE
              )}
              element={renderPageContainerLayoutWithJSX(
                <EditSectionProductRecommendations />
              )}
            />

            <Route
              path={relativePath(
                ROUTES.MY_PAGE_ADD_PRODUCT_RECOMMENDATIONS,
                ROUTES.MY_PAGE
              )}
              element={renderPageContainerLayoutWithJSX(
                <AddSectionProductRecommendations />
              )}
            />

            {/* Tab menu only present for this pages */}
            <Route
              path="*"
              element={
                <Tabs
                  index={header.selectedTabIndex}
                  onChange={() => undefined}
                >
                  <TabList>
                    <Link to={ROUTES.MY_PAGE}>
                      <Tab
                        isActive={header.selectedTabIndex === 0}
                        label={t('creator:my_page_tabs.sections')}
                        iconId="MyPage"
                        iconActiveId="MyPageActive"
                      />
                    </Link>
                    <Link to={ROUTES.MY_PAGE_APPEARANCE}>
                      <Tab
                        isActive={header.selectedTabIndex === 1}
                        label={t('creator:my_page_tabs.appearance')}
                        iconId="Paint"
                        iconActiveId="PaintActive"
                      />
                    </Link>
                    <Link to={ROUTES.MY_PAGE_SETTINGS}>
                      <Tab
                        isActive={header.selectedTabIndex === 2}
                        label={t('creator:my_page_tabs.settings')}
                        iconId="Settings"
                        iconActiveId="SettingsActive"
                      />
                    </Link>
                  </TabList>

                  <TabPanels>
                    {renderPageContainerLayoutWithJSX(
                      <>
                        <TabPanel>
                          {creatorPage && (
                            <MyPageContent
                              creatorPage={creatorPage}
                              isFetchingCreatorPage={
                                creatorPage.queryStatus?.isFetching
                              }
                            />
                          )}
                        </TabPanel>
                        <TabPanel>
                          {creatorPage && (
                            <MyPageAppearance creatorPage={creatorPage} />
                          )}
                        </TabPanel>
                        <TabPanel>
                          <MyPageSettings />
                        </TabPanel>
                      </>,
                      {
                        showMobileActions: true,
                      }
                    )}
                  </TabPanels>
                </Tabs>
              }
            />
          </Routes>
          {isSharePopupOpen && (
            <SharePopup
              onClose={() => setIsSharePopupOpen(false)}
              creatorPublicURL={creatorPublicURL}
            />
          )}
          {shareLinkContextData?.url && (
            <ShareLinkPopup
              title={t('app:share_popup.share_link')}
              messages={{
                t,
                namespace: 'app',
                copyButton: {
                  buttonText: t('app:button_copy'),
                  copiedTooltipText: t('app:button_tooltip_copied'),
                },
              }}
              isReduced
            />
          )}
        </PageWrapper>
      </ShareLinkContext.Provider>
    </>
  )
}
