import { api, DateHelpers, Money } from '@web-apps/utils-shared'
import {
  PayoutInfoType,
  PayoutsHistoryAPIResponse,
  PayoutsHistoryType,
} from '@web-apps/utils-types'

const payoutApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getPayoutInfo: builder.query<PayoutInfoType, { path: string }>({
      query: ({ path }) => ({
        path,
        method: 'get',
      }),
      providesTags: ['PayoutInfo'],
    }),
    getPayoutsHistory: builder.query<PayoutsHistoryType, { path: string }>({
      query: ({ path }) => ({
        path,
        method: 'get',
      }),
      transformResponse: (response: PayoutsHistoryAPIResponse) => ({
        payouts: response.content.map((item) => ({
          amount: Money.formatPrice(item.amount),
          paidDate: DateHelpers.formatDateToText(item.paid_at),
          invoiceNumber: item.invoice_number,
        })),
        payoutsCount: response.page.total_elements,
        prevPage: response._links.prev?.href,
        nextPage: response._links.next?.href,
      }),
    }),
  }),
})

export const { useGetPayoutInfoQuery, useGetPayoutsHistoryQuery } = payoutApi
