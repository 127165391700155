import styled from 'styled-components'

const StyledPageTitle = styled.h2`
  align-self: center;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0;
  font-size: 24px;
  line-height: 32px;
  white-space: nowrap;
  max-width: 100%;
`

type PageTitleProps = {
  title: string
}
const PageTitle = ({ title }: PageTitleProps) => {
  return <StyledPageTitle>{title}</StyledPageTitle>
}

export default PageTitle
