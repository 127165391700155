import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import {
  Box,
  Button,
  ButtonSize,
  ButtonVariant,
  Flex,
  Typography,
} from '@web-apps/ui-shared'

const StyledEmoji = styled.div`
  font-weight: 700;
  font-size: 96px;
`

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 100vh;
  text-align: center;
`

export const ErrorApp = () => {
  const { t } = useTranslation(['app'])

  return (
    <StyledContainer>
      <StyledEmoji>
        {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
        <span role="img" arial-label={t('app:error_boundary.title')}>
          🤕
        </span>
      </StyledEmoji>
      <Box my={64}>
        <Flex gap={16} direction="column" align="center">
          <Typography variant="h2" fontWeight="600">
            {t('app:error_boundary.title')}
          </Typography>
          <div>
            <Typography>
              {t('app:error_boundary.description.header')}
            </Typography>
            <Typography>
              {t('app:error_boundary.description.message')}
            </Typography>
          </div>
        </Flex>
      </Box>
      <Flex gap={8} direction="column" align="center">
        <Button
          type="button"
          size={ButtonSize.REGULAR}
          clickHandler={() => window.location.reload()}
        >
          {t('app:error_boundary.primary_button_text')}
        </Button>
      </Flex>
    </StyledContainer>
  )
}
