import { CustomCard, CustomCardProps } from './CustomCard'
import { TitledCardFooter, TitledCardFooterProps } from './TitledCardFooter'

export type TitledCardProps = CustomCardProps & TitledCardFooterProps

export const TitledCard = ({
  title,
  description,
  ...props
}: TitledCardProps) => {
  return (
    <CustomCard
      {...props}
      footer={<TitledCardFooter title={title} description={description} />}
    />
  )
}
