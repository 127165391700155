import { FC, PropsWithChildren, useRef } from 'react'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { StyledContainer } from './ShowHideAnimationWrapper.styles'

export const ShowHideAnimationWrapper: FC<
  PropsWithChildren<
    | {
        in: boolean
      }
    | { state: string; mode?: 'out-in' | 'in-out' }
  >
> = (props) => {
  const nodeRef = useRef(null)

  if ('state' in props) {
    return (
      <SwitchTransition mode={props.mode}>
        <CSSTransition
          in
          key={props.state}
          nodeRef={nodeRef}
          unmountOnExit
          timeout={200}
          classNames="fade"
        >
          <StyledContainer ref={nodeRef}>{props.children}</StyledContainer>
        </CSSTransition>
      </SwitchTransition>
    )
  }

  return (
    <CSSTransition
      nodeRef={nodeRef}
      in={props.in}
      timeout={200}
      unmountOnExit
      classNames="fade"
    >
      <StyledContainer ref={nodeRef}>{props.children}</StyledContainer>
    </CSSTransition>
  )
}
