import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { useDebouncedCallback } from 'use-debounce'

import { useValidateSlugMutation } from '@web-apps/feature-auth'
import { Form, TextField, SvgIcon } from '@web-apps/ui-shared'
import {
  FormValidations,
  isFetchBaseQueryErrorType,
} from '@web-apps/utils-shared'

import { useUpdateSlugMutation } from '../../../../../services/user.api'

import { FormActionsBar } from '../../../../../components'
import { ReactComponent as LinkIcon } from '../../../../../assets/icons/link-chain.svg'
import { ReactComponent as ErrorIcon } from '../../../../../assets/icons/error-icon.svg'
import { ReactComponent as SuccessIcon } from '../../../../../assets/icons/success-icon.svg'

import { StyledSlugInput } from './PageUrlSection.styles'

type Props = {
  slug: string
}

export const PageUrlSection = ({ slug }: Props) => {
  const { t } = useTranslation(['creator', 'app'])
  const [updateSlug, { isSuccess, isError, isLoading }] =
    useUpdateSlugMutation()
  const [
    validateSlug,
    {
      isSuccess: isSlugValid,
      isError: isSlugInvalid,
      error: slugValidationError,
    },
  ] = useValidateSlugMutation()

  const debouncedSlugValidation = useDebouncedCallback(
    (slug: string) =>
      FormValidations.slugRegex.test(slug) && validateSlug({ slug }),
    500
  )

  useEffect(() => {
    if (isSuccess) {
      toast.success(t('creator:profile_updated_message_success'))
    }
  }, [isSuccess, t])

  useEffect(() => {
    if (isError) {
      toast.error(t('creator:profile_updated_message_error'))
    }
  }, [isError, t])

  let slugValidationErrorStatus = 0
  if (
    isSlugInvalid &&
    slugValidationError &&
    isFetchBaseQueryErrorType(slugValidationError)
  ) {
    slugValidationErrorStatus = slugValidationError.status as number
  }

  const initialValues = {
    profileURL: slug,
  }

  const onFormSubmit = useCallback(
    ({
      profileURL,
    }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
    any) => updateSlug(profileURL),
    [updateSlug]
  )

  const validateProfileURLField = (slug: string) => {
    const isSlugWellFormatted = FormValidations.slugRegex.test(slug)

    if (isSlugWellFormatted && isSlugValid) return true
    if (isSlugWellFormatted && slugValidationErrorStatus === 409) {
      return t('creator:duplicate_url')
    } else if (!isSlugWellFormatted) {
      return t('creator:invalid_profile_url', {
        minChars: FormValidations.slugMinLength,
        maxChars: FormValidations.slugMaxLength,
      })
    }
  }

  return (
    <Form
      onSubmit={onFormSubmit}
      initialValues={initialValues}
      initialWatchableFields={['profileURL']}
      validations={{
        descriptors: {
          profileURL: {
            validate: validateProfileURLField,
          },
        },
      }}
    >
      {({ getValues, formState: { errors, dirtyFields } }) => {
        const profileURLErrorMessage = validateProfileURLField(
          getValues('profileURL')
        )

        return (
          <>
            <StyledSlugInput>
              <TextField
                label={t('creator:my_page_url_field_label')}
                name="profileURL"
                errorMessages={
                  typeof profileURLErrorMessage === 'string'
                    ? [profileURLErrorMessage]
                    : undefined
                }
                leftDecorator={
                  <SvgIcon verticalAlign="middle" mr={13}>
                    <LinkIcon />
                  </SvgIcon>
                }
                rightDecorator={
                  isSlugValid ? (
                    <SuccessIcon />
                  ) : isSlugInvalid ||
                    (errors.profileURL && dirtyFields.profileURL) ? (
                    <ErrorIcon />
                  ) : undefined
                }
                onChange={({ target: { value } }) => {
                  debouncedSlugValidation(value)
                }}
              />
            </StyledSlugInput>
            <FormActionsBar
              hideSecondaryAction
              isLoading={isLoading}
              mb={0}
              justifyContent="flex-start"
            />
          </>
        )
      }}
    </Form>
  )
}
