import React from 'react'

import { Card } from '@web-apps/ui-shared'
import {
  StyledFormSectionContainer,
  StyledFormSectionTitle,
} from '../MyPageSettings.styles'
import { FormSections } from '../MyPageSettings.utils'

type FormSectionProps = {
  title: string
  sectionId: FormSections
}

export const FormSection: React.FC<React.PropsWithChildren<FormSectionProps>> = ({
  children,
  sectionId,
  title,
}) => {
  return (
    <StyledFormSectionContainer id={sectionId}>
      <Card>
        <StyledFormSectionTitle>{title}</StyledFormSectionTitle>
        {children}
      </Card>
    </StyledFormSectionContainer>
  )
}
