import { getAvatarImage } from '@web-apps/feature-creator-page'
import {
  Localisation,
  FormValidations,
  DateHelpers,
  api,
  imageUrlBuilder,
} from '@web-apps/utils-shared'
import {
  UserIdType,
  UserBalanceApiResponseType,
  UserBalanceType,
  UserAccountType,
  UserAccountResponseType,
  UserAccountSettingsFormType,
} from '@web-apps/utils-types'

const MAX_AVATAR_SIZE = 40

const userApi = api.injectEndpoints({
  endpoints: (builder) => ({
    updateSlug: builder.mutation<void, string>({
      query: (slug) => ({
        path: 'account/slug',
        method: 'put',
        data: {
          slug,
        },
      }),
      invalidatesTags: ['CreatorDetails', 'CreatorPage', 'UserAccount'],
    }),
    getUserAccount: builder.query<UserAccountType, { userId: UserIdType }>({
      query: ({ userId }) => ({
        path: `/users/${userId}`,
        method: 'get',
      }),
      transformResponse: (responseData: UserAccountResponseType) => ({
        id: responseData._links.self.id,
        email: responseData.email,
        name: responseData.name,
        phone: responseData.phone_number || undefined,
        language: responseData.language,
        timeZone: responseData.time_zone,
        createdAt: responseData.created_at,
        userId: responseData._links.self.id,
        slug: responseData._links.creator_page[0].name,
        pageId: responseData._links.creator_page[0].id,
        legacyCoachId: responseData._links.self.__legacy_id,
        isMetapicUser: responseData.is_metapic_referral,
        metapicUserId: responseData.metapic_user_id,
        avatar: imageUrlBuilder({
          image: getAvatarImage(responseData),
          width: MAX_AVATAR_SIZE * 4,
        }),
        affiliateBalanceEndpoint: responseData._links.affiliate_balance.href,
        affiliatePayoutsEndpoint: responseData._links.affiliate_payouts.href,
        creatorPageEndpoint: responseData._links.creator_page[0].href,
        payoutInfoEndpoint: responseData._links.payout_info.href,
        payoutInfoFormEndpoint: responseData._links.payout_info_form?.href,
        region: responseData.region,
      }),
      /* Keep it cached for 30 minutes, and should be invalidated when user subscription is changed */
      keepUnusedDataFor: 30 * 60,
      providesTags: ['UserAccount'],
    }),
    updateUserAccount: builder.mutation<
      Record<string, never>,
      { userId: string; userAccountData: UserAccountSettingsFormType }
    >({
      query: ({ userId, userAccountData }) => ({
        path: `/users/${userId}`,
        method: 'put',
        data: {
          name: FormValidations.isEmpty(userAccountData.name)
            ? undefined
            : userAccountData.name,
          email: userAccountData.email,
          language: userAccountData.language,
          time_zone:
            Localisation.CURRENT_DEVICE_TIMEZONE ||
            Localisation.DEFAULT_TIMEZONE,
        },
      }),
      transformResponse: () => ({}),
      invalidatesTags: (result) =>
        result ? ['UserAccount', 'APIConfigs'] : [],
    }),
    getAffiliateBalance: builder.query<UserBalanceType, { path: string }>({
      query: ({ path }) => ({
        path,
        method: 'get',
      }),
      transformResponse: (data: UserBalanceApiResponseType) => ({
        outstanding: data.outstanding,
        payoutThreshold: data.payout_threshold,
        thresholdReached: data.threshold_reached,
        nextPayoutDate: DateHelpers.formatDateToText(data.next_payout_date),
      }),
      providesTags: ['UserAffiliateBalance'],
    }),
  }),
})

export const {
  useUpdateSlugMutation,
  useGetUserAccountQuery,
  useUpdateUserAccountMutation,
  useGetAffiliateBalanceQuery,
} = userApi
