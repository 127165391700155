import Plausible from 'plausible-tracker'

let plausible: ReturnType<typeof Plausible>

export const initPlausible = (domain: string) => {
  plausible = Plausible({ domain })
}

export const sendAnalyticsEvent = (
  event: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props: any
) => {
  plausible.trackEvent(event, props)
}
