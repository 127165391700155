import { BREAKPOINTS, Button, Flex, media, Theme } from '@web-apps/ui-shared'

import styled from 'styled-components'

export const StyledAffiliateLinkDescriptionContainer = styled(Flex)`
  /* needed for text ellipsis to work for title */
  min-width: 0;

  flex: 1;
  max-width: 100%;
`
export const StyledImage = styled.img`
  width: 64px;
  height: 64px;

  border: 1px solid ${Theme.Card.borderColor};
  border-radius: ${Theme.Card.borderRadiusInPx}px;

  object-fit: contain;
`

export const StyledAffiliateLinkActionButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  ${media.from(
    BREAKPOINTS.md,
    `
        flex-direction: row;
    `
  )}
`

export const StyledButton = styled(Button)`
  white-space: nowrap;
  width: 100%;

  ${media.from(
    BREAKPOINTS.md,
    `
      width: revert;
      flex-shrink: 1;
    `
  )}
`

export const StyledAddButton = styled(StyledButton)`
  min-width: 203px;
`
