import { creatorUrlBuilders, api as apiBase } from '@web-apps/utils-shared'
import { APIConfigs, APIConfigsResponse } from '@web-apps/utils-types'

export const api = apiBase

const coreApi = apiBase.injectEndpoints({
  endpoints: (builder) => ({
    fetchExternalLinkImage: builder.mutation<
      { imageUrl: string; externalUrl: string; pageTitle?: string },
      { externalUrl: string }
    >({
      query: ({ externalUrl }) => ({
        baseUrl: '',
        path: `${
          process.env.NODE_ENV === 'test'
            ? ''
            : creatorUrlBuilders.calculatePlatformRootURL()
        }/api/scraper/image?externalUrl=${encodeURIComponent(externalUrl)}`,
        method: 'GET',
        withCredentials: false,
      }),
    }),
    getAPIConfigs: builder.query<APIConfigs, void>({
      query: () => ({
        path: '/',
        method: 'get',
      }),
      providesTags: ['APIConfigs'],
      transformResponse: (response: APIConfigsResponse) => ({
        endpointPaths: {
          latestCommissionIncreases:
            response._links.latest_commission_increases?.href,
          getStarted: response._links.get_started?.href,
          publishGetStartedEvent: response._links['get_started/events']?.href,
          analyticsSources: response._links['analytics/sources']?.href,
          analytics: response._links.analytics?.href,
          activeChallenge: response._links.active_challenge?.href,
          inspectLink: response._links['affiliate_link/inspect']?.href,
          topBrands: response._links['top-brands']?.href,
          brandsSearch: response._links['brands/search']?.href,
        },
      }),
    }),
  }),
})

export const { useFetchExternalLinkImageMutation, useGetAPIConfigsQuery } =
  coreApi
