import { Flex } from '../../atoms'
import { Theme } from '../../theme'
import {
  StyledContainer,
  StyledGrowth,
  StyledPanelResultsContainer,
  StyledPanelTitle,
  StyledValue,
} from './MetricSummaryPanel.styles'

import { MetricSummaryPanelProps } from './MetricSummaryPanel.types'
import { propsForPercentageGrowth } from './MetricSummaryPanel.utils'

export const MetricSummaryPanel = ({
  title,
  value,
  percentageGrowth,
  customRightIndicatorForTitle,
  customLeftIndicatorForTitle,
  customRightIndicatorForValue,
  customColorForValue = 'regular',
}: MetricSummaryPanelProps) => {
  const percentageGrowthProps = percentageGrowth
    ? propsForPercentageGrowth(percentageGrowth)
    : undefined

  return (
    <StyledContainer>
      <Flex align="center" gap={4}>
        {customLeftIndicatorForTitle && customLeftIndicatorForTitle}
        <StyledPanelTitle>{title}</StyledPanelTitle>
        {customRightIndicatorForTitle}
      </Flex>
      <StyledPanelResultsContainer>
        <StyledValue $color={Theme.Colors.typography[customColorForValue]}>
          {value}
        </StyledValue>
        {percentageGrowthProps && (
          <StyledGrowth $color={percentageGrowthProps.color}>
            {percentageGrowthProps.text}
          </StyledGrowth>
        )}
        {customRightIndicatorForValue && customRightIndicatorForValue}
      </StyledPanelResultsContainer>
    </StyledContainer>
  )
}
