export const isEmpty = (value?: string) => {
  return !(value && value.trim().length > 0)
}

export const gridLimitReached = (error: any) => {
  if (
    error.status !== 400 ||
    error?.data?.type !==
      'https://zezam.gitlab.io/api/problem/constraint-violation'
  ) {
    return false
  }
  return error.data.violations?.some(
    (v: any) =>
      v.field === 'addSection.id' &&
      v.message === '{io.zezam.creator_page.CanAddNestedSection.message}'
  )
}

export const sectionLimitReached = (error: any) => {
  if (
    error.status !== 400 ||
    error?.data?.type !==
      'https://zezam.gitlab.io/api/problem/constraint-violation'
  ) {
    return false
  }
  return error.data.violations?.some(
    (v: any) =>
      (v.field === 'createSection.pageId' || v.field === 'addSection.pageId') &&
      v.message === '{io.zezam.creator_page.CanAddPageSection.message}'
  )
}
