import i18next from 'i18next'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

import { Localisation } from '@web-apps/utils-shared'
import { config } from '../utils/helpers/config.helpers'

const cdnBaseUrl = 'https://cdn.simplelocalize.io'
const environment =
  process.env.NODE_ENV === 'production' ? '_production' : '_latest'
const loadPathWithNamespaces = `${cdnBaseUrl}/${
  config.simpleLocaliseProjectToken
}/${environment}/{{lng}}/{{ns}}${
  process.env.NODE_ENV === 'production'
    ? `?v=${process.env.REACT_APP_TRANSLATIONS_FILE_HASH}`
    : ''
}`

i18next
  .use(Backend)
  .use(initReactI18next)
  .init({
    initImmediate: false,
    fallbackLng: Localisation.DEFAULT_LANGUAGE,
    lng: Localisation.TRANSLATION_LANGUAGE,
    backend: {
      loadPath:
        config.environment === 'local'
          ? '/locales/{{lng}}/{{ns}}.json'
          : loadPathWithNamespaces,
    },
    react: { useSuspense: true },
  })

export default i18next
