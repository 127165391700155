import { createGlobalStyle } from 'styled-components'

import { BREAKPOINTS, media, Theme, ToastContainer } from '@web-apps/ui-shared'
import { useIsSidebarMenuHidden } from '../Sidebar/Sidebar.hooks'

const ToastsStyle = createGlobalStyle<{ $isSidebarMenuHidden: boolean }>`
  :root {
    --toastify-toast-width: 550px;
    --toastify-toast-min-height: 72px;
    --toastify-toast-max-height: 480px;
    --toastify-color-dark: ${Theme.Colors.background.dark};
  }

  .Toastify__toast-container {
    bottom: ${({ $isSidebarMenuHidden }) =>
      $isSidebarMenuHidden ? '16px' : '80px'};


    ${media.from(
      BREAKPOINTS.lg,
      `
      bottom: 20px;
    `
    )}

    ${media.to(
      BREAKPOINTS.xs,
      `
        width: calc(100vw - 32px);
        left: 50%;
        transform: translateX(-50%);
      `
    )}
  }

  .Toastify__toast {
    padding: 24px;
    border-radius: 8px;
  }

  .Toastify__toast-body {
    padding: 0;
  }

  .Toastify__close-button {
    opacity: 1;
    position: absolute;
    top: 8px;
    right: 8px;
    width: 16px;
    height: 16px;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  .Toastify__toast-icon {
    align-self: baseline;
    padding: 2px;
    width: 24px;
  }
`

export const ToastsWrapper = () => {
  const isSidebarMenuHidden = useIsSidebarMenuHidden()

  return (
    <>
      <ToastsStyle $isSidebarMenuHidden={isSidebarMenuHidden} />
      <ToastContainer />
    </>
  )
}
