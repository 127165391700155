import { useState } from 'react'
import { SettingsFlow, UpdateSettingsFlowBody } from '@ory/client'
import { useTranslation } from 'react-i18next'
import { useFlag } from '@unleash/proxy-client-react'

import { FormValidations, usePasswordHider } from '@web-apps/utils-shared'
import {
  Box,
  Button,
  Flex,
  Form,
  IconButton,
  LoadingSpinner,
  StyledError,
  TextField,
  Theme,
  Typography,
} from '@web-apps/ui-shared'

import { useGetSettingsFlow } from '../../utils/hooks'
import { submitSettingsFlow } from '../../utils/flows'
import { AuthProviderName } from '../../utils/constants'

import {
  FLAG_FACEBOOK_AUTH,
  FLAG_GITHUB_AUTH,
  FLAG_GOOGLE_AUTH,
} from '../../utils/flags.constants'
import { ConnectSocialOption } from '../ConnectSocialOption/ConnectSocialOption'

type SettingsProps = {
  onSuccess: () => void
  onError?: (error: string) => void
  flowId?: string
}

type FormType = 'password' | AuthProviderName

export const Settings = ({ onSuccess }: SettingsProps) => {
  const isGoogleAuthEnabled = useFlag(FLAG_GOOGLE_AUTH)
  const isGithubAuthEnabled = useFlag(FLAG_GITHUB_AUTH)
  const isFacebookAuthEnabled = useFlag(FLAG_FACEBOOK_AUTH)
  const isThereSocialAuth =
    isGoogleAuthEnabled ||
    isGithubAuthEnabled ||
    isFacebookAuthEnabled

  const { t } = useTranslation(['auth'])
  const { flow, fields, isFlowLoading, error: flowError } = useGetSettingsFlow()
  const { isPasswordVisible, togglePasswordVisibility } = usePasswordHider()

  const [submitErrorMessage, setSubmitErrorMessage] = useState<string | null>(
    null
  )
  const [isSubmittingForm, setIsSubmittingForm] = useState<FormType | null>(
    null
  )

  const onSubmit = (form: FormType) => {
    return async (values: UpdateSettingsFlowBody) => {
      setSubmitErrorMessage(null)
      setIsSubmittingForm(form)

      const response = await submitSettingsFlow(flow as SettingsFlow, values)
      if (response?.error) {
        setSubmitErrorMessage(response.error)
      } else {
        onSuccess()
      }

      setIsSubmittingForm(null)
    }
  }

  if (isFlowLoading)
    return (
      <Flex align="center" direction="column">
        <Box py={24}>
          <LoadingSpinner size={42} color={Theme.Colors.background.dark} />
        </Box>
      </Flex>
    )

  return (
    <div>
      {fields && (
        <>
          {isThereSocialAuth && (
            <>
              <Typography variant="h3">
                {t('auth:settings.linked_accounts_section.title')}
              </Typography>
              <Box mt={8}>
                <Typography variant="hint" color="inactive">
                  {t('auth:settings.linked_accounts_section.description')}
                </Typography>
              </Box>

              <Box mt={16}>
                <Flex direction="column" gap="16">
                  {Boolean(fields.google.length) && isGoogleAuthEnabled && (
                    <ConnectSocialOption
                      onSubmit={onSubmit(AuthProviderName.GOOGLE)}
                      providerName={AuthProviderName.GOOGLE}
                      oryInputs={fields.google}
                      isSubmitting={
                        isSubmittingForm === AuthProviderName.GOOGLE
                      }
                    />
                  )}

                  {Boolean(fields.facebook.length) && isFacebookAuthEnabled && (
                    <ConnectSocialOption
                      onSubmit={onSubmit(AuthProviderName.FACEBOOK)}
                      providerName={AuthProviderName.FACEBOOK}
                      oryInputs={fields.facebook}
                      isSubmitting={
                        isSubmittingForm === AuthProviderName.FACEBOOK
                      }
                    />
                  )}

                  {Boolean(fields.apple.length) && (
                    <ConnectSocialOption
                      onSubmit={onSubmit(AuthProviderName.APPLE)}
                      providerName={AuthProviderName.APPLE}
                      oryInputs={fields.apple}
                      isSubmitting={isSubmittingForm === AuthProviderName.APPLE}
                    />
                  )}

                  {Boolean(fields.tiktok.length) && (
                    <ConnectSocialOption
                      onSubmit={onSubmit(AuthProviderName.TIKTOK)}
                      providerName={AuthProviderName.TIKTOK}
                      oryInputs={fields.tiktok}
                      isSubmitting={
                        isSubmittingForm === AuthProviderName.TIKTOK
                      }
                    />
                  )}

                  {Boolean(fields.github.length) && isGithubAuthEnabled && (
                    <ConnectSocialOption
                      onSubmit={onSubmit(AuthProviderName.GITHUB)}
                      providerName={AuthProviderName.GITHUB}
                      oryInputs={fields.github}
                      isSubmitting={
                        isSubmittingForm === AuthProviderName.GITHUB
                      }
                    />
                  )}
                </Flex>
              </Box>
            </>
          )}

          {Boolean(fields.password.length) && (
            <Form
              onSubmit={onSubmit('password')}
              validations={{
                descriptors: {
                  password: {
                    required: t('auth:errors.password_required'),
                    minLength: {
                      value: FormValidations.passwordMinLength,
                      message: t('auth:errors.password_is_short', {
                        number: FormValidations.passwordMinLength,
                      }),
                    },
                  },
                },
              }}
            >
              {() => {
                return (
                  <Box my={24}>
                    <Typography variant="h3">
                      {t('auth:settings.password_section.title')}
                    </Typography>
                    <Box mt={8}>
                      <Typography variant="hint" color="inactive">
                        {t('auth:settings.password_section.description')}
                      </Typography>
                    </Box>

                    {[...fields.csrfToken, ...fields.method].map(
                      ({ attributes }) => (
                        <TextField
                          key={attributes.name}
                          name={attributes.name}
                          type="hidden"
                          value={attributes.value}
                        />
                      )
                    )}
                    {fields.password.map(({ attributes }) => (
                      <Box my={16} key={attributes.name}>
                        <TextField
                          label={t(
                            'auth:settings.password_section.new_password_label'
                          )}
                          name={attributes.name}
                          maxLength={FormValidations.passwordMaxLength}
                          type={isPasswordVisible ? 'text' : 'password'}
                          rightDecorator={
                            <IconButton
                              icon={
                                isPasswordVisible
                                  ? 'PasswordHide'
                                  : 'PasswordShow'
                              }
                              onClick={togglePasswordVisibility}
                            />
                          }
                        />
                      </Box>
                    ))}
                    <Button
                      width="100%"
                      isLoading={isSubmittingForm === 'password'}
                    >
                      {t('auth:settings.password_section.submit_button')}
                    </Button>
                  </Box>
                )
              }}
            </Form>
          )}
          <Box my={24}>
            {(submitErrorMessage || flowError) && (
              <Flex align="center" justify="center">
                <Box mb={24}>
                  <StyledError $size="regular">
                    {t(`auth:errors.${submitErrorMessage || flowError}`)}
                  </StyledError>
                </Box>
              </Flex>
            )}
          </Box>
        </>
      )}
    </div>
  )
}
