import { useState, useMemo, useCallback } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import ReactGA from 'react-ga4'

import {
  Box,
  Icon,
  Flex,
  IconAssetsKeys,
  shareToSocialConfig,
  StyledLinkShareListItem,
  UrlCopyField,
} from '@web-apps/ui-shared'
import {
  isAppleDevice,
  copyLinkToClipboard,
  getSocialData,
} from '@web-apps/utils-shared'

import { SocialNetworksEnum, ANALYTICS_CATEGORY } from '@web-apps/utils-types'

import { StyledSection, StyledSharePopover } from './SharePopup.styles'

export enum ViewTypes {
  initial = 'initial',
  socials = 'socials',
  settings = 'settings',
}

type SharePopupProps = {
  onClose: () => void
  creatorPublicURL: string
  initialView?: ViewTypes
  disableNavigation?: boolean
  className?: string
}

const SETTINGS_CONFIG = [
  {
    title: getSocialData(SocialNetworksEnum.Instagram).title,
    href: isAppleDevice()
      ? 'instagram://editprofile'
      : 'https://www.instagram.com/accounts/edit/',
    IconComponent:
      Icon[getSocialData(SocialNetworksEnum.Instagram).icon as IconAssetsKeys],
  },
  {
    title: getSocialData(SocialNetworksEnum.TikTok).title,
    href: 'https://vm.tiktok.com',
    IconComponent:
      Icon[getSocialData(SocialNetworksEnum.TikTok).icon as IconAssetsKeys],
  },
  {
    title: getSocialData(SocialNetworksEnum.Twitter).title,
    href: 'https://twitter.com/settings/profile',
    IconComponent:
      Icon[getSocialData(SocialNetworksEnum.Twitter).icon as IconAssetsKeys],
  },
]

export const SharePopup = ({
  onClose,
  creatorPublicURL,
  initialView = ViewTypes.initial,
  disableNavigation = false,
  className,
}: SharePopupProps) => {
  const { t } = useTranslation(['app'])
  const [currentScreen, setCurrentScreen] = useState<ViewTypes>(initialView)
  const shareButtons = useMemo(
    () => shareToSocialConfig(creatorPublicURL, t),
    [creatorPublicURL, t]
  )

  const getHeaderTitle = (type: ViewTypes) => {
    switch (type) {
      case ViewTypes.initial: {
        return 'app:share_popup.sections.initial.title'
      }
      case ViewTypes.socials: {
        return 'app:share_popup.sections.socials.title'
      }
      case ViewTypes.settings: {
        return 'app:share_popup.sections.settings.title'
      }
    }
  }

  const getCloseCallback = useCallback(
    (type: ViewTypes) => {
      if (disableNavigation) return onClose

      switch (type) {
        case ViewTypes.initial: {
          return onClose
        }
        default: {
          return () => setCurrentScreen(ViewTypes.initial)
        }
      }
    },
    [setCurrentScreen, onClose, disableNavigation]
  )

  return (
    <StyledSharePopover
      className={className}
      title={t(getHeaderTitle(currentScreen))}
      onClose={getCloseCallback(currentScreen)}
      withArrowBack={!disableNavigation && currentScreen !== ViewTypes.initial}
      hideHeaderOnDesktop={false}
    >
      <Box mb={24}>
        <UrlCopyField
          name="url"
          url={creatorPublicURL}
          messages={{
            buttonText: t('app:button_copy'),
            copiedTooltipText: t('app:button_tooltip_copied'),
          }}
        />
      </Box>

      {currentScreen === ViewTypes.initial && (
        <>
          <StyledSection
            onClick={() => {
              ReactGA.event({
                action: 'share:on_page_category:click',
                category: ANALYTICS_CATEGORY.MARKETING,
              })
              setCurrentScreen(ViewTypes.settings)
            }}
          >
            <Flex justify="space-between">
              {t('app:share_popup.menu.settings')}
              <Icon.ArrowRight size={16} />
            </Flex>
          </StyledSection>
          <StyledSection
            onClick={() => {
              ReactGA.event({
                action: 'share:on_social_network_category:click',
                category: ANALYTICS_CATEGORY.MARKETING,
              })
              setCurrentScreen(ViewTypes.socials)
            }}
          >
            <Flex justify="space-between">
              {t('app:share_popup.menu.socials')}
              <Icon.ArrowRight size={16} />
            </Flex>
          </StyledSection>
        </>
      )}

      {currentScreen === ViewTypes.settings && (
        <>
          <Box mb={8}>
            <strong>{t('app:share_popup.sections.settings.subtitle')}</strong>
          </Box>
          <Box mb={8}>
            <Trans i18nKey="app:share_popup.sections.settings.description">
              Copy your zezam URL and paste it into the <strong>website</strong>{' '}
              field of your social media profiles.
            </Trans>
          </Box>
          {SETTINGS_CONFIG.map(({ title, href, IconComponent }) => (
            <StyledLinkShareListItem
              href={href}
              target="_blank"
              key={title}
              onClick={async () => {
                await copyLinkToClipboard(creatorPublicURL)
                ReactGA.event({
                  action: 'share:settings_category:click',
                  label: title,
                  category: ANALYTICS_CATEGORY.MARKETING,
                })
              }}
            >
              <Flex justify="space-between">
                <Flex>
                  <Box mr={16}>
                    <IconComponent />
                  </Box>
                  {t('app:share_popup.sections.settings.go_to_link', {
                    socialNetworkName: title,
                  })}
                </Flex>
                <Icon.LinkExternal size={18} />
              </Flex>
            </StyledLinkShareListItem>
          ))}
        </>
      )}
      {currentScreen === ViewTypes.socials &&
        shareButtons.map(
          ({
            ShareComponent,
            shareComponentsProps,
            translation,
            IconComponent,
          }) => (
            <StyledLinkShareListItem as="div" key={translation}>
              <ShareComponent
                {...shareComponentsProps}
                onClick={() => {
                  ReactGA.event({
                    action: 'share:socials_category:click',
                    label: shareComponentsProps.name,
                    category: ANALYTICS_CATEGORY.MARKETING,
                  })
                }}
              >
                <Flex justify="space-between">
                  <Flex>
                    <Box mr={16}>
                      <IconComponent />
                    </Box>
                    {translation}
                  </Flex>
                  <Icon.LinkExternal size={18} />
                </Flex>
              </ShareComponent>
            </StyledLinkShareListItem>
          )
        )}
    </StyledSharePopover>
  )
}
