import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Money } from '@web-apps/utils-shared'
import {
  AnalyticsBarPanel,
  AnalyticsBarPanelProps,
  ContainerHeightVariant,
  IconCatalogPaths,
  StyledNonSelectableText,
  Typography,
} from '@web-apps/ui-shared'
import {
  AffiliateLinkAnalytics,
  CreatorPageAffiliateLinksAnalytics,
} from '@web-apps/utils-types'
import { LEGACY_AFFILIATE_LINKS } from '@web-apps/feature-creator-page'

import { CommissionHint } from '../../../../../components'

import { StyledAffiliateLinkImage } from './CommissionsDashboard.styles'

type CommissionsDashboardProps = {
  affiliateLinksAnalytics: CreatorPageAffiliateLinksAnalytics
  isLoading?: boolean
  onScrollBottomReached?: () => void
  onLinkClick: (link: AffiliateLinkAnalytics) => void
}

export const CommissionsDashboard = ({
  affiliateLinksAnalytics,
  isLoading = false,
  onScrollBottomReached,
  onLinkClick,
}: CommissionsDashboardProps) => {
  const { t } = useTranslation(['metrics', 'brands'], { useSuspense: false })

  const commissionsBarPanelProps: AnalyticsBarPanelProps = {
    title: t('metrics:commissions.title'),
    columns: [
      { title: t('metrics:commissionsDashboard.table.headers.leftColumn') },
      { title: t('metrics:commissionsDashboard.table.headers.rightColumn') },
    ],
    messages: {
      noData: t('metrics:noDataApprovedCommissions'),
      callToActionInfo: t('metrics:advanced.callToActionInfo'),
      callToActionButtonText: t('metrics:advanced.callToActionButtonText'),
    },
    stats: (affiliateLinksAnalytics.results || []).map((a) => {
      return {
        id: a.id,
        text:
          a.id === LEGACY_AFFILIATE_LINKS
            ? t('metrics:my_legacy_links')
            : a.description || (
                <Typography
                  as="span"
                  variant="note"
                  color="inactive"
                  fontStyle="italic"
                >
                  {/* Following the second suggestion here: https://stackoverflow.com/a/38723634 to avoid italic text be cut */}
                  {t('brands:affiliate_links_list.no_title_placeholder')}&nbsp;
                </Typography>
              ),
        value: a.earnings?.approved.amount || 0,
        subtitle: a.originalURL && (
          <StyledNonSelectableText>{a.originalURL}</StyledNonSelectableText>
        ),
        hint: <CommissionHint commission={a.commission} />,
        icon: (
          <StyledAffiliateLinkImage
            alt={a.description}
            src={
              a.image ? a.image.href : IconCatalogPaths.ProductImagePlaceholder
            }
            $isCover={Boolean(a.image?.name === 'product')}
          />
        ),
        as: Link,
        to: a.detailsPageURL,
        onClick: () => onLinkClick(a),
        customizableValueOptions: {
          valueFormatter: () =>
            (a.earnings?.approved &&
              Money.formatPriceWithoutRounding(a.earnings.approved)) ||
            '',
        },
        titleVariant: 'subTitleBold',
      }
    }),
  }

  return (
    <AnalyticsBarPanel
      {...commissionsBarPanelProps}
      isLoading={isLoading}
      containerHeightVariant={ContainerHeightVariant.Medium}
      onBottomScrollReached={onScrollBottomReached}
    />
  )
}
