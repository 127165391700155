import styled from 'styled-components'

import { Theme } from '@web-apps/ui-shared'

export const StyledSlugInput = styled.div`
  position: relative;

  &:before {
    content: 'zez.am/';
    position: absolute;
    font-weight: 600;
    top: 49px;
    left: 56px;
    z-index: 1;
    color: ${Theme.Colors.typography.inactive};
  }

  input {
    padding-left: 118px !important;
  }
`
