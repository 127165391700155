import { lazy } from 'react'
import { Navigate } from 'react-router-dom'
import cloneDeep from 'lodash.clonedeep'

import { UserRoles } from '@web-apps/feature-auth'

import { ROUTES } from './RouteEnums'
import {
  Analytics,
  BrandCollaborations,
  EarningsPage,
  MyPage,
  PaymentDetailsPage,
  TrendingProductsPage,
  ViewsAnalyticsPage,
} from '../containers/creator'

import PersonalSettings from '../containers/PersonalSettings/PersonalSettings'
import { NotFound } from '../containers/NotFound/NotFound'
import { LogoutPage } from '../containers/auth/Logout'
import { BrandsPage } from '../containers/creator/BrandCollaborations/BrandsPage'
import { LinkConverterPage } from '../containers/creator/BrandCollaborations/components/LinkConverterPage'
import { AffiliateLinkAnalyticsDetails } from '../containers/creator/Analytics/AffiliateLinkAnalyticsDetails'
import { ShareLinkContextWrapper } from '../components'

const BrandManagementPage = lazy(() =>
  import('../containers/admin/BrandManagementPage').then(
    ({ BrandManagementPage }) => ({ default: BrandManagementPage })
  )
)

const TopOffersCuratorPage = lazy(() =>
  import('../containers/admin/BrandManagementPage/TopOffersCuratorPage').then(
    ({ TopOffersCuratorPage }) => ({ default: TopOffersCuratorPage })
  )
)

const EditBrandPage = lazy(() =>
  import('../containers/admin/BrandManagementPage/EditBrandPage').then(
    ({ EditBrandPage }) => ({ default: EditBrandPage })
  )
)

const AdminBrandsPage = lazy(() =>
  import('../containers/admin/BrandManagementPage/BrandsPage').then(
    ({ BrandsPage }) => ({ default: BrandsPage })
  )
)

const OffersCuratorPage = lazy(() =>
  import('../containers/admin/BrandManagementPage/OffersCuratorPage').then(
    ({ OffersCuratorPage }) => ({ default: OffersCuratorPage })
  )
)

const EditOfferPage = lazy(() =>
  import('../containers/admin/BrandManagementPage/EditOfferPage').then(
    ({ EditOfferPage }) => ({ default: EditOfferPage })
  )
)

export const RoutesComponents = [
  {
    element: <PersonalSettings />,
    path: `${ROUTES.PERSONAL_SETTINGS}/*`,
  },
  {
    element: <MyPage />,
    path: `${ROUTES.MY_PAGE}/*`,
  },
  {
    element: (
      <ShareLinkContextWrapper>
        <AffiliateLinkAnalyticsDetails />
      </ShareLinkContextWrapper>
    ),
    path: ROUTES.AFFILIATE_LINK_ANALYTICS_DETAILS,
  },
  {
    element: <BrandCollaborations />,
    path: ROUTES.BRANDS,
    children: [
      {
        path: '',
        element: <BrandsPage />,
      },
      {
        path: ROUTES.BRAND_DIALOG_MODAL,
        element: <BrandsPage />,
      },
      {
        path: ROUTES.TRENDING_PRODUCTS,
        element: <TrendingProductsPage />,
      },
      {
        path: ROUTES.LINK_CONVERTER,
        element: <LinkConverterPage />,
      },
    ],
  },
  {
    element: <Analytics />,
    path: ROUTES.ANALYTICS,
    children: [
      {
        path: ROUTES.ANALYTICS_EARNINGS,
        element: <EarningsPage />,
      },
      {
        path: ROUTES.ANALYTICS_PAGE,
        element: <ViewsAnalyticsPage />,
      },
      {
        path: ROUTES.ANALYTICS_PAYMENT_DETAILS,
        element: <PaymentDetailsPage />,
      },
    ],
  },
  {
    element: <LogoutPage />,
    path: ROUTES.LOGOUT,
  },
  {
    element: <NotFound />,
    path: '*',
  },
  {
    path: ROUTES.DASHBOARD,
    element: <Navigate to={ROUTES.MY_PAGE} replace />,
  },
  {
    path: '/',
    element: <Navigate to={ROUTES.MY_PAGE} replace />,
  },
  {
    path: ROUTES.REGISTER,
    element: <Navigate to={ROUTES.MY_PAGE} replace />,
  },
  {
    path: ROUTES.RESET_PASSWORD,
    element: <Navigate to={ROUTES.MY_PAGE} replace />,
  },
  {
    path: ROUTES.LOGIN,
    element: <Navigate to={ROUTES.MY_PAGE} replace />,
  },
]

export const injectNonPublicRouteComponentsForRoles = (
  userRoles: UserRoles[],
  initialRoutes = RoutesComponents
) => {
  let routes = cloneDeep(initialRoutes)

  if (userRoles.includes(UserRoles.BRAND_MANAGER)) {
    routes = [
      ...routes,
      {
        element: <BrandManagementPage />,
        path: ROUTES.ADMIN_BRAND_MANAGER,
      },
      {
        element: <TopOffersCuratorPage />,
        path: ROUTES.ADMIN_TOP_OFFERS,
      },
      {
        element: <EditBrandPage />,
        path: ROUTES.ADMIN_BRAND_EDIT,
      },
      {
        element: <AdminBrandsPage />,
        path: ROUTES.ADMIN_BRANDS,
      },
      {
        element: <OffersCuratorPage />,
        path: ROUTES.ADMIN_OFFERS,
      },
      {
        element: <EditOfferPage />,
        path: ROUTES.ADMIN_OFFER_EDIT,
      },
    ]
  }

  return routes
}
