import React, { InputHTMLAttributes, CSSProperties } from 'react'
import {
  StyledLabel,
  StyledInputRadio,
  StyledContent,
  StyledText,
} from './RadioInputWithIcon.styles'

export type RadioInputType = InputHTMLAttributes<HTMLInputElement> & {
  label?: string | React.ReactNode
  name: string
  icon?: React.ReactElement
  height?: number | string
  width?: number | string
  containerStyles?: CSSProperties
}

export const RadioInputWithIcon = React.forwardRef<
  HTMLInputElement,
  RadioInputType
>(
  (
    {
      label,
      name,
      icon,
      height,
      width,
      value,
      containerStyles = {},
      ...otherProps
    }: RadioInputType,
    ref
  ) => {
    return (
      <StyledLabel $h={height} $w={width} data-testid={`radio-${value}`}>
        <StyledInputRadio
          id={`radio-${value}`}
          ref={ref}
          name={name}
          type="radio"
          value={value}
          {...otherProps}
        />
        <StyledContent style={containerStyles}>
          {icon ? (
            <>
              {icon}
              {typeof label === 'string' ? (
                <StyledText variant="hint">{label}</StyledText>
              ) : (
                label
              )}
            </>
          ) : (
            <StyledText variant="h4">{label}</StyledText>
          )}
        </StyledContent>
      </StyledLabel>
    )
  }
)
