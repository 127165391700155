import { useTranslation } from 'react-i18next'

import {
  CreatorPageContent,
  EmbedContext,
  useCreateSignupMutation,
} from '@web-apps/feature-creator-page'
import { CreatorPageType } from '@web-apps/utils-types'

type ContainerProps = {
  creatorPage: CreatorPageType
}

export const CreatorPageContainer = ({ creatorPage }: ContainerProps) => {
  const { t } = useTranslation(['app', 'creator'])

  const messages = {
    freeLabel: t('app:free_symbol'),
    contactList: {
      placeholder: t('creator:contact_list_section.placeholder'),
      successMessage: t('creator:contact_list_section.successMessage'),
      errorMessage: t('app:generic_error'),
      requiredMessage: t('app:field_is_required'),
      emailValidationMessage: t('app:email_validation_message'),
    },
  }

  return (
    <EmbedContext.Provider value={{ isPreview: true }}>
      <CreatorPageContent
        creatorPage={creatorPage}
        messages={messages}
        useCreateSignupMutation={useCreateSignupMutation}
        useRelativePaths={false}
        hideRestrictPopup
        skipImageErrorHandler
        preventContactListSubmission
        isPreview
      />
    </EmbedContext.Provider>
  )
}
