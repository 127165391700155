import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Button,
  Dialog,
  Flex,
  Icon,
  Theme,
  Typography,
} from '@web-apps/ui-shared'
import {
  StyledDescription,
  StyledRow,
  StyledCell,
} from './PayoutsHistory.styles'
import { useAccountDetails } from '../../../../../utils/hooks/useAccountDetails.hooks'
import { useGetPayoutsHistoryQuery } from '../../../../../services/payout.api'
import { SkeletonWrapper } from '../../../../../components/skeletons'
import { PayoutsHistorySkeleton } from '../../../../../components/skeletons/templates/PayoutsHistorySkeleton.component'
import { PayoutType } from '@web-apps/utils-types'

type Props = {
  onClose: () => void
}
export const PayoutsHistory = ({ onClose }: Props) => {
  const { t } = useTranslation(['app', 'metrics'], { useSuspense: false })
  const { affiliatePayoutsEndpoint } = useAccountDetails()
  const [nextPage, setNextPage] = useState(affiliatePayoutsEndpoint)
  const { data, isLoading, isFetching, isError } = useGetPayoutsHistoryQuery(
    { path: nextPage },
    {
      skip: !nextPage || !affiliatePayoutsEndpoint,
      refetchOnMountOrArgChange: true,
    }
  )
  const [payouts, setPayouts] = useState<PayoutType[]>([])

  useEffect(() => {
    if (data) {
      setPayouts((prev) => [...prev, ...(data.payouts || [])])
    }
  }, [data])

  return (
    <Dialog
      isOpen
      onDismiss={onClose}
      title={t('metrics:payouts.title')}
      showHeaderBorder={false}
    >
      <StyledDescription gap={8}>
        <Icon.Info
          fillColor={Theme.Colors.typography.inactive}
          width={16}
          height={16}
        />
        <Typography variant="hint" color="inactive">
          {t('metrics:payouts.description')}
        </Typography>
      </StyledDescription>
      <Box mt={24}>
        <SkeletonWrapper
          isLoading={isLoading}
          isError={isError}
          showEmptyState={data && data.payoutsCount === 0}
          Skeleton={PayoutsHistorySkeleton}
        >
          {data && (
            <div>
              <StyledRow>
                <StyledCell flex={2}>
                  <Box p={8}>
                    <Typography
                      color="inactive"
                      variant="extraSmall"
                      lineHeight="24px"
                      fontWeight={600}
                    >
                      {t('metrics:payouts.date')}
                    </Typography>
                  </Box>
                </StyledCell>
                <StyledCell flex={1} justify="flex-end">
                  <Box p={8}>
                    <Typography
                      color="inactive"
                      variant="extraSmall"
                      lineHeight="24px"
                      fontWeight={600}
                    >
                      {t('metrics:payouts.amount')}
                    </Typography>
                  </Box>
                </StyledCell>
                <StyledCell flex={1} justify="flex-end">
                  <Box p={8}>
                    <Typography
                      color="inactive"
                      variant="extraSmall"
                      lineHeight="24px"
                      fontWeight={600}
                    >
                      {t('metrics:payouts.invoice')}
                    </Typography>
                  </Box>
                </StyledCell>
              </StyledRow>
              {payouts.map((item) => (
                <StyledRow>
                  <StyledCell flex={2}>
                    <Box px={8} py={16}>
                      <Typography variant="note">{item.paidDate}</Typography>
                    </Box>
                  </StyledCell>
                  <StyledCell flex={1} justify="flex-end">
                    <Box px={8} py={16}>
                      <Typography variant="note">{item.amount}</Typography>
                    </Box>
                  </StyledCell>
                  <StyledCell flex={1} justify="flex-end">
                    <Box px={8} py={16}>
                      <Typography variant="note" fontWeight={600}>
                        {item.invoiceNumber}
                      </Typography>
                    </Box>
                  </StyledCell>
                </StyledRow>
              ))}
              {data?.nextPage && (
                <Flex justify="center">
                  <Button
                    inverse
                    clickHandler={() => {
                      data.nextPage && setNextPage(data.nextPage)
                    }}
                    type="button"
                    isLoading={isFetching}
                  >
                    {t('app:load_more')}
                  </Button>
                </Flex>
              )}
            </div>
          )}
        </SkeletonWrapper>
      </Box>
    </Dialog>
  )
}
