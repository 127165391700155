import { useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'

import { AffiliateRequestParams } from '@web-apps/utils-types'

export const useGetBrandsFilters = (): {
  filterParams: AffiliateRequestParams
  setFilterParams: (value: AffiliateRequestParams) => void
} => {
  const [searchParams, setSearchParams] = useSearchParams()

  const setFilterParams = useCallback(
    ({ cpaMetric, cpcMetric, region }: AffiliateRequestParams) =>
      setSearchParams({
        ...(cpaMetric && { cpa: 'true' }),
        ...(cpcMetric && { cpc: 'true' }),
        ...(region && { region }),
      }),
    [setSearchParams]
  )

  return {
    filterParams: {
      cpaMetric: Boolean(searchParams.get('cpa')),
      cpcMetric: Boolean(searchParams.get('cpc')),
      region: searchParams.get('region') || undefined,
    },
    setFilterParams,
  }
}
