import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import {
  Box,
  Button,
  Flex,
  Form,
  TextField,
  Typography,
} from '@web-apps/ui-shared'
import { FormValidations } from '@web-apps/utils-shared'

import { StyledLink, StyledSlugInput } from './SelectSlug.styles'
import { useValidateSlugMutation } from '../../api'

type SelectSlugProps = {
  predefinedSlug?: string | null
  onSlugSubmit: (slug: string) => void
}
export const SelectSlug = ({
  predefinedSlug,
  onSlugSubmit,
}: SelectSlugProps) => {
  const { t } = useTranslation(['auth'])
  const [slug, setSlug] = useState<string | null>(predefinedSlug || null)

  const [
    validateSlug,
    {
      isLoading: isValidateSlugLoading,
      isError: isValidateSlugError,
      isSuccess: isValidateSlugSuccess,
    },
  ] = useValidateSlugMutation()

  const handleSlugSubmit = async ({ slug }: { slug: string }) => {
    validateSlug({ slug })
    setSlug(slug)
  }

  useEffect(() => {
    if (isValidateSlugSuccess && slug) onSlugSubmit(slug)
  }, [isValidateSlugSuccess, onSlugSubmit, slug])

  return (
    <>
      <Box mb={24}>
        <Flex direction="column" align="center" gap={16}>
          <Typography variant="h2" textAlign="center">
            {t('auth:register.slug_form_title')}
          </Typography>
          <Typography textAlign="center" fontWeight={600}>
            {t('auth:register.slug_form_description')}
          </Typography>
        </Flex>
      </Box>
      <Box mb={24}>
        <Form
          initialValues={{
            slug: predefinedSlug || '',
          }}
          validations={{
            descriptors: {
              slug: {
                required: t('auth:errors.slug_required'),
                minLength: {
                  value: FormValidations.slugMinLength,
                  message: t('auth:errors.slug_is_short', {
                    number: FormValidations.slugMinLength,
                  }),
                },
                pattern: {
                  value: FormValidations.slugRegex,
                  message: t('auth:errors.slug_is_invalid'),
                },
              },
            },
          }}
          onSubmit={handleSlugSubmit}
        >
          {() => {
            return (
              <>
                <Box mb={24}>
                  <StyledSlugInput>
                    <TextField
                      name="slug"
                      placeholder={t('auth:input_placeholders.slug')}
                      autoComplete="off"
                      autoCorrect="off"
                      autoCapitalize="off"
                      spellCheck="false"
                      errorMessages={
                        (isValidateSlugError && [
                          t('auth:errors.slug_already_taken'),
                        ]) ||
                        undefined
                      }
                    />
                  </StyledSlugInput>
                </Box>
                <Button
                  type="submit"
                  width="100%"
                  isLoading={isValidateSlugLoading}
                >
                  {t('auth:register.continue')}
                </Button>
              </>
            )
          }}
        </Form>
      </Box>
      <Typography variant="navigation" as="p" textAlign="center">
        <Trans
          i18nKey="auth:register.accept_terms"
          /* eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid */
          components={{ a: <StyledLink /> }}
        />
      </Typography>
    </>
  )
}
