import cloneDeep from 'lodash.clonedeep'

import { DateHelpers } from '@web-apps/utils-shared'
import { AffiliateLinksListType } from '@web-apps/utils-types'

/**
 *
 * @param prevList current list
 * @param nextPageList last list fetched when load more is clicked
 * @returns list merged grouped by month
 */
export const mergeAffiliateLinksLists = (
  prevList: AffiliateLinksListType,
  nextPageList: AffiliateLinksListType,
  options?: {
    preserveCurrentPaginationLinks: boolean
  }
): AffiliateLinksListType => {
  const nextAffiliateLinksListPage = cloneDeep(prevList)

  if (!options?.preserveCurrentPaginationLinks) {
    nextAffiliateLinksListPage.prevAPILink = nextPageList.prevAPILink
    nextAffiliateLinksListPage.nextAPILink = nextPageList.nextAPILink
  }

  // updating each group by month, when month group does not exist the new one is added
  nextPageList.linksByMonthDescSorted.forEach((monthToAdd) => {
    const existingMonth =
      nextAffiliateLinksListPage.linksByMonthDescSorted.find(
        (m) =>
          m.fullYear === monthToAdd.fullYear &&
          m.monthIndex === monthToAdd.monthIndex
      )

    // grouping for month already exist, we need to check each affiliate link and avoid duplicates
    if (existingMonth) {
      monthToAdd.linksDescSortedByDate.forEach((linkToAdd) => {
        const existingLinkIndex = existingMonth.linksDescSortedByDate.findIndex(
          (l) => l.id === linkToAdd.id
        )

        // existing affiliate link we update with last information
        if (existingLinkIndex > -1) {
          existingMonth.linksDescSortedByDate[existingLinkIndex] = linkToAdd
          // does not exist we append it to the list
        } else {
          existingMonth.linksDescSortedByDate.push(linkToAdd)
        }
      })

      // to making sure the order is correct
      existingMonth.linksDescSortedByDate =
        existingMonth.linksDescSortedByDate.sort((al1, al2) =>
          DateHelpers.getDifferenceInMilliseconds(al1.createdAt, al2.createdAt)
        )
    } else {
      nextAffiliateLinksListPage.linksByMonthDescSorted.push(monthToAdd)
    }
  })

  return nextAffiliateLinksListPage
}

export const updateAffiliateLinkInList = (
  _list: AffiliateLinksListType,
  { id, ...data }: { id: string; title?: string; imageUrl?: string }
): AffiliateLinksListType => {
  const list = cloneDeep(_list)

  list.linksByMonthDescSorted.forEach((item, index) => {
    const linkIndex = item.linksDescSortedByDate.findIndex((i) => i.id === id)
    if (linkIndex > -1) {
      Object.assign(
        list.linksByMonthDescSorted[index].linksDescSortedByDate[linkIndex],
        data,
        { isFetching: false }
      )
    }
  })

  return list
}
