import React, { useEffect, useState } from 'react'
import ReactGA from 'react-ga4'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import {
  Box,
  Button,
  ButtonVariant,
  CustomCard,
  Dialog,
  Flex,
  Icon,
  IconButton,
  IconCatalogPaths,
  LoadingIndicator,
  TextEllipsis,
  Theme,
  Typography,
} from '@web-apps/ui-shared'
import { computeCommissionToShowFromAggregate } from '@web-apps/utils-shared'
import {
  AffiliateLinkType,
  AffiliateProductType,
  ANALYTICS_CATEGORY,
} from '@web-apps/utils-types'

import { useCreateAffiliateLinkAtomicMutation } from '../../../../../services/affiliates.api'
import {
  StyledActions,
  StyledAddIcon,
  StyledCardDescription,
  StyledDeleteIcon,
  StyledIconsContainer,
  StyledWrapper,
  StyledDetailsWrapper,
  StyledDetailsImage,
  StyledDetailsImageWrapper,
  StyledDetailsContentWrapper,
  StyledCommissionBox,
} from './AffiliateProductCard.styles'
import { fromTrendingProductsToProductFormType } from '../../../MyPage/SectionProductRecommendationsForm/SectionProductRecommendationsForm.adapters'
import { AddToMyPage } from '../AddToMyPage'

type AffiliateProductCardProps = {
  pageId: string
  product: AffiliateProductType
  onConverted: (value: AffiliateLinkType) => void
}

export const AffiliateProductCard = ({
  pageId,
  product,
  onConverted,
}: AffiliateProductCardProps) => {
  const { t } = useTranslation(['brands'])
  const {
    title,
    brandName,
    mediaURL,
    commission,
    url,
    convertUrl,
    price,
    discount,
  } = product
  const commissionInfo = computeCommissionToShowFromAggregate(commission)
  const [createAffiliateLink, { data, isLoading, isError, isSuccess }] =
    useCreateAffiliateLinkAtomicMutation()
  const [isDetailsDialogOpen, setIsDetailsDialogOpen] = useState(false)
  const [isAddToPageDialogOpen, setIsAddToPageDialogOpen] = useState(false)

  useEffect(() => {
    if (data) {
      onConverted(data)
    }

    if (isError) {
      toast.error(t('brands:errors.affiliate_link_creation'))
    }

    if (isSuccess) {
      setIsDetailsDialogOpen(false)
    }
  }, [data, isError, isSuccess, onConverted, t])

  return (
    <StyledWrapper>
      <CustomCard
        overlayImage={{
          src: mediaURL || IconCatalogPaths.ProductImagePlaceholder,
          alt: title,
        }}
        onClick={() => {
          setIsDetailsDialogOpen(true)
          ReactGA.event({
            action: `add_products:product_card:click`,
            category: ANALYTICS_CATEGORY.PRODUCTS,
          })
        }}
        forceHoverEffect
        isSquare
        footer={
          <StyledWrapper>
            <Flex direction="column" gap={4}>
              {brandName && (
                <Typography variant="hint" color="inactive">
                  {brandName}
                </Typography>
              )}
              {title && (
                <Typography
                  variant="hint"
                  as="div"
                  fontWeight="bold"
                  style={{ overflow: 'hidden' }}
                >
                  <TextEllipsis>{title}</TextEllipsis>
                </Typography>
              )}
              {price && (
                <Typography
                  as="span"
                  variant="hint"
                  fontWeight="600"
                  color={discount ? 'danger' : 'regular'}
                >
                  {price}
                  {discount && (
                    <Typography
                      as="span"
                      variant="hint"
                      fontWeight={400}
                      color="danger"
                    >
                      &nbsp;{discount.formattedText}
                    </Typography>
                  )}
                </Typography>
              )}
              <Flex justify="space-between" align="center">
                <StyledCardDescription>
                  <Typography variant="hint" fontWeight="bold">
                    {t(`brands:commission.${commissionInfo.type}`, {
                      commission: commissionInfo.commissionText,
                    })}
                  </Typography>
                </StyledCardDescription>
              </Flex>
            </Flex>
          </StyledWrapper>
        }
      >
        <StyledActions justify="space-around" align="center">
          <StyledIconsContainer>
            <StyledAddIcon>
              <Icon.ButtonAdd />
            </StyledAddIcon>
            <StyledDeleteIcon>
              <Icon.ButtonDelete />
            </StyledDeleteIcon>
          </StyledIconsContainer>
        </StyledActions>
      </CustomCard>

      <Dialog
        isOpen={isAddToPageDialogOpen}
        onDismiss={() => setIsAddToPageDialogOpen(false)}
        showHeaderBorder={false}
        leftHeaderDecorator={
          <IconButton
            title="<-"
            icon="ArrowBack"
            onClick={() => {
              setIsDetailsDialogOpen(true)
              setIsAddToPageDialogOpen(false)
            }}
            iconProps={{ fillColor: Theme.Colors.typography.light }}
          />
        }
      >
        <AddToMyPage
          onSuccess={() => {
            setIsAddToPageDialogOpen(false)
          }}
          onError={() => {
            setIsAddToPageDialogOpen(false)
          }}
          product={fromTrendingProductsToProductFormType(product)}
          api={{
            createAffiliateLink: product.api.createAffiliateLink,
            addToGrid: product.api.addToGrid,
            addAsSection: product.api.addAsSection,
          }}
        />
      </Dialog>

      <Dialog
        isOpen={isDetailsDialogOpen}
        onDismiss={() => setIsDetailsDialogOpen(false)}
        showHeaderBorder={false}
      >
        {isLoading ? (
          <Flex justify="space-around">
            <LoadingIndicator />
          </Flex>
        ) : (
          <StyledDetailsWrapper>
            <StyledDetailsImageWrapper>
              <StyledDetailsImage
                src={mediaURL || IconCatalogPaths.ProductImagePlaceholder}
                alt={title}
              />
            </StyledDetailsImageWrapper>
            <StyledDetailsContentWrapper>
              <Flex direction="column" gap={8}>
                {brandName && (
                  <Typography variant="note" color="inactive">
                    {brandName}
                  </Typography>
                )}
                {title && (
                  <Typography as="div" fontWeight="bold">
                    {title}
                  </Typography>
                )}
                {price && (
                  <Typography
                    as="span"
                    fontWeight="600"
                    color={discount ? 'danger' : 'regular'}
                  >
                    {price}
                    {discount && (
                      <Typography
                        as="span"
                        variant="hint"
                        fontWeight={400}
                        color="danger"
                      >
                        &nbsp;{discount.formattedText}
                      </Typography>
                    )}
                  </Typography>
                )}
                <Box mt={8}>
                  <Typography color="inactive" variant="note">
                    {product.retailer}
                  </Typography>
                </Box>
                <StyledCommissionBox>
                  <Box>
                    <Typography
                      variant="extraSmall"
                      color="inactive"
                      textTransform="uppercase"
                    >
                      {t('brands:trending_products.card.earnings')}
                    </Typography>
                  </Box>
                  <Typography fontWeight="bold">
                    {t(`brands:commission.${commissionInfo.type}`, {
                      commission: commissionInfo.commissionText,
                    })}
                  </Typography>
                </StyledCommissionBox>
              </Flex>
              <Button
                clickHandler={() => {
                  ReactGA.event({
                    action: `search_product:add_to_page:click`,
                    category: ANALYTICS_CATEGORY.PRODUCTS,
                  })
                  setIsDetailsDialogOpen(false)
                  setIsAddToPageDialogOpen(true)
                }}
                width="100%"
              >
                <Flex gap={4} align="center" justify="center">
                  <Icon.PlusCircle fillColor={Theme.Button.primaryTextColor} />
                  {t('brands:trending_products.context_menu.add_to_page')}
                </Flex>
              </Button>
              <Box mt={8}>
                <Button
                  variant={ButtonVariant.OUTLINE}
                  clickHandler={() => {
                    ReactGA.event({
                      action: `search_product:convert:click`,
                      category: ANALYTICS_CATEGORY.PRODUCTS,
                    })
                    createAffiliateLink({
                      uri: url,
                      createAffiliateLinkEndpoint: convertUrl,
                      pageId,
                      pageTitle: title,
                      imageUrl: mediaURL,
                    })
                  }}
                  width="100%"
                >
                  <Flex gap={4} align="center" justify="center">
                    <Icon.Convert />
                    {t(
                      'brands:trending_products.context_menu.convert_and_share'
                    )}
                  </Flex>
                </Button>
              </Box>
              <Box mt={24}>
                <Button
                  variant={ButtonVariant.PLAIN}
                  width="100%"
                  clickHandler={() => {
                    ReactGA.event({
                      action: `search_product:open_website:click`,
                      category: ANALYTICS_CATEGORY.PRODUCTS,
                    })
                    window.open(url)
                  }}
                >
                  <Flex gap={4} align="center" justify="center">
                    <Icon.LinkExternal />
                    {t(
                      'brands:trending_products.context_menu.link_to_product_page'
                    )}
                  </Flex>
                </Button>
              </Box>
            </StyledDetailsContentWrapper>
          </StyledDetailsWrapper>
        )}
      </Dialog>
    </StyledWrapper>
  )
}
