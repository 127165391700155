import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Box,
  Button,
  ButtonVariant,
  Flex,
  Icon,
  PopoverMenu,
  TextField,
  Typography,
} from '@web-apps/ui-shared'
import type { TrendingProductsFiltrationType } from '@web-apps/utils-types'

import {
  StyledButtonWrapper,
  StyledDivider,
  StyledSlider,
  StyledSliderHandle,
  StyledTrack,
} from './Filtration.styles'

const MIN_PRICE = 1
const MAX_PRICE = 400
const ABSOLUTE_MAX_PRICE = 1000000

export const Filtration = ({
  onChange,
}: {
  onChange: (filtration: TrendingProductsFiltrationType) => void
}) => {
  const { t } = useTranslation(['app'], { useSuspense: false })

  const [showSort, setShowSort] = useState(false)
  const [showPrice, setShowPrice] = useState(false)

  const [sortValues, setSortValues] = useState<
    TrendingProductsFiltrationType | undefined
  >()
  const [isPriceActive, setIsPriceActive] = useState(false)
  const [minPrice, setMinPrice] = useState(MIN_PRICE)
  const [maxPrice, setMaxPrice] = useState(MAX_PRICE)

  const onOptionSelect = (params: TrendingProductsFiltrationType) => {
    setSortValues(params)
    setShowSort(false)
    onChange({
      ...params,
      ...(isPriceActive && {
        min_price: minPrice * 100,
        max_price: maxPrice * 100,
      }),
    })
  }

  const onPriceSubmit = (reset?: boolean) => {
    setShowPrice(false)

    if (reset) {
      setIsPriceActive(false)
      setMinPrice(MIN_PRICE)
      setMaxPrice(MAX_PRICE)
      if (sortValues) onChange(sortValues)
    } else {
      setIsPriceActive(true)
      onChange({
        ...(sortValues ? sortValues : { sort: 'standard' }),
        min_price: minPrice * 100,
        max_price: maxPrice * 100,
      })
    }
  }

  return (
    <Flex gap={16}>
      <StyledButtonWrapper>
        <Button
          width="100%"
          variant={ButtonVariant.OUTLINE}
          clickHandler={() => setShowSort(true)}
        >
          <Flex justify="space-between">
            <Typography>
              {t('app:header.brands.products_page.filtration.sort_by')}
            </Typography>
            <Icon.Chevron />
          </Flex>
        </Button>
        {showSort && (
          <PopoverMenu
            onDismiss={() => setShowSort(false)}
            title={t('app:header.brands.products_page.filtration.sort_by')}
            options={[
              {
                key: 'standard',
                value: 'standard',
                title: t(
                  'app:header.brands.products_page.filtration.sort_categories.relevance'
                ),
                action: () => onOptionSelect({ sort: 'standard' }),
                analyticsKey: 'sort_products:by_relevance',
                isActive: sortValues?.sort === 'standard' || !sortValues,
              },
              {
                key: 'ascending',
                value: 'ascending',
                title: t(
                  'app:header.brands.products_page.filtration.sort_categories.ascending'
                ),
                action: () =>
                  onOptionSelect({ sort: 'price', ascending: 'true' }),
                analyticsKey: 'sort_products:by_lowest',
                isActive:
                  sortValues?.ascending === 'true' &&
                  sortValues?.sort === 'price',
              },
              {
                key: 'descending',
                value: 'descending',
                title: t(
                  'app:header.brands.products_page.filtration.sort_categories.descending'
                ),
                action: () =>
                  onOptionSelect({ sort: 'price', ascending: 'false' }),
                analyticsKey: 'sort_products:by_highest',
                isActive:
                  sortValues?.ascending === 'false' &&
                  sortValues?.sort === 'price',
              },
              {
                key: 'sale',
                value: 'sale',
                title: t(
                  'app:header.brands.products_page.filtration.sort_categories.sale'
                ),
                action: () => onOptionSelect({ sort: 'sale' }),
                analyticsKey: 'sort_products:by_relevance',
                isActive: sortValues?.sort === 'sale',
              },
            ]}
          />
        )}
      </StyledButtonWrapper>
      <StyledButtonWrapper>
        <Button
          width="100%"
          variant={ButtonVariant.OUTLINE}
          clickHandler={() => setShowPrice(true)}
        >
          <Flex justify="space-between">
            <Typography>
              {t('app:header.brands.products_page.filtration.price')}
            </Typography>
            <Icon.Chevron />
          </Flex>
        </Button>
        {showPrice && (
          <PopoverMenu
            onDismiss={() => setShowPrice(false)}
            title={t('app:header.brands.products_page.filtration.price')}
            containerWidth={375}
          >
            <Flex justify="space-between" align="center">
              <Box w={140}>
                <TextField
                  name="min"
                  value={minPrice}
                  onChange={(e) => {
                    const value = e.target.value
                    if (/^\d+$/.test(value)) {
                      const number = Number(value)
                      setMinPrice(number >= maxPrice ? maxPrice - 1 : number)
                      setIsPriceActive(true)
                    } else {
                      setMinPrice(0)
                    }
                  }}
                />
              </Box>
              <StyledDivider />
              <Box w={140}>
                <TextField
                  width={140}
                  name="max"
                  value={maxPrice}
                  onChange={(e) => {
                    const value = e.target.value
                    if (/^\d+$/.test(value)) {
                      const number = Number(value)
                      setMaxPrice(
                        number < ABSOLUTE_MAX_PRICE
                          ? number
                          : ABSOLUTE_MAX_PRICE
                      )
                      setIsPriceActive(true)
                    } else {
                      setMaxPrice(0)
                    }
                  }}
                />
              </Box>
            </Flex>
            <Box mt={24} mb={32} h={24}>
              <StyledSlider
                trackClassName="track"
                defaultValue={[MIN_PRICE, MAX_PRICE]}
                renderThumb={(props) => (
                  <div {...props}>
                    <StyledSliderHandle />
                  </div>
                )}
                onAfterChange={(value) => {
                  if (typeof value === 'object') {
                    setMinPrice(value[0])
                    setMaxPrice(value[1])
                    setIsPriceActive(true)
                  }
                }}
                renderTrack={(props, state) => (
                  <div {...props}>
                    <StyledTrack $state={state} />
                  </div>
                )}
                min={0}
                max={MAX_PRICE}
                pearling
                value={[minPrice, maxPrice]}
                minDistance={5}
              />
            </Box>
            <Flex gap={8}>
              <Button
                width="calc(50% - 4px)"
                variant={ButtonVariant.OUTLINE}
                clickHandler={() => {
                  onPriceSubmit(true)
                }}
              >
                {t(
                  'app:header.brands.products_page.filtration.price_filtration.reset'
                )}
              </Button>
              <Button
                width="calc(50% - 4px)"
                clickHandler={() => {
                  onPriceSubmit()
                }}
              >
                {t(
                  'app:header.brands.products_page.filtration.price_filtration.save'
                )}
              </Button>
            </Flex>
          </PopoverMenu>
        )}
      </StyledButtonWrapper>
    </Flex>
  )
}
