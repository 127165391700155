import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Settings, Recovery } from '@web-apps/feature-auth'
import { Dialog } from '@web-apps/ui-shared'

type AuthSettingsProps = {
  onDismiss: () => void
  onSuccess: () => void
  isRecoveryFlow?: boolean
}

const DIALOG_MAX_WIDTH_NOT_MOBILE = '375px'

export const AuthSettings = ({
  onDismiss,
  onSuccess,
  isRecoveryFlow,
}: AuthSettingsProps) => {
  const [, setSearchParams] = useSearchParams()
  const { t } = useTranslation(['auth'])

  return isRecoveryFlow ? (
    <Dialog
      isOpen
      onDismiss={onDismiss}
      showCloseButton={false}
      isBlocked
      title={t('auth:recovery.title')}
      showHeaderBorder={false}
      maxWidthNotMobile={DIALOG_MAX_WIDTH_NOT_MOBILE}
    >
      <Recovery
        onRecovery={() => {
          setSearchParams({}, { replace: true })
          onDismiss()

          onSuccess()
        }}
      />
    </Dialog>
  ) : (
    <Dialog
      isOpen
      onDismiss={onDismiss}
      title={t('auth:settings.title')}
      showHeaderBorder={false}
      maxWidthNotMobile={DIALOG_MAX_WIDTH_NOT_MOBILE}
    >
      <Settings
        onSuccess={() => {
          onDismiss()

          onSuccess()
        }}
      />
    </Dialog>
  )
}
