import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { useDebouncedCallback } from 'use-debounce'

import { useInspectLinkMutation } from '../../../../services/affiliates.api'
import { AffiliateTooltipStatus } from '../components/AffiliateTooltip'
import { AffiliateLinkConversionData } from '@web-apps/utils-types'
import { useAccountDetails } from '../../../../utils/hooks/useAccountDetails.hooks'

type Props = {
  defaultStatus: AffiliateTooltipStatus
  ignoreMetapicLink?: boolean
  alternativePath?: string
}
export const useInspectLink = ({
  defaultStatus,
  ignoreMetapicLink,
  alternativePath,
}: Props): [
  (uri: string) => void,
  {
    data: AffiliateLinkConversionData | undefined
    isLoading: boolean
    isError: boolean
    isSuccess: boolean
    status: AffiliateTooltipStatus
    reset: () => void
  }
] => {
  const {
    apiConfigs: {
      endpointPaths: { inspectLink },
    },
  } = useAccountDetails()
  const { t } = useTranslation(['app', 'creator'], { useSuspense: false })
  const [status, setStatus] = useState<AffiliateTooltipStatus>(defaultStatus)
  const [
    inspectRequest,
    { data, isSuccess, isError, isLoading, reset: resetQuery },
  ] = useInspectLinkMutation()
  const reset = () => {
    setStatus('initial')
    resetQuery()
  }
  const inspect = useDebouncedCallback((uri) => {
    inspectLink && inspectRequest({ path: alternativePath || inspectLink, uri })
  }, 750)

  useEffect(() => {
    if (isSuccess && data) {
      if (data.isConvertible) {
        setStatus('convertible')
      } else {
        setStatus('not_convertible')
      }
    }
    if (isLoading) {
      setStatus('loading')
    }
  }, [setStatus, data, isSuccess, isLoading, ignoreMetapicLink])

  useEffect(() => {
    if (isError) {
      setStatus('initial')
      toast.error(t('app:generic_error'))
    }
  }, [isError, setStatus, t])

  return [
    inspect,
    {
      data,
      status,
      isSuccess,
      isError,
      isLoading,
      reset,
    },
  ]
}
