import { combineReducers } from 'redux'
import { PreviewReducer } from '../store/creator/preview.slice'
import { api } from '../services/api'

export default combineReducers({
  creator: combineReducers({
    preview: PreviewReducer,
  }),
  [api.reducerPath]: api.reducer,
})
