import React from 'react'
import Skeleton from 'react-loading-skeleton'
import styled from 'styled-components'
import {
  Box,
  BREAKPOINTS,
  Button,
  Card,
  Flex,
  media,
  Typography,
} from '@web-apps/ui-shared'

export const StyledButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  ${media.from(
    BREAKPOINTS.md,
    `
        flex-direction: row;
    `
  )}
`

export const AffiliateLinksListItemSkeleton = () => {
  return (
    <div>
      <Typography variant="subTitleBig" as="h4">
        <Skeleton />
      </Typography>
      <Box mt={16} mb={48}>
        <Flex gap={24} direction="column">
          {Array(3)
            .fill(0)
            .map((_, index) => (
              <Card key={index}>
                <Flex gap={24} direction="column">
                  <Flex gap={8}>
                    <Skeleton width={48} height={48} />
                    <Flex flex={1} direction="column">
                      <Skeleton />
                      <Box mb={4}>
                        <Typography variant="hint" color="inactive">
                          <Skeleton />
                        </Typography>
                      </Box>
                      <Typography variant="hint" as="span">
                        <Skeleton />
                      </Typography>
                    </Flex>
                  </Flex>
                  <Skeleton height={54} />
                  <StyledButtonsContainer>
                    <Button inverse disabled type="button">
                      <Skeleton width={80} height={24} />
                    </Button>
                    <Button inverse disabled type="button">
                      <Skeleton width={200} height={24} />
                    </Button>
                    <Button inverse disabled type="button">
                      <Skeleton width={150} height={24} />
                    </Button>
                  </StyledButtonsContainer>
                  <Typography variant="hint" color="inactive">
                    <Skeleton />
                  </Typography>
                </Flex>
              </Card>
            ))}
        </Flex>
      </Box>
    </div>
  )
}
