import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Grid,
  FormFieldController,
  ImageUploader,
  FormArgsType,
  Typography,
  ShowHideAnimationWrapper,
} from '@web-apps/ui-shared'
import {
  SectionVariantEnum,
  ImageNamesEnum,
  SectionCoverFormat,
} from '@web-apps/utils-types'
import { RadioInputWithIcon } from '../../../../../components'
import {
  MAX_SECTION_IMAGE_SIZE_IN_BYTES,
  MAX_SECTION_IMAGE_SIZE_IN_MEGABYTES,
} from '../../utils/MyPage.utils'
import { generateCustomisationData } from '../../MyPageAppearance/MyPageAppearance.utils'

export enum SectionStyleFormVariant {
  DEFAULT = 'default',
  IMAGE_FIRST = 'image',
}

type Props = {
  // eslint-disable-next-line
  formReturnProps: FormArgsType<any>
  isImageLoading?: boolean
  variant?: SectionStyleFormVariant
}

export const SectionStyleFormBlock = ({
  formReturnProps,
  isImageLoading,
  variant = SectionStyleFormVariant.DEFAULT,
}: Props) => {
  const {
    control,
    getValues,
    formState: { errors },
  } = formReturnProps
  const [isImageInvalid, setImageInvalid] = useState(false)
  const { t } = useTranslation(['app', 'creator'])

  const buttonCoverStyleValues = useMemo(() => {
    const { buttonCoverStyleValues } = generateCustomisationData(t)
    return buttonCoverStyleValues
  }, [t])

  const buttonVariantStyleValues = useMemo(() => {
    const { buttonVariantStyleValues } = generateCustomisationData(t)

    if (variant === SectionStyleFormVariant.IMAGE_FIRST) {
      const [first, ...rest] = buttonVariantStyleValues

      return [...rest, first]
    }

    return buttonVariantStyleValues
  }, [variant, t])

  const stylesVariantValue = getValues('styles.variant')

  return (
    <>
      <FormFieldController
        name="styles.variant"
        control={control}
        defaultValue={getValues('styles.variant')}
        render={({ field }) => {
          return (
            <Grid gap={16} templateColumns={'repeat(3, minmax(30%, 1fr))'}>
              {buttonVariantStyleValues.map((item) => (
                <RadioInputWithIcon
                  key={item.value}
                  name="styles.variant"
                  label={item.label}
                  value={item.value}
                  icon={item.icon}
                  checked={item.value === field.value}
                  onChange={field.onChange}
                />
              ))}
            </Grid>
          )
        }}
      />
      <ShowHideAnimationWrapper state={stylesVariantValue}>
        {stylesVariantValue !== SectionVariantEnum.PLAIN && (
          <Box mt={16}>
            <FormFieldController
              name="imageData"
              control={control}
              rules={{ required: true }}
              defaultValue={null}
              render={({ field: { onChange, value: uploadedImage } }) => {
                return (
                  <ImageUploader
                    inputName="imageData"
                    uploadControlText={t(
                      uploadedImage
                        ? 'app:replace_image'
                        : 'app:image_upload_control_text'
                    )}
                    infoMessage={t('creator:background_image_upload_info', {
                      megaBytes: MAX_SECTION_IMAGE_SIZE_IN_MEGABYTES,
                    })}
                    image={uploadedImage}
                    isLoading={isImageLoading}
                    onClear={() => onChange(null)}
                    onError={() => onChange(null)}
                    onImageChange={(imageData) => {
                      onChange({
                        ...imageData,
                        name: uploadedImage?.name || ImageNamesEnum.DEFAULT,
                      })
                      setImageInvalid(false)
                    }}
                    maxSizeInBytes={MAX_SECTION_IMAGE_SIZE_IN_BYTES}
                    onFileRejected={() => {
                      setImageInvalid(true)
                    }}
                    acceptedExtensions={[
                      '.png',
                      '.jpg',
                      '.jpeg',
                      '.svg',
                      '.gif',
                    ]}
                    isInvalid={isImageInvalid || errors.imageData}
                  />
                )
              }}
            />
          </Box>
        )}
        {stylesVariantValue === SectionVariantEnum.COVER && (
          <>
            <Box mb={8} mt={16}>
              <Typography fontWeight="600">
                {t('creator:button_visual_style_cover_format.title')}
              </Typography>
            </Box>
            <FormFieldController
              name="styles.cover"
              control={control}
              defaultValue={SectionCoverFormat.LANDSCAPE}
              render={({ field }) => {
                return (
                  <Grid
                    gap={16}
                    templateColumns={'repeat(3, minmax(30%, 1fr))'}
                  >
                    {buttonCoverStyleValues.map((item) => (
                      <RadioInputWithIcon
                        key={item.value}
                        name="styles.cover"
                        label={item.label}
                        value={item.value}
                        icon={item.icon}
                        checked={item.value === field.value}
                        onChange={field.onChange}
                      />
                    ))}
                  </Grid>
                )
              }}
            />
          </>
        )}
      </ShowHideAnimationWrapper>
    </>
  )
}
