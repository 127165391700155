import { useCallback, useEffect, useMemo, useState } from 'react'
import cookie from 'js-cookie'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import {
  AuthFormWrapper,
  AuthPageWrapper,
  Register,
  SelectSlug,
  SessionType,
  UseFormLink,
  useRandomSlug,
} from '@web-apps/feature-auth'
import {
  Theme,
  Box,
  Button,
  ButtonVariant,
  Dialog,
  Flex,
  Icon,
  LoadingIndicator,
  Typography,
} from '@web-apps/ui-shared'
import {
  ApiErrorTypeEnum,
  isCriteriaMatchedForApiErrorResponse,
  Localisation,
  METAPIC_PARTNER_IDENTIFIER,
} from '@web-apps/utils-shared'

import { ROUTES } from '../../../routes/RouteEnums'
import { getEnvironmentConfigs } from '../../../services/utility'
import { useRegisterUserMutation } from '../../../services/auth'
import { CREATE_ACCOUNT_DATA_COOKIE } from '../../../utils/constants/app.constants'

import { sendAnalyticsEvent } from '../../../services/plausible'
import { config } from '../../../utils/helpers/config.helpers'

type RegistrationDataType = {
  slug: string
  email?: string
  provider?: string
  password?: string
}

export const RegisterPage = ({ session }: { session?: SessionType | null }) => {
  const { t } = useTranslation(['auth'])
  const navigate = useNavigate()
  const { search } = useLocation()

  const userId = session?.identity.metadata_public?.user_id
  const { validSlug } = useRandomSlug({
    skip: Boolean(userId),
    baseURL: config.apiBaseUrl || '',
  })

  // Getting data passed to the cookies on ory registration step
  const createUserData = cookie.get(CREATE_ACCOUNT_DATA_COOKIE)

  // Using all the needed parameters in the URL search
  const cookiesDomain = useMemo(() => getEnvironmentConfigs().cookieDomain, [])
  const searchParams = new URLSearchParams(search)
  const slug = searchParams.get('slug')
  const flow = searchParams.get('flow')
  const email = searchParams.get('email')
  const partner = searchParams.get('partner')
  const metapicRegistrationFailed = searchParams.get('wrong_metapic_email')
  const importedProfile = searchParams.get('profile')

  searchParams.delete('flow')
  const passParametersToLogin = searchParams.toString()

  const [selectedSlug, setSelectedSlug] = useState<string | undefined>()
  const [isMetapicErrorOpen, setIsMetapicErrorOpen] = useState(
    Boolean(metapicRegistrationFailed)
  )

  const isMetapicReferral = useMemo(
    () => partner === METAPIC_PARTNER_IDENTIFIER,
    [partner]
  )

  const [
    registerCreator,
    {
      isSuccess: registrationSuccess,
      isError: isRegistrationFailed,
      error: registrationError,
      isUninitialized: isRegistrationUninitialized,
    },
  ] = useRegisterUserMutation()

  const cleanCookies = useCallback(() => {
    cookie.remove(CREATE_ACCOUNT_DATA_COOKIE, { domain: cookiesDomain })
  }, [cookiesDomain])

  // All possible flows that should be handled on this page.
  const registrationFlows: { [key in string]: boolean } = useMemo(
    () => ({
      // These are initial states when we haven't created an ory identity or get
      // an oid registration flow error.
      // Whenever we get a flow parameter we need to ignore the rest of the cookies and
      // parameters for the users to be able to create an account.
      oryUserRegistration: Boolean(!session || (createUserData && flow)),

      // After ory registration we need to create a zezam user manually, and we
      // only want to perform this once so that registration never runs twice
      zezamUserRegistrationFromRegisterPage: Boolean(
        createUserData && session && !userId && isRegistrationUninitialized
      ),

      // This is handling of an edge case where users tried to log in with a fresh
      // oid that has an email not yet present in ory and got registered instead.
      // Now we have to register them manually with a randomized slug.
      zezamUserRegistrationFromLoginPage: Boolean(
        !userId && session && validSlug && !createUserData
      ),

      // This case happens when chosen oid is already linked to another profile
      // (because they have the same email), in this case ory will just log the
      // user in, and we don't need to create a zezam account.
      zezamUserLoginFromRegisterPage: Boolean(
        createUserData && session && userId
      ),
    }),
    [
      session,
      createUserData,
      flow,
      userId,
      validSlug,
      isRegistrationUninitialized,
    ]
  )

  useEffect(() => {
    if (registrationFlows.zezamUserRegistrationFromLoginPage) {
      const registerFreshOidUser = async () => {
        const registeredOryEmail = session?.identity.traits.email as string
        await registerCreator({
          email: registeredOryEmail,
          slug: validSlug || '',
          time_zone:
            Localisation.CURRENT_DEVICE_TIMEZONE ||
            Localisation.DEFAULT_TIMEZONE,
          is_metapic_referral: isMetapicReferral,
        })
      }

      registerFreshOidUser()
    }
  }, [
    session,
    registerCreator,
    validSlug,
    registrationFlows.zezamUserRegistrationFromLoginPage,
    isMetapicReferral,
  ])

  useEffect(() => {
    if (registrationFlows.zezamUserLoginFromRegisterPage) {
      cleanCookies()
      navigate(ROUTES.MY_PAGE)
    }
  }, [registrationFlows, cleanCookies, navigate])

  useEffect(() => {
    if (registrationFlows.zezamUserRegistrationFromRegisterPage) {
      const createZezamAccount = async () => {
        const formattedRegistrationData: {
          slug: string
          email: string
          provider?: string
          password?: string
          time_zone?: string
        } = {
          ...JSON.parse(createUserData || '{}'),
          ...{
            page_import_id: importedProfile,
            time_zone:
              Localisation.CURRENT_DEVICE_TIMEZONE ||
              Localisation.DEFAULT_TIMEZONE,
          },
        }

        if (formattedRegistrationData.email) {
          await registerCreator({
            ...formattedRegistrationData,
            is_metapic_referral: isMetapicReferral,
          })
        } else {
          const registeredOryEmail = session?.identity.traits.email as string

          await registerCreator({
            ...formattedRegistrationData,
            ...{ email: registeredOryEmail },
            is_metapic_referral: isMetapicReferral,
          })
        }
      }

      createZezamAccount()
    }
  }, [
    createUserData,
    session,
    registerCreator,
    cleanCookies,
    registrationFlows,
    importedProfile,
    isMetapicReferral,
  ])

  useEffect(() => {
    if (registrationSuccess) {
      cleanCookies()
      if (importedProfile) {
        sendAnalyticsEvent('get_started', {
          props: {
            event: 'registration_page_successful_registration',
          },
        })
      }

      window.location.href = `${ROUTES.REGISTER}${search || ''}`
    }
  }, [
    registrationSuccess,
    cookiesDomain,
    cleanCookies,
    search,
    importedProfile,
  ])

  const onError = (error: string) => toast.error(error)

  // We want to track a visit from get-started page
  useEffect(() => {
    if (importedProfile) {
      sendAnalyticsEvent('get_started', {
        props: {
          event: 'registration_page_visit',
        },
      })
    }
  }, [importedProfile])

  useEffect(() => {
    if (!isRegistrationFailed) return
    if (
      isCriteriaMatchedForApiErrorResponse(
        { status: 400, type: ApiErrorTypeEnum.UserEmailNotAllowed },
        registrationError
      ) &&
      isMetapicReferral
    ) {
      cleanCookies()
      const slug = JSON.parse(createUserData || '{}').slug
      window.location.href = `${
        ROUTES.REGISTER
      }?partner=metapic&wrong_metapic_email=true${slug ? `&slug=${slug}` : ''}`
    } else {
      onError(t('auth:register.errors.general_error'))
    }
  }, [
    t,
    navigate,
    partner,
    slug,
    createUserData,
    cleanCookies,
    isRegistrationFailed,
    registrationError,
    isMetapicReferral,
  ])

  return registrationFlows.oryUserRegistration ? (
    <div>
      <AuthPageWrapper
        homePage={getEnvironmentConfigs().homePage}
        partner={searchParams.get('partner')}
      >
        <AuthFormWrapper>
          {selectedSlug ? (
            <Register
              onRegister={(
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                values?: any
              ) => {
                if (values) {
                  const formattedValues: RegistrationDataType = {
                    ...(values.traits?.email && { email: values.traits.email }),
                    ...(values.provider && { provider: values.provider }),
                    ...{
                      slug: values.slug,
                    },
                    ...(importedProfile && { page_import_id: importedProfile }),
                  }

                  // When registering a new identity with ORY, it automatically logs
                  // users in, and we need to store the data in the cookies, so we can
                  // use slug and get email when it is oidc flow and use it for zezam
                  // account creation.
                  // For consistency, we also do the same logic with email registering.

                  cookie.set(
                    CREATE_ACCOUNT_DATA_COOKIE,
                    JSON.stringify(formattedValues),
                    {
                      domain: cookiesDomain,
                    }
                  )

                  // If we are registering with an email, we need to manually redirect to
                  // /my-page to finish the registration.
                  if (values.traits?.email)
                    window.location.href = `${ROUTES.MY_PAGE}${search || ''}`
                }
              }}
              slug={selectedSlug}
              predefinedEmail={email}
              flowId={flow ?? undefined}
              partner={partner}
            />
          ) : (
            <SelectSlug
              onSlugSubmit={(slug) => setSelectedSlug(slug)}
              predefinedSlug={slug}
            />
          )}
        </AuthFormWrapper>
        {metapicRegistrationFailed && (
          <Dialog
            isOpen={isMetapicErrorOpen}
            showCloseButton={false}
            isBlocked={true}
            onDismiss={() => setIsMetapicErrorOpen(false)}
            showHeaderBorder={false}
          >
            <Box maxW={340} mx="auto">
              <Flex direction="column" align="center">
                <Box mb={18}>
                  <Icon.InfoRegular
                    fillColor={Theme.Colors.typography.danger}
                    width={20}
                    height={20}
                  />
                </Box>
                <Typography textAlign="center" as="div">
                  <Typography variant="h3">
                    {t('auth:register.errors.metapic_email_error.title')}
                  </Typography>
                  <Box my={24}>
                    {t('auth:register.errors.metapic_email_error.description')}
                  </Box>
                </Typography>
                <Button
                  clickHandler={() => setIsMetapicErrorOpen(false)}
                  width="100%"
                >
                  {t('auth:register.errors.metapic_email_error.try_again')}
                </Button>
                <Box my={24}>
                  <Button
                    variant={ButtonVariant.PLAIN}
                    clickHandler={() => navigate(ROUTES.REGISTER)}
                  >
                    <Typography fontWeight="bold">
                      {t('auth:register.errors.metapic_email_error.reject')}
                    </Typography>
                  </Button>
                </Box>
              </Flex>
            </Box>
          </Dialog>
        )}
        <Box mt={16} mb={48}>
          <UseFormLink withBoldLink>
            {t('auth:register.already_have_account')}{' '}
            <Link to={ROUTES.LOGIN + '?' + (passParametersToLogin || '')}>
              {t('auth:login.log_in')}
            </Link>
          </UseFormLink>
        </Box>
      </AuthPageWrapper>
    </div>
  ) : (
    <LoadingIndicator size={100} isCentered />
  )
}
