import styled from 'styled-components'
import { Theme, BREAKPOINTS, media } from '@web-apps/ui-shared'

export const StyledComponentWrapper = styled.div<{ $isHidden: boolean }>`
  display: ${({ $isHidden }) => ($isHidden ? 'none' : 'flex')};
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: auto;
  gap: 8px;
  align-items: center;
  padding: 0 8px calc(8px + env(safe-area-inset-bottom));
  height: calc(${Theme.Sidebar.height}px + env(safe-area-inset-bottom));
  background: ${Theme.Colors.background.base};
  border-top: 1px solid ${Theme.Colors.border.darker};
  overflow: hidden;
  z-index: 3;

  ${media.from(
    BREAKPOINTS.lg,
    `
      display: flex;
      top: 0;
      bottom: 0;
      left: 0;
      right: auto;
      flex-direction: column;
      width: ${Theme.Sidebar.width}px;
      height: auto;
      overflow: visible;
      padding: 20px 8px 26px;
      border-right: 1px solid ${Theme.Colors.border.darker};
    `
  )};
`

export const StyledLogo = styled.div`
  display: none;

  ${media.from(
    BREAKPOINTS.lg,
    `
      display: block;
      margin-bottom: 54px;
    `
  )};
`

export const StyledElementWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  flex: 1;

  ${media.from(
    BREAKPOINTS.lg,
    `
      flex: initial;
    `
  )};
`
