import { useLocation } from 'react-router-dom'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '../../../../utils/hooks/useQuery.hooks'

export enum PeriodsEnum {
  DAYS_7 = '7d',
  DAYS_30 = '30d',
  BY_MONTH = 'month',
  // LAST_MONTH,
  MONTHS_6 = '6mo',
  MONTHS_12 = '12mo',
  REALTIME = 'realtime',
  CUSTOM = 'custom',
}

export const LOCAL_VAR_ANALYTICS_PERIOD = 'analyticsDateRangePeriod'

const formatQueryStringForPeriod = (period: PeriodsEnum) => `period=${period}`

const periodsMap = {
  [PeriodsEnum.DAYS_7]: () => formatQueryStringForPeriod(PeriodsEnum.DAYS_7),
  [PeriodsEnum.DAYS_30]: () => formatQueryStringForPeriod(PeriodsEnum.DAYS_30),
  [PeriodsEnum.BY_MONTH]: () =>
    formatQueryStringForPeriod(PeriodsEnum.BY_MONTH),
  //[PeriodsEnum.LAST_MONTH]: () => `period=month&date=${format(sub(new Date(), { months: 1 }), FORMAT)}`,
  [PeriodsEnum.MONTHS_6]: () =>
    formatQueryStringForPeriod(PeriodsEnum.MONTHS_6),
  [PeriodsEnum.MONTHS_12]: () =>
    formatQueryStringForPeriod(PeriodsEnum.MONTHS_12),
  // [PeriodsEnum.CUSTOM]: (from: string, to: string) => `period=${PeriodsEnum.TODAY}&from=${from}&to=${to}`,
}

export const useSearchPeriod = () => {
  const query = useQuery()

  return useMemo(
    () =>
      query.get('period') ||
      localStorage.getItem(LOCAL_VAR_ANALYTICS_PERIOD) ||
      PeriodsEnum.DAYS_30,
    [query]
  )
}

export const useSelectorParams = () => {
  const { t } = useTranslation(['metrics'], { useSuspense: false })
  const location = useLocation()

  const [label, setLabel] = useState<string | null>(null)
  const [isDatepickerOpen, openDatepicker] = useState(false)

  const optionGroups = useMemo(() => {
    return [
      [
        {
          id: PeriodsEnum.DAYS_7,
          to: `${location.pathname}?${periodsMap[PeriodsEnum.DAYS_7]()}`,
          title: t('metrics:selector.lastDay', { count: 7 }),
        },
        {
          id: PeriodsEnum.DAYS_30,
          to: `${location.pathname}?${periodsMap[PeriodsEnum.DAYS_30]()}`,
          title: t('metrics:selector.lastDay', { count: 30 }),
        },
      ],
      [
        {
          id: PeriodsEnum.BY_MONTH,
          to: `${location.pathname}?${periodsMap[PeriodsEnum.BY_MONTH]()}`,
          title: t('metrics:selector.month'),
        },
        // {
        //   id: PeriodsEnum.LAST_MONTH,
        //   to: `${location.pathname}?${periodsMap[PeriodsEnum.LAST_MONTH]()}`,
        //   title: t('metrics:selector.lastMonth'),
        // },
      ],
      [
        {
          id: PeriodsEnum.MONTHS_6,
          to: `${location.pathname}?${periodsMap[PeriodsEnum.MONTHS_6]()}`,
          title: t('metrics:selector.lastMonth', { count: 6 }),
        },
        {
          id: PeriodsEnum.MONTHS_12,
          to: `${location.pathname}?${periodsMap[PeriodsEnum.MONTHS_12]()}`,
          title: t('metrics:selector.lastMonth', { count: 12 }),
        },
      ],
      // [
      //   {
      //     id: PeriodsEnum.CUSTOM,
      //     onClick: () => openDatepicker(true),
      //     title: t('metrics:selector.custom'),
      //   },
      // ],
    ]
  }, [location.pathname, t])

  useEffect(() => {
    if (location.search) {
      localStorage.setItem(
        LOCAL_VAR_ANALYTICS_PERIOD,
        new URLSearchParams(location.search).get('period') || ''
      )
    }
  }, [location])

  useEffect(() => {
    const relativeLink = location.pathname + location.search
    const options = optionGroups.flat()
    let activeIndex = options.findIndex((item) => item.to === relativeLink)
    const periodFromStorage = localStorage.getItem(LOCAL_VAR_ANALYTICS_PERIOD)

    let activeOption
    if (activeIndex === -1 && periodFromStorage) {
      activeIndex = options.findIndex((item) => item.id === periodFromStorage)
      activeOption = options[activeIndex]
    } else if (activeIndex === -1 && !periodFromStorage) {
      activeOption = options[1]
    } else {
      activeOption = options[activeIndex]
    }

    Object.assign(activeOption, { isActive: true })

    switch (activeOption.id) {
      // case PeriodsEnum.LAST_MONTH:
      //   setLabel(format(sub(new Date(), { months: 1 }), 'MMMM yyyy'))
      //   break
      // case PeriodsEnum.CUSTOM:
      //   setLabel(
      //     `${format(new Date(params.get('from')), 'd MMM')} - ${format(
      //       new Date(params.get('to')),
      //       'd MMM'
      //     )}`
      //   )
      //   break
      default:
        setLabel(activeOption.title)
    }
  }, [location, optionGroups])

  return { isDatepickerOpen, openDatepicker, optionGroups, label }
}
