import { Theme } from '../../theme'
import { PercentageGrowthProps } from './MetricSummaryPanel.types'

export const propsForPercentageGrowth = (
  percentageGrowth: number
): PercentageGrowthProps | undefined => {
  if (!percentageGrowth) return
  return {
    text: `${percentageGrowth > 0 ? '+' : ''}${percentageGrowth}%`,
    color:
      percentageGrowth > 0
        ? Theme.Colors.typography.valid
        : Theme.Colors.typography.danger,
  }
}
