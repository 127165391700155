import styled, { css } from 'styled-components'
import { BREAKPOINTS, Flex, media, Theme } from '@web-apps/ui-shared'

const iconStyled = css`
  position: absolute;
  left: 0;
  top: 0;
  width: 40px;
  height: 40px;
`

export const StyledAddIcon = styled.div`
  display: none;
  opacity: 0;
  ${iconStyled}
`
export const StyledCheckedIcon = styled.div`
  ${iconStyled}
`

export const StyledDeleteIcon = styled.div`
  display: none;
  opacity: 0;
  ${iconStyled}
`

export const StyledActions = styled(Flex)<{ $isActive?: boolean }>`
  width: 100%;
  transition: background-color 0.3s ease;
  border-radius: 8px 8px 0 0;
  cursor: pointer;

  ${({ $isActive }) => $isActive && 'background: rgba(0, 0, 0, 0.64);'}
  ${media.from(
    BREAKPOINTS.md,
    `
      // We are testing the new product search API and for now commenting out the hover part
      //
      // &:hover {
      //   background: rgba(0, 0, 0, 0.64);
      // }
      //
      // & ${StyledAddIcon}, & ${StyledDeleteIcon} {
      //   display: block;
      // }
      //
      // &:hover ${StyledCheckedIcon} {
      //   opacity: 0;
      // }
    `
  )} // &:hover ${StyledAddIcon} {
  //   ${({ $isActive }) => !$isActive && 'opacity: 1;'}
  // }
  //
  // &:hover ${StyledDeleteIcon} {
  //   ${({ $isActive }) => $isActive && 'opacity: 1;'}
  // }
`

export const StyledIconsContainer = styled.div`
  position: absolute;
  width: 40px;
  height: 40px;
`

export const StyledCardDescription = styled.div`
  width: fit-content;
  padding: 8px;
  background: ${Theme.Colors.background.alternate};
  border-radius: 8px;
`

export const StyledWrapper = styled.div`
  position: relative;
`

export const StyledDetailsWrapper = styled.div`
  padding-bottom: 24px;

  ${media.from(
    BREAKPOINTS.sm,
    `
      display: flex;
      gap: 24px;
    `
  )}
`

export const StyledDetailsContentWrapper = styled.div`
  ${media.from(
    BREAKPOINTS.sm,
    `
      flex: 0 1 auto;
    `
  )}
`

export const StyledDetailsImageWrapper = styled.div`
  height: 260px;
  margin-bottom: 24px;

  ${media.from(
    BREAKPOINTS.sm,
    `
      flex: 0 0 200px;
      margin-bottom: 0;
    `
  )}
  ${media.from(
    BREAKPOINTS.md,
    `
      flex: 0 0 260px;
    `
  )}
`

export const StyledDetailsImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`

export const StyledCommissionBox = styled.div`
  margin: 16px 0 24px;
  padding: 16px;
  border-radius: 8px;
  background: ${Theme.Colors.lightSand};
`
