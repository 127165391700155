import React from 'react'
import {
  StyledContainer,
  StyledFilterButton,
  StyledWrapper,
  HorizontalButtonsFilterVariant,
} from './HorizontalButtonsFilter.styles'

type Props<DataValue> = {
  filterData: {
    key: number | string
    title: string | React.ReactNode
    value: DataValue
    isActive: boolean
  }[]
  handleChange: (value: DataValue) => void
  variant?: HorizontalButtonsFilterVariant
} & React.HTMLAttributes<HTMLDivElement>

export const HorizontalButtonsFilter = <T,>({
  filterData,
  handleChange,
  variant = HorizontalButtonsFilterVariant.REGULAR,
  ...divProps
}: Props<T>) => {
  return (
    <StyledWrapper {...divProps}>
      <StyledContainer $variant={variant}>
        {filterData.map(({ key, title, value, isActive }) => (
          <StyledFilterButton
            $variant={variant}
            key={key}
            $isActive={isActive}
            onClick={() => handleChange(value)}
          >
            {title}
          </StyledFilterButton>
        ))}
      </StyledContainer>
    </StyledWrapper>
  )
}
