import React from 'react'
import { Trans } from 'react-i18next'
import { CommissionSource, TrafficSourceNamesEnum } from '@web-apps/utils-types'
import { Flex, Icon, Typography } from '@web-apps/ui-shared'
import { StyledContainer } from './TrafficSourceBadge.styles'

type Props = {
  sources?: CommissionSource[]
  centered?: boolean
}

export const TrafficSourceBadge = ({ sources, centered }: Props) => {
  if (!sources) {
    return null
  }

  return (
    <Flex gap={8} wrap="wrap" justify={centered ? 'center' : 'left'}>
      {sources.map((item, index) => (
        <StyledContainer key={index} p={8} borderRadius={8}>
          <Flex gap={8} align="center">
            <Flex gap={2}>
              {item.sources.map((source) => {
                switch (source) {
                  case TrafficSourceNamesEnum.TIKTOK:
                    return <Icon.TikTokMono key={source} />
                  case TrafficSourceNamesEnum.INSTAGRAM:
                    return <Icon.InstagramMono key={source} />
                  case TrafficSourceNamesEnum.OTHER:
                  default:
                    return <Icon.Link key={source} />
                }
              })}
            </Flex>
            <Typography fontSize="12px">
              <Trans
                i18nKey={`brands:sources.${item.renderType}`}
                components={{ strong: <b style={{ fontWeight: 600 }} /> }}
                values={item.values}
              />
            </Typography>
          </Flex>
        </StyledContainer>
      ))}
    </Flex>
  )
}
