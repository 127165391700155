import { DEFAULT_AFFILIATE_LINK_COMMISSION_TEXT } from './affiliates.constants'

import {
  CommissionAggregateType,
  CommissionBaseType,
} from '@web-apps/utils-types'

export const computeCommissionToShowFromAggregate = (
  commissionAggregate?: CommissionAggregateType
) => {
  /* This is the default to be shown for initial tooltip explaining affiliate conversion */
  const commissionInfo: {
    type: CommissionBaseType
    commissionText: string
  } = {
    type: 'cpa',
    commissionText: DEFAULT_AFFILIATE_LINK_COMMISSION_TEXT,
  }

  if (commissionAggregate?.cpc) {
    commissionInfo.type = 'cpc'
    commissionInfo.commissionText = commissionAggregate.cpc.formattedText
  } else if (commissionAggregate?.cpa) {
    commissionInfo.type = 'cpa'
    commissionInfo.commissionText = commissionAggregate.cpa.formattedText
  } else if (commissionAggregate?.custom) {
    commissionInfo.type = 'custom'
    commissionInfo.commissionText = commissionAggregate.custom
  }

  return commissionInfo
}
