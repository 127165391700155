import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ComposedChart,
  Line,
} from 'recharts'
import { Card, Flex, Typography, Theme } from '@web-apps/ui-shared'
import {
  StyledChartContainer,
  StyledLineLegendIcon,
  StyledSquareLegendIcon,
  StyledTooltipStatsLineContainer,
} from './AffiliateLinkAnalyticsChart.styles'

type AffiliateLinkAnalyticsChartProps = {
  data: {
    name: string
    clicks: number
    sales: number
    commissions?: number
    commissionsLabel?: string
  }[]
  labelFormatter: (label: string) => string
  tickFormatter: (label: string) => string
  withSales: boolean
  withClicks: boolean
}

const CustomTooltip = ({
  label,
  active,
  payload,
  labelFormatter,
}: {
  label?: string
  active?: boolean
  labelFormatter?: (label: string) => string
  payload?: Array<{
    name: string
    dataKey: 'clicks' | 'commissions' | 'sales'
    color: string
    payload: {
      name: string
      clicks: number
      sales: number
      commissions?: number
      commissionsLabel?: string
    }
  }>
}) => {
  if (active && label && payload) {
    return (
      <Card>
        <Flex direction="column" gap={4}>
          <Typography variant="hint" fontWeight="600">
            {labelFormatter ? labelFormatter(label) : label}
          </Typography>
          {payload.map(({ name, dataKey, color, payload }) => {
            if (dataKey === 'commissions') {
              return (
                <StyledTooltipStatsLineContainer key={dataKey}>
                  <StyledLineLegendIcon $color={color} />
                  <div>
                    <Typography variant="hint" as="span">
                      {name}:{' '}
                    </Typography>
                    <Typography variant="hint" fontWeight="600" as="span">
                      {payload.commissionsLabel}
                    </Typography>
                  </div>
                </StyledTooltipStatsLineContainer>
              )
            }

            return (
              <StyledTooltipStatsLineContainer key={dataKey}>
                <StyledSquareLegendIcon $color={color} />
                <div>
                  <Typography variant="hint" as="span">
                    {name}:{' '}
                  </Typography>
                  <Typography variant="hint" fontWeight="600" as="span">
                    {payload[dataKey]}
                  </Typography>
                </div>
              </StyledTooltipStatsLineContainer>
            )
          })}
        </Flex>
      </Card>
    )
  }

  return null
}

export const AffiliateLinkAnalyticsChart = ({
  data,
  labelFormatter,
  tickFormatter,
  withClicks = false,
  withSales = false,
}: AffiliateLinkAnalyticsChartProps) => {
  const { t } = useTranslation(['metrics'], { useSuspense: false })

  return (
    <StyledChartContainer>
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart
          data={data}
          margin={{ top: 5, bottom: 5, right: 10, left: -35 }}
        >
          <CartesianGrid
            vertical={false}
            stroke={Theme.Colors.background.alternate}
          />
          <XAxis
            width={20}
            dataKey="name"
            tickFormatter={tickFormatter}
            stroke={Theme.Colors.typography.inactive}
            fontSize={10}
          />
          <YAxis
            tickLine={false}
            axisLine={false}
            stroke={Theme.Colors.typography.inactive}
            fontSize={10}
            allowDecimals={true}
          />

          <Tooltip
            labelFormatter={labelFormatter}
            content={<CustomTooltip />}
          />
          {withClicks && (
            <Bar
              isAnimationActive
              dataKey="clicks"
              barSize={12}
              name={t('metrics:chartLegend.approved_clicks')}
              fill={Theme.Colors.typography.light}
              radius={[4, 4, 0, 0]}
            />
          )}
          {withSales && (
            <Bar
              isAnimationActive
              dataKey="sales"
              barSize={12}
              name={t('metrics:chartLegend.approved_orders')}
              fill={Theme.Colors.status.accepted}
              radius={[4, 4, 0, 0]}
            />
          )}
          <Line
            type="monotone"
            dataKey="commissions"
            stroke={Theme.Colors.status.approved}
            name={t('metrics:chartLegend.approved_earnings')}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </StyledChartContainer>
  )
}
