import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useFlag } from '@unleash/proxy-client-react'

import {
  Box,
  Button,
  Flex,
  FormArgsType,
  Icon,
  LoadingIndicator,
  Theme,
  Typography,
} from '@web-apps/ui-shared'
import { computeCommissionToShowFromAggregate } from '@web-apps/utils-shared'
import { AffiliateLinkConversionData, Brand } from '@web-apps/utils-types'
import {
  StyledContainer,
  StyledCommissionTypeContainer,
} from './AffiliateTooltip.styles'

import { ROUTES } from '../../../../../routes/RouteEnums'
import { CommissionTypeSelector } from './CommissionTypeSelector'
import { MissedHints } from './MissedHints'
import { FLAG_COMMISSION_SOURCE_SPLITTING } from '../../../../../utils/constants/flags.constants'
import { TrafficSourceBadge } from '../../../../../components'
import { TrafficSourceSelector } from './TrafficSourceSelector'

// TODO: Affiliate links: transform into enum at the end
export type AffiliateTooltipStatus =
  | 'loading'
  | 'initial'
  | 'convertible'
  | 'not_convertible'
  | 'ignored'
  | 'info'

type AffiliateTooltipProps = {
  status: AffiliateTooltipStatus
  commission?: AffiliateLinkConversionData['commission']
  productBrand?: Brand
  missedHints?: AffiliateLinkConversionData['missedHints']
  matches?: AffiliateLinkConversionData['matches']
  showButton?: boolean
  formReturnProps: FormArgsType<any>
}

export const AffiliateTooltip = ({
  status,
  commission,
  productBrand,
  missedHints,
  matches = [],
  formReturnProps,
  showButton,
}: AffiliateTooltipProps) => {
  const { t } = useTranslation(['creator'], { useSuspense: false })
  const isCommissionSourceSplittingEnabled = useFlag(
    FLAG_COMMISSION_SOURCE_SPLITTING
  )

  switch (status) {
    case 'loading': {
      return (
        <StyledContainer>
          <LoadingIndicator
            isPositionRelative
            isCentered
            size={24}
            containerHeight={24}
          />
        </StyledContainer>
      )
    }
    case 'convertible': {
      if (isCommissionSourceSplittingEnabled && commission?.sources) {
        return (
          <Box mt={8}>
            <MissedHints missedHints={missedHints} />
            <TrafficSourceSelector
              sources={commission?.sources}
              formReturnProps={formReturnProps}
              matches={matches}
            />
          </Box>
        )
      }

      return (
        <StyledContainer>
          <MissedHints missedHints={missedHints} />
          <CommissionTypeSelector
            formReturnProps={formReturnProps}
            matches={matches}
          />
        </StyledContainer>
      )
    }
    case 'info': {
      if (isCommissionSourceSplittingEnabled && commission?.sources) {
        return (
          <Box mt={8}>
            <TrafficSourceBadge sources={commission?.sources} />
          </Box>
        )
      }

      const commissionInfo = computeCommissionToShowFromAggregate(commission)

      return (
        <StyledContainer>
          <Flex direction="column" gap={8}>
            <MissedHints missedHints={missedHints} />
            <StyledCommissionTypeContainer align="center" gap={8}>
              <Icon.PlainCheck width={24} height={24} />
              <Typography fontWeight="600" variant="hint">
                {t(
                  `creator:affiliate_links.conversion_tooltip.converted.description_1_${commissionInfo.type}`,
                  { commission: commissionInfo.commissionText }
                )}
              </Typography>
            </StyledCommissionTypeContainer>
            <Typography variant="hint" color="inactive">
              {t(
                `creator:affiliate_links.conversion_tooltip.converted.${
                  productBrand
                    ? `description_2_${commissionInfo.type}`
                    : `description_2_no_brand_${commissionInfo.type}`
                }`,
                { brandName: productBrand?.name }
              )}
            </Typography>
          </Flex>
        </StyledContainer>
      )
    }
    case 'not_convertible': {
      return (
        <StyledContainer>
          <Flex gap={8} align="center">
            <Icon.Remove />
            <Typography variant="hint">
              {t(
                'creator:affiliate_links.conversion_tooltip.not_convertible.description'
              )}
            </Typography>
          </Flex>
        </StyledContainer>
      )
    }
    case 'initial': {
      return (
        <StyledContainer>
          <Flex gap={8} align="center">
            <span
              role="img"
              aria-label="$"
              style={{ lineHeight: '24px', fontSize: '24px' }}
            >
              💰
            </span>
            <Typography variant="hint">
              {t(
                'creator:affiliate_links.conversion_tooltip.initial.description'
              )}
            </Typography>
          </Flex>
          {showButton && (
            <Box mt={8}>
              <Link to={ROUTES.BRANDS}>
                <Button type="button">
                  <Flex gap={8}>
                    <span>
                      {t(
                        'creator:affiliate_links.conversion_tooltip.initial.call_to_action'
                      )}
                    </span>
                    <Icon.LinkExternal
                      fillColor={Theme.Colors.typography.inverse}
                    />
                  </Flex>
                </Button>
              </Link>
            </Box>
          )}
        </StyledContainer>
      )
    }
    case 'ignored':
    default:
      return null
  }
}
