import styled from 'styled-components'
import { Card, Theme } from '@web-apps/ui-shared'

export const StyledFormContainer = styled(Card)`
  margin-bottom: 48px;
  padding: 24px;
`

export const StyledFormLabel = styled.span<{ $danger?: boolean }>`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 0;
  ${({ $danger }) => $danger && `color: ${Theme.Colors.typography.danger}`}
`

// TODO: get rid of these component when we have design system for fonts
export const StyledSmallText = styled.p`
  margin-bottom: 0;
  margin-top: 4px;
  font-size: 12px;
  line-height: 16px;
  color: ${Theme.Colors.typography.inactive};
`

export const StyledSubTitle = styled.h3`
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  margin-bottom: 0;
  margin-right: 16px;
`
