import Skeleton from 'react-loading-skeleton'

import { TitledMediaCard } from '@web-apps/ui-shared'

export const BrandsListItemSkeleton = () => {
  return (
    <TitledMediaCard
      image={{ isSkeleton: true }}
      title={<Skeleton />}
      description={<Skeleton width={100} />}
      variant="small"
    />
  )
}

export const TopBrandsListItemDashboardSkeleton = () => {
  return (
    <TitledMediaCard
      image={{ isSkeleton: true }}
      title={<Skeleton />}
      description={<Skeleton width={238} />}
      variant="regular"
      disableSeparator={false}
    />
  )
}
