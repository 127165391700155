import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { config } from '../../utils/helpers/config.helpers'
import { useAdminLogin } from '../../utils/hooks/useAdminLogin.hooks'
import { useAccountDetails } from '../../utils/hooks/useAccountDetails.hooks'
import { StyledLink } from './AdminTooltip.styles'
import { toast } from 'react-toastify'

export const AdminTooltip = () => {
  const { t } = useTranslation(['app'], { useSuspense: false })
  const { metapicUserId, email } = useAccountDetails()

  const [isAdminLogin] = useAdminLogin()

  useEffect(() => {
    if (isAdminLogin) {
      toast.info(
        <div>
          {t('app:admin_tooltip.description')}
          {' #'}
          <StyledLink href={`${config.adminReturnUrl}/${metapicUserId}`}>
            {metapicUserId}
          </StyledLink>{' '}
          [{email}] .{' '}
          <StyledLink href={config.adminReturnUrl}>
            {t('app:admin_tooltip.back')}
          </StyledLink>
        </div>,
        {
          hideProgressBar: true,
          autoClose: false,
        }
      )
    }
  }, [isAdminLogin, email, metapicUserId, t])

  return null
}
