import styled, { css } from 'styled-components'
import { Tag, TagVariantType, Theme } from '@web-apps/ui-shared'

const StyledContainer = styled.div<{
  $hiddenBorders?: boolean
  $disabled?: boolean
}>`
  position: relative;

  display: flex;
  align-items: center;
  gap: 8px;

  ${({ $disabled }) =>
    $disabled &&
    css`
      > div {
        opacity: 0.32;
      }
    `};

  ${({ $hiddenBorders }) =>
    $hiddenBorders
      ? css`
          padding: 8px 0;
        `
      : css`
          padding: 8px;
          border-radius: 8px;
          border: 1px solid #dddfe1;
        `}

  color: #333333;

  p {
    margin: 0;
    word-break: break-word;
    white-space: normal;
  }
`

const StyledTitle = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`

const StyledTagContainer = styled.div`
  position: absolute;
  top: -4px;
  left: -4px;

  z-index: 1;
`

const StyledDescription = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  color: ${Theme.Colors.typography.inactive};
  max-height: 34px;
  overflow: hidden;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
`

const StyledLeftDecorator = styled.div`
  border-radius: 8px;
  background: ${Theme.Colors.background.alternate};
`

type ListItemProps = {
  leftDecorator?: JSX.Element
  rightDecorator?: JSX.Element
  title: string
  description?: string | JSX.Element
  hiddenBorders?: boolean
  isDisabled?: boolean
  tagText?: string
  tagVariant?: TagVariantType
}

export const ListItem = ({
  leftDecorator,
  rightDecorator,
  title,
  description,
  hiddenBorders,
  isDisabled,
  tagText,
  tagVariant = 'emphasis',
}: ListItemProps) => {
  return (
    <StyledContainer $hiddenBorders={hiddenBorders} $disabled={isDisabled}>
      {leftDecorator && (
        <StyledLeftDecorator>{leftDecorator}</StyledLeftDecorator>
      )}
      <div>
        <StyledTitle>{title}</StyledTitle>
        {description && <StyledDescription>{description}</StyledDescription>}
      </div>
      {rightDecorator}

      {tagText && (
        <StyledTagContainer
          translate={tagVariant === 'metapic' ? 'no' : undefined}
        >
          <Tag text={tagText} variant={tagVariant} />
        </StyledTagContainer>
      )}
    </StyledContainer>
  )
}
