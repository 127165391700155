import React from 'react'
import { ControllerFieldState } from 'react-hook-form'
import { HexColorPicker } from 'react-colorful'
import { Box, StyledError } from '@web-apps/ui-shared'
import { useComponentVisible } from '../../../utils/hooks/useComponentVisible.hooks'

import {
  StyledColorInput,
  StyledColorInputContainer,
  StyledColorSquare,
  StyledLabel,
  StyledPopover,
} from './ColorPickerInput.styles'

type ColorPickerInputProps = {
  name: string
  label: string | React.ReactNode
  value?: string
  error?: ControllerFieldState['error']
  onChange: (hex: string) => void
}

export const ColorPickerInput = ({
  name,
  label,
  value,
  error,
  onChange,
}: ColorPickerInputProps) => {
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible<HTMLDivElement>(false)
  const handleOpen = () => setIsComponentVisible(true)

  return (
    <div>
      <Box mb={8}>
        <StyledLabel htmlFor={name}>{label}</StyledLabel>
      </Box>
      <StyledColorInputContainer ref={ref}>
        <StyledColorSquare
          onClick={handleOpen}
          style={{ backgroundColor: value }}
        />
        <StyledColorInput
          name={name}
          value={value}
          maxLength={7}
          onChange={(e) => onChange(e.target.value)}
        />
        {isComponentVisible && (
          <StyledPopover>
            <HexColorPicker color={value} onChange={onChange} />
          </StyledPopover>
        )}
      </StyledColorInputContainer>
      {error && <StyledError>{error.message}</StyledError>}
    </div>
  )
}
