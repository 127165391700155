import styled from 'styled-components'
import { Button, CountryFlag, Theme } from '@web-apps/ui-shared'

export const StyledFlagImage = styled(CountryFlag)`
  margin-right: 8px;
  vertical-align: middle;
`

export const StyledDescription = styled.div<{ $isOpen: boolean }>`
  max-height: 0;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  background: ${Theme.Colors.background.global};
  border-radius: 8px;

  ${({ $isOpen }) => $isOpen && `max-height: 320px;`}
`

export const StyledButton = styled(Button)`
  display: flex;
  gap: 16px;
  padding: 15px;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  color: ${Theme.Colors.typography.regular};
  width: 100%;
  background: ${Theme.Colors.background.base};
  border-radius: 8px;
  border: 1px solid ${Theme.Colors.line};

  &:disabled {
    border-color: ${Theme.Colors.typography.inactive};
  }
`
