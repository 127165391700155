import styled from 'styled-components'

export const StyledFormSectionContainer = styled.fieldset`
  padding-top: 48px;
`

export const StyledFormSectionTitle = styled.h3`
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;

  margin-bottom: 24px;
`
