import { useEffect, useState, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import * as Sentry from '@sentry/browser'

import { editSectionsOrder } from '../../../../store/creator/preview.slice'

import { Box } from '@web-apps/ui-shared'

import {
  CreatorPageType,
  SectionType,
  ANALYTICS_CATEGORY,
} from '@web-apps/utils-types'
import { useUpdateSectionsOrderMutation } from '@web-apps/feature-creator-page'
import {
  extractHyperlinkFrom,
  HyperlinkIdentifierNameEnum,
  api,
} from '@web-apps/utils-shared'

import { MenuButton, SectionsList } from '../components'

import {
  generateSectionOptionsProps,
  isSectionsLimitReached,
  SECTIONS_COUNT_LIMIT,
} from '../utils/MyPage.utils'
import { StyledAddSectionButtonsContainer } from '../MyPage.styles'

type MyPageContentProps = {
  creatorPage: CreatorPageType
  isFetchingCreatorPage?: boolean
}

export const MyPageContent = ({
  creatorPage,
  isFetchingCreatorPage = false,
}: MyPageContentProps) => {
  const [sections, setSections] = useState<SectionType[]>(creatorPage.sections)
  const dispatch = useDispatch()
  const { t } = useTranslation(['creator'])
  const orderChangedRef = useRef(false)

  const [updateSections, { isError }] = useUpdateSectionsOrderMutation()

  const contentOptionsProps = useMemo(
    () =>
      generateSectionOptionsProps({
        t,
        creatorPageSections: sections,
      }),
    [t, sections]
  )

  const orderEndpoint = extractHyperlinkFrom(creatorPage, {
    propertyName: 'edit',
    hyperlinkIdentifierName:
      HyperlinkIdentifierNameEnum.SECTION_ORDER_CREATOR_PAGE,
  })?.href

  useEffect(() => {
    setSections(creatorPage.sections)
  }, [creatorPage])

  useEffect(() => {
    if (isError) {
      toast.error(t('creator:my_page_updated_message_error'))
    }
  }, [isError, t])

  useEffect(() => {
    return () => {
      /* we need to invalidate creator page details because while user is ordering it we can not invalidate,
          it due to problems synchronizing the data when user is too fast or network is slow between drag and drops
      */
      if (orderChangedRef.current) {
        dispatch(api.util.invalidateTags(['CreatorDetails']))
      }
    }
  }, [dispatch])

  return (
    <>
      <StyledAddSectionButtonsContainer>
        <MenuButton
          text={
            isSectionsLimitReached(sections)
              ? t('creator:add_section_limit', {
                  limit: SECTIONS_COUNT_LIMIT,
                })
              : t('creator:add_section_button')
          }
          disabled={isSectionsLimitReached(sections)}
          options={contentOptionsProps}
          buttonGroupAnalyticsDetails={{
            action: 'add_section:click',
            category: ANALYTICS_CATEGORY.APPLICATION,
          }}
        />
      </StyledAddSectionButtonsContainer>

      {sections && (
        <Box mb={160}>
          <SectionsList
            isFetchingCreatorPage={isFetchingCreatorPage}
            sections={sections}
            onSectionsOrderChanged={(orderedSections) => {
              if (!orderEndpoint) {
                Sentry.captureException(
                  new Error(
                    'endpoint to section order not returned or found on API payload'
                  )
                )
                return
              }

              setSections(orderedSections)
              dispatch(editSectionsOrder(orderedSections))

              updateSections({
                path: orderEndpoint,
                sectionsIds: [
                  ...orderedSections
                    .filter(({ _links }) => Boolean(_links?.self?.id))
                    .map(({ _links }) => _links.self.id),
                ],
                stopInvalidatesTags: true,
              })

              orderChangedRef.current = true
            }}
          />
        </Box>
      )}
    </>
  )
}
