import { useTranslation } from 'react-i18next'
import ReactTooltip from 'react-tooltip'

import {
  Box,
  Flex,
  FormFieldController,
  FormArgsType,
  Switch,
  SwitchVariant,
} from '@web-apps/ui-shared'

import {
  StyledFormContainer,
  StyledSmallText,
  StyledSubTitle,
} from '../../../../../components/styles'

type PublishedRedirectSectionControlsProps = {
  isPublishingDisabled?: boolean
  isRedirectEnabled?: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formProps: Pick<FormArgsType<any>, 'setValue' | 'getValues' | 'control'>
}

export const PublishedRedirectSectionControls = (
  props: PublishedRedirectSectionControlsProps
) => {
  const { t } = useTranslation(['creator'])

  const isRedirectEnabled = props?.isRedirectEnabled ?? true
  const { setValue, getValues, control } = props.formProps

  return (
    <>
      <StyledFormContainer>
        <FormFieldController
          render={({ field }) => {
            return (
              <Flex justify="space-between" align="flex-start">
                <div>
                  <Flex align="center">
                    <StyledSubTitle>
                      {t('creator:display_section.title')}
                    </StyledSubTitle>
                  </Flex>
                  <StyledSmallText>
                    {t('creator:display_section.description')}
                  </StyledSmallText>
                </div>
                <Box mt={6}>
                  <Switch
                    name={field.name}
                    onChange={(checked) => {
                      if (!checked) {
                        setValue('isRedirect', false)
                      }

                      field.onChange(checked)
                    }}
                    checked={field.value}
                    variant={SwitchVariant.SMALL}
                  />
                </Box>
              </Flex>
            )
          }}
          control={control}
          name="published"
        />
      </StyledFormContainer>

      {isRedirectEnabled && (
        <StyledFormContainer>
          <FormFieldController
            render={({ field }) => {
              return (
                <Flex justify="space-between" align="flex-start">
                  <div>
                    <Box mb={4}>
                      <StyledSubTitle>
                        {t('creator:redirect_section.title')}
                      </StyledSubTitle>
                    </Box>
                    <StyledSmallText>
                      {t('creator:redirect_section.description')}
                    </StyledSmallText>
                  </div>
                  <div
                    data-tip
                    data-for="redirect-control"
                    data-tip-disable={getValues('published')}
                  >
                    <Switch
                      name={field.name}
                      onChange={field.onChange}
                      checked={field.value}
                      disabled={!getValues('published')}
                      variant={SwitchVariant.SMALL}
                    />
                  </div>

                  <ReactTooltip
                    id="redirect-control"
                    multiline={true}
                    effect="solid"
                    place="top"
                    type="dark"
                  >
                    {t('creator:redirect_section.warning')}
                  </ReactTooltip>
                </Flex>
              )
            }}
            control={control}
            name="isRedirect"
          />
        </StyledFormContainer>
      )}
    </>
  )
}
