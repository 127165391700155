import * as Sentry from '@sentry/browser'

import {
  api,
  fromAffiliateLinkConversionAPI,
  fromBrandSearchAPI,
  fromAffiliateLinkAPI,
  fromAffiliateLinksListAPI,
  fromTopBrandsAPI,
  fromBrandAPI,
  fromTrendingProductsSearchAPI,
  callApiEndpoint,
  truncateText,
  encodeUriWhenNotEncoded,
  fromRecentAffiliateLinksListApi,
  fromOffersLatestCommissionIncreasesAPI,
} from '@web-apps/utils-shared'
import {
  AffiliateLinkConversionData,
  BrandSearchData,
  AffiliateProductsDataType,
  AffiliateLinkType,
  AffiliateLinksListType,
  Brand,
  AffiliateRequestParams,
  ImageFormType,
  ImageNamesEnum,
  TrendingProductsFiltrationType,
  RecentAffiliateLinksType,
  OfferWithCommissionDiff,
} from '@web-apps/utils-types'
import {
  DASHBOARD_PRODUCT_STATUS_WIDGETS_PERIOD,
  DASHBOARD_PRODUCT_STATUS_WIDGETS_SIZES,
} from '../utils/constants/queryParams.constants'
import { MAX_TITLE_LENGTH } from '@web-apps/ui-shared'

const affiliatesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    inspectLink: builder.mutation<
      AffiliateLinkConversionData,
      { uri: string; path: string }
    >({
      query: ({ uri, path }) => ({
        path: path,
        method: 'post',
        data: { uri },
      }),
      transformResponse: fromAffiliateLinkConversionAPI,
    }),
    updateAffiliateSettings: builder.mutation<void, { region: string }>({
      query: ({ region }) => ({
        path: `/affiliate/settings`,
        method: 'put',
        data: { region },
      }),
      invalidatesTags: ['UserAccount', 'APIConfigs'],
    }),
    getTopBrands: builder.query<
      Brand[],
      AffiliateRequestParams & { path?: string }
    >({
      query: ({ cpaMetric = false, cpcMetric = false, region, path }) => {
        let updatedPath = `/views/top-offers?with_cpc=${cpcMetric}&with_cpa=${cpaMetric}${
          region ? `&region=${region}` : ''
        }`

        if (path) {
          const url = new URL(path)
          url.searchParams.set('with_cpc', String(cpcMetric))
          url.searchParams.set('with_cpa', String(cpaMetric))
          updatedPath = url.href
        }

        return {
          path: updatedPath,
          method: 'get',
        }
      },
      transformResponse: fromTopBrandsAPI,
    }),
    getOffersLatestCommissionIncreases: builder.query<
      OfferWithCommissionDiff[],
      { path: string }
    >({
      query: ({ path }) => ({
        path,
        method: 'get',
      }),
      transformResponse: fromOffersLatestCommissionIncreasesAPI,
    }),
    getBrand: builder.query<
      Brand,
      { brandId: string; userId: string; path?: string }
    >({
      query: ({ brandId, userId, path }) => ({
        path: path || `/views/brands/${brandId}?user_id=${userId}`,
        method: 'get',
      }),
      transformResponse: fromBrandAPI,
    }),
    getBrands: builder.query<
      BrandSearchData,
      { searchTerm: string; path?: string }
    >({
      query: ({ searchTerm, path }) => {
        let updatedPath = `/views/offers?q=${searchTerm}`

        if (path) {
          const url = new URL(path)
          url.searchParams.set('q', searchTerm)
          updatedPath = url.href
        }

        return {
          path: updatedPath,
          method: 'get',
        }
      },
      transformResponse: fromBrandSearchAPI,
    }),
    createAffiliateLink: builder.mutation<
      AffiliateLinkType,
      {
        uri: string
        title?: string
        pageId?: string
        path?: string
        stopInvalidatesTags?: boolean
      }
    >({
      query: ({ uri, title, pageId, path }) => ({
        path: path || `/creator-pages/${pageId}/affiliate-links`,
        method: 'post',
        data: {
          uri,
          title,
        },
      }),
      transformResponse: fromAffiliateLinkAPI,
      invalidatesTags: (result, error, args) =>
        result && !args.stopInvalidatesTags ? ['AffiliateLinks'] : [],
    }),
    addImageToAffiliateLink: builder.mutation<
      AffiliateLinkType,
      {
        linkId: string
        imageUrl?: string
        pageId: string
        imageData?: ImageFormType & {
          wasUserUpload?: boolean
          name?: ImageNamesEnum
        }
        stopInvalidatesTags?: boolean
      }
    >({
      query: ({ linkId, imageUrl, imageData, pageId }) => {
        let data

        if (imageUrl) data = { source: encodeUriWhenNotEncoded(imageUrl) }
        if (imageData && imageData.imageFile) {
          data = new FormData()
          data.set('file', imageData.imageFile)
          data.set('name', imageData.name || ImageNamesEnum.DEFAULT)
        }
        return {
          path: `/creator-pages/${pageId}/affiliate-links/${linkId}/image`,
          method: 'post',
          data,
        }
      },
      invalidatesTags: (result, error, args) =>
        !error && !args.stopInvalidatesTags ? ['AffiliateLinks'] : [],
    }),
    getAffiliateLinks: builder.query<
      AffiliateLinksListType,
      { path?: string; pageId?: string }
    >({
      query: ({ path, pageId }) => ({
        path: path || `/creator-pages/${pageId}/affiliate-links`,
        method: 'get',
      }),
      transformResponse: fromAffiliateLinksListAPI,
      providesTags: ['AffiliateLinks'],
    }),
    getRecentAffiliateLinks: builder.query<
      RecentAffiliateLinksType,
      { pageId?: string }
    >({
      query: ({ pageId }) => ({
        path: `/creator-pages/${pageId}/affiliate-links?period=${DASHBOARD_PRODUCT_STATUS_WIDGETS_PERIOD}&size=${DASHBOARD_PRODUCT_STATUS_WIDGETS_SIZES}`,
        method: 'get',
      }),
      transformResponse: fromRecentAffiliateLinksListApi,
      providesTags: ['RecentAffiliateLinks'],
    }),
    updateAffiliateLinkAtomic: builder.mutation<
      {
        id: string
        title?: string
        imageUrl?: string
      },
      { affiliateLink: AffiliateLinkType }
    >({
      async queryFn({ affiliateLink }, _queryApi) {
        const linkId = affiliateLink.id
        const pageId = affiliateLink.author?.id as string

        if (!pageId) {
          Sentry.configureScope((scope) =>
            scope.setFingerprint([
              'no-page-id-on-update-affiliate-link-data-async',
            ])
          )
          Sentry.captureException(new Error('No page id.'))

          return { data: { id: linkId } }
        }

        let pageTitle, imageUrl
        try {
          const response = await callApiEndpoint(
            'fetchExternalLinkImage',
            _queryApi,
            { externalUrl: affiliateLink.originalUrl }
          )

          pageTitle = response.pageTitle
          imageUrl = response.imageUrl
        } catch (e) {
          Sentry.configureScope((scope) =>
            scope.setFingerprint([
              'scrape-image-failure-on-update-affiliate-link-data-async',
            ])
          )
          Sentry.setExtra('error', e)
          Sentry.captureException(
            new Error('Scraper has failed while affiliate link creation')
          )
          return { data: { id: linkId } }
        }

        try {
          if (pageTitle) {
            await callApiEndpoint('updateAffiliateLink', _queryApi, {
              pageId,
              linkId,
              title: truncateText(pageTitle, {
                maxLength: MAX_TITLE_LENGTH,
                useEllipsis: true,
              }),
            })
          }
        } catch (e) {
          Sentry.configureScope((scope) =>
            scope.setFingerprint([
              'update-title-failure-on-update-affiliate-link-data-async',
            ])
          )
          Sentry.setExtra('error', e)
          Sentry.captureException(
            new Error(
              'Update of title has failed while affiliate link creation'
            )
          )
          return { data: { id: linkId } }
        }

        try {
          if (imageUrl) {
            await callApiEndpoint('addImageToAffiliateLink', _queryApi, {
              pageId,
              linkId,
              imageUrl,
            })
          }

          return { data: { id: linkId, imageUrl, title: pageTitle } }
        } catch (e) {
          const err = e as { code: string }
          if (err.code !== 'ERR_NETWORK') {
            Sentry.configureScope((scope) =>
              scope.setFingerprint([
                'update-image-failure-on-update-affiliate-link-data-async',
              ])
            )
            Sentry.setExtra('error', e)
            Sentry.captureException(
              new Error('Update image has failed while affiliate link creation')
            )
          }
          return { data: { id: linkId } }
        }
      },
    }),
    createAffiliateLinkAtomic: builder.mutation<
      AffiliateLinkType,
      {
        uri: string
        pageId: string
        createAffiliateLinkEndpoint?: string
        pageTitle?: string
        imageUrl?: string
        imageData?: ImageFormType & {
          wasUserUpload?: boolean
          name?: ImageNamesEnum
        }
        stopInvalidatesTags?: boolean
      }
    >({
      async queryFn(
        {
          uri,
          pageId,
          createAffiliateLinkEndpoint,
          pageTitle,
          imageUrl,
          imageData,
        },
        _queryApi
      ) {
        let title
        if (pageTitle) {
          title = truncateText(pageTitle, {
            maxLength: MAX_TITLE_LENGTH,
            useEllipsis: true,
          })
        }
        const creationResponse = await callApiEndpoint(
          'createAffiliateLink',
          _queryApi,
          {
            uri,
            path: createAffiliateLinkEndpoint,
            pageId,
            title,
          }
        )
        if (imageUrl || imageData) {
          try {
            await callApiEndpoint('addImageToAffiliateLink', _queryApi, {
              pageId,
              linkId: creationResponse.id,
              imageUrl,
              imageData,
            })

            return { data: { ...creationResponse, imageUrl } }
          } catch (e) {
            Sentry.captureException(
              new Error(
                `The image [${imageUrl}] failed to be added to the affiliate link [${uri}]`
              )
            )
          }
        }

        return { data: creationResponse }
      },
      invalidatesTags: (result, error, params) =>
        result && !params.stopInvalidatesTags ? ['AffiliateLinks'] : [],
    }),
    updateAffiliateLink: builder.mutation<
      AffiliateLinkType,
      {
        linkId: string
        title: string
        pageId: string
      }
    >({
      query: ({ linkId, title, pageId }) => ({
        path: `/creator-pages/${pageId}/affiliate-links/${linkId}`,
        method: 'put',
        data: {
          title,
        },
      }),
    }),
    searchTrendingProducts: builder.query<
      AffiliateProductsDataType,
      { query: string; path?: string; filters?: TrendingProductsFiltrationType }
    >({
      query: ({ path, query, filters }) => {
        const params = new URLSearchParams()
        params.set('q', query)

        if (filters) {
          const getKeys = Object.keys as <T extends object>(
            obj: T
          ) => Array<keyof T>
          getKeys(filters).forEach((filter) => {
            if (filter) {
              params.set(filter, (filters?.[filter] || '').toString())
            }
          })
        }

        return {
          path: path || `/views/product-search-v0?${params.toString()}`,
          method: 'get',
        }
      },
      transformResponse: fromTrendingProductsSearchAPI,
    }),
  }),
})

export const {
  useInspectLinkMutation,
  useCreateAffiliateLinkMutation,
  useUpdateAffiliateLinkAtomicMutation,
  useCreateAffiliateLinkAtomicMutation,
  useGetBrandQuery,
  useGetTopBrandsQuery,
  useGetOffersLatestCommissionIncreasesQuery,
  useSearchTrendingProductsQuery,
  useGetBrandsQuery,
  useGetAffiliateLinksQuery,
  useGetRecentAffiliateLinksQuery,
  useUpdateAffiliateSettingsMutation,
  useUpdateAffiliateLinkMutation,
} = affiliatesApi
