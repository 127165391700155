import { InputHTMLAttributes, useState } from 'react'
import {
  emptyFunctionForOptionals,
  FormValidations,
} from '@web-apps/utils-shared'

type UseURLInputHandlersProps = {
  onValueChange?: (
    newValue: string,
    setValue: (value: string) => void,
    urlWasPasted: boolean
  ) => void
}

const addHttpsTo = (value: string) => `https://${value}`
const needsToHaveProtocol = (value: string) =>
  !FormValidations.isValidURL(value) &&
  FormValidations.isValidURL(addHttpsTo(value))

export const useURLInputHandlers = ({
  onValueChange = emptyFunctionForOptionals,
}: UseURLInputHandlersProps) => {
  const [urlWasPasted, setUrlWasPasted] = useState(false)
  const onPaste: InputHTMLAttributes<HTMLInputElement>['onPaste'] = (e) => {
    setUrlWasPasted(Boolean(e.clipboardData.getData('Text')))
  }

  return {
    onPaste,
    onChangeBuilder: (setValue: (value: string) => void) => {
      const onChange: InputHTMLAttributes<HTMLInputElement>['onChange'] = ({
        target: { value },
      }) => {
        if (urlWasPasted && needsToHaveProtocol(value)) {
          const link = addHttpsTo(value)
          setValue(link)
          onValueChange(link, setValue, urlWasPasted)
        } else {
          onValueChange(value, setValue, urlWasPasted)
        }

        setUrlWasPasted(false)
      }

      return onChange
    },
    onBlurBuilder: (setValue: (value: string) => void) => {
      const onBlur: InputHTMLAttributes<HTMLInputElement>['onBlur'] = ({
        target: { value },
      }) => {
        if (needsToHaveProtocol(value)) {
          const link = addHttpsTo(value)
          setValue(link)
          onValueChange(link, setValue, urlWasPasted)
        }
      }

      return onBlur
    },
    onKeyPressBuilder: (
      currentValue: string,
      setValue: (value: string) => void
    ) => {
      const onKeyPress: InputHTMLAttributes<HTMLInputElement>['onKeyPress'] = ({
        code,
      }) => {
        if (code === 'Enter' && needsToHaveProtocol(currentValue)) {
          const link = addHttpsTo(currentValue)
          setValue(link)
          onValueChange(link, setValue, urlWasPasted)
        }
      }

      return onKeyPress
    },
  }
}
