import styled from 'styled-components'
import { Theme, setUnity, Typography } from '@web-apps/ui-shared'

export const StyledLabel = styled.label<{
  $w?: string | number
  $h?: string | number
}>`
  display: flex;
  width: 100%;
  ${({ $w }) => $w && `width: ${setUnity($w)};`}
  ${({ $h }) => $h && `height: ${setUnity($h)};`}
  position: relative;
  margin: 0;
  text-align: center;
  user-select: none;
  cursor: pointer;
`

export const StyledContent = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 16px;
  border-radius: 7px;
  gap: 8px;
  border: 1px solid ${Theme.Colors.line};
`

export const StyledText = styled(Typography)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: normal;
`

export const StyledInputRadio = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  &:checked ~ ${StyledContent} {
    border: 1px solid ${Theme.Colors.typography.light};
    background-color: ${Theme.Colors.background.highlight};
    transition: border-color 0.25s ease-in-out;

    & ${StyledText} {
      font-weight: 600;
    }
  }
`
