import { useTranslation } from 'react-i18next'
import { Link, useLocation, Outlet } from 'react-router-dom'
import { Tabs, Tab, TabList, TabPanels } from '@web-apps/ui-shared'

import { ROUTES } from '../../../routes/RouteEnums'
import {
  PageHeader,
  PageTitle,
  PageWrapper,
} from '../../../components/PageComponents'
import { useAccountDetails } from '../../../utils/hooks/useAccountDetails.hooks'

const TAB_INDEX_FOR_ROUTES: { [key: string]: number } = {
  [ROUTES.ANALYTICS_EARNINGS]: 0,
  [ROUTES.ANALYTICS_PAGE]: 1,
  [ROUTES.ANALYTICS_PAYMENT_DETAILS]: 2,
}

export const Analytics = () => {
  const { t } = useTranslation(['app', 'creator'], { useSuspense: false })
  const { pathname } = useLocation()
  const activeTabIndex = TAB_INDEX_FOR_ROUTES[pathname] || 0
  const { payoutInfoFormEndpoint } = useAccountDetails()

  return (
    <>
      <PageHeader hasBorder={false}>
        <PageTitle title={t('app:header.analytics.title')} />
      </PageHeader>
      <PageWrapper hasTabs>
        <Tabs index={activeTabIndex} onChange={() => undefined}>
          <TabList>
            <Link to={ROUTES.ANALYTICS_EARNINGS}>
              <Tab
                isActive={
                  activeTabIndex ===
                  TAB_INDEX_FOR_ROUTES[ROUTES.ANALYTICS_EARNINGS]
                }
                label={t('app:sidebar.earnings')}
              />
            </Link>
            <Link to={ROUTES.ANALYTICS_PAGE}>
              <Tab
                isActive={
                  activeTabIndex === TAB_INDEX_FOR_ROUTES[ROUTES.ANALYTICS_PAGE]
                }
                label={t('app:sidebar.page_analytics')}
              />
            </Link>
            {payoutInfoFormEndpoint && (
              <Link to={ROUTES.ANALYTICS_PAYMENT_DETAILS}>
                <Tab
                  isActive={
                    activeTabIndex ===
                    TAB_INDEX_FOR_ROUTES[ROUTES.ANALYTICS_PAYMENT_DETAILS]
                  }
                  label={t('app:sidebar.payment_details')}
                />
              </Link>
            )}
          </TabList>
          <TabPanels>
            <Outlet />
          </TabPanels>
        </Tabs>
      </PageWrapper>
    </>
  )
}
