import { nanoid } from 'nanoid'

import { updateImages } from '../../../../utils/helpers/preview.slice.helpers'

import { getSectionImage } from '@web-apps/feature-creator-page'
import {
  AffiliateLinkSection,
  AffiliateProductType,
  ContainerItemAPIPayloadType,
  ContainerSectionAPIPayloadType,
  ExternalLinkMode,
  ExternalLinkSection,
  ImageFormType,
  ImageNamesEnum,
  ProductRecommendationsSectionType,
  SectionExtension,
  SectionType,
  SectionTypeEnum,
  SectionVariantEnum,
} from '@web-apps/utils-types'
import {
  ProductFormType,
  ProductRecommendationsFormType,
} from './SectionProductionRecommendations.types'
import { fromCommissionAggregateAPI } from '@web-apps/utils-shared'

type ProductSectionType = (
  | Omit<ExternalLinkSection, '_links' | 'label'>
  | Omit<AffiliateLinkSection, '_links' | 'label' | 'affiliate_link'>
) & {
  label?: string
}

export const formatBaseProductRecommendationsForm = (
  productRecommendationsForm: ProductRecommendationsFormType
): Omit<ProductRecommendationsSectionType, '_links' | 'items'> => ({
  type: SectionTypeEnum.CONTAINER,
  styles: productRecommendationsForm.styles,
  published: productRecommendationsForm.published || true,
  label: productRecommendationsForm.title || undefined,
})

export const formatProductRecommendationsFormToPreview = (
  productRecommendationsForm: ProductRecommendationsFormType
): Omit<ProductRecommendationsSectionType, '_links'> & {
  imageData?: ImageFormType
} => {
  const baseProductRecommendationsForm = formatBaseProductRecommendationsForm(
    productRecommendationsForm
  )

  return {
    ...baseProductRecommendationsForm,
    imageData: productRecommendationsForm.imageData,
    items: (productRecommendationsForm.products || []).map((product) => ({
      label: product.title || undefined,
      styles: {
        variant: SectionVariantEnum.COVER,
      },
      mode: ExternalLinkMode.LINK,
      published: true,
      type: SectionTypeEnum.EXTERNAL_LINK,
      _links: {
        about: {
          href: product.href,
          id: nanoid(),
        },
        alternate: product.alternate
          ? {
              href: product.alternate,
            }
          : undefined,
        self: {
          href: '',
          id: nanoid(),
        },
        author: {
          href: '',
          name: '',
          id: nanoid(),
        },
        parent: {
          href: '',
          id: nanoid(),
        },
        'self/edit-image': { href: '' },
        'self/image': updateImages(
          undefined,
          [ImageNamesEnum.DEFAULT, ImageNamesEnum.SECTION],
          product.image
        ),
      },
    })),
  }
}

export const formatProductFormToApi = (
  product: ProductFormType
): ProductSectionType & {
  link_id?: string
  image_source?: string
  imageData?: ImageFormType
  href: string
} => ({
  label: product.title || undefined,
  styles: {
    variant: SectionVariantEnum.COVER,
  },
  mode: ExternalLinkMode.LINK,
  published: true,
  href: product.href,
  imageData:
    product.image?.hasImageSource && product.image.href
      ? { href: product.image.href, hasImageSource: true }
      : product.image,
  image_source:
    product.image?.href && product.image?.hasImageSource
      ? product.image.href
      : undefined,
  type:
    product.affiliateLinkId || product.alternate
      ? SectionTypeEnum.AFFILIATE_LINK
      : SectionTypeEnum.EXTERNAL_LINK,
  link_id: product.affiliateLinkId,
})

export const formatProductRecommendationsToCreationApi = (
  productRecommendationsForm: ProductRecommendationsFormType
): Omit<ProductRecommendationsSectionType, '_links' | 'items'> & {
  items: (ProductSectionType & {
    link_id?: string
    image_source?: string
    imageData?: ImageFormType
    href: string
  })[]
} => {
  const baseProductRecommendationsForm = formatBaseProductRecommendationsForm(
    productRecommendationsForm
  )

  return {
    ...baseProductRecommendationsForm,
    items: (productRecommendationsForm.products || []).map(formatProductFormToApi),
  }
}

const formatAffiliateProductToSection = (
  product: AffiliateProductType
): ProductSectionType => ({
  label: product.title || undefined,
  type: SectionTypeEnum.EXTERNAL_LINK,
  styles: {
    variant: SectionVariantEnum.COVER,
  },
  mode: ExternalLinkMode.LINK,
  published: true,
})

export const formatAffiliateProductToApi = (product: AffiliateProductType) => ({
  ...formatAffiliateProductToSection(product),
  href: product.url,
  imageData: product.mediaURL
    ? { href: product.mediaURL, hasImageSource: true }
    : undefined,
  image_source: product.mediaURL || undefined,
  convert_to_affiliate: true,
})

export const formatAffiliateProductsToGridCreationApi = ({
  products,
  title,
}: {
  products: AffiliateProductType[]
  title?: string
}) => {
  const baseProductRecommendationsForm = {
    type: SectionTypeEnum.CONTAINER,
    published: true,
    label: title || undefined,
    styles: {
      variant: SectionVariantEnum.PLAIN,
      is_product_recommendations: true,
    },
  }

  return {
    ...baseProductRecommendationsForm,
    items: products.map((product) => formatAffiliateProductToApi(product)),
  }
}

export const isProductRecommendationsSection = (section: SectionType) =>
  section.type === SectionTypeEnum.CONTAINER &&
  section.styles?.['is_product_recommendations']

export const formatContainerToForm = (
  section: ContainerSectionAPIPayloadType
): ProductRecommendationsFormType => {
  return {
    id: section._links.self.id,
    title: section.label,
    published: section.published,
    styles: {
      extension: SectionExtension.NONE,
      ...section.styles,
    },
    imageData: getSectionImage(section),
    products: (section.items || [])
      .filter((nestedSection) => {
        return Boolean(nestedSection._links.about?.href)
      })
      .map((p: ContainerItemAPIPayloadType) => ({
        id: p._links.self.id,
        href: p._links.about?.href || '',
        alternate: p._links.alternate?.href,
        title: p.label,
        image: getSectionImage(p as SectionType) as ImageFormType,
        ...('affiliate_link' in p && {
          commission: fromCommissionAggregateAPI(p.affiliate_link.commission),
        }),
      })),
  }
}

export const fromTrendingProductsToProductFormType = (
  product: AffiliateProductType
): ProductFormType => ({
  href: product.url,
  image: product.mediaURL
    ? { hasImageSource: true, href: product.mediaURL }
    : undefined,
  title: product.title,
  convertAffiliateLink: false,
})

export const formatTrendingProductToApi = (product: AffiliateProductType) => ({
  label: product.title,
  type: SectionTypeEnum.EXTERNAL_LINK,
  styles: {
    variant: SectionVariantEnum.COVER,
  },
  mode: ExternalLinkMode.LINK,
  published: true,
  href: product.url,
  image_source: product.mediaURL ? product.mediaURL : undefined,
  convert_to_affiliate: false,
})
