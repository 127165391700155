import { Flex, Typography } from '../../atoms'

import { BaseTooltip } from './BaseTooltip'
import { TooltipProps } from './Tooltip'

type InfoTooltipProps = TooltipProps & {
  title: string
  message?: string
  hint?: string
}

export const InfoTooltip = ({
  id,
  title,
  message,
  hint,
  maxWidthOverride,
}: InfoTooltipProps) => (
  <BaseTooltip id={id} maxWidthOverride={maxWidthOverride}>
    <Flex direction="column" gap={4}>
      <Typography variant="hint" fontWeight="600">
        {title}
      </Typography>
      {message && <Typography variant="hint">{message}</Typography>}
      {hint && (
        <Typography variant="hint" color="inactive" fontStyle="italic">
          {hint}
        </Typography>
      )}
    </Flex>
  </BaseTooltip>
)
