const hostnameRegex =
  /\.*([^.]+)\.(com|io|xyz|de|am|co\.uk|org\.uk|ac\.uk|uk)/im

const getCurrentEnvName = () => {
  let host = window.location.hostname
  if (!host.includes('http')) {
    host = `https://${host}`
  }
  const hostname = new URL(host).hostname
  const hostMatch = hostname.match(hostnameRegex)

  return hostMatch
    ? `${hostMatch[1].toLowerCase()}.${hostMatch[2].toLowerCase()}`
    : 'zez.am'
}

export const getEnvironmentConfigs = () => {
  const envName = getCurrentEnvName()

  return envName === 'zez.am'
    ? {
        cookieDomain: '.zez.am',
        publicApp: 'https://zez.am',
        marketplaceDomain: 'zez.am',
        creatorApp: 'https://my.zez.am',
        homePage: 'https://zezam.io',
      }
    : {
        cookieDomain: '.zezam.xyz',
        publicApp: 'https://zezam.xyz',
        marketplaceDomain: 'zezam.xyz',
        creatorApp: 'https://my.zezam.xyz',
        homePage: 'https://zezam.io',
      }
}
