import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

import {
  BREAKPOINTS,
  media,
  Theme,
  BasePopoverCSS,
  BasePopoverListCSS,
  StyledPopoverWrapper as BaseStyledPopoverWrapper,
} from '@web-apps/ui-shared'

export const StyledContainer = styled.div`
  display: flex;
  justify-content: space-around;
  border-radius: 8px;
  border: 1px solid ${Theme.Colors.line};
`

export const StyledButtonWrapper = styled.div`
  position: relative;
`

export const StyledButton = styled.button`
  display: flex;
  gap: 14px;
  justify-content: center;
  align-items: center;

  color: ${Theme.Colors.typography.regular};
  min-width: 125px;
  padding: 8px 16px;
  background: none;
  border: none;

  &:disabled {
    border-color: ${Theme.Colors.typography.inactive};
  }
`

export const StyledPopover = styled.div`
  ${BasePopoverCSS};

  ${media.from(
    BREAKPOINTS.sm,
    `
    top: calc(100% - 2px);
    left: calc(50% - 88px);
    `
  )};
`

export const StyledPopoverWrapper = styled(BaseStyledPopoverWrapper)`
  ${media.from(
    BREAKPOINTS.sm,
    `
      width: 175px;
      padding: 0;
      max-width: revert;
      min-width: revert;
      margin-inline: revert;
      margin-bottom: revert;
    `
  )};
`

export const StyledList = styled.ul`
  ${BasePopoverListCSS};
  gap: 0;
  padding: 0;
`

export const StyledGroup = styled.li`
  padding: 8px 0;
  margin: 0 16px;
  border-bottom: 1px solid ${Theme.Colors.line};
`

export const StyledLinkItem = styled(NavLink)`
  display: flex;
  padding: 8px 16px;
  margin-inline: -16px;

  &:hover {
    background-color: ${Theme.Colors.lightSand};
  }

  &.active {
    font-weight: 600;
  }
`
