import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import {
  ProductFormType,
  ProductRecommendationsFormType,
} from './SectionProductionRecommendations.types'

import {
  Form,
  FormArgsType,
  ShowHideAnimationWrapper,
  TextField,
} from '@web-apps/ui-shared'
import {
  ContainerSectionAPIPayloadType,
  SectionExtension,
} from '@web-apps/utils-types'

import { ROUTES } from '../../../../routes/RouteEnums'

import { StyledFormContainer } from '../../../../components/styles'
import { FormActionsBar, FormFieldBox } from '../../../../components'
import {
  DeleteBlock,
  PublishedRedirectSectionControls,
  SectionExpandableStyleFormBlock,
  SectionStyleFormBlock,
} from '../components'
import { NestedSectionsControl } from './components/NestedSectionsControl'

type SectionProductRecommendationsFormProps = {
  initialValues?: ProductRecommendationsFormType
  onSubmit: (values: ProductRecommendationsFormType) => void
  handleDelete?: () => void
  isSubmitting?: boolean
  disabled?: boolean
  onChange?: (values: ProductRecommendationsFormType) => void
  onProductDelete?: (product: ProductFormType) => Promise<boolean>
  onProductEdit?: (product: ProductFormType) => Promise<boolean>
  onProductAdd?: (product: ProductFormType) => Promise<{ id: string } | false>
  pageId?: string
  section?: ContainerSectionAPIPayloadType
}

export const SectionProductRecommendationsForm = ({
  initialValues,
  onSubmit,
  handleDelete,
  isSubmitting = false,
  disabled = false,
  onChange,
  section,
}: SectionProductRecommendationsFormProps) => {
  const navigate = useNavigate()
  const { t } = useTranslation(['app', 'creator'], { useSuspense: false })
  const subscription = useRef<ReturnType<FormArgsType['watch']>>()

  return (
    <Form
      initialWatchableFields={['products']}
      initialValues={initialValues}
      onSubmit={onSubmit}
      validations={{
        descriptors: {
          products: {
            validate: (value = []) => {
              return value.length === 0
                ? t('creator:product_recommendations.products_required_message')
                : true
            },
          },
        },
      }}
    >
      {(formProps) => {
        const { control, getValues, setValue, watch } = formProps
        if (!subscription.current && onChange) {
          subscription.current = watch((data) =>
            onChange(data as ProductRecommendationsFormType)
          )
        }

        return (
          <>
            <StyledFormContainer>
              <NestedSectionsControl
                section={section}
                isLoading={isSubmitting}
                formReturnProps={formProps}
              />
            </StyledFormContainer>

            <StyledFormContainer>
              <FormFieldBox
                mb={0}
                title={t('creator:custom_link.title_section_button_style')}
              >
                <SectionExpandableStyleFormBlock
                  formReturnProps={formProps}
                  isMultiItemsIcon
                />
                <ShowHideAnimationWrapper
                  in={
                    getValues('styles.extension') === SectionExtension.ACCORDION
                  }
                >
                  <SectionStyleFormBlock formReturnProps={formProps} />
                </ShowHideAnimationWrapper>
                <FormFieldBox mt={24}>
                  <TextField
                    name="title"
                    label={t(
                      'creator:product_recommendations.field_label_title'
                    )}
                    labelDecorator={t(
                      'app:optional_form_field_label_decorator'
                    )}
                    showAllowedChars
                    maxLength={100}
                  />
                </FormFieldBox>
              </FormFieldBox>
            </StyledFormContainer>
            <PublishedRedirectSectionControls
              isRedirectEnabled={false}
              formProps={{
                control,
                getValues,
                setValue,
              }}
            />
            <DeleteBlock handleDelete={handleDelete} />
            <FormActionsBar
              hasHeaderSaveButton
              isLoading={isSubmitting}
              secondaryAction={(e) => {
                e.preventDefault()
                navigate(ROUTES.MY_PAGE)
              }}
              secondaryActionName={t('app:button_discard')}
              disabled={disabled}
            />
          </>
        )
      }}
    </Form>
  )
}
