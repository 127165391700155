import styled from 'styled-components'
import { Card } from '@web-apps/ui-shared'
import { FontEnum } from '@web-apps/utils-types'

export const StyledFormGroupContainer = styled(Card)`
  margin-block-end: 32px;
  position: relative;
`

export const StyledFormGroupHeader = styled.h3`
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  margin-bottom: 24px;
`

export const StyledFontOptionIcon = styled.div<{ $fontFamily: FontEnum }>`
  font-family: ${({ $fontFamily }) => $fontFamily};
  font-size: 24px;
  line-height: 24px;
  font-weight: 500;
`
