import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { Box, CustomCard, Flex, Tag, Typography } from '@web-apps/ui-shared'

export const ProductsListItemSkeleton = () => {
  return (
    <CustomCard
      overlayImage={{
        isSkeleton: true,
      }}
      isSquare
      footer={
        <Flex direction="column" gap={4}>
          <Typography variant="hint">
            <Skeleton />
          </Typography>
          <Typography variant="hint">
            <Skeleton />
          </Typography>
          <Typography variant="hint">
            <Skeleton />
          </Typography>
          <Box mt={8}>
            <Tag
              text={
                <Typography variant="hint">
                  <Skeleton />
                </Typography>
              }
            />
          </Box>
        </Flex>
      }
    />
  )
}
