import { BREAKPOINTS, media } from '@web-apps/ui-shared'
import styled from 'styled-components'

export const StyledHeaderButtonWrapper = styled.div`
  position: fixed;
  z-index: 10;
  top: 11px;
  right: 16px;

  ${media.from(
    BREAKPOINTS.lg,
    `
      right: 48px;
    `
  )}
`
