import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import * as Sentry from '@sentry/browser'
import { useFlag } from '@unleash/proxy-client-react'

import { FormValidations } from '@web-apps/utils-shared'

import {
  ColorPickerInput,
  FormFieldBox,
  RadioInputWithIcon,
} from '../../../../components'
import { editAppearance } from '../../../../store/creator/preview.slice'
import { useDebouncedUpdate } from '../hooks/preview.hooks'

import {
  Box,
  Card,
  Flex,
  Form,
  FormArgsType,
  FormFieldController,
  Grid,
  ImageUploader,
  RichTextEditor,
  TextField,
  Typography,
  Switch,
  SwitchVariant,
  ShowHideAnimationWrapper,
} from '@web-apps/ui-shared'

import {
  colorsInitialValues,
  computeBackgroundInfoForStyles,
  getAvatarImage,
  getImageByName,
  initialValuesForCustomization,
  useUpdateCreatorPageAppearanceMutation,
} from '@web-apps/feature-creator-page'

import {
  generateCustomisationData,
  generateFormValidations,
  MAX_PROFILE_IMAGE_SIZE_IN_BYTES,
  MAX_PROFILE_IMAGE_SIZE_IN_MEGABYTES,
  normalizeAboutValue,
  validateRadioButtons,
} from './MyPageAppearance.utils'
import {
  StyledFormGroupContainer,
  StyledFormGroupHeader,
} from './MyPageAppearance.styles'
import {
  BackgroundType,
  ButtonBorderStyle,
  ButtonShadowStyle,
  CreatorPageType,
  CustomizationFormProperties,
  MyPageAppearanceForm,
  ImageNamesEnum,
  ColorOpacity,
} from '@web-apps/utils-types'
import { ActionsBanner } from '../components/ActionsBanner'
import { StyledFormLabel } from '../../../../components/styles'
import { FLAG_HIDE_DESIGN_OPTIONS } from '../../../../utils/constants/flags.constants'

type Props = {
  creatorPage: CreatorPageType
}

const GRADIENT_START_COLOR = 'gradientStartColor'
const GRADIENT_END_COLOR = 'gradientEndColor'
const BACKGROUND_IMAGE = 'backgroundImage'
const HIDE_FOOTER_BANNER = 'hideFooterBanner'
const INPUT_NAMES_RELATED_WITH_STYLES = [
  ...Object.values(CustomizationFormProperties),
  GRADIENT_START_COLOR,
  GRADIENT_END_COLOR,
  BACKGROUND_IMAGE,
  HIDE_FOOTER_BANNER,
]

export const MyPageAppearance = ({ creatorPage }: Props) => {
  const { t } = useTranslation(['app', 'creator'])
  const hideDesignOptions = useFlag(FLAG_HIDE_DESIGN_OPTIONS)
  const [wasBackgroundImageRejected, setBackgroundImageRejected] =
    useState(false)
  const [wasImageProfileRejected, setImageProfileRejected] = useState(false)
  const profileImageOperationIdRef = useRef<'none' | 'upload' | 'delete'>(
    'none'
  )

  const subscription = useRef<ReturnType<FormArgsType['watch']>>()
  const updateAppearancePreview = useDebouncedUpdate(editAppearance)

  const validations = useMemo(() => generateFormValidations({ t }), [t])
  const {
    buttonBorderStyleValues,
    buttonShapeStyleValues,
    headerPositionStyleValues,
    profileImageShapeOptions,
    buttonVariantStyleValues,
    backgroundStylesOptions,
    backgroundEffectsOptions,
    buttonThumbnailPositionValues,
    buttonShadowStyleValues,
    buttonOpacityValues,
    fontOptions,
  } = useMemo(
    () => generateCustomisationData(t, !hideDesignOptions),
    [t, hideDesignOptions]
  )

  // transforming data for the UI form
  const initialValues: MyPageAppearanceForm = useMemo(() => {
    const styles = creatorPage.styles
    const defaultGradientColors = initialValuesForCustomization[
      CustomizationFormProperties.BACKGROUND_GRADIENT_COLORS
    ] as [string, string]
    const backgroundInfo = computeBackgroundInfoForStyles(creatorPage)

    return {
      ...(styles && Object.keys(styles)?.length
        ? validateRadioButtons({
            customizationData: { ...initialValuesForCustomization, ...styles },
            customizationConfigs: {
              buttonBorderStyleValues,
              buttonShapeStyleValues,
              headerPositionStyleValues,
              buttonVariantStyleValues,
              profileImageShapeOptions,
              fontOptions,
            },
          }) // forcing default, when not defined in styles, coming from server
        : initialValuesForCustomization),
      [CustomizationFormProperties.BACKGROUND_TYPE]:
        backgroundInfo.backgroundType,
      backgroundImage:
        (backgroundInfo.backgroundType === BackgroundType.Image ||
          backgroundInfo.backgroundType === BackgroundType.Header) &&
        creatorPage._links['self/image']
          ? getImageByName(
              creatorPage._links['self/image'],
              ImageNamesEnum.BACKGROUND
            )
          : undefined,
      // transform gradient values from API to our internal form
      gradientStartColor:
        (backgroundInfo.backgroundType === BackgroundType.Gradient &&
          backgroundInfo.gradientColors[0]) ||
        defaultGradientColors[0],
      gradientEndColor:
        (backgroundInfo.backgroundType === BackgroundType.Gradient &&
          backgroundInfo.gradientColors[1]) ||
        defaultGradientColors[1],
      name: creatorPage.name || '',
      about: creatorPage.tagline
        ? normalizeAboutValue(creatorPage.tagline)
        : '',
      profileImage: getAvatarImage(creatorPage),
      hideFooterBanner: !creatorPage.settings.show_sticky_gradient_footer,
    }
  }, [
    creatorPage,
    buttonBorderStyleValues,
    buttonShapeStyleValues,
    headerPositionStyleValues,
    buttonVariantStyleValues,
    profileImageShapeOptions,
    fontOptions,
  ])

  const lastSubmittedValueRef = useRef(initialValues)
  const resetFormCallbackRef =
    useRef<FormArgsType<MyPageAppearanceForm>['reset']>()
  const isStyleFormDirtyRef = useRef(false)

  const [
    updateCreatorPageAppearance,
    {
      isSuccess: isCreatorPageAppearanceUpdated,
      isError: hasCreatorPageAppearanceFailed,
      isLoading: isCreatorPageAppearanceUpdateLoading,
    },
  ] = useUpdateCreatorPageAppearanceMutation()

  useEffect(() => {
    if (isCreatorPageAppearanceUpdated) {
      toast.success(t('creator:profile_updated_message_success'))

      // important to clear isDirty state from form component */
      if (resetFormCallbackRef.current) {
        resetFormCallbackRef.current(lastSubmittedValueRef.current)
      }
    }
  }, [isCreatorPageAppearanceUpdated, t])

  useEffect(() => {
    if (hasCreatorPageAppearanceFailed) {
      toast.error(t('creator:profile_updated_message_error'))
    }
  }, [hasCreatorPageAppearanceFailed, t])

  const handleSubmit = (data: MyPageAppearanceForm) => {
    lastSubmittedValueRef.current = data

    if (!creatorPage._links.styles?.href) {
      Sentry.captureException(
        new Error(
          'endpoint to update creator page styles not returned or found on API payload'
        )
      )

      return
    }

    updateCreatorPageAppearance({
      ...data,
      [CustomizationFormProperties.BUTTONS_SHADOW_OPACITY]:
        data[CustomizationFormProperties.BUTTONS_SHADOW_OPACITY] ||
        ColorOpacity['60%'],
      [CustomizationFormProperties.SOCIALS_PLACEMENT]:
        creatorPage.styles[CustomizationFormProperties.SOCIALS_PLACEMENT],
      about: normalizeAboutValue(data.about || ''),
      profileImageAction: profileImageOperationIdRef.current,
      _links: creatorPage._links,
      isStyleFormDirty: isStyleFormDirtyRef.current,
      path: creatorPage._links.styles.href,
    })
  }

  useEffect(
    () => () =>
      subscription.current?.unsubscribe && subscription.current.unsubscribe(),
    []
  )

  return (
    <Form
      initialValues={initialValues}
      validations={validations}
      onSubmit={handleSubmit}
      initialWatchableFields={[
        CustomizationFormProperties.BACKGROUND_TYPE,
        CustomizationFormProperties.BUTTONS_BORDER_STYLE,
        CustomizationFormProperties.BUTTONS_SHADOW,
      ]}
    >
      {({
        control,
        watch,
        getValues,
        reset,
        formState: { errors, isDirty, dirtyFields },
      }) => {
        isStyleFormDirtyRef.current = INPUT_NAMES_RELATED_WITH_STYLES.some(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (i) => (dirtyFields as any)[i]
        )

        if (!subscription.current) {
          subscription.current = watch(
            ({
              backgroundImage,
              gradientStartColor,
              gradientEndColor,
              name,
              about = '',
              profileImage,
              ...value
            }) => {
              // needed to be able to reset form outside render props
              resetFormCallbackRef.current = reset

              const backgroundTypeForPreview =
                value[CustomizationFormProperties.BACKGROUND_TYPE]

              updateAppearancePreview({
                backgroundImage:
                  backgroundTypeForPreview === BackgroundType.Image ||
                  backgroundTypeForPreview === BackgroundType.Header
                    ? backgroundImage
                    : undefined,
                styles: {
                  ...value,
                  [CustomizationFormProperties.BACKGROUND_GRADIENT_COLORS]:
                    gradientStartColor && gradientEndColor
                      ? [gradientStartColor, gradientEndColor]
                      : undefined,
                },
                name,
                profileImage,
                about: normalizeAboutValue(about),
              })
            }
          )
        }

        const backgroundType = getValues(
          CustomizationFormProperties.BACKGROUND_TYPE
        )
        const buttonsBorderStyle = getValues(
          CustomizationFormProperties.BUTTONS_BORDER_STYLE
        )

        return (
          <>
            <Box mb={32}>
              <Card>
                <StyledFormGroupHeader>
                  {t('creator:page_information_title')}
                </StyledFormGroupHeader>
                <Box>
                  <FormFieldBox>
                    <FormFieldController
                      control={control}
                      name="profileImage"
                      render={({
                        field: { onChange, value: uploadedProfileImage },
                      }) => {
                        return (
                          <ImageUploader
                            inputName="profileImage"
                            label={t('creator:profile_image_field_label')}
                            uploadControlText={t('creator:upload_image_label')}
                            infoMessage={t('creator:upload_image_info', {
                              megaBytes: MAX_PROFILE_IMAGE_SIZE_IN_MEGABYTES,
                            })}
                            image={uploadedProfileImage}
                            onClear={() => {
                              onChange(null)
                              profileImageOperationIdRef.current = 'delete'
                            }}
                            onImageChange={(imageData) => {
                              setImageProfileRejected(false)
                              onChange({
                                ...imageData,
                                name:
                                  uploadedProfileImage?.name ||
                                  ImageNamesEnum.DEFAULT,
                              })

                              profileImageOperationIdRef.current = 'upload'
                            }}
                            maxSizeInBytes={MAX_PROFILE_IMAGE_SIZE_IN_BYTES}
                            onFileRejected={() => setImageProfileRejected(true)}
                            cropperProps={{
                              dialogTitle: t('app:crop_image'),
                              cropButtonText: t('app:button_save'),
                              discardButtonText: t('app:button_discard'),
                              forceSquare: true,
                            }}
                            isInvalid={wasImageProfileRejected}
                            acceptedExtensions={['.png', '.jpg', '.jpeg']}
                          />
                        )
                      }}
                    />
                  </FormFieldBox>
                  <FormFieldBox>
                    <TextField
                      label={t('creator:name_field_label')}
                      name="name"
                      maxLength={50}
                      showAllowedChars
                    />
                  </FormFieldBox>
                  <FormFieldController
                    name="about"
                    render={({ field }) => {
                      return (
                        <FormFieldBox>
                          <RichTextEditor
                            label={t('creator:about_me_field_label')}
                            maxLength={FormValidations.textareaMaxLength}
                            errorMessages={
                              errors.about ? [errors.about.message] : undefined
                            }
                            {...field}
                          />
                        </FormFieldBox>
                      )
                    }}
                    control={control}
                  />
                </Box>
              </Card>
            </Box>
            <StyledFormGroupContainer>
              <StyledFormGroupHeader>
                {t('creator:sub_title_customize_background')}
              </StyledFormGroupHeader>
              <FormFieldBox>
                <Grid
                  gap={16}
                  templateColumns={'repeat(auto-fit, minmax(120px, 1fr))'}
                >
                  {backgroundStylesOptions.map((item) => (
                    <RadioInputWithIcon
                      key={item.value}
                      label={item.label}
                      name={CustomizationFormProperties.BACKGROUND_TYPE}
                      value={item.value}
                      icon={item.icon}
                    />
                  ))}
                </Grid>
              </FormFieldBox>

              <ShowHideAnimationWrapper state={backgroundType}>
                {backgroundType === BackgroundType.Gradient && (
                  <>
                    <FormFieldBox>
                      <FormFieldController
                        control={control}
                        name={GRADIENT_START_COLOR}
                        render={({ field, fieldState }) => (
                          <ColorPickerInput
                            label={t('creator:gradient_start_color_label')}
                            onChange={field.onChange}
                            name={field.name}
                            value={field.value}
                            error={fieldState.error}
                          />
                        )}
                      />
                    </FormFieldBox>

                    <FormFieldController
                      control={control}
                      name={GRADIENT_END_COLOR}
                      render={({ field, fieldState }) => (
                        <ColorPickerInput
                          label={t('creator:gradient_end_color_label')}
                          onChange={field.onChange}
                          name={field.name}
                          value={field.value}
                          error={fieldState.error}
                        />
                      )}
                    />
                  </>
                )}

                {!hideDesignOptions &&
                  (backgroundType === BackgroundType.Image ||
                    backgroundType === BackgroundType.Header) && (
                    <>
                      <FormFieldBox>
                        <FormFieldController
                          control={control}
                          name={BACKGROUND_IMAGE}
                          render={({
                            field: { onChange, value: uploadedBgImage },
                          }) => {
                            return (
                              <ImageUploader
                                inputName={BACKGROUND_IMAGE}
                                uploadControlText={t(
                                  'app:image_upload_control_text'
                                )}
                                infoMessage={t(
                                  'creator:background_image_upload_info',
                                  {
                                    megaBytes:
                                      MAX_PROFILE_IMAGE_SIZE_IN_MEGABYTES,
                                  }
                                )}
                                acceptedExtensions={[
                                  '.png',
                                  '.jpg',
                                  '.jpeg',
                                  '.gif',
                                  '.svg',
                                ]}
                                image={uploadedBgImage}
                                onClear={() => onChange(null)}
                                onImageChange={(imageData) => {
                                  setBackgroundImageRejected(false)
                                  onChange({
                                    ...imageData,
                                    name:
                                      uploadedBgImage?.name ||
                                      ImageNamesEnum.BACKGROUND,
                                  })
                                }}
                                maxSizeInBytes={MAX_PROFILE_IMAGE_SIZE_IN_BYTES}
                                onFileRejected={() =>
                                  setBackgroundImageRejected(true)
                                }
                                isInvalid={
                                  wasBackgroundImageRejected ||
                                  Boolean(errors.backgroundImage)
                                }
                              />
                            )
                          }}
                        />
                      </FormFieldBox>
                      <Box mb={8}>
                        <StyledFormLabel>
                          {t('creator:appearance.effect')}
                        </StyledFormLabel>
                      </Box>
                      <Grid
                        gap={16}
                        templateColumns={'repeat(auto-fit, minmax(30%, 1fr))'}
                      >
                        {backgroundEffectsOptions.map((item) => (
                          <RadioInputWithIcon
                            key={item.value}
                            name={
                              CustomizationFormProperties.BACKGROUND_TYPE_STYLE
                            }
                            label={item.label}
                            value={item.value}
                            icon={item.icon}
                          />
                        ))}
                      </Grid>
                    </>
                  )}

                {(backgroundType === BackgroundType.Solid ||
                  backgroundType === BackgroundType.Header) && (
                  <Box mt={24}>
                    <FormFieldController
                      control={control}
                      name={CustomizationFormProperties.BACKGROUND}
                      defaultValue={
                        colorsInitialValues[
                          CustomizationFormProperties.BACKGROUND
                        ]
                      }
                      render={({ field, fieldState }) => (
                        <ColorPickerInput
                          label={t('creator:colors_label_page_background')}
                          onChange={field.onChange}
                          name={field.name}
                          value={field.value}
                          error={fieldState.error}
                        />
                      )}
                    />
                  </Box>
                )}
              </ShowHideAnimationWrapper>
            </StyledFormGroupContainer>

            <StyledFormGroupContainer>
              <StyledFormGroupHeader>
                {t('creator:sub_title_customize_header')}
              </StyledFormGroupHeader>
              {!hideDesignOptions && (
                <>
                  <FormFieldBox
                    title={t(
                      'creator:design_customisation.header_alignment.title'
                    )}
                  >
                    <Flex gap={16}>
                      {(headerPositionStyleValues || []).map((item) => (
                        <RadioInputWithIcon
                          key={item.value}
                          name={CustomizationFormProperties.HEADER_POSITION}
                          label={item.label}
                          value={item.value}
                          icon={item.icon}
                        />
                      ))}
                    </Flex>
                  </FormFieldBox>
                  <FormFieldBox
                    title={t(
                      'creator:design_customisation.profile_image_shape.title'
                    )}
                  >
                    <Flex gap={16}>
                      {(profileImageShapeOptions || []).map((item) => (
                        <RadioInputWithIcon
                          key={item.value}
                          name={CustomizationFormProperties.PROFILE_IMAGE_SHAPE}
                          label={item.label}
                          value={item.value}
                          icon={item.icon}
                        />
                      ))}
                    </Flex>
                  </FormFieldBox>
                </>
              )}
              <FormFieldBox>
                <FormFieldController
                  control={control}
                  name={CustomizationFormProperties.TEXT}
                  defaultValue={
                    colorsInitialValues[CustomizationFormProperties.TEXT]
                  }
                  render={({ field, fieldState }) => (
                    <ColorPickerInput
                      label={t('creator:label_text_color')}
                      onChange={field.onChange}
                      name={field.name}
                      value={field.value}
                      error={fieldState.error}
                    />
                  )}
                />
              </FormFieldBox>
            </StyledFormGroupContainer>

            <StyledFormGroupContainer>
              <StyledFormGroupHeader>
                {t('creator:sub_title_customize_buttons')}
              </StyledFormGroupHeader>
              <FormFieldBox
                title={t(
                  'creator:design_customisation.button_form_style.title'
                )}
              >
                <Grid gap={16} templateColumns={'repeat(3, minmax(30%, 1fr))'}>
                  {(buttonShapeStyleValues || []).map((item) => (
                    <RadioInputWithIcon
                      key={item.value}
                      name={CustomizationFormProperties.BUTTONS_SHAPE}
                      label={item.label}
                      value={item.value}
                      icon={item.icon}
                    />
                  ))}
                </Grid>
              </FormFieldBox>

              <FormFieldBox
                title={t(
                  'creator:design_customisation.button_fill_style.title'
                )}
              >
                <Grid gap={16} templateColumns={'repeat(3, minmax(30%, 1fr))'}>
                  {(buttonBorderStyleValues || []).map((item) => (
                    <RadioInputWithIcon
                      key={item.value}
                      name={CustomizationFormProperties.BUTTONS_BORDER_STYLE}
                      label={item.label}
                      value={item.value}
                      icon={item.icon}
                    />
                  ))}
                </Grid>
              </FormFieldBox>

              <FormFieldBox>
                <ShowHideAnimationWrapper state={buttonsBorderStyle}>
                  {getValues(
                    CustomizationFormProperties.BUTTONS_BORDER_STYLE
                  ) === ButtonBorderStyle.FILLED && (
                    <>
                      <FormFieldBox>
                        <FormFieldController
                          control={control}
                          name={
                            CustomizationFormProperties.BUTTONS_BACKGROUND_COLOR
                          }
                          defaultValue={
                            colorsInitialValues[
                              CustomizationFormProperties
                                .BUTTONS_BACKGROUND_COLOR
                            ]
                          }
                          render={({ field, fieldState }) => (
                            <ColorPickerInput
                              label={
                                <Typography fontWeight="normal">
                                  {t('creator:label_buttons_background_color')}
                                </Typography>
                              }
                              onChange={field.onChange}
                              name={field.name}
                              value={field.value}
                              error={fieldState.error}
                            />
                          )}
                        />
                      </FormFieldBox>
                      <FormFieldBox>
                        <Box mb={8}>
                          <Typography>
                            {t(
                              'creator:label_buttons_background_color_opacity'
                            )}
                          </Typography>
                        </Box>
                        <Grid
                          gap={16}
                          templateColumns={'repeat(3, minmax(30%, 1fr))'}
                        >
                          {buttonOpacityValues.map((item) => (
                            <RadioInputWithIcon
                              key={item.value}
                              name={
                                CustomizationFormProperties.BUTTONS_BACKGROUND_OPACITY
                              }
                              label={item.label}
                              value={item.value}
                              icon={item.icon}
                            />
                          ))}
                        </Grid>
                      </FormFieldBox>
                    </>
                  )}

                  {getValues(
                    CustomizationFormProperties.BUTTONS_BORDER_STYLE
                  ) === ButtonBorderStyle.OUTLINE && (
                    <FormFieldBox>
                      <FormFieldController
                        control={control}
                        name={CustomizationFormProperties.BUTTONS_BORDER_COLOR}
                        defaultValue={
                          colorsInitialValues[
                            CustomizationFormProperties.BUTTONS_BORDER_COLOR
                          ]
                        }
                        render={({ field, fieldState }) => (
                          <ColorPickerInput
                            label={
                              <Typography fontWeight="normal">
                                {t('creator:label_buttons_border_color')}
                              </Typography>
                            }
                            onChange={field.onChange}
                            name={field.name}
                            value={field.value}
                            error={fieldState.error}
                          />
                        )}
                      />
                    </FormFieldBox>
                  )}
                </ShowHideAnimationWrapper>

                <FormFieldController
                  control={control}
                  name={CustomizationFormProperties.BUTTONS_TEXT_COLOR}
                  defaultValue={
                    colorsInitialValues[
                      CustomizationFormProperties.BUTTONS_TEXT_COLOR
                    ]
                  }
                  render={({ field, fieldState }) => (
                    <ColorPickerInput
                      label={
                        <Typography fontWeight="normal">
                          {t('creator:label_buttons_text_color')}
                        </Typography>
                      }
                      onChange={field.onChange}
                      name={field.name}
                      value={field.value}
                      error={fieldState.error}
                    />
                  )}
                />
              </FormFieldBox>

              <FormFieldBox
                title={t('creator:design_customisation.button_shadow.title')}
              >
                <Grid gap={16} templateColumns={'repeat(3, minmax(30%, 1fr))'}>
                  {buttonShadowStyleValues.map((item) => (
                    <RadioInputWithIcon
                      key={item.value}
                      name={CustomizationFormProperties.BUTTONS_SHADOW}
                      label={item.label}
                      value={item.value}
                      icon={item.icon}
                    />
                  ))}
                </Grid>
              </FormFieldBox>
              {!hideDesignOptions && (
                <ShowHideAnimationWrapper
                  in={
                    getValues(CustomizationFormProperties.BUTTONS_SHADOW) !==
                    ButtonShadowStyle.NO_SHADOW
                  }
                >
                  <>
                    <FormFieldBox
                      title={
                        <Typography fontWeight="normal">
                          {t(
                            'creator:design_customisation.button_shadow.shadow_opacity'
                          )}
                        </Typography>
                      }
                    >
                      <Grid
                        gap={16}
                        templateColumns={'repeat(3, minmax(30%, 1fr))'}
                      >
                        {buttonOpacityValues.map((item) => (
                          <RadioInputWithIcon
                            key={item.value}
                            name={
                              CustomizationFormProperties.BUTTONS_SHADOW_OPACITY
                            }
                            label={item.label}
                            value={item.value}
                          />
                        ))}
                      </Grid>
                    </FormFieldBox>
                    <FormFieldBox>
                      <FormFieldController
                        control={control}
                        name={CustomizationFormProperties.BUTTONS_SHADOW_COLOR}
                        defaultValue={
                          colorsInitialValues[
                            CustomizationFormProperties.BUTTONS_SHADOW_COLOR
                          ]
                        }
                        render={({ field, fieldState }) => (
                          <ColorPickerInput
                            label={
                              <Typography fontWeight="normal">
                                {t(
                                  'creator:design_customisation.button_shadow.color.title'
                                )}
                              </Typography>
                            }
                            onChange={field.onChange}
                            name={field.name}
                            value={field.value}
                            error={fieldState.error}
                          />
                        )}
                      />
                    </FormFieldBox>
                  </>
                </ShowHideAnimationWrapper>
              )}
              {!hideDesignOptions && (
                <FormFieldBox
                  title={t(
                    'creator:design_customisation.button_thumbnail_style.title'
                  )}
                >
                  <Grid
                    gap={16}
                    templateColumns={'repeat(3, minmax(30%, 1fr))'}
                  >
                    {(buttonThumbnailPositionValues || []).map((item) => (
                      <RadioInputWithIcon
                        key={item.value}
                        name={
                          CustomizationFormProperties.BUTTONS_THUMBNAIL_POSITION
                        }
                        label={item.label}
                        value={item.value}
                        icon={item.icon}
                      />
                    ))}
                  </Grid>
                </FormFieldBox>
              )}
            </StyledFormGroupContainer>

            <StyledFormGroupContainer>
              <StyledFormGroupHeader>
                {t('creator:sub_title_fonts')}
              </StyledFormGroupHeader>
              <FormFieldBox>
                <Grid gap={16} templateColumns={'repeat(3, minmax(30%, 1fr))'}>
                  {fontOptions.map((item) => (
                    <RadioInputWithIcon
                      key={item.value}
                      name={CustomizationFormProperties.FONT}
                      label={item.label}
                      value={item.value}
                      icon={item.icon}
                    />
                  ))}
                </Grid>
              </FormFieldBox>
            </StyledFormGroupContainer>

            {!hideDesignOptions && (
              <StyledFormGroupContainer>
                <Flex justify="space-between" align="center">
                  <Flex align="center" gap={16}>
                    <StyledFormLabel>
                      {t('creator:appearance.hide_zezam_ad')}
                    </StyledFormLabel>
                  </Flex>
                  <FormFieldController
                    render={({ field }) => (
                      <Switch
                        name={field.name}
                        onChange={field.onChange}
                        checked={field.value}
                        variant={SwitchVariant.SMALL}
                      />
                    )}
                    control={control}
                    name={HIDE_FOOTER_BANNER}
                  />
                </Flex>
              </StyledFormGroupContainer>
            )}

            <ActionsBanner
              show={isDirty}
              text={
                isCreatorPageAppearanceUpdateLoading
                  ? t('creator:save_banner.submitting_text')
                  : t('creator:save_banner.text')
              }
              primaryAction={{
                text: t('creator:save_banner.primary_action_text'),
                type: 'submit',
                disabled: isCreatorPageAppearanceUpdateLoading,
              }}
              secondaryAction={
                isCreatorPageAppearanceUpdateLoading
                  ? undefined
                  : {
                      text: t('creator:save_banner.secondary_action_text'),
                      type: 'button',
                      onClick: () => {
                        reset(lastSubmittedValueRef.current)
                      },
                      disabled: isCreatorPageAppearanceUpdateLoading,
                    }
              }
            />
          </>
        )
      }}
    </Form>
  )
}
