import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  Typography,
  Dialog,
  Flex,
  Box,
  useWindowSize,
} from '@web-apps/ui-shared'
import { MenuItemType } from '../Sidebar.types'

import {
  StyledBadge,
  StyledBadgeWrapper,
  StyledMenuItem,
  StyledMenuItemLink,
  StyledTooltip,
  StyledMenuItemCoverDesktop,
  StyledMenuItemCoverMobile,
  StyledIcon,
  StyledLabel,
  StyledMenuActiveBackground,
  StyledSubMenuLink,
  StyledMenuItemExternalLink,
  StyledSubMenuWrapper,
} from './MenuItem.styles'
import ReactGA from 'react-ga4'
import { ANALYTICS_CATEGORY } from '@web-apps/utils-types'

type Props = {
  item: MenuItemType
}
export const MenuItem = ({ item }: Props) => {
  const navigate = useNavigate()
  const size = useWindowSize()
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  if ('isExternal' in item && item.link) {
    return (
      <StyledMenuItemExternalLink href={item.link} rel="noopener noreferrer">
        <StyledMenuActiveBackground />
        <StyledTooltip>
          <Typography variant="hint" color="inverse">
            {item.title}
          </Typography>
        </StyledTooltip>
        <StyledIcon>{item.icon}</StyledIcon>
        <StyledLabel>
          <Typography variant="extraSmall">{item.title}</Typography>
        </StyledLabel>
      </StyledMenuItemExternalLink>
    )
  }

  if ('link' in item) {
    return (
      <StyledMenuItemLink
        to={item.link || ''}
        onClick={() => {
          ReactGA.event({
            action: `navigation:${item.id}:click`,
            category: ANALYTICS_CATEGORY.APPLICATION,
          })
        }}
      >
        {({ isActive }) => (
          <>
            <StyledMenuActiveBackground $isActive={isActive} />
            <StyledTooltip>
              <Typography variant="hint" color="inverse">
                {item.title}
              </Typography>
            </StyledTooltip>
            <StyledIcon>
              {item.icon &&
                item.activeIcon &&
                (isActive ? item.activeIcon : item.icon)}
            </StyledIcon>
            <StyledLabel>
              <Typography variant="extraSmall">{item.title}</Typography>
            </StyledLabel>
            {!!item.badge && <StyledBadge>{item.badge}</StyledBadge>}
          </>
        )}
      </StyledMenuItemLink>
    )
  }

  if ('subMenu' in item)
    return (
      <StyledMenuItem
        onClick={() => {
          ReactGA.event({
            action: `navigation:${item.id}:click`,
            category: ANALYTICS_CATEGORY.APPLICATION,
          })
        }}
      >
        <StyledMenuItemCoverMobile onClick={() => setIsDialogOpen(true)} />
        <StyledMenuActiveBackground $isActive={item.isActive} />
        <StyledIcon>
          {item.icon &&
            item.activeIcon &&
            (item.isActive ? item.activeIcon : item.icon)}
        </StyledIcon>
        <StyledLabel>
          <Typography variant="extraSmall">{item.title}</Typography>
        </StyledLabel>
        {!!item.badge && (
          <StyledBadgeWrapper>
            <StyledBadge>{item.badge}</StyledBadge>
          </StyledBadgeWrapper>
        )}

        {/* This is the menu for desktop that is shown on hover */}
        <StyledMenuItemCoverDesktop>
          <StyledSubMenuWrapper
            $top={Number(size?.height) > 560 ? -20 : undefined}
            $bottom={Number(size?.height) > 560 ? undefined : -20}
          >
            <Typography variant="subTitleBig" fontWeight="600">
              {item.title}
            </Typography>
            {(item.subMenu || []).map((item) => (
              <StyledSubMenuLink
                key={item.id}
                onClick={(event) => {
                  navigate(item.link)
                  event.stopPropagation()
                  ReactGA.event({
                    action: `navigation:sub_item_${item.id}:click`,
                    category: ANALYTICS_CATEGORY.APPLICATION,
                  })
                  setIsDialogOpen(false)
                }}
              >
                <Flex align="center">
                  <Box w={40}>{item.icon}</Box>
                  <Typography variant="note">{item.title}</Typography>
                  {!!item.badge && (
                    <Box ml={8}>
                      <StyledBadge>{item.badge}</StyledBadge>
                    </Box>
                  )}
                </Flex>
              </StyledSubMenuLink>
            ))}
          </StyledSubMenuWrapper>
        </StyledMenuItemCoverDesktop>

        {/* This is the menu for mobiles that is shown on click */}
        <Dialog
          isOpen={isDialogOpen}
          title={item.title}
          showHeaderBorder={false}
          onDismiss={() => setIsDialogOpen(false)}
        >
          {(item.subMenu || []).map((item) => (
            <StyledSubMenuLink
              key={item.id}
              onClick={() => {
                navigate(item.link)
                ReactGA.event({
                  action: `navigation:sub_item_${item.id}:click`,
                  category: ANALYTICS_CATEGORY.APPLICATION,
                })
                setIsDialogOpen(false)
              }}
            >
              <Flex align="center">
                <Box w={40}>{item.icon}</Box>
                <Typography>{item.title}</Typography>
                {!!item.badge && (
                  <Box ml={8}>
                    <StyledBadge>{item.badge}</StyledBadge>
                  </Box>
                )}
              </Flex>
            </StyledSubMenuLink>
          ))}
        </Dialog>
      </StyledMenuItem>
    )

  return null
}
