import React from 'react'
import styled, { css } from 'styled-components'

import { media, BREAKPOINTS, SuspenseWrapper } from '@web-apps/ui-shared'

const StyledPageWrapper = styled.div<{
  $hasTabs?: boolean
  $fullSize?: boolean
}>`
  margin: 24px 16px;

  ${media.from(
    BREAKPOINTS.lg,
    `
      padding: 48px;
    `
  )}

  ${media.from(
    BREAKPOINTS.xl,
    `
      padding: 48px 0;
    `
  )}

  ${({ $fullSize }) =>
    !$fullSize &&
    css`
      ${media.from(
        BREAKPOINTS.lg,
        `
          margin: 0 auto;
          width: 960px;
      `
      )}
    `};

  ${({ $hasTabs }) =>
    $hasTabs &&
    css`
      margin-top: 72px;
      ${media.from(
        BREAKPOINTS.lg,
        `
        margin-top: 48px;
      `
      )}
    `};
`

type StyledPageWrapperProps = React.HTMLAttributes<HTMLDivElement> & {
  children: React.ReactNode
  hasTabs?: boolean
  fullSize?: boolean
}
const PageWrapper = ({
  children,
  hasTabs = false,
  fullSize = false,
  ...divProps
}: StyledPageWrapperProps) => (
  <SuspenseWrapper>
    <StyledPageWrapper $hasTabs={hasTabs} $fullSize={fullSize} {...divProps}>
      {children}
    </StyledPageWrapper>
  </SuspenseWrapper>
)

export default PageWrapper
