import { CSSProperties } from 'react'
import Skeleton from 'react-loading-skeleton'

import { DraggableCard } from '../DraggableCard'

import {
  StyledCardContainer,
  StyledImage,
  StyledRow,
  StyledItemInfoContainer,
  StyledTextStack,
  StyledInfoText,
  StyledStatusContainer,
  StyledStatusIndicator,
  StyledTitle,
  StyledLeftColumnContainer,
  StyledActionsOutsideContainer,
  StyledActionInsideContainer,
  StyledCard,
} from './ItemCard.styles'

export type ItemCardProps = {
  thumbnailProps?:
    | {
        src: string
        alt?: string
      }
    | { isSkeleton: boolean }
  statusProps?: {
    cssColor: CSSProperties['color']
    text?: string | JSX.Element
  }
  title: string | JSX.Element
  subtitle?: string | JSX.Element
  infoText?: string | JSX.Element
  actionButtons?: JSX.Element[]
  isDraggable?: boolean
}

export const ItemCard = ({
  thumbnailProps,
  title,
  subtitle,
  infoText,
  statusProps,
  actionButtons,
  isDraggable = false,
}: ItemCardProps) => {
  const itemCardContent = (
    <StyledCardContainer>
      <StyledRow>
        <StyledLeftColumnContainer>
          <StyledItemInfoContainer>
            {thumbnailProps ? (
              'isSkeleton' in thumbnailProps ? (
                <Skeleton width={48} height={48} />
              ) : (
                <StyledImage
                  src={thumbnailProps.src}
                  alt={thumbnailProps.alt}
                />
              )
            ) : null}
            <StyledTextStack>
              {typeof title === 'string' && <StyledTitle>{title}</StyledTitle>}
              {typeof title === 'object' && title}

              {typeof subtitle === 'string' && <p>{subtitle}</p>}
              {typeof subtitle === 'object' && subtitle}
            </StyledTextStack>
          </StyledItemInfoContainer>
          {infoText && <StyledInfoText>{infoText}</StyledInfoText>}
        </StyledLeftColumnContainer>

        {statusProps && !actionButtons?.length && (
          <div>
            <StyledStatusContainer $cssColor={statusProps.cssColor}>
              <StyledStatusIndicator $cssColor={statusProps.cssColor} />
              <span>{statusProps.text}</span>
            </StyledStatusContainer>
          </div>
        )}

        {!!actionButtons?.length && (
          <StyledActionsOutsideContainer>
            <StyledActionInsideContainer>
              {actionButtons}
            </StyledActionInsideContainer>
          </StyledActionsOutsideContainer>
        )}
      </StyledRow>
    </StyledCardContainer>
  )

  const WrapperComponent = isDraggable ? DraggableCard : StyledCard

  return <WrapperComponent>{itemCardContent}</WrapperComponent>
}
