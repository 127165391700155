import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { Box, Button, Flex, Tag, Typography } from '@web-apps/ui-shared'
import {
  StyledPayoutsPanelCard,
  StyledProgressBarContainer,
  StyledSeparator,
  StyledStatusHeader,
  StyledStatusPanel,
} from '../../../containers/creator/Analytics/components/PayoutsPanel/PayoutsPanel.styles'

export const BalanceBlockSkeleton = () => {
  return (
    <StyledPayoutsPanelCard>
      <Flex direction="column" flex={1} justify="center" align="center">
        <Flex gap={8} align="center">
          <Typography>
            <Skeleton width={180} />
          </Typography>
        </Flex>
        <Typography variant="h1" fontWeight="600" as="p">
          <Skeleton width={100} />
        </Typography>
        <StyledProgressBarContainer>
          <Skeleton width={200} />
        </StyledProgressBarContainer>
        <Typography color="inactive" variant="hint">
          <Skeleton width={120} />
        </Typography>
      </Flex>
      <StyledSeparator />
      <StyledStatusPanel>
        <StyledStatusHeader>
          <Typography variant="h4">
            <Skeleton width={120} />
          </Typography>
          <Tag text={<Skeleton width={60} />} />
        </StyledStatusHeader>
        <Box mt={8} mb={24}>
          <Typography color="inactive" variant="hint">
            <Skeleton width={60} />
          </Typography>
        </Box>
        <Button disabled type="button">
          <Skeleton width={150} />
        </Button>
      </StyledStatusPanel>
    </StyledPayoutsPanelCard>
  )
}
export const AnalyticsPagePeriodBlockSkeleton = () => {
  return (
    <Flex direction="column" align="center">
      <Typography variant="h4" as="span">
        <Skeleton width={200} />
      </Typography>
      <Typography variant="note" color="inactive" as="span">
        <Skeleton width={150} />
      </Typography>
    </Flex>
  )
}

export const AnalyticsPageTabSkeleton = () => {
  return (
    <Flex direction="column" align="center">
      <Typography variant="extraSmall" as="span">
        <Skeleton width={100} />
      </Typography>
      <Typography variant="h3" color="inactive" as="span">
        <Skeleton width={65} />
      </Typography>
    </Flex>
  )
}

export const AnalyticsPageChartSkeleton = () => {
  return <Skeleton height={350} />
}
