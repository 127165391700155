import { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import { Confirm } from '@web-apps/ui-shared'
import {
  getSectionImage,
  useDeleteCreatorPageSectionMutation,
  useEditCreatorPageSectionAtomicMutation,
} from '@web-apps/feature-creator-page'
import {
  creatorUrlBuilders,
  EmbedProvidersCategory,
  isImageDownloadApiError,
} from '@web-apps/utils-shared'
import {
  ANALYTICS_CATEGORY,
  ExternalLinkSection,
  SectionBasePropertiesType,
} from '@web-apps/utils-types'

import { editDraftEmbedExternalSection } from '../../../../store/creator/preview.slice'
import { ROUTES } from '../../../../routes/RouteEnums'
import { useIsSectionRedirectChecker } from '../hooks/useIsSectionRedirectChecker'
import { useDraftSection } from '../hooks/preview.hooks'
import { EmbedSectionFormData, SectionEmbedForm } from './SectionEmbedForm'
import { useCreatorPage } from '../../../../utils/hooks/useCreatorPage.hooks'
import { ToastShareWrapper } from '../../../../components'

type Props = {
  type: EmbedProvidersCategory.MUSIC | EmbedProvidersCategory.VIDEO
}

export const EditSectionEmbed = ({ type }: Props) => {
  const { t } = useTranslation(['app', 'creator'])
  const navigate = useNavigate()
  const { id } = useParams<{ id: string }>()
  const [isConfirmOpen, toggleConfirm] = useState(false)
  const updatePreview = useDraftSection(id, editDraftEmbedExternalSection, true)
  const creatorPage = useCreatorPage()

  const section = creatorPage?.sections.find(
    (item): item is ExternalLinkSection => item._links.self.id === id
  )

  const { isSectionRedirect } = useIsSectionRedirectChecker({ section })

  const [
    editSection,
    {
      isSuccess: isEditSuccess,
      isError: isEditError,
      error: editError,
      isLoading: isEditLoading,
    },
  ] = useEditCreatorPageSectionAtomicMutation()

  const [
    deleteSection,
    {
      isSuccess: isDeleteSuccess,
      isError: isDeleteError,
      isLoading: isDeleteLoading,
    },
  ] = useDeleteCreatorPageSectionMutation()

  const handleSubmit = useCallback(
    ({
      published,
      isRedirect,
      label,
      href,
      styles,
      imageData,
    }: Omit<EmbedSectionFormData, 'embedURL'> & {
      label: string
      href: string
      styles: SectionBasePropertiesType['styles']
    }) => {
      if (!section) return

      // only delete if it is the current redirect and isRedirect is false
      // only update to be the redirect if it is not the current redirect and isRedirect is true
      let redirect: 'delete' | 'update' | undefined = undefined
      if (isSectionRedirect && !isRedirect) redirect = 'delete'
      if (!isSectionRedirect && isRedirect) redirect = 'update'

      editSection({
        _links: section._links,
        imageData,
        sectionData: {
          label,
          published,
          href: href,
          mode: section.mode,
          type: section.type,
          styles,
        },
        redirect,
      })
    },
    [editSection, section, isSectionRedirect]
  )

  useEffect(() => {
    const section = `$t(creator:sections.${type})`
    if (isEditSuccess) {
      navigate(ROUTES.MY_PAGE)
      toast.success(
        <ToastShareWrapper
          message={t('creator:toast.edit_success', { section })}
          href={creatorUrlBuilders.creatorUrlBuilder({
            creatorSlug: creatorPage._links.self.name,
          })}
          analyticsEventProps={{
            actionNamePrefix: 'edit_embed_section',
            category: ANALYTICS_CATEGORY.CONTENT,
          }}
        />
      )
    }

    if (isDeleteSuccess) {
      navigate(ROUTES.MY_PAGE)
      toast.success(t('creator:toast.delete_success', { section }))
    }

    if (isEditError) {
      if (isImageDownloadApiError(editError)) {
        toast.error(t('app:imageUploadError'))
      } else {
        toast.error(t('creator:toast.edit_error', { section }))
      }
    }

    if (isDeleteError) {
      toast.error(t('creator:toast.delete_error', { section }))
    }
  }, [
    isEditSuccess,
    isDeleteSuccess,
    isEditError,
    editError,
    isDeleteError,
    creatorPage,
    t,
    navigate,
    type,
  ])

  if (!section) return null

  return (
    <>
      <SectionEmbedForm
        type={type}
        handleSubmit={handleSubmit}
        updatePreview={updatePreview}
        handleDelete={() => toggleConfirm(!isConfirmOpen)}
        isLoading={isDeleteLoading || isEditLoading}
        initialValues={{
          embedURL: section._links?.about?.href || '',
          published: section.published,
          isRedirect: isSectionRedirect,
          styles: section.styles,
          label: section.label,
          tagline: section.tagline,
          imageData: getSectionImage(section),
        }}
      />
      {isConfirmOpen && (
        <Confirm
          onDismiss={() => toggleConfirm(false)}
          title={t('creator:confirm.title')}
          buttons={[
            {
              label: t('creator:confirm.cancel'),
              onClick: () => toggleConfirm(false),
              inverse: true,
            },
            {
              label: t('creator:confirm.delete'),
              onClick: () => {
                deleteSection({ path: section._links.self.href })
                toggleConfirm(false)
              },
            },
          ]}
        />
      )}
    </>
  )
}
