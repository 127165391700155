import { ChangeEvent, useMemo, useState, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDebouncedCallback } from 'use-debounce'
import { getCountries } from 'react-phone-number-input'

import {
  Box,
  Button,
  ButtonVariant,
  Flex,
  IconButton,
  SearchField,
  TextEllipsis,
  Theme,
  Typography,
  FlagSprite,
} from '@web-apps/ui-shared'
import { CountryType } from '@web-apps/utils-types'
import { Localisation } from '@web-apps/utils-shared'
import {
  StyledFlagImage,
  StyledContainer,
  StyledSeparator,
  StyledItem,
} from './CountrySelectionView.styles'

type Props = {
  handleSelect: (str: string) => void
  handleClose: () => void
}

const FEATURED_COUNTRIES = [
  'ES',
  'FR',
  'DE',
  'NL',
  'GB',
  'NO',
  'SE',
  'US',
  'CA',
  'AU',
]

export const CountrySelectionView = ({ handleSelect, handleClose }: Props) => {
  const searchRef = useRef<HTMLInputElement>(null)
  const { t } = useTranslation(['app'])
  const featuredCountries = useMemo(() => {
    return Localisation.getCountriesList({
      countriesISOCodes: FEATURED_COUNTRIES,
    })
  }, [])
  const countriesList: CountryType[] = useMemo(
    () => Localisation.getCountriesList({ countriesISOCodes: getCountries() }),
    []
  )
  const [results, setResults] = useState<CountryType[]>(countriesList)
  const [searchText, setSearchText] = useState('')

  const debouncedSearchCallback = useDebouncedCallback((query) => {
    if (query) {
      const filteredSearchItems = countriesList.filter((country) =>
        country.name.toLowerCase().includes(query.toLowerCase())
      )
      return setResults(filteredSearchItems.length ? filteredSearchItems : [])
    } else {
      setResults(countriesList)
    }
  }, 100)

  const handleReset = () => {
    setSearchText('')
    setResults(countriesList)
  }

  useEffect(() => {
    if (searchRef.current) {
      searchRef.current.focus()
    }
  }, [])

  return (
    <div>
      <Flex align="center" justify="space-between">
        <Typography variant="h3">
          {t('app:add_audience_country.title_selection')}
        </Typography>
        <IconButton
          title="X"
          icon="Close"
          onClick={handleClose}
          iconProps={{ fillColor: Theme.Colors.typography.light }}
        />
      </Flex>
      <Box mt={24} mb={16}>
        <SearchField
          placeholder={t('app:add_audience_country.placeholder_selection')}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            setSearchText(event.target.value)
            debouncedSearchCallback(event.target.value)
          }}
          tabIndex={1}
          onReset={handleReset}
          ref={searchRef}
          value={searchText}
          name="countries"
        />
      </Box>
      <StyledContainer>
        {searchText === '' && (
          <>
            <Box mb={16}>
              <Typography variant="subTitle" color="inactive">
                {t('app:add_audience_country.popular')}
              </Typography>
            </Box>
            <Box mb={16}>
              {featuredCountries.map((country, index) => (
                <StyledItem mb={16} key={country.countryISOCode + index}>
                  <Button
                    width="100%"
                    variant={ButtonVariant.PLAIN}
                    clickHandler={() => handleSelect(country.countryISOCode)}
                  >
                    <Flex align="center">
                      <StyledFlagImage
                        aria-label={country.name}
                        $countryISOCode={country.countryISOCode}
                        $imgFile={FlagSprite}
                      />
                      <TextEllipsis>
                        <Typography variant="navigation">
                          {country.name}
                        </Typography>
                      </TextEllipsis>
                    </Flex>
                  </Button>
                </StyledItem>
              ))}
            </Box>
            <StyledSeparator />
            <Box mt={16} mb={16}>
              <Typography variant="subTitle" color="inactive">
                {t('app:add_audience_country.all_countries')}
              </Typography>
            </Box>
          </>
        )}
        <div>
          {results.map((country, index) => (
            <StyledItem mb={16} key={index + country.countryISOCode}>
              <Button
                width="100%"
                variant={ButtonVariant.PLAIN}
                clickHandler={() => handleSelect(country.countryISOCode)}
              >
                <Flex align="center">
                  <StyledFlagImage
                    aria-label={country.name}
                    $countryISOCode={country.countryISOCode}
                    $imgFile={FlagSprite}
                  />
                  <TextEllipsis>
                    <Typography variant="navigation">{country.name}</Typography>
                  </TextEllipsis>
                </Flex>
              </Button>
            </StyledItem>
          ))}
        </div>
      </StyledContainer>
    </div>
  )
}
