import styled from 'styled-components'
import { Theme } from '@web-apps/ui-shared'

export const StyleContainer = styled.div`
  padding: 16px;
  z-index: 10;

  position: fixed;
  left: 32px;
  right: 32px;
  bottom: 20px;

  background: ${Theme.Tooltip.background.dark};
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`

export const StyledArrow = styled.div`
  position: absolute;
  width: 24px;
  height: 24px;
  left: calc(50% - 24px / 2 - 4.5px);
  bottom: -8px;

  background: ${Theme.Tooltip.background.dark};
  border-radius: 4px;
  transform: rotate(-45deg);
  z-index: -1;
`

export const StyledLogo = styled.div`
  background: white;
  border-radius: 8px;
  padding: 8px;
  width: 40px;
  height: 40px;
`

export const StyledDescription = styled.div`
  display: flex;
  gap: 8px;
  padding: 8px 16px;
  background: rgba(255, 255, 255, 0.1);
  color: ${Theme.Colors.typography.inverse};
  border-radius: 8px;
  font-size: 14px;
  line-height: 24px;
  width: 100%;
  white-space: nowrap;
`

export const StyledCloseButton = styled.div`
  cursor: pointer;
  position: absolute;
  top: 8px;
  right: 8px;
  margin: 3px;
`
