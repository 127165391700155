import styled from 'styled-components'
import { Theme, CountryFlag, Box } from '@web-apps/ui-shared'

export const StyledFlagImage = styled(CountryFlag)`
  margin-right: 8px;
  vertical-align: middle;
`
export const StyledContainer = styled.div`
  height: min(70vh, 505px);
  overflow: auto;
  margin: 0 -24px;
  padding: 0 24px;
`

export const StyledSeparator = styled.div`
  height: 1px;
  background: ${Theme.Colors.line};
`

export const StyledItem = styled(Box)`
  &:last-child {
    margin-bottom: 0;
  }
`
