import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactGA from 'react-ga4'

import {
  Box,
  Button,
  Dialog,
  DialogProps,
  Flex,
  Icon,
  IconButton,
  Theme,
} from '@web-apps/ui-shared'
import { AffiliateLinkType, ANALYTICS_CATEGORY } from '@web-apps/utils-types'

import { ProductForm } from '../../../../../../MyPage/SectionProductRecommendationsForm/ProductForm.component'
import { ProductFormType } from '../../../../../../MyPage/SectionProductRecommendationsForm/SectionProductionRecommendations.types'
import { useAccountDetails } from '../../../../../../../../utils/hooks/useAccountDetails.hooks'
import { AddToMyPage } from '../../../../AddToMyPage'

type AddSectionDialogProps = Omit<
  DialogProps,
  'title' | 'children' | 'isOpen'
> & {
  selectedLink: AffiliateLinkType
  onSuccess: () => void
  onDismiss: () => void
}

export const AddAffiliateAsSectionDialog = ({
  selectedLink,
  onSuccess,
  onDismiss,
  ...dialogProps
}: AddSectionDialogProps) => {
  const { t } = useTranslation(['brands'])

  const { pageId } = useAccountDetails()

  const dialogRef = useRef<HTMLDivElement>(null)
  const [productData, setProductData] = useState<ProductFormType>()

  /* clearing it when the dialog is changed */
  useEffect(() => {
    if (selectedLink) {
      setProductData(undefined)
    }
  }, [selectedLink])

  /* push the dialog scroll up when showing grids overlay */
  useEffect(() => {
    if (productData && dialogRef.current) {
      dialogRef.current.scrollTo({ top: 0 })
    }
  }, [productData])

  return (
    <Dialog
      ref={dialogRef}
      title={
        selectedLink?.brandName || t('brands:product_dialog.fallback_title')
      }
      ariaLabel={
        selectedLink?.brandName || t('brands:product_dialog.arial_label')
      }
      isOpen={Boolean(selectedLink)}
      onDismiss={onDismiss}
      showHeaderBorder={false}
      showCloseButton={!productData}
      leftHeaderDecorator={
        productData ? (
          <IconButton
            title="<-"
            icon="ArrowBack"
            onClick={() => setProductData(undefined)}
            iconProps={{ fillColor: Theme.Colors.typography.light }}
          />
        ) : undefined
      }
      {...dialogProps}
    >
      {selectedLink && (
        <>
          <div style={{ display: productData ? 'none' : 'block' }}>
            <Box mt={24}>
              <ProductForm
                productBrand={undefined}
                pageId={pageId}
                initialValues={{
                  href: selectedLink.originalUrl,
                  title: selectedLink.title || '',
                  image: selectedLink.imageUrl
                    ? { hasImageSource: true, href: selectedLink.imageUrl }
                    : undefined,
                }}
                isURLReadOnly
                affiliateLinkId={selectedLink.id}
                handleSubmit={(productData) => setProductData(productData)}
                validationDescriptorsOverrides={{
                  title: {
                    required: t('app:field_is_required'),
                  },
                  image: undefined,
                }}
                renderActionsFooter={(formInfo) => (
                  <Flex direction="column" gap="16">
                    <Button
                      width="100%"
                      isLoading={formInfo.isLoading}
                      clickHandler={() => {
                        ReactGA.event({
                          action:
                            'affiliates:add_to_my_page_button_inner:click',
                          category: ANALYTICS_CATEGORY.AFFILIATES,
                        })
                      }}
                    >
                      <Flex gap={12} justify="center" align="center">
                        <Icon.PlusCircle
                          width={20}
                          height={20}
                          fillColor={Theme.Colors.typography.inverse}
                        />
                        {t('brands:product_dialog.add_to_my_page_button')}
                      </Flex>
                    </Button>
                  </Flex>
                )}
              />
            </Box>
          </div>

          {productData && (
            <AddToMyPage
              product={productData}
              api={{
                addToGrid: selectedLink.api.addToGrid,
                addAsSection: selectedLink.api.addAsSection,
              }}
              onSuccess={onSuccess}
              onError={onDismiss}
            />
          )}
        </>
      )}
    </Dialog>
  )
}
