import { createGlobalStyle } from 'styled-components'

import { resetStyleCss, Theme } from '@web-apps/ui-shared'

const GlobalStyle = createGlobalStyle`
  ${resetStyleCss}

   html {
     scroll-behavior: smooth;
   }

  select {
    background-color: transparent;
  }

  a {
    text-decoration: none;
  }

  body {
    background-color: ${Theme.CreatorApp.backgroundColor}
  }

  input {
    margin: 0;
  }
`

export default GlobalStyle
