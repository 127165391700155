import { ReactCropProps } from 'react-image-crop'

import { UploadedImageType } from '@web-apps/utils-types'

const getImageFromUrl = (url: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', reject)
    image.src = url
  })

const getBlobFromCanvas = (
  canvas: HTMLCanvasElement,
  file: Blob
): Promise<Blob> =>
  new Promise((resolve, reject) => {
    canvas.toBlob((blob) => {
      if (blob) {
        resolve(blob)
      } else {
        reject(new Error('Canvas is empty'))
      }
    }, file.type)
  })

const getBase64FromCanvas = (canvas: HTMLCanvasElement, file: Blob) => {
  return canvas.toDataURL(file.type, 1)
}

export const cropImage = async (
  { imageUrl, imageFile }: UploadedImageType,
  crop: ReactCropProps['crop']
): Promise<UploadedImageType | undefined> => {
  if (
    typeof crop.width === 'undefined' ||
    typeof crop.x === 'undefined' ||
    typeof crop.y === 'undefined'
  )
    return

  const image = await getImageFromUrl(imageUrl)
  const croppedSide = (image.naturalWidth / 100) * crop.width

  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  canvas.width = croppedSide
  canvas.height = croppedSide

  if (ctx) {
    ctx.drawImage(
      image,
      (image.naturalWidth / 100) * crop.x,
      (image.naturalHeight / 100) * crop.y,
      croppedSide,
      croppedSide,
      0,
      0,
      croppedSide,
      croppedSide
    )
  }

  return {
    imageUrl: getBase64FromCanvas(canvas, imageFile),
    imageFile: await getBlobFromCanvas(canvas, imageFile),
  }
}
