import { useCallback, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Box, Button, Icon, Typography, Flex } from '@web-apps/ui-shared'
import {
  ResetPassword,
  UseFormLink,
  AuthPageWrapper,
  AuthFormWrapper,
} from '@web-apps/feature-auth'

import { ROUTES } from '../../../routes/RouteEnums'
import { getEnvironmentConfigs } from '../../../services/utility'

export const ResetPasswordPage = () => {
  const navigate = useNavigate()
  const { t } = useTranslation(['auth'])
  const { search } = useLocation()
  const searchParams = useMemo(() => new URLSearchParams(search), [search])

  const [isSuccessful, setIsSuccessful] = useState(false)

  const onError = (error: string) => toast.error(error)

  const redirectTo = useCallback(() => {
    navigate(ROUTES.PERSONAL_SETTINGS_CHANGE_PASSWORD)
  }, [navigate])

  return (
    <div>
      <AuthPageWrapper
        homePage={getEnvironmentConfigs().homePage}
        partner={searchParams.get('partner')}
      >
        <AuthFormWrapper
          title={t('auth:reset_password.title')}
          description={
            isSuccessful ? undefined : t('auth:reset_password.description')
          }
        >
          {isSuccessful ? (
            <>
              <Typography color="light" textAlign="center">
                <Box mb={10}>
                  <Flex align="center" direction="column">
                    <Icon.RoundedCheck />
                  </Flex>
                </Box>
                {t('auth:reset_password.change_password_successful')}
              </Typography>
              <Box mt={24}>
                <Link to={ROUTES.LOGIN}>
                  <Button width="100%">
                    {t('auth:reset_password.link_to_login')}
                  </Button>
                </Link>
              </Box>
            </>
          ) : (
            <ResetPassword
              onReset={() => setIsSuccessful(true)}
              onError={onError}
              redirectTo={redirectTo}
            />
          )}
        </AuthFormWrapper>

        {!isSuccessful && (
          <Box my={32}>
            <UseFormLink withBoldLink>
              <Link to={ROUTES.LOGIN + (search || '')}>
                <>{t('auth:reset_password.login')}</>
              </Link>
            </UseFormLink>
          </Box>
        )}
      </AuthPageWrapper>
    </div>
  )
}
