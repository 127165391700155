import styled from 'styled-components'
import { Card } from '@web-apps/ui-shared'

export const StyledCard = styled(Card)`
  display: flex;
  align-items: center;
  gap: 16px;
`

export const StyleListItem = styled.li`
  margin-bottom: 16px;
`
