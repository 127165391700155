import { Suspense, ReactNode } from 'react'
import { LoadingIndicator } from '../../atoms'

type Props = {
  children: ReactNode
  fallback?: ReactNode
}

export const SuspenseWrapper = ({ children, fallback }: Props) => {
  return (
    <Suspense fallback={fallback || <LoadingIndicator size={100} isCentered />}>
      {children}
    </Suspense>
  )
}
