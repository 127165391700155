import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { useAddCreatorPageSectionAtomicMutation } from '@web-apps/feature-creator-page'
import {
  creatorUrlBuilders,
  EmbedProvidersCategory,
  isImageDownloadApiError,
} from '@web-apps/utils-shared'
import {
  ANALYTICS_CATEGORY,
  ExternalLinkMode,
  SectionExtension,
  SectionTypeEnum,
  SectionVariantEnum,
} from '@web-apps/utils-types'
import { editDraftEmbedExternalSection } from '../../../../store/creator/preview.slice'
import { ROUTES } from '../../../../routes/RouteEnums'
import { useDraftSection } from '../hooks/preview.hooks'
import { EmbedSectionSubmitData, SectionEmbedForm } from './SectionEmbedForm'
import { useAccountDetails } from '../../../../utils/hooks/useAccountDetails.hooks'
import { ToastShareWrapper } from '../../../../components'
import { sectionLimitReached } from '../../../../utils/helpers/validations.helpers'

type Props = {
  type: EmbedProvidersCategory.MUSIC | EmbedProvidersCategory.VIDEO
}

export const AddSectionEmbed = ({ type }: Props) => {
  const { t } = useTranslation(['app', 'creator'])
  const navigate = useNavigate()
  const { pageId, slug: creatorSlug } = useAccountDetails()

  const updatePreview = useDraftSection('draft', editDraftEmbedExternalSection)

  const [addSection, { isSuccess, isError, error, isLoading }] =
    useAddCreatorPageSectionAtomicMutation()

  const handleSubmit = ({
    published,
    isRedirect,
    label,
    href,
    styles,
    imageData,
  }: EmbedSectionSubmitData) => {
    addSection({
      pageId: pageId || '',
      imageData,
      sectionData: {
        label,
        published,
        href: href,
        type: SectionTypeEnum.EXTERNAL_LINK,
        mode: ExternalLinkMode.EMBED,
        styles,
      },
      redirect: isRedirect,
    })
  }

  useEffect(() => {
    if (isSuccess) {
      navigate(ROUTES.MY_PAGE)
      toast.success(
        <ToastShareWrapper
          message={t('creator:toast.add_success', {
            section: `$t(creator:sections.${type})`,
          })}
          href={creatorUrlBuilders.creatorUrlBuilder({ creatorSlug })}
          analyticsEventProps={{
            actionNamePrefix: 'add_embed_section',
            category: ANALYTICS_CATEGORY.CONTENT,
          }}
        />
      )
    }
  }, [isSuccess, creatorSlug, t, navigate, type])

  useEffect(() => {
    if (!isError) return

    if (isImageDownloadApiError(error)) {
      toast.error(t('app:imageUploadError'))
      return
    }

    if (sectionLimitReached(error)) {
      toast.error(t('creator:toast.section_limit_reached'))
      return
    }

    toast.error(
      t('creator:toast.add_error', {
        section: `$t(creator:sections.${type})`,
      })
    )
  }, [isError, error, t, type])

  return (
    <SectionEmbedForm
      type={type}
      handleSubmit={handleSubmit}
      updatePreview={updatePreview}
      isLoading={isLoading}
      initialValues={{
        embedURL: '',
        published: true,
        isRedirect: false,
        styles: {
          extension: SectionExtension.NONE,
          variant: SectionVariantEnum.PLAIN,
        },
      }}
    />
  )
}
