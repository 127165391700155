import { useState, useEffect, Dispatch, SetStateAction } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

export const useReturnLink = (
  baseLink: string
): [string, Dispatch<SetStateAction<string>>] => {
  const location = useLocation()
  const navigate = useNavigate()
  const [returnUrl, setReturnUrl] = useState(baseLink)

  useEffect(() => {
    if (location.state) {
      setReturnUrl(location.state)
      navigate(location.pathname, { replace: true })
    }
  }, [location.pathname, location.state, navigate])

  return [returnUrl, setReturnUrl]
}
