import styled from 'styled-components'
import { Flex, Theme } from '@web-apps/ui-shared'

export const StyledDescription = styled(Flex)`
  border-radius: 8px;
  padding: 16px;
  background: ${Theme.Colors.background.global};
`

export const StyledCell = styled(Flex)`
  width: 100%;
`

export const StyledRow = styled(Flex)`
  border-top: 1px solid ${Theme.Colors.border.darker};

  &:first-child {
    border-top: none;
  }
`
