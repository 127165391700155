import { applyMiddleware, compose, createStore } from 'redux'
import promise from 'redux-promise-middleware'
import thunk from 'redux-thunk'
import { setupListeners } from '@reduxjs/toolkit/query'

import { reduxUnexpectedErrorsLogger } from '@web-apps/utils-shared'

import reducers from '../store/reducers'
import errorMiddleware from '../services/error'

import { api } from '../services/api'

type ComposeEnhancer = typeof compose

export const configureStore = () => {
  const composeEnhancers =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ((window as any)[
      '__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'
    ] as ComposeEnhancer) || compose

  const middleware = [
    thunk,
    errorMiddleware,
    promise,
    reduxUnexpectedErrorsLogger,
    api.middleware,
  ]

  return createStore(reducers, composeEnhancers(applyMiddleware(...middleware)))
}

export const store = configureStore()

setupListeners(store.dispatch)

export type GlobalState = ReturnType<typeof store.getState>
