export enum ROUTES {
  LOGIN = '/login',
  REGISTER = '/register',
  RESET_PASSWORD = '/reset-password',
  LOGOUT = '/logout',
  PERSONAL_SETTINGS = '/personal-settings',
  PERSONAL_SETTINGS_CHANGE_PASSWORD = '/personal-settings?change-password',
  ANALYTICS = '/analytics',
  ANALYTICS_EARNINGS = '/analytics/earnings',
  ANALYTICS_PAGE = '/analytics/page',
  ANALYTICS_PAYMENT_DETAILS = '/analytics/payment-details',
  AFFILIATE_LINK_ANALYTICS_DETAILS = '/analytics/affiliate-link/:linkId',
  MY_PAGE = '/my-page',
  MY_PAGE_SETTINGS = '/my-page/settings',
  MY_PAGE_APPEARANCE = '/my-page/appearance',
  MY_PAGE_CUSTOM_LINK = '/my-page/add-link',
  MY_PAGE_CUSTOM_LINK_EDIT = '/my-page/link/:id',
  MY_PAGE_AFFILIATE_LINK_SECTION = '/my-page/add-affiliate-link',
  MY_PAGE_EDIT_AFFILIATE_LINK_SECTION = '/my-page/affiliate-link/:id',
  MY_PAGE_CONTENT_SECTION = '/my-page/add-content-section',
  MY_PAGE_EDIT_CONTENT_SECTION = '/my-page/content-section/:id',
  MY_PAGE_ADD_VIDEO = '/my-page/add-video',
  MY_PAGE_VIDEO_EDIT = '/my-page/video/:id',
  MY_PAGE_ADD_MUSIC = '/my-page/add-music',
  MY_PAGE_MUSIC_EDIT = '/my-page/music/:id',
  MY_PAGE_ADD_PRODUCT_RECOMMENDATIONS = '/my-page/product-recommendations',
  MY_PAGE_EDIT_PRODUCT_RECOMMENDATIONS = '/my-page/product-recommendations/:id',
  BRANDS = '/brands',
  BRAND_DIALOG_MODAL = '/brands/:brandId',
  TRENDING_PRODUCTS = '/brands/products',
  LINK_CONVERTER = '/brands/converter',
  ADMIN_BRAND_MANAGER = '/admin/brand-management',
  ADMIN_TOP_OFFERS = '/admin/brand-management/top-offers',
  ADMIN_BRANDS = '/admin/brand-management/brands',
  ADMIN_BRAND_EDIT = '/admin/brand-management/brands/:id',
  ADMIN_OFFERS = '/admin/brand-management/offers',
  ADMIN_OFFER_EDIT = '/admin/brand-management/offers/:id',
  DASHBOARD = '/dashboard',
}
