import { useEffect } from 'react'
import { AnyAction } from 'redux'
import { useDispatch, useSelector } from 'react-redux'
import { useDebouncedCallback } from 'use-debounce'

import {
  addDraftSection,
  editCurrentSectionId,
} from '../../../../store/creator/preview.slice'
import { GlobalState } from '../../../../store'

export const useDraftSection = (
  id = 'randomId',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  action: (actionPayload: any) => AnyAction,
  isExisted?: boolean
) => {
  const dispatch = useDispatch()
  const { currentSectionId } = useSelector(
    (state: GlobalState) => state.creator.preview
  )
  const updatePreview = useDebouncedCallback(
    (value) => dispatch(action({ ...value, id })),
    250
  )
  useEffect(() => {
    if (!currentSectionId) {
      // setTimeout needs to guarantee
      // that the draft section will be added after resetting the preview
      setTimeout(() =>
        dispatch(isExisted ? editCurrentSectionId(id) : addDraftSection(id))
      )
    }
  }, [currentSectionId, isExisted, id, dispatch])

  return updatePreview
}

export const useDebouncedUpdate = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  action: (actionPayload: any) => AnyAction
) => {
  const dispatch = useDispatch()

  return useDebouncedCallback((value) => {
    if (value) {
      dispatch(action({ ...value }))
    }
  }, 250)
}
