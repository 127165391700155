import styled from 'styled-components'

import { MY_PAGE_PREVIEW_ZINDEX } from '../../../../../utils/constants/theme.constants'
import { media, BREAKPOINTS, Theme, Button } from '@web-apps/ui-shared'

const DESKTOP_PREVIEW_HEIGHT = 654
const DESKTOP_PREVIEW_WIDTH = 368
const ADDITIONAL_HEIGHT = 160
const DESKTOP_PREVIEW_MIN_HEIGHT = 300

export const StyledMobilePreviewContainer = styled.div<{ $isOpen: boolean }>`
  width: 100%;
  transition: all 0.5s;
  transform: ${({ $isOpen }) =>
    $isOpen ? 'translateX(0)' : 'translateX(110%)'};
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${MY_PAGE_PREVIEW_ZINDEX};
  overflow: auto;

  background-color: ${Theme.Colors.background.base};
`

export const StyledMobileClose = styled(Button)`
  padding: 13px;
  border-radius: 50%;
  background: ${Theme.Colors.background.base};
  position: fixed;
  top: 16px;
  right: 16px;
  z-index: 5;
`

export const StyledMobilePreview = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
`

export const StyledPreviewContainer = styled.div`
  position: sticky;
  top: 112px;
  width: 100%;
  height: 1px;

  ${media.to(
    BREAKPOINTS.md,
    `
    display: none;
  `
  )}
`

/*
  This container is always 9:6 scale, and preview actual size is DESKTOP_PREVIEW_WIDTH:DESKTOP_PREVIEW_HEIGHT.
  We need to scale the whole container with the content to look proportional instead of shrinking or expanding the
  width of the preview itself.

  The proportion calculation formula is the following: we find the proportion between the parent container and preview
  proportion and scale down according to the result.
*/

const getPreviewScreenProportions = (
  width?: number,
  height?: number
): number => {
  if (!width || !height) return 1

  // If the height of the page does not fit the preview in the scale 1:1, we need to fit it in based on the height
  if (height - ADDITIONAL_HEIGHT < DESKTOP_PREVIEW_HEIGHT) {
    if (height - ADDITIONAL_HEIGHT < DESKTOP_PREVIEW_MIN_HEIGHT) {
      return Number(
        (DESKTOP_PREVIEW_MIN_HEIGHT / DESKTOP_PREVIEW_HEIGHT).toFixed(2)
      )
    }
    return Number(
      ((height - ADDITIONAL_HEIGHT - 20) / DESKTOP_PREVIEW_HEIGHT).toFixed(2)
    )
    // Then, if the height is ok, but the width doesn't fit, we scale down based on width
  } else if (width < DESKTOP_PREVIEW_WIDTH) {
    return Number((width / DESKTOP_PREVIEW_WIDTH).toFixed(2))
  }
  return 1
}

export const StyledPreviewContainerScale = styled.div<{
  $parentWrapperWidth?: number
  $pageHeight?: number
}>`
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(
    ${({ $parentWrapperWidth, $pageHeight }) =>
      getPreviewScreenProportions($parentWrapperWidth, $pageHeight)}
  );
  transform-origin: top left;
`

export const StyledPreview = styled.div`
  margin-top: 0;
  width: ${DESKTOP_PREVIEW_WIDTH}px;
  height: ${DESKTOP_PREVIEW_HEIGHT}px;
  border: 8px solid #333333;
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.25);
  border-radius: 32px;
  overflow: hidden;
  isolation: isolate;
`

export const StyledPreviewWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
`
