import React, { useEffect } from 'react'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Box, MetricSummaryPanel, Theme } from '@web-apps/ui-shared'
import {
  useGetCreatorPageAffiliateAnalyticsQuery,
  useGetCreatorPageSimpleAnalyticsAggregationQuery,
} from '@web-apps/feature-creator-page'

import {
  AnalyticsPageTabSkeleton,
  SkeletonWrapper,
} from '../../../../components/skeletons'
import { useCreatorPage } from '../../../../utils/hooks/useCreatorPage.hooks'
import { useAccountDetails } from '../../../../utils/hooks/useAccountDetails.hooks'
import { PageAnalytics } from '../components/PageAnalytics'
import { PeriodSelector } from '../components/PeriodSelector'
import { useSearchPeriod } from '../hooks/useSelectorParams'
import { toURLWithParams } from '../Analytics.utils'

const StyledMetricSummaryContainer = styled.div`
  padding-top: 24px;
  border-radius: 8px 8px 0 0;
  border-top: 1px solid ${Theme.Colors.border.darker};
  border-left: 1px solid ${Theme.Colors.border.darker};
  border-right: 1px solid ${Theme.Colors.border.darker};
  background: ${Theme.Colors.background.base};
`

export const ViewsAnalyticsPage = () => {
  const { t } = useTranslation(['creator'], { useSuspense: false })

  const creatorPage = useCreatorPage()
  const { pageId } = useAccountDetails()
  const period = useSearchPeriod()

  const affiliateAnalyticsAPIPath = toURLWithParams({
    apiLink: creatorPage?._links.affiliate_analytics,
    period,
  })

  const {
    data: affiliatesAnalyticsData,
    isError: isAffiliateOverallAnalyticsError,
    isLoading: isAffiliateOverallAnalyticsLoading,
  } = useGetCreatorPageAffiliateAnalyticsQuery(
    { path: affiliateAnalyticsAPIPath as string },
    { skip: !affiliateAnalyticsAPIPath }
  )

  const {
    data: analyticsAggregation,
    isError: isAnalyticsAggregationError,
    isLoading: isAnalyticsAggregationLoading,
  } = useGetCreatorPageSimpleAnalyticsAggregationQuery(
    { pageId: pageId || '', period },
    { skip: !pageId }
  )

  useEffect(() => {
    if (isAffiliateOverallAnalyticsError || isAnalyticsAggregationError) {
      toast.error(t('creator:toast.page_error'))
    }
  }, [isAffiliateOverallAnalyticsError, isAnalyticsAggregationError, t])

  return (
    <>
      <Box mb={24}>
        {affiliatesAnalyticsData && (
          <PeriodSelector
            dates={{
              start: affiliatesAnalyticsData?.timeseries[0].date,
              end: affiliatesAnalyticsData?.timeseries[
                affiliatesAnalyticsData?.timeseries.length - 1
              ].date,
            }}
          />
        )}
      </Box>
      <SkeletonWrapper
        Skeleton={AnalyticsPageTabSkeleton}
        isLoading={
          isAnalyticsAggregationLoading || isAffiliateOverallAnalyticsLoading
        }
      >
        <StyledMetricSummaryContainer>
          <MetricSummaryPanel
            title={t('app:header.analytics.tabs.page_views.title')}
            value={analyticsAggregation?.aggregate.pageviews || '0'}
          />
        </StyledMetricSummaryContainer>
        <PageAnalytics />
      </SkeletonWrapper>
    </>
  )
}
