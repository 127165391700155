import { useEffect, useState, useRef, MouseEventHandler } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation, Trans } from 'react-i18next'
import { toast } from 'react-toastify'
import { useDebouncedCallback } from 'use-debounce'

import {
  Form,
  TextField,
  FormArgsType,
  Typography,
  Flex,
  Switch,
  SwitchVariant,
  Box,
  ShowHideAnimationWrapper,
  MAX_TITLE_LENGTH,
} from '@web-apps/ui-shared'
import {
  EmbedProvidersCategory,
  getEmbedUrlDetails,
  isUrlSupported,
  EMBED_PROVIDERS_MAP,
  FormValidations,
} from '@web-apps/utils-shared'
import {
  ImageFormType,
  SectionBasePropertiesType,
  SectionExtension,
  SectionVariantEnum,
} from '@web-apps/utils-types'

import {
  FormActionsBar,
  FormFieldBox,
  RadioInput,
} from '../../../../components'
import {
  StyledFormContainer,
  StyledFormLabel,
} from '../../../../components/styles'
import { ROUTES } from '../../../../routes/RouteEnums'
import { useURLInputHandlers } from '../hooks/useURLInputHandlers.hook'
import {
  DeleteBlock,
  PublishedRedirectSectionControls,
  SectionExpandableStyleFormBlock,
  SectionStyleFormBlock,
} from '../components'

export type EmbedSectionFormData = {
  label?: string
  tagline?: string
  embedURL: string
  published: boolean
  isRedirect: boolean
  variant?: SectionVariantEnum
  styles: SectionBasePropertiesType['styles']
  videoType?: VideoTypeEnum
  imageData?: ImageFormType
}

export type EmbedSectionSubmitData = Omit<EmbedSectionFormData, 'embedURL'> & {
  label: string
  tagline?: string
  href: string
  styles: SectionBasePropertiesType['styles']
  videoType?: VideoTypeEnum
  imageData?: ImageFormType
}

type Props = {
  type: EmbedProvidersCategory.MUSIC | EmbedProvidersCategory.VIDEO
  handleSubmit: (d: EmbedSectionSubmitData) => void
  handleDelete?: () => void
  updatePreview: (d: {
    data: {
      href?: string | null
      published: boolean
      label?: string
      tagline?: string
      imageData?: ImageFormType
      styles: SectionBasePropertiesType['styles']
    }
  }) => void
  isLoading: boolean
  initialValues: EmbedSectionFormData
}

enum VideoTypeEnum {
  LINK = 'video-link',
  DISPLAY = 'video-display',
}

const VIDEO_VARIANTS = [VideoTypeEnum.DISPLAY, VideoTypeEnum.LINK]

export const SectionEmbedForm = ({
  type,
  handleSubmit,
  handleDelete,
  initialValues,
  isLoading,
  updatePreview,
}: Props) => {
  const navigate = useNavigate()
  const subscription = useRef<ReturnType<FormArgsType['watch']>>()
  const [hasTagline, toggleTagline] = useState(!!initialValues?.tagline)
  const { t } = useTranslation(['app', 'creator'])
  const [isLoadingInfo, setLoadingInfo] = useState(false)

  const debounceUpdatePreview = useDebouncedCallback(
    async ({ embedURL, ...rest }) => {
      if (!FormValidations.isValidWebURL(embedURL)) return

      const result = await getEmbedUrlDetails(embedURL)

      updatePreview({ data: { href: result?.url || null, ...rest } })
    },
    250
  )
  const { onPaste, onChangeBuilder, onBlurBuilder, onKeyPressBuilder } =
    useURLInputHandlers({})

  const handleCancel: MouseEventHandler<HTMLElement> = (event) => {
    event.preventDefault()
    navigate(ROUTES.MY_PAGE)
  }

  const handleSubmitWrapper = async ({
    embedURL,
    videoType,
    ...data
  }: EmbedSectionFormData) => {
    try {
      setLoadingInfo(true)
      const result = await getEmbedUrlDetails(embedURL)

      if (!result?.title || !result?.url) throw new Error('invalid-info')

      handleSubmit({
        ...data,
        label: data.label || result.title.substring(0, 100),
        href: result.url,
        styles: {
          ...data.styles,
          is_video_link: videoType === VideoTypeEnum.LINK,
        },
      })
    } catch {
      toast.error(t(`creator:embed_section.${type}.error`))
    } finally {
      setLoadingInfo(false)
    }
  }

  useEffect(() => () => subscription?.current?.unsubscribe(), [])

  return (
    <Form
      initialWatchableFields={['embedURL', 'published', 'styles']}
      initialValues={
        initialValues && {
          ...initialValues,
          videoType:
            type === EmbedProvidersCategory.VIDEO &&
            initialValues.styles?.['is_video_link']
              ? VideoTypeEnum.LINK
              : VideoTypeEnum.DISPLAY,
        }
      }
      onSubmit={handleSubmitWrapper}
      validations={{
        descriptors: {
          embedURL: {
            required: t('app:field_is_required'),
            validate: (fieldValue) => {
              const isValid = isUrlSupported(
                fieldValue,
                EMBED_PROVIDERS_MAP[type]
              )

              if (typeof isValid === 'string') {
                return t(`creator:embed_errors.${isValid}`)
              }

              return (
                isValid ||
                t(`creator:embed_section.${type}.invalid_url_message`)
              )
            },
          },
        },
      }}
    >
      {(formProps) => {
        const { control, getValues, setValue, watch, register, unregister } =
          formProps

        const setURLValue = (value: string) => setValue('embedURL', value)
        if (!subscription.current)
          subscription.current = watch(debounceUpdatePreview)

        return (
          <>
            <StyledFormContainer>
              <FormFieldBox mb={type === EmbedProvidersCategory.VIDEO ? 24 : 0}>
                <TextField
                  name="embedURL"
                  label={t(`creator:embed_section.${type}.url_label`)}
                  placeholder={t(`creator:embed_section.${type}.placeholder`)}
                  onPaste={onPaste}
                  onChange={onChangeBuilder(setURLValue)}
                  onBlur={onBlurBuilder(setURLValue)}
                  onKeyPress={onKeyPressBuilder(
                    getValues('embedURL'),
                    setURLValue
                  )}
                />
                <Box mt={8}>
                  <Typography variant="hint" color="inactive">
                    <Trans
                      i18nKey={`creator:embed_section.${type}.info_message`}
                    />
                  </Typography>
                </Box>
              </FormFieldBox>

              {type === EmbedProvidersCategory.VIDEO && (
                <FormFieldBox
                  mb={0}
                  title={t('creator:embed_section.video.display_options.label')}
                >
                  <Flex direction="column" gap={8}>
                    {VIDEO_VARIANTS.map((v) => (
                      <RadioInput
                        key={v}
                        name="videoType"
                        label={t(
                          `creator:embed_section.video.display_options.option_labels.${v}`
                        )}
                        value={v}
                      />
                    ))}
                  </Flex>
                </FormFieldBox>
              )}
            </StyledFormContainer>
            <StyledFormContainer>
              <FormFieldBox
                mb={0}
                title={t('creator:custom_link.title_section_button_style')}
              >
                <SectionExpandableStyleFormBlock formReturnProps={formProps} />
                <ShowHideAnimationWrapper
                  in={
                    getValues('styles.extension') === SectionExtension.ACCORDION
                  }
                >
                  <>
                    <SectionStyleFormBlock formReturnProps={formProps} />
                    <FormFieldBox mt={24}>
                      <TextField
                        name="label"
                        label={t('creator:custom_link.field_label_title')}
                        showAllowedChars
                        maxLength={MAX_TITLE_LENGTH}
                      />
                    </FormFieldBox>
                    <Flex justify="space-between" align="center">
                      <StyledFormLabel>
                        {t('creator:custom_link.field_label_tagline')}
                      </StyledFormLabel>
                      <Switch
                        name="uncontrolled_hasTagline"
                        variant={SwitchVariant.SMALL}
                        onChange={() => {
                          if (hasTagline) {
                            setValue('tagline', undefined)
                            unregister('tagline')
                          }
                          toggleTagline(!hasTagline)
                        }}
                        checked={hasTagline}
                      />
                    </Flex>
                    <ShowHideAnimationWrapper in={hasTagline}>
                      <Box mt={8}>
                        <TextField
                          showAllowedChars
                          name="tagline"
                          maxLength={100}
                          {...(hasTagline &&
                            register('tagline', {
                              required: t('app:field_is_required'),
                            }))}
                        />
                      </Box>
                    </ShowHideAnimationWrapper>
                  </>
                </ShowHideAnimationWrapper>
              </FormFieldBox>
            </StyledFormContainer>
            <PublishedRedirectSectionControls
              formProps={{
                control,
                getValues,
                setValue,
              }}
            />
            <DeleteBlock handleDelete={handleDelete} />
            <FormActionsBar
              hasHeaderSaveButton
              secondaryAction={handleCancel}
              secondaryActionName={t('app:button_discard')}
              isLoading={isLoading || isLoadingInfo}
            />
          </>
        )
      }}
    </Form>
  )
}
