import { CSSProperties } from 'react'
import styled from 'styled-components'

import { media, BREAKPOINTS, Theme } from '@web-apps/ui-shared'

export const StyledChartContainer = styled.div`
  margin-block-start: 8px;
  height: clamp(150px, 50vw, 300px);
  min-height: 150px;
  max-height: 300px;

  /* TODO: look for a better way -- not find how to change it colors and font-size of text legend on documentation */

  .recharts-legend-item-text {
    color: ${Theme.Colors.typography.inactive} !important;
    font-size: 10px;
  }

  ${media.from(
    BREAKPOINTS.md,
    `
    margin-block-start: 16px;
    `
  )}
`

export const StyledSquareLegendIcon = styled.div<{
  $color: CSSProperties['color']
}>`
  width: 8px;
  height: 8px;

  background: ${({ $color }) => $color};
`

export const StyledLineLegendIcon = styled.div<{
  $color: CSSProperties['color']
}>`
  width: 8px;
  height: 2px;

  background: ${({ $color }) => $color};
`

export const StyledTooltipStatsLineContainer = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  line-height: 16px;
`
