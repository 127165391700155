import { useState, useEffect } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { toast } from 'react-toastify'
import ReactGA from 'react-ga4'

import {
  Box,
  Button,
  ButtonVariant,
  Dialog,
  Flex,
  Form,
  FormFieldController,
  Icon,
  Typography,
  FlagSprite,
} from '@web-apps/ui-shared'
import { Localisation } from '@web-apps/utils-shared'
import { FormFieldBox } from '../Form'
import { useUpdateAffiliateSettingsMutation } from '../../services/affiliates.api'
import { CountrySelectionView } from './CountrySelectionView'
import {
  StyledDescription,
  StyledButton,
  StyledFlagImage,
} from './AddAudienceCountry.styles'
import { ANALYTICS_CATEGORY } from '@web-apps/utils-types'

export const AddAudienceCountry = () => {
  const { t } = useTranslation(['app'])
  const [isDescriptionOpen, setIsDescriptionOpen] = useState(false)
  const [isCountrySelectionOpen, setIsCountrySelection] = useState(false)
  const [updateSettings, { data, isError, isSuccess, isLoading }] =
    useUpdateAffiliateSettingsMutation()

  useEffect(() => {
    if (isError) {
      toast.error(t('app:add_audience_country.error'))
    }

    if (isSuccess) {
      toast.success(t('app:add_audience_country.success'))
      ReactGA.event({
        action: 'add_audience_country:banner:been_set',
        category: ANALYTICS_CATEGORY.APPLICATION,
      })
    }
  }, [isError, isSuccess, t, data])

  useEffect(() => {
    ReactGA.event({
      action: 'add_audience_country:banner:view',
      category: ANALYTICS_CATEGORY.APPLICATION,
    })
  }, [])

  return (
    <Dialog
      ariaLabel={t('app:add_audience_country.title')}
      isOpen={true}
      showHeaderBorder={false}
      showCloseButton={false}
      maxWidthNotMobile="375px"
    >
      <Form
        onSubmit={updateSettings}
        validations={{
          descriptors: {
            region: {
              required: t('app:field_is_required'),
            },
          },
        }}
      >
        {({ control, getValues, setValue }) => {
          const isDisabled = !getValues('region')

          return isCountrySelectionOpen ? (
            <CountrySelectionView
              handleSelect={(region) => {
                setIsCountrySelection(false)
                setValue('region', region)
              }}
              handleClose={() => setIsCountrySelection(false)}
            />
          ) : (
            <Flex direction="column">
              <Box mb={24}>
                <Typography variant="h3">
                  {t('app:add_audience_country.title')}
                </Typography>
              </Box>
              <Box mb={16}>
                <Box mb={8}>
                  <Button
                    type="button"
                    variant={ButtonVariant.PLAIN}
                    clickHandler={() =>
                      setIsDescriptionOpen(!isDescriptionOpen)
                    }
                  >
                    <Flex>
                      <Typography fontWeight={500}>
                        {t('app:add_audience_country.description_title')}
                      </Typography>
                      <Icon.Chevron
                        style={{
                          transform: isDescriptionOpen
                            ? 'rotate(180deg)'
                            : 'rotate(0)',
                          transition: 'all 0.4s ease 0s',
                        }}
                      />
                    </Flex>
                  </Button>
                </Box>
                <StyledDescription $isOpen={isDescriptionOpen}>
                  <Box p={16}>
                    <Typography>
                      <Trans
                        i18nKey={t('app:add_audience_country.description')}
                      />
                    </Typography>
                  </Box>
                </StyledDescription>
              </Box>
              <FormFieldBox>
                <FormFieldController
                  name="region"
                  render={({ field }) => {
                    return (
                      <StyledButton
                        type="button"
                        variant={ButtonVariant.OUTLINE}
                        clickHandler={() => setIsCountrySelection(true)}
                      >
                        {field.value ? (
                          <Flex align="center">
                            <StyledFlagImage
                              aria-label={''}
                              $imgFile={FlagSprite}
                              $countryISOCode={field.value}
                            />
                            {Localisation.getCountryNameFromCountryISOCode({
                              countryISOCode: field.value,
                            })}
                          </Flex>
                        ) : (
                          <Typography color="inactive">
                            {t('app:add_audience_country.placeholder')}
                          </Typography>
                        )}
                        <Icon.Chevron />
                      </StyledButton>
                    )
                  }}
                  control={control}
                />
                <Box mt={8}>
                  <Typography variant="hint" color="inactive">
                    <Trans
                      i18nKey={t('app:add_audience_country.hint')}
                      components={{ strong: <b style={{ fontWeight: 600 }} /> }}
                    />
                  </Typography>
                </Box>
              </FormFieldBox>
              <Button type="submit" isLoading={isLoading} disabled={isDisabled}>
                {t('app:add_audience_country.cta')}
              </Button>
            </Flex>
          )
        }}
      </Form>
    </Dialog>
  )
}
