import styled from 'styled-components'

import {
  BREAKPOINTS,
  media,
  PlainButton,
  BasePopoverCSS,
  BasePopoverListCSS,
  Popover,
} from '@web-apps/ui-shared'

export const StyledContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;

  width: 100%;
`

export const StyledPopover = styled(Popover)`
  ${BasePopoverCSS};
  ${media.from(
    BREAKPOINTS.sm,
    `
      left: unset;
      right: unset;
    `
  )}
`

export const StyledList = styled.ul`
  ${BasePopoverListCSS}
`

export const StyledListItemButton = styled(PlainButton)`
  width: 100%;
  text-align: left;
`
