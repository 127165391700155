import { useEffect, useRef, useState } from 'react'
import { useFlagsStatus, useUnleashContext } from '@unleash/proxy-client-react'
import cookie from 'js-cookie'
import ReactGA from 'react-ga4'
import { useLocation } from 'react-router-dom'
import * as Sentry from '@sentry/browser'

import { useGetSession } from '@web-apps/feature-auth'
import { initCookies, CookieBannerVariantEnum } from '@web-apps/utils-shared'

import { config } from '../../../utils/helpers/config.helpers'
import { CREATE_ACCOUNT_DATA_COOKIE } from '../../../utils/constants/app.constants'
import { PageLayout } from '../../../layouts'
import { RegisterPage, PostRegisterPage } from '../RegisterPage'
import { NonAuthenticated } from './NonAuthenticated'
import { getEnvironmentConfigs } from '../../../services/utility'
import { useTranslation } from 'react-i18next'
import { Theme } from '@web-apps/ui-shared'

/*
 *
 * This component is checking if a user is authenticated or not and renders
 * according components: for logged-in users and non-logged in users.
 *
 * Additionally we check cookies to see if we need to register a user cause ory
 * identity has just been created and there are credentials in the cookies we
 * should use.
 *
 * `session` is an ory instance that keeps the user login state data and user ID
 *  that we need to use further in the app.
 *
 * */

export const Auth = () => {
  const { flagsReady } = useFlagsStatus()
  const { session, isSessionLoading } = useGetSession()
  const updateContext = useUnleashContext()
  const { t } = useTranslation(['app', 'auth'])

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const flow = searchParams.get('flow')
  const recoveryParam = searchParams.get('change-password')
  const postImportProfileData = searchParams.get('profile')

  const postOryRegistrationData = cookie.get(CREATE_ACCOUNT_DATA_COOKIE)
  const userId = session?.identity?.metadata_public?.user_id
  const email = session?.identity?.traits?.email

  const cookieConsentBannerContainerRef = useRef<HTMLDivElement>(null)
  const [cookiesStatus, setCookiesStatus] = useState(
    cookie.get('cookieconsent_status')
  )

  const isPending = !flagsReady || isSessionLoading

  useEffect(() => {
    if (!cookieConsentBannerContainerRef.current || isPending) return

    initCookies({
      containerElement: cookieConsentBannerContainerRef.current,
      cookieBannerVariant: CookieBannerVariantEnum.COOKIE,
      messages: {
        message: t('app:cookie_consent.message'),
        allow: t('app:cookie_consent.allow'),
        deny: t('app:cookie_consent.deny'),
        link: t('app:cookie_consent.link'),
        linkText: t('app:cookie_consent.link_text'),
        cookieBannerText: t('app:cookie_consent.title'),
      },
      cookieDomain: getEnvironmentConfigs().cookieDomain,
      host: window.location.host,
      onCookieStatusChange: (status: string) => setCookiesStatus(status),
      styles: {
        buttonBackgroundColor: Theme.CookieBanner.buttonBackgroundColor,
        mainButtonTextColor: Theme.CookieBanner.mainButtonTextColor,
        mainButtonBackgroundColor: Theme.CookieBanner.mainButtonBackgroundColor,
        buttonTextColor: Theme.CookieBanner.buttonTextColor,
        titleTextColor: Theme.CookieBanner.titleTextColor,
      },
    })
  }, [t, isPending])

  useEffect(() => {
    if (userId && email) {
      updateContext({
        userId,
        properties: {
          userEmail: email,
        },
      })
      Sentry.setUser({ id: userId, ip_address: undefined })
    }
  }, [userId, email, updateContext])

  useEffect(() => {
    if (userId && config.googleAnalyticsId && cookiesStatus === 'allow') {
      ReactGA.initialize([
        {
          trackingId: config.googleAnalyticsId,
          gaOptions: {
            userId,
          },
        },
      ])
    }
  }, [userId, cookiesStatus])

  useEffect(() => {
    ReactGA.send('pageview')
  }, [location])

  let contentToRender: JSX.Element | null = null
  if (isPending) contentToRender = null

  // Post ory zezam account creation flow or processing failed flow if ory sends
  // us one on the redirect after oid registration (but we don't have a session).
  if (
    (postOryRegistrationData && session) ||
    (flow && !session && !recoveryParam)
  )
    contentToRender = <RegisterPage session={session} />

  // This is handling of an edge case where users tried to log in with a fresh
  // oid and got registered instead.
  if (session && !userId)
    return (
      <div ref={cookieConsentBannerContainerRef}>
        <RegisterPage session={session} />
      </div>
    )

  if (postImportProfileData && session)
    return (
      <div ref={cookieConsentBannerContainerRef}>
        <PostRegisterPage />
      </div>
    )

  // User is not logged in
  if (!session && !isSessionLoading) contentToRender = <NonAuthenticated />

  // User is logged in
  if (session && userId) return <PageLayout userId={userId} session={session} />

  return <div ref={cookieConsentBannerContainerRef}>{contentToRender}</div>
}
