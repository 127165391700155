import { ImgHTMLAttributes, HTMLAttributes } from 'react'
import { Link } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'
import { CardProps } from '../../atoms'
import {
  StyledBaseCard,
  StyledBodyContainer,
  StyledContentContainer,
  StyledFooterContainer,
  StyledOverlayImage,
  StyledOverlayImageContainer,
} from './CustomCard.styles'

export type CustomCardProps = CardProps & {
  forceHoverEffect?: boolean
  disableHoverEffect?: boolean
  overlayImage?: ImgHTMLAttributes<HTMLImageElement> | { isSkeleton: boolean }
  footer?: JSX.Element
  footerStyles?: HTMLAttributes<HTMLDivElement>['style']
  isSquare?: boolean
}

export const enableHoverEffect = ({ as }: { as: CardProps['as'] }) =>
  as === 'a' || as === Link || as === 'button'

export const CustomCard = ({
  children,
  overlayImage,
  footer,
  disableHoverEffect = false,
  forceHoverEffect = false,
  footerStyles = {},
  isSquare = false,
  ...props
}: CustomCardProps) => {
  return (
    // FIXME: after I added Link as a possibility for "as" prop, typescript started complaining about prop types
    // I did not found a solution for it, but this works as expected.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <StyledBaseCard
      $enableHoverEffect={
        disableHoverEffect
          ? false
          : enableHoverEffect({ as: props.as }) || forceHoverEffect
      }
      {...props}
    >
      <StyledBodyContainer>
        {overlayImage && (
          <StyledOverlayImageContainer>
            {'isSkeleton' in overlayImage ? (
              <Skeleton width="100%" height="100%" />
            ) : (
              <StyledOverlayImage
                {...overlayImage}
                $needsFullBorderRadius={!footer}
              />
            )}
          </StyledOverlayImageContainer>
        )}
        <StyledContentContainer $isSquare={isSquare}>
          {children}
        </StyledContentContainer>
      </StyledBodyContainer>
      {footer && (
        <StyledFooterContainer style={footerStyles}>
          {footer}
        </StyledFooterContainer>
      )}
    </StyledBaseCard>
  )
}
