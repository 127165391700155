import { Theme } from '../../theme'
import { Box, Icon } from '../../atoms'
import {
  CopyButtonProps,
  CopyButton,
  TextField,
  TextFieldSize,
  ButtonSize,
} from '../../molecules'

import { StyledLinkShare } from '../ShareLinkPopup'

export type UrlCopyFieldProps = {
  name: string
  url: string
} & Omit<CopyButtonProps, 'value' | 'buttonSize' | 'buttonVariant'>

export const UrlCopyField = ({
  name,
  url,
  messages: copyButtonMessages,
  onClick,
}: UrlCopyFieldProps) => (
  <TextField
    name={name}
    leftDecorator={<Icon.Link />}
    readOnly
    value={url}
    fieldSize={TextFieldSize.REGULAR}
    rightDecorator={
      <StyledLinkShare>
        <a href={url} target="_blank" rel="noreferrer">
          <Box h={42} w={54} pr={12} pt={15} pl={28}>
            <Icon.LinkExternal
              width={14}
              height={14}
              fillColor={Theme.Button.backgroundColor}
            />
          </Box>
        </a>
        <CopyButton
          value={url}
          buttonSize={ButtonSize.SLIM}
          messages={copyButtonMessages}
          onClick={onClick}
        />
      </StyledLinkShare>
    }
  />
)
