import styled from 'styled-components'
import {
  Button,
  Flex,
  media,
  BREAKPOINTS,
  Theme,
  ShareLinkPopup,
} from '@web-apps/ui-shared'

export const AFFILIATE_IMAGE_SIZE_PX = 64

export const StyledAffiliateLinkImage = styled.img`
  width: ${AFFILIATE_IMAGE_SIZE_PX}px;
  height: ${AFFILIATE_IMAGE_SIZE_PX}px;
  border: 1px solid ${Theme.Card.borderColor};
  border-radius: 8px;
  object-fit: contain;
`

export const StyledHeaderWrapper = styled(Flex)`
  ${media.to(
    BREAKPOINTS.md,
    `
    flex-wrap: wrap;
  `
  )}
`

export const StyledHeaderContent = styled(Flex)`
  overflow: hidden;

  ${media.to(
    BREAKPOINTS.md,
    `
    flex: 1 0 100%;
  `
  )}
`

export const StyledShareButton = styled(Button)`
  position: relative;

  ${media.to(
    BREAKPOINTS.md,
    `
    margin-top: 16px;
    flex: 1 0 100%;
  `
  )}
`

export const StyledHeaderDescrContent = styled.div`
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  gap: 4px;

  /* important for ellipsis to work */
  min-width: 0;
`

export const StyledShareLinkPopup = styled(ShareLinkPopup)`
  padding-block-start: 16px;
`
