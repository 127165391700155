import React, { useMemo } from 'react'
import {
  StyledLeftBadge,
  StyledLeftBadgesContainer,
  StyledRightBadgesContainer,
  StyledRightBadge,
} from './Badges.styles'

export type BadgeType = {
  key: string
  side: string
  text: string
  progress: number
  tooltip?: JSX.Element
}
type Props = {
  badges: BadgeType[]
}

export const Badges = ({ badges = [] }: Props) => {
  const leftBadges = useMemo(
    () => badges.filter((item) => item.side === 'left'),
    [badges]
  )
  const rightBadges = useMemo(
    () => badges.filter((item) => item.side === 'right'),
    [badges]
  )

  return (
    <>
      {leftBadges.length > 0 && (
        <StyledLeftBadgesContainer>
          {leftBadges.map((item) => {
            return (
              <div key={item.key}>
                <StyledLeftBadge
                  $progress={item.progress * 100}
                  data-tip={true}
                  data-for={item.key}
                >
                  {item.text}
                </StyledLeftBadge>
                {item.tooltip}
              </div>
            )
          })}
        </StyledLeftBadgesContainer>
      )}
      {rightBadges.length > 0 && (
        <StyledRightBadgesContainer>
          {rightBadges.map((item) => {
            return (
              <div key={item.key}>
                <StyledRightBadge data-tip={true} data-for={item.key}>
                  {item.text}
                </StyledRightBadge>
                {item.tooltip}
              </div>
            )
          })}
        </StyledRightBadgesContainer>
      )}
    </>
  )
}
