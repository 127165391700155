import { useTranslation } from 'react-i18next'
import ReactGA from 'react-ga4'
import { UaEventOptions } from 'react-ga4/types/ga4'

import { ToastShare } from '@web-apps/ui-shared'
import { ANALYTICS_CATEGORY } from '@web-apps/utils-types'

type Props = {
  message: string
  href: string
  analyticsEventProps?: {
    actionNamePrefix: string
    category: ANALYTICS_CATEGORY
  } & Omit<UaEventOptions, 'action' | 'category'>
}

export const ToastShareWrapper = ({
  href,
  message,
  analyticsEventProps,
  ...rest
}: Props) => {
  const { t } = useTranslation(['app'])

  return (
    <ToastShare
      href={href}
      message={message}
      {...rest}
      buttonText={t('app:button_copy')}
      copiedTooltipText={t('app:button_tooltip_copied')}
      shareButtonText={t('app:button_share')}
      onCopyClick={() => {
        if (!analyticsEventProps) return

        const { actionNamePrefix, category, ...restAnalyticsEventProps } =
          analyticsEventProps
        ReactGA.event({
          action: `success_toast:copy_btn:click`,
          label: actionNamePrefix,
          category: category,
          ...restAnalyticsEventProps,
        })
      }}
      onShareClick={() => {
        if (!analyticsEventProps) return

        const { actionNamePrefix, category, ...restAnalyticsEventProps } =
          analyticsEventProps
        ReactGA.event({
          action: `success_toast:instagram_share_btn:click`,
          label: actionNamePrefix,
          category: category,
          ...restAnalyticsEventProps,
        })
      }}
    />
  )
}
