import styled from 'styled-components'

import { media, BREAKPOINTS } from '@web-apps/ui-shared'

export const StyledAffiliateLinksListContainer = styled.div`
  margin-block-start: 24px;

  ${media.from(
    BREAKPOINTS.md,
    `
    margin-block-start: 48px;
    `
  )}
`

export const StyledAffiliateLinksGroup = styled.ul`
  display: flex;
  flex-direction: column;

  margin-block-start: 16px;
  margin-block-end: 48px;
  gap: 24px;
`
