import { TFunction } from 'next-i18next'

import { Icon, Box } from '@web-apps/ui-shared'
import { CreatorPageType } from '@web-apps/utils-types'

import { ROUTES } from '../../../../routes/RouteEnums'

import {
  MenuButtonOptionProps,
  SECTION_OPTIONS,
  SectionOptionEnum,
  MenuOptionLeafProps,
} from '../MyPage.types'

export const SECTIONS_COUNT_LIMIT = 100
export const MAX_SECTION_IMAGE_SIZE_IN_MEGABYTES = 5
export const MAX_SECTION_IMAGE_SIZE_IN_BYTES =
  MAX_SECTION_IMAGE_SIZE_IN_MEGABYTES * 1024 * 1024

export const isSectionsLimitReached = (
  creatorPageSections?: CreatorPageType['sections']
): boolean => (creatorPageSections || []).length >= SECTIONS_COUNT_LIMIT

export const generateSectionOptionsProps = ({
  t,
  tValues,
  disabledFeatures = {},
}: {
  t: TFunction
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tValues?: { sectionDescription: { [key in SectionOptionEnum]?: any } }
  creatorPageSections?: CreatorPageType['sections']
  disabledFeatures?: { [key: string]: boolean }
}): MenuButtonOptionProps[] => {
  return convertSectionListToComponents(
    SECTION_OPTIONS,
    t,
    disabledFeatures,
    tValues
  )
}

const LinksForSectionOption: {
  [key in SectionOptionEnum]?: ROUTES
} = {
  [SectionOptionEnum.CustomLink]: ROUTES.MY_PAGE_CUSTOM_LINK,
  [SectionOptionEnum.AffiliateLink]: ROUTES.MY_PAGE_AFFILIATE_LINK_SECTION,
  [SectionOptionEnum.Content]: ROUTES.MY_PAGE_CONTENT_SECTION,
  [SectionOptionEnum.EmbedVideo]: ROUTES.MY_PAGE_ADD_VIDEO,
  [SectionOptionEnum.EmbedMusic]: ROUTES.MY_PAGE_ADD_MUSIC,
  [SectionOptionEnum.ContactList]: undefined,
  [SectionOptionEnum.ProductRecommendations]:
    ROUTES.MY_PAGE_ADD_PRODUCT_RECOMMENDATIONS,
}

const IconComponentsForSectionOption = {
  [SectionOptionEnum.CustomLink]: Icon.Link,
  [SectionOptionEnum.Content]: Icon.Text,
  [SectionOptionEnum.EmbedVideo]: Icon.Video,
  [SectionOptionEnum.EmbedMusic]: Icon.Audio,
  [SectionOptionEnum.ContactList]: Icon.EmailMono,
  [SectionOptionEnum.AffiliateLink]: Icon.CustomLink,
  [SectionOptionEnum.ProductRecommendations]: Icon.BrandCollaborations,
}

const IconComponentsForSectionSubOption = {
  ...IconComponentsForSectionOption,
  [SectionOptionEnum.ProductRecommendations]: Icon.Grid,
}

const SECTIONS_SUB_OPTIONS: {
  [s in SectionOptionEnum]?: { sections: SectionOptionEnum[] }
} = {
  [SectionOptionEnum.ProductRecommendations]: {
    sections: [
      SectionOptionEnum.AffiliateLink,
      SectionOptionEnum.ProductRecommendations,
    ],
  },
}

const convertSectionListToComponents = (
  sections: SectionOptionEnum[],
  t: TFunction,
  disabledFeatures?: { [key: string]: boolean },
  tValues?: { sectionDescription: { [key in SectionOptionEnum]?: string } },
  isSubOptionOf?: SectionOptionEnum
): MenuOptionLeafProps[] =>
  sections.map((id) => {
    const subOptionsData = SECTIONS_SUB_OPTIONS[id]

    return {
      id,
      disabled: disabledFeatures ? !!disabledFeatures[id] : false,
      IconComponent: () => {
        const Icon = isSubOptionOf
          ? IconComponentsForSectionSubOption[id]
          : IconComponentsForSectionOption[id]

        return (
          <Box m={12}>
            <Icon width={24} height={24} />
          </Box>
        )
      },
      link:
        subOptionsData && !isSubOptionOf
          ? undefined
          : LinksForSectionOption[id],
      title: t(
        isSubOptionOf
          ? `creator:section_options.${isSubOptionOf}.options.${id}.title`
          : `creator:section_options.${id}.title`
      ),
      description: t(
        isSubOptionOf
          ? `creator:section_options.${isSubOptionOf}.options.${id}.description`
          : `creator:section_options.${id}.description`,
        tValues?.sectionDescription?.[id]
      ),
      subOptions:
        isSubOptionOf || !subOptionsData
          ? undefined
          : convertSectionListToComponents(
              subOptionsData.sections,
              t,
              disabledFeatures,
              tValues,
              id
            ),
    }
  })
