import React from 'react'
import { useTranslation } from 'react-i18next'

import styled from 'styled-components'

import {
  PageHeader,
  PageTitle,
  PageWrapper,
} from '../../components/PageComponents'

import AccountView from './Account/AccountView'

const StyledTabContent = styled.div`
  max-width: 960px;
`

const StyledPageWrapper = styled(PageWrapper)`
  flex: 1;
  position: relative;
`

const PersonalSettings = () => {
  const { t } = useTranslation('personalSettings')
  const isSeamlessLogin = localStorage.getItem('isSeamlessAuth')

  if (isSeamlessLogin) {
    return null
  }

  return (
    <>
      <PageHeader>
        <PageTitle title={t('settings')} />
      </PageHeader>
      <StyledPageWrapper>
        <StyledTabContent>
          <AccountView />
        </StyledTabContent>
      </StyledPageWrapper>
    </>
  )
}

export default PersonalSettings
