import styled from 'styled-components'

import { media, BREAKPOINTS } from '@web-apps/ui-shared'

const NUMBER_OF_PANELS_BY_ROW_DESKTOP = 2
const PANELS_GAP_ROW_DESKTOP_IN_PXS = 48

export const StyledBaseDashboardContainer = styled.div`
  gap: 24px;
  margin-top: 48px;
  margin-bottom: 24px;

  ${media.from(
    BREAKPOINTS.md,
    `
    gap: ${PANELS_GAP_ROW_DESKTOP_IN_PXS}px;
    margin-block-start: 48px;
    `
  )}
`

export const StyledStatsDashboardContainer = styled(
  StyledBaseDashboardContainer
)`
  display: grid;
  grid-template-columns: minmax(100%, 1fr);
  gap: 24px;
  margin-top: 24px;

  ${media.from(
    BREAKPOINTS.md,
    `
    /* calculations for minmax are considering the gap between items */
    grid-template-columns: repeat(${NUMBER_OF_PANELS_BY_ROW_DESKTOP}, minmax(calc(${NUMBER_OF_PANELS_BY_ROW_DESKTOP}% - ${
      (PANELS_GAP_ROW_DESKTOP_IN_PXS / 2) *
      (NUMBER_OF_PANELS_BY_ROW_DESKTOP - 1)
    }px), 1fr));
    `
  )}
`
