import { Navigate, Route, Routes, useLocation } from 'react-router-dom'

import { REDIRECT_PARAM_KEY } from '@web-apps/feature-auth'

import { ROUTES } from '../../../routes/RouteEnums'
import { LoginPage } from '../Login'
import { RegisterPage } from '../RegisterPage'
import { ResetPasswordPage } from '../ResetPassword'

export const NonAuthenticated = () => {
  const location = useLocation()

  return (
    <Routes>
      <Route path={ROUTES.LOGIN} element={<LoginPage />} />
      <Route path={ROUTES.REGISTER} element={<RegisterPage />} />
      <Route path={ROUTES.RESET_PASSWORD} element={<ResetPasswordPage />} />
      <Route
        path="*"
        element={
          <Navigate
            replace
            to={`${ROUTES.LOGIN}?${REDIRECT_PARAM_KEY}=${encodeURIComponent(
              `${location.pathname}${location.search}${location.hash}`
            )}`}
          />
        }
      />
    </Routes>
  )
}
