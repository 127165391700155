import React from 'react'
import styled from 'styled-components'
import Skeleton from 'react-loading-skeleton'
import { Box, CustomCard, Flex, Typography } from '@web-apps/ui-shared'

export const StyledContainer = styled(Box)`
  &:last-child {
    margin-bottom: 0;
  }
`

export const ListItemSkeleton = () => {
  return (
    <StyledContainer mb={8}>
      <CustomCard>
        <Box p={16}>
          <Flex gap={8}>
            <Skeleton width={48} height={48} />
            <Flex
              justify="space-between"
              align="center"
              flex={1}
              style={{ minWidth: 0 }}
            >
              <div style={{ minWidth: 0, overflow: 'hidden' }}>
                <Typography variant="h4" as="p" fontWeight={500}>
                  <Skeleton width={200} />
                </Typography>
                <Typography variant="hint" color="inactive">
                  <Skeleton width={150} />
                </Typography>
              </div>
              <Box ml={8}>
                <Skeleton width={48} />
              </Box>
            </Flex>
          </Flex>
        </Box>
      </CustomCard>
    </StyledContainer>
  )
}
