import { useEffect, useRef, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'

import { FormValidations, truncateText } from '@web-apps/utils-shared'
import {
  ExternalLinkSectionFormType,
  ImageNamesEnum,
} from '@web-apps/utils-types'
import { FormArgsType, MAX_TITLE_LENGTH } from '@web-apps/ui-shared'
import { useFetchExternalLinkImageMutation } from '../../../../../services/api'
import { SectionStyleFormBlock } from '../index'
import { SectionStyleFormVariant } from '../SectionStyleFormBlock'

type Props = {
  formReturnProps: FormArgsType<ExternalLinkSectionFormType>
  externalUrl?: string
}

export const SectionLinkStyleFormBlock = ({
  formReturnProps,
  externalUrl,
}: Props) => {
  const { getValues, setValue } = formReturnProps
  const [fetchImage, { data, isLoading: isImageFetching }] =
    useFetchExternalLinkImageMutation()
  const fetchImageRequestRef = useRef<ReturnType<typeof fetchImage>>()

  const debouncedFetchImage = useDebouncedCallback((args) => {
    if (fetchImageRequestRef.current) fetchImageRequestRef.current.abort()

    const imageRequest = fetchImage(args)
    fetchImageRequestRef.current = imageRequest

    imageRequest.finally(() => {
      fetchImageRequestRef.current = undefined
    })
  }, 1000)

  const [fetchedImageData, setFetchedImageData] = useState<{
    imageUrl: string
    externalUrl: string
  }>()

  useEffect(() => {
    const image = getValues('imageData')
    const hasCustomImage = image?.type || image?.imageFile

    if (
      !!externalUrl &&
      FormValidations.isValidWebURL(externalUrl) &&
      !hasCustomImage &&
      fetchedImageData?.externalUrl !== externalUrl
    ) {
      debouncedFetchImage({ externalUrl })
      setValue('imageData', undefined)
    }
  }, [externalUrl, fetchedImageData, getValues, setValue, debouncedFetchImage])

  useEffect(() => {
    if (data) {
      const label = getValues('label')

      setFetchedImageData(data)
      if (data.imageUrl) {
        setValue(
          'imageData',
          {
            hasImageSource: true,
            href: data.imageUrl,
            name: ImageNamesEnum.DEFAULT,
          },
          { shouldValidate: true }
        )
      }
      if (data.pageTitle && !label) {
        setValue(
          'label',
          truncateText(data.pageTitle, {
            maxLength: MAX_TITLE_LENGTH,
            useEllipsis: true,
          }),
          { shouldValidate: true }
        )
      }
    }
  }, [data, getValues, setValue, setFetchedImageData])

  return (
    <SectionStyleFormBlock
      variant={SectionStyleFormVariant.IMAGE_FIRST}
      formReturnProps={formReturnProps}
      isImageLoading={isImageFetching}
    />
  )
}
