import { useState, useEffect } from 'react'
import ReactGA from 'react-ga4'
import { useLocation } from 'react-router-dom'

import { CommissionsDashboard } from '../CommissionsDashboard'
import {
  AffiliateLinkAnalytics,
  ANALYTICS_CATEGORY,
  CreatorPageAffiliateAnalyticsAPIPayload,
} from '@web-apps/utils-types'
import { ROUTES } from '../../../../../routes/RouteEnums'
import { StyledBaseDashboardContainer } from './EarningsAnalytics.styles'

import { AnalyticsMetricPanelsDashboard, Card } from '@web-apps/ui-shared'
import { AffiliateLinkAnalyticsChart } from '../AffiliateLinkAnalyticsChart'
import {
  formatChartDate,
  formatChartTooltipDate,
  toURLWithParams,
  transformTimeseriesPointToChartData,
} from '../../Analytics.utils'
import { useAffiliateLinkPanelProps } from '../../AffiliateLinkAnalyticsDetails/AffiliateLinkAnalyticsDetails.utils'
import { useSearchPeriod } from '../../hooks/useSelectorParams'
import { useGetCreatorPageAffiliateLinksAnalyticsQuery } from '@web-apps/feature-creator-page'
import { useCreatorPage } from '../../../../../utils/hooks/useCreatorPage.hooks'
import {
  AnalyticsPageChartSkeleton,
  SkeletonWrapper,
} from '../../../../../components/skeletons'

type Props = {
  isLoading: boolean
  affiliatesAnalyticsData?: CreatorPageAffiliateAnalyticsAPIPayload
}

export const EarningsAnalytics = ({
  isLoading,
  affiliatesAnalyticsData,
}: Props) => {
  const { search } = useLocation()
  const period = useSearchPeriod()
  const creatorPage = useCreatorPage()
  const metrics = useAffiliateLinkPanelProps(
    affiliatesAnalyticsData?.aggregate,
    { withSales: false, withClicks: false }
  )
  const [
    fetchAffiliateLinksAnalyticsNextPagePath,
    setFetchAffiliateLinksAnalyticsNextPagePath,
  ] = useState<string>()
  const [
    affiliateLinksAnalyticsResultsToShow,
    setAffiliateLinksAnalyticsResultsToShow,
  ] = useState<AffiliateLinkAnalytics[]>([])

  const {
    data: affiliateLinksAnalytics,
    isLoading: isAffiliateLinksAnalyticsLoading,
  } = useGetCreatorPageAffiliateLinksAnalyticsQuery(
    {
      path: fetchAffiliateLinksAnalyticsNextPagePath || '',
    },
    { skip: !fetchAffiliateLinksAnalyticsNextPagePath }
  )

  useEffect(() => {
    setAffiliateLinksAnalyticsResultsToShow([])
    setFetchAffiliateLinksAnalyticsNextPagePath(
      toURLWithParams({
        apiLink: creatorPage?._links.affiliate_analytics_links,
        period,
      })
    )
  }, [period, creatorPage._links.affiliate_analytics_links])

  useEffect(() => {
    if (affiliateLinksAnalytics) {
      setAffiliateLinksAnalyticsResultsToShow((prev) => [
        ...prev,
        ...affiliateLinksAnalytics.results,
      ])
    }
  }, [affiliateLinksAnalytics])

  return (
    <>
      <Card style={{ borderTop: 'none', borderRadius: '0 0 8px 8px' }}>
        <SkeletonWrapper
          Skeleton={AnalyticsPageChartSkeleton}
          isLoading={isLoading}
        >
          {affiliatesAnalyticsData && (
            <>
              <AffiliateLinkAnalyticsChart
                tickFormatter={(label) => formatChartDate(label, period)}
                labelFormatter={(label) =>
                  formatChartTooltipDate(label, period)
                }
                withSales={false}
                withClicks={false}
                data={affiliatesAnalyticsData.timeseries.map(
                  transformTimeseriesPointToChartData
                )}
              />
              <AnalyticsMetricPanelsDashboard panelsProps={metrics} />
            </>
          )}
        </SkeletonWrapper>
      </Card>
      <StyledBaseDashboardContainer>
        <CommissionsDashboard
          affiliateLinksAnalytics={{
            results: affiliateLinksAnalyticsResultsToShow.map((a) => ({
              ...a,
              detailsPageURL: `${ROUTES.ANALYTICS}/affiliate-link/${a.id}${search}`,
            })),
            prevAPILink: affiliateLinksAnalytics?.prevAPILink,
            nextAPILink: affiliateLinksAnalytics?.nextAPILink,
          }}
          isLoading={isAffiliateLinksAnalyticsLoading}
          onScrollBottomReached={() => {
            if (isAffiliateLinksAnalyticsLoading) return
            if (!affiliateLinksAnalytics?.nextAPILink) return

            setFetchAffiliateLinksAnalyticsNextPagePath(
              affiliateLinksAnalytics?.nextAPILink
            )
          }}
          onLinkClick={() => {
            ReactGA.event({
              action: 'analytics_page:affiliate_link:click',
              category: ANALYTICS_CATEGORY.APPLICATION,
            })
          }}
        />
      </StyledBaseDashboardContainer>
    </>
  )
}
