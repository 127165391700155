import { useRef } from 'react'
import { Link } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'

import { Theme } from '../../theme'
import { Box, Flex, Icon, TextEllipsis, Typography } from '../../atoms'
import { Button } from '..'

import { calculatePercentageForStats } from './AnalyticsBarPanel.utils'
import {
  StyledBarContentWrapper,
  StyledCallToActionButtonContent,
  StyledCallToActionContainer,
  StyledCallToActionHeader,
  StyledColumnHeader,
  StyledEmptyStatsContainer,
  StyledFilledBar,
  StyledHeaderContainer,
  StyledInfoColumn,
  StyledPanelContainer,
  StyledPanelContent,
  StyledResultColumn,
  StyledRightChevronIcon,
  StyledStatsInfoCell,
  StyledStatsInfoContentBox,
  StyledStatsResultCell,
  StyledStatsTable,
  StyledValueColumnHeader,
} from './AnalyticsBarPanel.styles'

import {
  AnalyticsBarPanelProps,
  ContainerHeightVariant,
  AnalyticsBarPanelSkeletonVariant,
} from './AnalyticsBarPanel.types'

const BOTTOM_THRESHOLD_PX = 25

export const AnalyticsBarPanel = ({
  title,
  columns,
  stats,
  messages,
  disabled = false,
  callToActionButtonProps,
  isLoading = false,
  onBottomScrollReached,
  containerHeightVariant = ContainerHeightVariant.Small,
  skeletonVariant = AnalyticsBarPanelSkeletonVariant.Default,
}: AnalyticsBarPanelProps) => {
  const tableDivRef = useRef<HTMLDivElement>(null)

  return (
    <StyledPanelContainer>
      <StyledPanelContent $disabled={disabled}>
        <Typography variant="h3">{title}</Typography>
        <div>
          {isLoading ? (
            <StyledEmptyStatsContainer $variant={containerHeightVariant}>
              <StyledStatsTable
                $variant={containerHeightVariant}
                ref={tableDivRef}
              >
                <StyledHeaderContainer>
                  <StyledColumnHeader>{columns[0].title}</StyledColumnHeader>
                  <StyledValueColumnHeader>
                    {columns[1].title}
                  </StyledValueColumnHeader>
                </StyledHeaderContainer>
                <Flex direction="column">
                  {Array(5)
                    .fill(0)
                    .map((_, index) => {
                      if (
                        skeletonVariant ===
                        AnalyticsBarPanelSkeletonVariant.Affiliates
                      ) {
                        return (
                          <Flex key={index} gap={8} flex={1}>
                            <Skeleton width={64} height={64} />
                            <Flex flex={1}>
                              <Box w="100%">
                                <Typography
                                  variant="subTitleBig"
                                  as="span"
                                  style={{ textTransform: 'none' }}
                                >
                                  <Skeleton />
                                </Typography>
                                <Typography variant="hint" as="span">
                                  <Skeleton />
                                </Typography>
                                <Typography variant="hint" as="span">
                                  <Skeleton />
                                </Typography>
                              </Box>
                            </Flex>
                          </Flex>
                        )
                      }

                      return (
                        <Typography variant="h2" key={index}>
                          <Skeleton />
                        </Typography>
                      )
                    })}
                </Flex>
              </StyledStatsTable>
            </StyledEmptyStatsContainer>
          ) : stats?.length ? (
            <StyledStatsTable
              $variant={containerHeightVariant}
              ref={tableDivRef}
              onScroll={() => {
                if (!onBottomScrollReached || !tableDivRef.current) return
                if (
                  !tableDivRef.current.scrollTop ||
                  !tableDivRef.current.clientHeight ||
                  !tableDivRef.current.scrollHeight
                )
                  return

                if (
                  tableDivRef.current.scrollTop +
                    tableDivRef.current.clientHeight >=
                  tableDivRef.current?.scrollHeight - BOTTOM_THRESHOLD_PX
                ) {
                  onBottomScrollReached()
                }
              }}
            >
              <StyledHeaderContainer>
                <StyledColumnHeader>{columns[0].title}</StyledColumnHeader>
                <StyledValueColumnHeader>
                  {columns[1].title}
                </StyledValueColumnHeader>
              </StyledHeaderContainer>
              <Flex justify="space-between">
                <StyledInfoColumn>
                  {stats.map(
                    (
                      {
                        id,
                        icon,
                        text,
                        subtitle,
                        hint,
                        as,
                        customizableValueOptions,
                        titleVariant,
                        ...customProps
                      },
                      index
                    ) => {
                      return (
                        <StyledStatsInfoCell
                          key={id}
                          title={!subtitle ? text : undefined}
                          as={as}
                          {...customProps}
                        >
                          <StyledFilledBar
                            $width={`${calculatePercentageForStats(
                              index,
                              stats
                            )}%`}
                          />
                          <StyledBarContentWrapper>
                            {icon}
                            <StyledStatsInfoContentBox>
                              <Typography
                                variant={titleVariant || 'subTitleBig'}
                                as="span"
                                style={{ textTransform: 'none' }}
                              >
                                <TextEllipsis>{text}</TextEllipsis>
                              </Typography>
                              {subtitle && (
                                <Typography
                                  variant="hint"
                                  as="span"
                                  color="inactive"
                                >
                                  <TextEllipsis>{subtitle}</TextEllipsis>
                                </Typography>
                              )}
                              {hint && (
                                <Typography variant="hint" as="span">
                                  <TextEllipsis>{hint}</TextEllipsis>
                                </Typography>
                              )}
                            </StyledStatsInfoContentBox>
                          </StyledBarContentWrapper>
                        </StyledStatsInfoCell>
                      )
                    }
                  )}
                </StyledInfoColumn>
                <StyledResultColumn>
                  {stats.map(
                    ({
                      id,
                      icon,
                      text,
                      subtitle,
                      hint,
                      as,
                      customizableValueOptions,
                      titleVariant,
                      value,
                      ...customProps
                    }) => (
                      <StyledStatsResultCell
                        key={id}
                        title={`${value}`}
                        as={as}
                        {...customProps}
                      >
                        <TextEllipsis>
                          <Typography
                            variant="note"
                            as="span"
                            fontWeight="600"
                            color={customizableValueOptions?.textColor}
                          >
                            {customizableValueOptions?.valueFormatter
                              ? customizableValueOptions.valueFormatter(value)
                              : value}
                          </Typography>
                        </TextEllipsis>

                        {as === Link || as === 'a' ? (
                          <StyledRightChevronIcon
                            fillColor={Theme.Colors.typography.inactive}
                          />
                        ) : null}
                      </StyledStatsResultCell>
                    )
                  )}
                </StyledResultColumn>
              </Flex>
            </StyledStatsTable>
          ) : (
            <StyledEmptyStatsContainer $variant={containerHeightVariant}>
              {messages.noData}
            </StyledEmptyStatsContainer>
          )}
        </div>
      </StyledPanelContent>
      {disabled && (
        <StyledCallToActionContainer>
          <StyledCallToActionHeader>
            {messages.callToActionInfo}
          </StyledCallToActionHeader>

          <Button {...callToActionButtonProps}>
            <StyledCallToActionButtonContent>
              <Icon.SparkMono />
              <span>{messages.callToActionButtonText}</span>
            </StyledCallToActionButtonContent>
          </Button>
        </StyledCallToActionContainer>
      )}
    </StyledPanelContainer>
  )
}
