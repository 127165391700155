import { useEffect, useMemo } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { TransitionGroup } from 'react-transition-group'
import * as Sentry from '@sentry/browser'

import { useDebouncedUpdate } from '../../../../../containers/creator/MyPage/hooks/preview.hooks'
import { editSocialLinks } from '../../../../../store/creator/preview.slice'
import { StyledFormLabel } from '../../../../../components/styles'
import { FormActionsBar, RadioInput } from '../../../../../components'
import { MenuButton } from '../../../../../containers/creator/MyPage/components'

import {
  TextField,
  Button,
  ButtonVariant,
  Icon,
  Theme,
  Flex,
  SvgIcon,
  Box,
  IconAssetsKeys,
  ShowHideAnimationWrapper,
} from '@web-apps/ui-shared'
import { getSocialData, isSocialValid } from '@web-apps/utils-shared'
import { useUpdateCreatorPageSocialLinksMutation } from '@web-apps/feature-creator-page'
import {
  UserIdType,
  SocialNetworksEnum,
  CustomizationFormProperties,
  SocialsPlacement,
  CreatorPageType,
} from '@web-apps/utils-types'
import { ReactComponent as DeleteIcon } from '../../../../../assets/icons/remove-white.svg'

import { generateSocialLinksOptions } from './SocialLinks.utils'
import { StyleListItem, StyledCard } from './SociaLinksSection.styles'

type Props = {
  creatorPage: CreatorPageType
  userNanoId: UserIdType
}

export const SocialLinksSection = ({ userNanoId, creatorPage }: Props) => {
  const updatePreview = useDebouncedUpdate(editSocialLinks)
  const { t } = useTranslation(['creator', 'app'])
  const {
    control,
    register,
    handleSubmit: submitWrapper,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      items: creatorPage?.social_links,
      placement:
        creatorPage?.styles[CustomizationFormProperties.SOCIALS_PLACEMENT] ||
        SocialsPlacement.TOP,
    },
  })
  const { fields, append, remove, move } = useFieldArray({
    control,
    name: 'items',
    keyName: 'type',
  })
  const [updateSocialLinks, { isLoading, isSuccess, isError }] =
    useUpdateCreatorPageSocialLinksMutation()
  const socialLinksOptionsProps = useMemo(() => {
    return generateSocialLinksOptions(fields.map((item) => item.type)).sort(
      (i1, i2) => i1.title.localeCompare(i2.title)
    )
  }, [fields])
  const socialsPlacementOptions = useMemo(() => {
    return [
      {
        label: t('creator:settings_tab.socials_placement.top'),
        value: SocialsPlacement.TOP,
      },
      {
        label: t('creator:settings_tab.socials_placement.bottom'),
        value: SocialsPlacement.BOTTOM,
      },
    ]
  }, [t])

  useEffect(() => {
    if (isSuccess) {
      toast.success(t('creator:profile_updated_message_success'))
    }
  }, [isSuccess, t])

  useEffect(() => {
    if (isError) {
      toast.error(t('creator:profile_updated_message_error'))
    }
  }, [isError, t])

  useEffect(() => {
    const subscription = watch((value) => updatePreview(value))

    return () => subscription.unsubscribe()
  }, [watch, updatePreview])

  const handleSubmit = submitWrapper(({ items, placement }) => {
    if (!creatorPage._links.styles?.href) {
      Sentry.captureException(
        new Error(
          'endpoint to update creator page styles not returned or found on API payload'
        )
      )

      return
    }

    const styles = {
      ...creatorPage.styles,
      [CustomizationFormProperties.SOCIALS_PLACEMENT]: placement,
    }

    updateSocialLinks({
      userNanoId,
      items,
      styles,
      stylesPath: creatorPage._links.styles.href,
    })
  })

  return (
    <form onSubmit={handleSubmit}>
      <Box mb={24}>
        <Flex gap={16}>
          <Box mr={8}>
            <StyledFormLabel>
              {t('creator:settings_tab.socials_placement.title')}
            </StyledFormLabel>
          </Box>
          {socialsPlacementOptions.map((item) => (
            <RadioInput
              key={item.value}
              {...register('placement')}
              label={item.label}
              value={item.value}
            />
          ))}
        </Flex>
      </Box>
      {fields.length < 10 && (
        <Box mb={24}>
          <MenuButton
            text={t('creator:add_social_link')}
            options={socialLinksOptionsProps}
            onOptionSelected={(data) =>
              append(
                { type: data.id as SocialNetworksEnum, handle: '' },
                { shouldFocus: true }
              )
            }
          />
        </Box>
      )}
      <DragDropContext
        onDragEnd={(result) => {
          if (result.destination && result.destination) {
            move(result.source.index, result.destination.index)
          }
        }}
      >
        <Droppable droppableId="section-droppable">
          {(provided) => (
            <ul {...provided.droppableProps} ref={provided.innerRef}>
              <TransitionGroup component={null}>
                {fields.map((item, index) => {
                  const socialNetwork = getSocialData(item.type)

                  if (!socialNetwork) {
                    return null
                  }

                  const SocialIcon =
                    Icon[socialNetwork.monoIcon as IconAssetsKeys]

                  const socialError = errors?.items
                    ? errors.items[index]
                    : undefined
                  const socialErrorMessage = socialError?.handle?.message

                  return (
                    <ShowHideAnimationWrapper key={item.type} in>
                      <Draggable
                        key={item.type}
                        draggableId={item.type}
                        index={index}
                      >
                        {(provided) => {
                          return (
                            <StyleListItem
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <StyledCard>
                                <span>
                                  <Icon.DragDots
                                    fillColor={Theme.Colors.typography.regular}
                                  />
                                </span>
                                <Flex grow={1} direction="column">
                                  <TextField
                                    leftDecorator={
                                      <SvgIcon
                                        verticalAlign="bottom"
                                        opacity={0.7}
                                      >
                                        <SocialIcon />
                                      </SvgIcon>
                                    }
                                    {...register(`items.${index}.handle`, {
                                      validate: (value) =>
                                        isSocialValid(item.type, value) ||
                                        (t(socialNetwork.errorMessage, {
                                          serviceName: socialNetwork.title,
                                        }) as string),
                                      maxLength: 256,
                                    })}
                                    placeholder={socialNetwork.placeholder}
                                    errorMessages={
                                      socialErrorMessage
                                        ? [socialErrorMessage]
                                        : undefined
                                    }
                                  />
                                </Flex>
                                <Button
                                  type="button"
                                  variant={ButtonVariant.PLAIN}
                                  clickHandler={() => remove(index)}
                                >
                                  <DeleteIcon />
                                </Button>
                              </StyledCard>
                            </StyleListItem>
                          )
                        }}
                      </Draggable>
                    </ShowHideAnimationWrapper>
                  )
                })}
              </TransitionGroup>
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
      <FormActionsBar
        hideSecondaryAction
        isLoading={isLoading}
        mb={0}
        justifyContent="flex-start"
      />
    </form>
  )
}
