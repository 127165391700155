import { useTranslation } from 'react-i18next'
import { Box, Flex, Icon, Typography } from '@web-apps/ui-shared'
import { MissedHintsEnum } from '@web-apps/utils-types'
import { StyledMissedHintContainer } from './MissedHints.styles'

type Props = {
  missedHints?: MissedHintsEnum[]
}

const KNOWN_HINTS = [
  MissedHintsEnum.OFFER,
  MissedHintsEnum.TYPE,
  MissedHintsEnum.REGION,
]

export const MissedHints = ({ missedHints = [] }: Props) => {
  const { t } = useTranslation(['creator'], { useSuspense: false })

  if (missedHints?.length === 0) {
    return null
  }

  return (
    <Box mb={8}>
      <Flex direction="column" gap={8}>
        {missedHints.map(
          (hint) =>
            KNOWN_HINTS.includes(hint) && (
              <StyledMissedHintContainer key={hint} align="center" gap={8}>
                <Icon.Info />
                <Typography variant="hint">
                  {t(`creator:affiliate_links.conversion_tooltip.hint.${hint}`)}
                </Typography>
              </StyledMissedHintContainer>
            )
        )}
      </Flex>
    </Box>
  )
}
