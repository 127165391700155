import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Typography,
  ProgressBar,
  Theme,
  InfoTooltip,
  Flex,
  Tag,
  Box,
  Button,
  TextEllipsis,
  ButtonVariant,
  Icon,
} from '@web-apps/ui-shared'
import { Money } from '@web-apps/utils-shared'

import {
  StyleBalancePanel,
  StyledHeaderActions,
  StyledPayoutsPanelCard,
  StyledProgressBarContainer,
  StyledSeparator,
  StyledStatusHeader,
  StyledStatusPanel,
} from './PayoutsPanel.styles'
import { useAccountDetails } from '../../../../../utils/hooks/useAccountDetails.hooks'
import { useGetAffiliateBalanceQuery } from '../../../../../services/user.api'
import { useGetPayoutInfoQuery } from '../../../../../services/payout.api'
import {
  BalanceBlockSkeleton,
  SkeletonWrapper,
} from '../../../../../components/skeletons'
import { PayoutInfoStatus } from '@web-apps/utils-types'
import { PayoutsHistory } from '../PayoutsHistory'

export const PayoutsPanel = () => {
  const { t } = useTranslation(['metrics', 'payoutForm'], {
    useSuspense: false,
  })
  const [isPayoutsHistoryOpen, setIsPayoutsHistoryOpen] = useState(false)
  const { affiliateBalanceEndpoint, payoutInfoEndpoint } = useAccountDetails()
  const { data: balanceData, isLoading: isAffiliateBalanceLoading } =
    useGetAffiliateBalanceQuery(
      { path: affiliateBalanceEndpoint },
      { skip: !affiliateBalanceEndpoint }
    )
  const { data: payoutInfo, isLoading: isPayoutInfoLoading } =
    useGetPayoutInfoQuery(
      { path: payoutInfoEndpoint },
      { skip: !payoutInfoEndpoint }
    )
  const isPayable = balanceData?.thresholdReached
  const percentage = useMemo(() => {
    if (!balanceData) {
      return 0
    }

    return balanceData.thresholdReached
      ? 100
      : Money.mathOperationFloats(
          Money.mathOperationFloats(
            balanceData.outstanding.amount,
            balanceData.payoutThreshold.amount,
            'division'
          ),
          100,
          'multiplication'
        )
  }, [balanceData])
  const isPayoutInfoFilled = useMemo(() => {
    return payoutInfo?.status === PayoutInfoStatus.VALID
  }, [payoutInfo])

  return (
    <SkeletonWrapper
      isLoading={isAffiliateBalanceLoading || isPayoutInfoLoading}
      Skeleton={BalanceBlockSkeleton}
    >
      {balanceData && payoutInfo && (
        <StyledPayoutsPanelCard>
          <StyleBalancePanel>
            <Flex gap={8} align="center">
              <Typography
                color="inactive"
                style={{
                  textTransform: 'uppercase',
                  fontSize: '10px',
                  fontWeight: 600,
                }}
              >
                {t('metrics:payoutsPanel.balancePanel.label')}
              </Typography>

              <InfoTooltip
                maxWidthOverride="220px"
                id="info-payouts-tooltip"
                title={t('metrics:payoutsPanel.balancePanel.tooltip.title')}
                message={t('metrics:payoutsPanel.balancePanel.tooltip.message')}
                hint={t('metrics:payoutsPanel.balancePanel.tooltip.hint')}
              />
            </Flex>

            <>
              <Typography variant="h1" fontWeight="600" as="p">
                {Money.formatPrice(balanceData.outstanding)}
              </Typography>
              <StyledProgressBarContainer>
                <ProgressBar
                  fillColor={
                    isPayable ? Theme.Colors.progress : Theme.Colors.star
                  }
                  completeIndicatorContent="🎉"
                  percentage={percentage}
                />
              </StyledProgressBarContainer>
              <Typography color="inactive" variant="hint">
                {t('metrics:payoutsPanel.balancePanel.message', {
                  amount: Money.formatPrice(balanceData.payoutThreshold, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  }),
                })}
              </Typography>
            </>
          </StyleBalancePanel>
          <StyledSeparator />
          <StyledStatusPanel>
            {isPayoutInfoFilled ? (
              <StyledStatusHeader $hasBackground>
                <Typography fontSize="24px">
                  {t('metrics:payoutsPanel.statusPanel.payoutInfo.emoji')}
                </Typography>
                <TextEllipsis>
                  <Typography variant="h4">
                    {t('metrics:payoutsPanel.statusPanel.payoutInfo.label')}
                  </Typography>
                </TextEllipsis>
                <TextEllipsis>
                  <Typography>{balanceData.nextPayoutDate}</Typography>
                </TextEllipsis>
                <Tag
                  text={t(
                    `metrics:payoutsPanel.statusPanel.status.${
                      isPayable ? 'payable' : 'notPayable'
                    }.tagText`
                  )}
                  style={{ fontWeight: 600 }}
                  variant={isPayable ? 'success' : 'warning'}
                />
              </StyledStatusHeader>
            ) : (
              <StyledStatusHeader>
                <Typography variant="h4">
                  {t('metrics:payoutsPanel.statusPanel.label')}
                </Typography>
                <Tag
                  text={t(
                    `metrics:payoutsPanel.statusPanel.status.${
                      isPayable ? 'payable' : 'notPayable'
                    }.tagText`
                  )}
                  style={{ fontWeight: 600 }}
                  variant={isPayable ? 'success' : 'warning'}
                />
              </StyledStatusHeader>
            )}
            <Box mt={8} mb={16}>
              <Typography color="inactive" variant="hint">
                {t(
                  isPayoutInfoFilled
                    ? `metrics:payoutsPanel.statusPanel.payoutInfo.${
                        isPayable ? 'payable' : 'notPayable'
                      }`
                    : `metrics:payoutsPanel.statusPanel.status.${
                        isPayable ? 'payable' : 'notPayable'
                      }.message`
                )}
              </Typography>
            </Box>
            <StyledHeaderActions gap={16}>
              {isPayable && (
                <Button href="mailto:contact@zezam.io?subject=Payment request">
                  {t('payoutForm.requestPayout')}
                </Button>
              )}
              <Button
                variant={ButtonVariant.PLAIN}
                clickHandler={() => setIsPayoutsHistoryOpen(true)}
              >
                <Flex gap={4} align="center">
                  <Icon.ArrowRight width={10} height={10} />
                  <Typography variant="navigation">
                    {t('metrics:payoutsPanel.statusPanel.payoutHistory')}
                  </Typography>
                </Flex>
              </Button>
            </StyledHeaderActions>
          </StyledStatusPanel>
          {isPayoutsHistoryOpen && (
            <PayoutsHistory onClose={() => setIsPayoutsHistoryOpen(false)} />
          )}
        </StyledPayoutsPanelCard>
      )}
    </SkeletonWrapper>
  )
}
