import styled from 'styled-components'

import { media, BREAKPOINTS } from '@web-apps/ui-shared'

const PANELS_GAP_ROW_DESKTOP_IN_PXS = 48

export const StyledBaseDashboardContainer = styled.div`
  gap: 24px;
  margin-block-start: 24px;

  ${media.from(
    BREAKPOINTS.md,
    `
    gap: ${PANELS_GAP_ROW_DESKTOP_IN_PXS}px;
    margin-block-start: 48px;
    `
  )}
`
