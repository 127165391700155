import React from 'react'
import styled from 'styled-components'

import { LoadingIndicator, media, BREAKPOINTS } from '@web-apps/ui-shared'

import { useAccountDetails } from '../../../../utils/hooks/useAccountDetails.hooks'
import { OldAnalyticsPage } from '../OldAnalyticsPage'

const StyledWrapper = styled.div`
  position: absolute;
  top: 40px;
  left: 0;
  right: 0;

  height: calc(100vh - 105px);

  ${media.to(
    BREAKPOINTS.md,
    `
    padding-bottom: 40px;
  `
  )}
`

const StyledIframeContainer = styled.div`
  position: relative;
  z-index: 2;
  height: 100%;
`

const StyledLoaderContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  height: calc(100vh - 105px);
`
export const EarningsPage = () => {
  const {
    apiConfigs: {
      endpointPaths: { analytics },
    },
    metapicUserId,
  } = useAccountDetails()

  if (!metapicUserId) return <OldAnalyticsPage />

  return analytics ? (
    <StyledWrapper>
      <StyledLoaderContainer>
        <LoadingIndicator size={100} isCentered />
      </StyledLoaderContainer>
      <StyledIframeContainer>
        <iframe
          src={analytics}
          width="100%"
          title="Payment settings"
          height="100%"
        />
      </StyledIframeContainer>
    </StyledWrapper>
  ) : (
    <StyledLoaderContainer>
      <LoadingIndicator size={100} isCentered />
    </StyledLoaderContainer>
  )
}
