import { AffiliateLinkConversionData } from '@web-apps/utils-types'
import { FC } from 'react'
import { TagVariantType } from '@web-apps/ui-shared'

export enum SectionOptionEnum {
  CustomLink = 'custom_link',
  AffiliateLink = 'affiliate_link',
  Content = 'content',
  EmbedVideo = 'embed_video',
  EmbedMusic = 'embed_music',
  ContactList = 'contact_list',
  ProductRecommendations = 'product_recommendations',
}

export type MenuOptionLeafProps = {
  id: string
  title: string
  description?: string
  IconComponent: FC<React.PropsWithChildren<unknown>>
  link?: string
  listItemProps?: { [key: string]: string | boolean | number | [] }
  disabled?: boolean
  tagText?: string
  tagVariant?: TagVariantType
}

export type MenuButtonOptionProps = MenuOptionLeafProps & {
  subOptions?: (MenuOptionLeafProps & { subOptions: undefined })[]
}

export const SECTION_OPTIONS = [
  SectionOptionEnum.ProductRecommendations,
  SectionOptionEnum.CustomLink,
  SectionOptionEnum.EmbedVideo,
  SectionOptionEnum.EmbedMusic,
  SectionOptionEnum.Content,
]

export type AffiliateTooltipStatus =
  | 'initial'
  | 'convertible'
  | 'not_convertible'
  | 'ignored'

export type AffiliateTooltipState = Partial<
  Omit<AffiliateLinkConversionData, 'isConvertible' | 'isAffiliateLink'>
> & {
  status: AffiliateTooltipStatus
  readyForAffiliateLinkConversion: boolean
}

export type AddProductActionVariant =
  | 'single-link'
  | 'new-grid'
  | 'existing-grid'
  | 'affiliate'
  | 'grid-limit-reached'
  | 'section-limit-reached'
