import styled from 'styled-components'
import { Flex, Theme } from '@web-apps/ui-shared'

export const StyledContainer = styled.div`
  margin-top: 8px;
  padding: 16px;
  background: ${Theme.Colors.background.global};
  border-radius: 8px;
  position: relative;
`

export const StyledCommissionTypeContainer = styled(Flex)`
  padding: 12px 8px;
  background: rgba(79, 192, 26, 0.2);
  border: 1px solid rgba(79, 192, 26, 0.2);
  border-radius: 8px;
  width: min-content;
  white-space: nowrap;
`
