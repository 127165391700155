import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Box,
  Button,
  Flex,
  ShareLinkPopup,
  Typography,
  useShareLinkData,
} from '@web-apps/ui-shared'
import {
  AffiliateLinksListType,
  AffiliateLinkType,
} from '@web-apps/utils-types'
import {
  creatorUrlBuilders,
  DateHelpers,
  nativeShareWrapper,
} from '@web-apps/utils-shared'

import { COPY_BUTTON_MESSAGES_KEYS } from '../../../../../../components/CopyButton/CopyButton'

import {
  AffiliateLinkItem,
  AffiliateLinkItemProps,
} from './components/AffiliateLinkItem/AffiliateLinkItem.component'
import { AddAffiliateAsSectionDialog } from './components/AddAffiliateAsSectionDialog'

import {
  StyledAffiliateLinksGroup,
  StyledAffiliateLinksListContainer,
} from './AffiliateLinksList.styles'
import {
  AffiliateLinksListItemSkeleton,
  SkeletonWrapper,
} from '../../../../../../components/skeletons'

type AffiliateLinksListProps = {
  affiliateLinksListData?: AffiliateLinksListType
  isLoading?: boolean
  isFetching?: boolean
  onLoadMore?: (nextAPILink: string) => void
  onAffiliateLinkEditSubmit: AffiliateLinkItemProps['onEditSubmit']
  onAddingSection: (affiliateLink: AffiliateLinkType) => void
}

export const AffiliateLinksList = ({
  affiliateLinksListData,
  isLoading = false,
  isFetching,
  onLoadMore,
  onAffiliateLinkEditSubmit,
  onAddingSection,
}: AffiliateLinksListProps) => {
  const { t } = useTranslation(['app', 'brands'], { useSuspense: false })
  const { setShareLink, url: shareUrl } = useShareLinkData()

  const [selectedLinkToAdd, setSelectedLinkToAdd] = useState<
    AffiliateLinkType | undefined
  >()

  return (
    <StyledAffiliateLinksListContainer>
      <Box mb={24}>
        <Typography variant="h3">
          {t('app:header.brands.converter_page.list_title')}
        </Typography>
      </Box>
      <SkeletonWrapper
        Skeleton={AffiliateLinksListItemSkeleton}
        isLoading={isLoading}
        showEmptyState={
          affiliateLinksListData?.linksByMonthDescSorted.length === 0
        }
      >
        {affiliateLinksListData && (
          <>
            {(affiliateLinksListData.linksByMonthDescSorted || []).map((m) => (
              <div key={`${m.fullYear}-${m.monthIndex}`}>
                <Typography variant="subTitleBig" as="h4" fontWeight={600}>
                  {DateHelpers.dateInstanceBuilder(
                    `${m.fullYear}-${m.monthIndex + 1}-1`
                  ).format('MMMM, YYYY')}
                </Typography>
                <StyledAffiliateLinksGroup>
                  {(m.linksDescSortedByDate || []).map((al) => (
                    <AffiliateLinkItem
                      key={al.id}
                      isFetching={al.isFetching}
                      affiliateLinkData={al}
                      onAddToMyPage={(affiliateLinkData) => {
                        setSelectedLinkToAdd(affiliateLinkData)
                      }}
                      onEditSubmit={onAffiliateLinkEditSubmit}
                      onShare={(affiliateLinkData, shareButtonElement) => {
                        const url = creatorUrlBuilders.generateUtmShareURL({
                          originalURL: affiliateLinkData.affiliateUrl,
                        })
                        return nativeShareWrapper(url, () =>
                          setShareLink({
                            url,
                            container: shareButtonElement,
                          })
                        )
                      }}
                    />
                  ))}
                </StyledAffiliateLinksGroup>
              </div>
            ))}
            {affiliateLinksListData.nextAPILink && onLoadMore && (
              <Flex justify="center">
                <Button
                  inverse
                  clickHandler={() =>
                    affiliateLinksListData.nextAPILink &&
                    onLoadMore(affiliateLinksListData.nextAPILink)
                  }
                  type="button"
                  isLoading={isFetching}
                >
                  {t('app:load_more')}
                </Button>
              </Flex>
            )}
          </>
        )}
      </SkeletonWrapper>
      {shareUrl && (
        <ShareLinkPopup
          title={t('app:share_popup.share_link')}
          messages={{
            t,
            namespace: 'app',
            copyButton: {
              buttonText: t(COPY_BUTTON_MESSAGES_KEYS.buttonText),
              copiedTooltipText: t(COPY_BUTTON_MESSAGES_KEYS.copiedTooltipText),
            },
          }}
          direction="left"
        />
      )}

      {selectedLinkToAdd && (
        <AddAffiliateAsSectionDialog
          onSuccess={() => {
            setSelectedLinkToAdd(undefined)
            onAddingSection(selectedLinkToAdd)
          }}
          onDismiss={() => setSelectedLinkToAdd(undefined)}
          selectedLink={selectedLinkToAdd}
        />
      )}
    </StyledAffiliateLinksListContainer>
  )
}
