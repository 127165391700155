import React, { useState } from 'react'
import { LoginFlow, UpdateLoginFlowBody } from '@ory/client'
import { Trans, useTranslation } from 'react-i18next'
import { useFlag } from '@unleash/proxy-client-react'

import { FormValidations, usePasswordHider } from '@web-apps/utils-shared'
import {
  Box,
  Button,
  Flex,
  Form,
  Icon,
  IconButton,
  StyledError,
  StyledCrossLine,
  TextField,
  Typography,
  LoadingSpinner,
  Theme,
  NotificationBanner,
} from '@web-apps/ui-shared'
import { AuthProviderName } from '../../utils/constants'

import { useGetLoginFlow } from '../../utils/hooks'
import { submitLoginFlow } from '../../utils/flows'
import {
  FLAG_FACEBOOK_AUTH,
  FLAG_FACEBOOK_MESSAGE,
  FLAG_GITHUB_AUTH,
  FLAG_GOOGLE_AUTH,
} from '../../utils/flags.constants'
import { Link } from 'react-router-dom'

type LoginProps = {
  onLogin?: () => void
  onError?: (error: string) => void
  returnToUrl?: string
  resetPasswordLink: string
}
export const Login = ({
  onLogin,
  returnToUrl,
  resetPasswordLink,
}: LoginProps) => {
  const isGoogleAuthEnabled = useFlag(FLAG_GOOGLE_AUTH)
  const isGithubAuthEnabled = useFlag(FLAG_GITHUB_AUTH)
  const isFacebookAuthEnabled = useFlag(FLAG_FACEBOOK_AUTH)
  const isThereSocialAuth =
    isGoogleAuthEnabled || isGithubAuthEnabled || isFacebookAuthEnabled
  const isFacebookInfoMessage = useFlag(FLAG_FACEBOOK_MESSAGE)

  const { t } = useTranslation(['auth'])
  const {
    flow,
    isFlowLoading,
    fields,
    error: flowError,
  } = useGetLoginFlow({
    returnToUrl,
  })
  const { isPasswordVisible, togglePasswordVisibility } = usePasswordHider()

  const [submitErrorMessage, setSubmitErrorMessage] = useState<string | null>(
    null
  )

  const onSubmit = async (values: UpdateLoginFlowBody) => {
    setSubmitErrorMessage(null)
    const response = await submitLoginFlow(flow as LoginFlow, values, onLogin)
    if (response?.error) setSubmitErrorMessage(response.error)
  }

  if (isFlowLoading)
    return (
      <Flex align="center" direction="column">
        <Box py={24}>
          <LoadingSpinner size={42} color={Theme.Colors.background.dark} />
        </Box>
      </Flex>
    )

  return (
    <div>
      {isFacebookInfoMessage && (
        <NotificationBanner variant="Warning">
          <div>
            <Trans i18nKey="auth:errors.facebook_disabled">
              If you signed up using Facebook, to access your account, you'll
              need to{' '}
              <Link to={resetPasswordLink}>
                <Typography
                  textDecoration="underline"
                  as="span"
                  fontWeight="bold"
                >
                  reset your password
                </Typography>
              </Link>
              . Make sure to use the email that's linked to your Facebook
              account for this reset.
            </Trans>
          </div>
        </NotificationBanner>
      )}
      {fields && (
        <>
          {Boolean(fields.google.length) && isGoogleAuthEnabled && (
            <Form onSubmit={onSubmit}>
              {() => {
                return (
                  <Box mb={16}>
                    {fields.google.map(({ attributes }) => (
                      <TextField
                        key={attributes.name}
                        name={attributes.name}
                        type="hidden"
                        value={attributes.value}
                      />
                    ))}
                    <Button width="100%" inverse>
                      <Flex gap={8} justify="center">
                        <Icon.Google />
                        <span>
                          {t('auth:login.login_with_provider', {
                            provider: AuthProviderName.GOOGLE,
                          })}
                        </span>
                      </Flex>
                    </Button>
                  </Box>
                )
              }}
            </Form>
          )}

          {Boolean(fields.facebook.length) && isFacebookAuthEnabled && (
            <Form onSubmit={onSubmit}>
              {() => {
                return (
                  <Box mb={16}>
                    {fields.facebook.map(({ attributes }) => (
                      <TextField
                        key={attributes.name}
                        name={attributes.name}
                        type="hidden"
                        value={attributes.value}
                      />
                    ))}
                    <Button width="100%" inverse>
                      <Flex gap={8} justify="center">
                        <Icon.Facebook />
                        <span>
                          {t('auth:login.login_with_provider', {
                            provider: AuthProviderName.FACEBOOK,
                          })}
                        </span>
                      </Flex>
                    </Button>
                  </Box>
                )
              }}
            </Form>
          )}

          {Boolean(fields.apple.length) && (
            <Form onSubmit={onSubmit}>
              {() => {
                return (
                  <Box mb={16}>
                    {fields.apple.map(({ attributes }) => (
                      <TextField
                        key={attributes.name}
                        name={attributes.name}
                        type="hidden"
                        value={attributes.value}
                      />
                    ))}
                    <Button width="100%" inverse>
                      <Flex gap={8} justify="center">
                        <Icon.Apple />
                        <span>
                          {t('auth:login.login_with_provider', {
                            provider: AuthProviderName.APPLE,
                          })}
                        </span>
                      </Flex>
                    </Button>
                  </Box>
                )
              }}
            </Form>
          )}

          {Boolean(fields.tiktok.length) && (
            <Form onSubmit={onSubmit}>
              {() => {
                return (
                  <Box mb={16}>
                    {fields.tiktok.map(({ attributes }) => (
                      <TextField
                        key={attributes.name}
                        name={attributes.name}
                        type="hidden"
                        value={attributes.value}
                      />
                    ))}
                    <Button width="100%" inverse>
                      <Flex gap={8} justify="center">
                        <Icon.TikTok />
                        <span>
                          {t('auth:login.login_with_provider', {
                            provider: AuthProviderName.TIKTOK,
                          })}
                        </span>
                      </Flex>
                    </Button>
                  </Box>
                )
              }}
            </Form>
          )}

          {Boolean(fields.github.length) && isGithubAuthEnabled && (
            <Form onSubmit={onSubmit}>
              {() => {
                return (
                  <Box mb={16}>
                    {fields.github.map(({ attributes }) => (
                      <TextField
                        key={attributes.name}
                        name={attributes.name}
                        type="hidden"
                        value={attributes.value}
                      />
                    ))}
                    <Button width="100%" inverse>
                      <Flex gap={8} justify="center">
                        <Icon.GitHub />
                        <span>
                          {t('auth:login.login_with_provider', {
                            provider: AuthProviderName.GITHUB,
                          })}
                        </span>
                      </Flex>
                    </Button>
                  </Box>
                )
              }}
            </Form>
          )}

          {Boolean(fields.email.length) && (
            <Form
              onSubmit={onSubmit}
              validations={{
                descriptors: {
                  identifier: {
                    required: t('auth:errors.email_required'),
                    pattern: {
                      value: FormValidations.emailRegex,
                      message: t('auth:errors.email_wrong_format'),
                    },
                  },
                  password: {
                    required: t('auth:errors.password_required'),
                  },
                },
              }}
            >
              {() => {
                return (
                  <Box mb={16}>
                    {isThereSocialAuth && (
                      <Box my={24}>
                        <StyledCrossLine>
                          <Typography
                            variant="hint"
                            as="span"
                            color="inactive"
                            fontWeight={600}
                          >
                            {t('auth:login.second_title')}
                          </Typography>
                        </StyledCrossLine>
                      </Box>
                    )}
                    {[...fields.csrfToken, ...fields.method].map(
                      ({ attributes }) => (
                        <TextField
                          key={attributes.name}
                          name={attributes.name}
                          type="hidden"
                          value={attributes.value}
                        />
                      )
                    )}
                    {fields.email.map(({ attributes }) => (
                      <Box mb={16} key={attributes.name}>
                        <TextField
                          name={attributes.name}
                          placeholder={t('auth:input_placeholders.email')}
                          autoComplete="off"
                          autoCorrect="off"
                          autoCapitalize="off"
                          spellCheck="false"
                        />
                      </Box>
                    ))}
                    {fields.password.map(({ attributes }) => (
                      <Box mb={24} key={attributes.name}>
                        <TextField
                          name={attributes.name}
                          placeholder={t('auth:input_placeholders.password')}
                          type={isPasswordVisible ? 'text' : 'password'}
                          rightDecorator={
                            <IconButton
                              icon={
                                isPasswordVisible
                                  ? 'PasswordHide'
                                  : 'PasswordShow'
                              }
                              onClick={togglePasswordVisibility}
                            />
                          }
                        />
                      </Box>
                    ))}
                    <Button width="100%">{t('auth:login.login_button')}</Button>
                  </Box>
                )
              }}
            </Form>
          )}
          <Box my={24}>
            {(submitErrorMessage || flowError) && (
              <Flex align="center" justify="center">
                <Box mb={16}>
                  <StyledError $size="regular">
                    {t(`auth:errors.${submitErrorMessage || flowError}`)}
                  </StyledError>
                </Box>
              </Flex>
            )}
          </Box>
        </>
      )}
    </div>
  )
}
