import { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { Confirm } from '@web-apps/ui-shared'
import {
  useEditContainerAtomicMutation,
  useDeleteCreatorPageSectionMutation,
} from '@web-apps/feature-creator-page'
import { creatorUrlBuilders } from '@web-apps/utils-shared'

import {
  ANALYTICS_CATEGORY,
  ContainerSectionAPIPayloadType,
} from '@web-apps/utils-types'
import {
  formatBaseProductRecommendationsForm,
  formatProductRecommendationsFormToPreview,
  formatContainerToForm,
} from './SectionProductRecommendationsForm.adapters'

import { SectionProductRecommendationsForm } from './SectionProductRecommendationsForm.component'

import { useDraftSection } from '../hooks/preview.hooks'
import { editDraftProductRecommendationsSection } from '../../../../store/creator/preview.slice'
import { ROUTES } from '../../../../routes/RouteEnums'
import { useCreatorPage } from '../../../../utils/hooks/useCreatorPage.hooks'
import { ToastShareWrapper } from '../../../../components'

export const EditSectionProductRecommendations = () => {
  const { t } = useTranslation(['app', 'creator'])
  const { id } = useParams<{ id: string }>()
  const navigate = useNavigate()
  const creatorPage = useCreatorPage()
  const [isConfirmOpen, toggleConfirm] = useState(false)
  const updatePreview = useDraftSection(
    id,
    editDraftProductRecommendationsSection,
    true
  )
  const [editContainer, { isSuccess, isError, isLoading }] =
    useEditContainerAtomicMutation()
  const [
    deleteSection,
    {
      isSuccess: isDeleteSuccess,
      isError: isDeleteError,
      isLoading: isDeleteLoading,
    },
  ] = useDeleteCreatorPageSectionMutation()
  const section = creatorPage.sections.find(
    (item): item is ContainerSectionAPIPayloadType => item._links.self.id === id
  )

  useEffect(() => {
    if (isSuccess) {
      navigate(ROUTES.MY_PAGE)
      toast.success(
        <ToastShareWrapper
          message={t('creator:toast.edit_success', {
            section: '$t(creator:sections.product_recommendations)',
            count: Number.POSITIVE_INFINITY,
          })}
          href={creatorUrlBuilders.creatorUrlBuilder({
            creatorSlug: creatorPage._links.self.name,
          })}
          analyticsEventProps={{
            actionNamePrefix: 'edit_product_recommendations_section',
            category: ANALYTICS_CATEGORY.CONTENT,
          }}
        />
      )
    }
  }, [isSuccess, creatorPage, t, navigate])

  useEffect(() => {
    if (isDeleteSuccess) {
      navigate(ROUTES.MY_PAGE)
      toast.success(
        t('creator:toast.delete_success', {
          section: '$t(creator:sections.product_recommendations)',
        })
      )
    }
  }, [isDeleteSuccess, t, navigate])

  useEffect(() => {
    if (isError || isDeleteError) {
      toast.error(t('creator:product_recommendations.edit_error'))
    }
  }, [isError, isDeleteError, t])

  if (!section) {
    return null
  }

  return (
    <>
      <SectionProductRecommendationsForm
        section={section}
        initialValues={formatContainerToForm(section)}
        onChange={(formData) => {
          updatePreview({
            data: formatProductRecommendationsFormToPreview(formData),
          })
        }}
        handleDelete={() => toggleConfirm(true)}
        onSubmit={({ imageData, ...values }) => {
          editContainer({
            path: section._links.self.href,
            imageData,
            sectionData: formatBaseProductRecommendationsForm(values),
            items: {
              orderById: (values.products || []).map((p) => p.id as string),
            },
          })
        }}
        isSubmitting={isLoading || isDeleteLoading}
      />
      {isConfirmOpen && (
        <Confirm
          onDismiss={() => toggleConfirm(false)}
          title={t('creator:confirm.title')}
          buttons={[
            {
              label: t('creator:confirm.cancel'),
              onClick: () => toggleConfirm(false),
              inverse: true,
            },
            {
              label: t('creator:confirm.delete'),
              onClick: () => {
                deleteSection({ path: section._links.self.href })
                toggleConfirm(false)
              },
            },
          ]}
        />
      )}
    </>
  )
}
