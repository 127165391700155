import { Flex } from '../../atoms'
import { Button, ButtonSize, ButtonVariant } from '../Button'

import { Dialog } from '../Dialog'

type Button = {
  label: string
  size?: ButtonSize
  variant?: ButtonVariant
  inverse?: boolean
  onClick: () => void
}

type Props = {
  onDismiss: () => void
  title: string
  buttons: Button[]
}

export const Confirm: (props: Props) => JSX.Element = ({
  onDismiss,
  title,
  buttons,
}) => {
  return (
    <Dialog
      title={title}
      isOpen={true}
      showHeaderBorder={true}
      showCloseButton={true}
      maxWidthNotMobile="375px"
      onDismiss={onDismiss}
    >
      <Flex gap={24} justify="center" data-testid="confirm-dialog">
        {buttons.map((item, key) => (
          <Button
            key={key}
            size={item.size}
            inverse={item.inverse}
            variant={item.variant}
            clickHandler={item.onClick}
          >
            {item.label}
          </Button>
        ))}
      </Flex>
    </Dialog>
  )
}
