import React from 'react'
import styled from 'styled-components'

import { BREAKPOINTS, media, Theme } from '@web-apps/ui-shared'

const StyledPageHeader = styled.div<{
  $hasArrowBack: boolean
  $hasBorder: boolean
}>`
  z-index: ${Theme.CreatorApp.header.zIndex};
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  align-items: center;
  background: ${Theme.Colors.background.base};
  height: ${Theme.CreatorApp.header.height}px;
  padding: 0 16px;

  ${media.from(
    BREAKPOINTS.lg,
    `
      padding: 0 48px;
      left: ${Theme.Sidebar.width}px;
    `
  )}

  ${({ $hasArrowBack }) => {
    return (
      $hasArrowBack &&
      `
        padding-left: 16px;
      `
    )
  }};

  ${({ $hasBorder }) => {
    return (
      $hasBorder && `border-bottom: solid 1px ${Theme.Colors.border.darker};`
    )
  }};
`

const StyledPageContent = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

type PageHeaderProps = {
  children: React.ReactNode
  hasArrowBack?: boolean
  hasBorder?: boolean
}

const PageHeader = ({
  children,
  hasArrowBack = false,
  hasBorder = true,
}: PageHeaderProps) => {
  return (
    <StyledPageHeader $hasArrowBack={hasArrowBack} $hasBorder={hasBorder}>
      <StyledPageContent>{children}</StyledPageContent>
    </StyledPageHeader>
  )
}

export default PageHeader
