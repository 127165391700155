import { IConfig } from 'unleash-proxy-client'

import { config } from '../utils/helpers/config.helpers'

export const flagsConfig: IConfig = {
  url: config.unleash.proxyUrl,
  clientKey: config.unleash.clientKey,
  environment: config.unleash.environment,
  appName: 'zezam-creator-app',
  disableMetrics: true,
}
