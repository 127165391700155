import { useState, useEffect } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import cookie from 'js-cookie'
import { add } from 'date-fns'
import ReactGA from 'react-ga4'

import { isSafariIOS } from '@web-apps/utils-shared'
import { Box, Flex, Icon, Typography } from '@web-apps/ui-shared'
import {
  StyleContainer,
  StyledDescription,
  StyledLogo,
  StyledCloseButton,
  StyledArrow,
} from './AddToHomeScreenTooltip.styles'
import { ANALYTICS_CATEGORY } from '@web-apps/utils-types'

export const AddToHomeScreenTooltip = () => {
  const { t } = useTranslation(['app'])
  const [isTooltipShown, setIsTooltipShown] = useState(false)

  useEffect(() => {
    if (isSafariIOS() && !cookie.get('temporary_add_to_home_screen_tooltip')) {
      setIsTooltipShown(true)
      ReactGA.event({
        action: 'add_to_home_screen:banner:view',
        category: ANALYTICS_CATEGORY.APPLICATION,
      })
    }
  }, [])

  const handleClose = () => {
    ReactGA.event({
      action: 'add_to_home_screen:banner_close_btn:click',
      category: ANALYTICS_CATEGORY.APPLICATION,
    })
    cookie.set('temporary_add_to_home_screen_tooltip', 'true', {
      expires: add(new Date(), { weeks: 3 }),
    })
    setIsTooltipShown(false)
  }

  if (!isTooltipShown) return null

  return (
    <StyleContainer>
      <StyledCloseButton onClick={handleClose}>
        <Icon.Close width={10} height={10} />
      </StyledCloseButton>
      <Box mb={16}>
        <Flex gap={8} align="center">
          <StyledLogo>
            <Icon.ZezamLogo width={24} height={24} />
          </StyledLogo>
          <Typography
            color="inverse"
            variant="note"
            fontWeight={600}
            style={{ lineHeight: '16px', paddingRight: '10px' }}
          >
            {t('app:homeScreenTooltip.description')}
          </Typography>
        </Flex>
      </Box>
      <StyledDescription>
        <Trans
          i18nKey="app:homeScreenTooltip.cta"
          components={{
            icon: <Icon.Share fillColor="#1789C9" />,
          }}
        />
      </StyledDescription>
      <StyledArrow />
    </StyleContainer>
  )
}
