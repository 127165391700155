import { createContext } from 'react'
import { UserAccountContextType } from '@web-apps/utils-types'

export const AccountContext = createContext<UserAccountContextType>({
  id: '',
  slug: '',
  pageId: '',
  userId: '',
  timeZone: '',
  legacyCoachId: '',
  email: '',
  name: '',
  language: '',
  affiliateBalanceEndpoint: '',
  affiliatePayoutsEndpoint: '',
  region: '',
  creatorPageEndpoint: '',
  payoutInfoEndpoint: '',
  createdAt: '',
  apiConfigs: {
    endpointPaths: {
      latestCommissionIncreases: '',
    },
  },
})
