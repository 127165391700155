import { Icon } from '../../atoms'
import { Tooltip, TooltipProps } from './Tooltip'

export const BaseTooltip: React.FC<React.PropsWithChildren<TooltipProps>> = ({
  id,
  children,
  maxWidthOverride = '200px',
}) => (
  <>
    <div data-tip data-for={id}>
      <Icon.InfoGrayScale width={12} height={12} />
    </div>
    <Tooltip id={id} maxWidthOverride={maxWidthOverride}>
      {children}
    </Tooltip>
  </>
)
