import React from 'react'

import {
  StyledSpinnerContainer,
  StyledSpinnerSection,
} from './LoadingSpinner.styles'

type LoadingSpinnerProps = {
  size: number
  color?: string
}
export const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({
  size,
  color,
}) => {
  return (
    <StyledSpinnerContainer $size={size}>
      <StyledSpinnerSection $size={size} $color={color} />
      <StyledSpinnerSection $index={1} $size={size} $color={color} />
      <StyledSpinnerSection $index={2} $size={size} $color={color} />
      <StyledSpinnerSection $index={3} $size={size} $color={color} />
    </StyledSpinnerContainer>
  )
}
