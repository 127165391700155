import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import {
  InfoTooltip,
  MetricSummaryPanelProps,
  StyledIndicatorApprovedClicks,
  StyledIndicatorApprovedEarnings,
  StyledIndicatorApprovedOrders,
} from '@web-apps/ui-shared'
import { Money } from '@web-apps/utils-shared'
import { CreatorPageAffiliateAnalyticsAPIPayload } from '@web-apps/utils-types'

type Options = {
  withClicks: boolean
  withSales: boolean
}

export const useAffiliateLinkPanelProps = (
  aggregate?: CreatorPageAffiliateAnalyticsAPIPayload['aggregate'],
  options?: Options
) => {
  const { t } = useTranslation(['metrics'], { useSuspense: false })

  return useMemo(() => {
    if (!aggregate) {
      return []
    }

    let metrics: MetricSummaryPanelProps[] = [
      {
        title: t('metrics:metricPanels.approved_earning'),
        value: Money.formatPrice(aggregate.earnings.approved),
        customRightIndicatorForTitle: (
          <InfoTooltip
            id="metrics-approved-earnings-tooltip"
            title={t('metrics:metrics_description.approved_earning.label')}
            message={t('metrics:metrics_description.approved_earning.tooltip')}
            hint={t('metrics:fee_value_hint')}
          />
        ),
        customLeftIndicatorForTitle: <StyledIndicatorApprovedEarnings />,
      },
      {
        title: t('metrics:metricPanels.pending_earning'),
        value: Money.formatPrice(aggregate.earnings.pending),
        customColorForValue: 'inactive',
        customRightIndicatorForTitle: (
          <InfoTooltip
            id="metrics-pending-earning-tooltip"
            title={t('metrics:metrics_description.pending_earning.label')}
            message={t('metrics:metrics_description.pending_earning.tooltip')}
            hint={t('metrics:fee_value_hint')}
          />
        ),
      },
      {
        title: t('metrics:metricPanels.canceled_earning'),
        value: Money.formatPrice(aggregate.earnings.cancelled),
        customColorForValue: 'danger',
        customRightIndicatorForTitle: (
          <InfoTooltip
            id="metrics-cancelled-earning-tooltip"
            title={t('metrics:metrics_description.cancelled_earnings.label')}
            message={t(
              'metrics:metrics_description.cancelled_earnings.tooltip'
            )}
            hint={t('metrics:fee_value_hint')}
          />
        ),
      },
    ]

    if (options?.withSales) {
      metrics = [
        {
          title: t('metrics:metricPanels.approved_orders'),
          value: aggregate.sales,
          customRightIndicatorForTitle: (
            <InfoTooltip
              id="metrics-approved-orders-tooltip"
              title={t('metrics:metrics_description.approved_orders.label')}
              message={t('metrics:metrics_description.approved_orders.tooltip')}
              hint={t('metrics:fee_value_hint')}
            />
          ),
          customLeftIndicatorForTitle: <StyledIndicatorApprovedOrders />,
        },
        ...metrics,
      ]
    }

    if (options?.withClicks) {
      metrics = [
        {
          title: t('metrics:metricPanels.approved_clicks'),
          value: aggregate.clicks,
          customRightIndicatorForTitle: (
            <InfoTooltip
              id="metrics-approved-clicks-tooltip"
              title={t('metrics:metrics_description.approved_clicks.label')}
              message={t('metrics:metrics_description.approved_clicks.tooltip')}
            />
          ),
          customLeftIndicatorForTitle: <StyledIndicatorApprovedClicks />,
        },
        ...metrics,
      ]
    }

    return metrics
  }, [aggregate, options, t])
}
