import {
  useEffect,
  useMemo,
  useRef,
  useState,
  ChangeEvent,
  InputHTMLAttributes,
} from 'react'
import { getCountries } from 'react-phone-number-input'
import { useTranslation } from 'react-i18next'
import { useDebouncedCallback } from 'use-debounce'

import { Localisation } from '@web-apps/utils-shared'
import { CountryISOCodeType, CountryType } from '@web-apps/utils-types'

import { BREAKPOINTS_SIZES, useWindowSize } from '../../helpers'

import { Box, Flex, TextEllipsis, Typography } from '../../atoms'
import { Button, ButtonSize, ButtonVariant, SearchField } from '../../molecules'

import {
  StyledFlagImage,
  StyledLine,
  StyledResultsWrapper,
  StyledResults,
  StyledInput,
  StyledPopoverSelector,
} from './CountrySelect.styles'

import { FlagSprite } from './assets'

const FEATURED_COUNTRIES = ['US', 'DE']

const CountryItem = ({
  country,
  clickHandler,
}: {
  country: CountryType
  clickHandler: (country: CountryISOCodeType) => void
}) => (
  <Button
    width="100%"
    variant={ButtonVariant.PLAIN}
    clickHandler={() => {
      clickHandler(country.countryISOCode)
    }}
  >
    <Flex align="center">
      <StyledFlagImage
        aria-label={country.name}
        $countryISOCode={country.countryISOCode}
        $imgFile={FlagSprite}
      />
      <TextEllipsis>
        <Typography variant="navigation">{country.name}</Typography>
      </TextEllipsis>
    </Flex>
  </Button>
)

type CountrySelectBaseProps = {
  defaultCountry: CountryISOCodeType
  onCountrySelect: (country: string) => void
  hasError?: boolean
}

type CountrySelectProps =
  | (CountrySelectBaseProps & {
      mode?: 'list'
    } & React.HTMLAttributes<HTMLDivElement>)
  | (CountrySelectBaseProps & {
      mode?: 'form-field'
    } & React.HTMLAttributes<HTMLDivElement>)
  | (CountrySelectBaseProps & {
      mode: 'search-only'
    } & InputHTMLAttributes<HTMLInputElement>)

export const CountrySelect = ({
  defaultCountry,
  onCountrySelect,
  mode = 'list',
  hasError,
  ...htmlProps
}: CountrySelectProps) => {
  const { t } = useTranslation(['app'])
  const searchRef = useRef<HTMLInputElement>(null)
  const windowSize = useWindowSize({ debounceMsc: 0 })

  const [searchResults, setSearchResults] = useState<CountryType[] | undefined>(
    undefined
  )
  const [searchText, setSearchText] = useState('')

  const countriesList: CountryType[] = useMemo(
    () => Localisation.getCountriesList({ countriesISOCodes: getCountries() }),
    []
  )
  const [isSelectOpen, setIsSelectOpen] = useState(false)
  const [selectedCountry, setSelectedCountry] =
    useState<CountryISOCodeType>(defaultCountry)
  const [selectedCountryName, setSelectedCountryName] = useState(
    Localisation.getCountryNameFromCountryISOCode({
      countryISOCode: defaultCountry,
    })
  )

  const onCountryClick = (countryISOCode: CountryISOCodeType) => {
    onCountrySelect(countryISOCode)
    setIsSelectOpen(false)
    setSelectedCountry(countryISOCode)
    setSearchText('')
    setSearchResults(undefined)
  }

  const debouncedSearchCallback = useDebouncedCallback((query) => {
    if (query.length > 1) {
      const filteredSearchItems = countriesList.filter((country) =>
        country.name.toLowerCase().includes(query.toLowerCase())
      )
      return setSearchResults(
        filteredSearchItems.length ? filteredSearchItems : undefined
      )
    } else {
      setSearchResults(undefined)
    }
  }, 100)

  useEffect(() => {
    setSelectedCountryName(
      Localisation.getCountryNameFromCountryISOCode({
        countryISOCode: selectedCountry,
      })
    )
  }, [selectedCountry])

  useEffect(() => {
    if (isSelectOpen) {
      searchRef.current?.focus()
    }
  }, [isSelectOpen])

  const usePortal = !((windowSize?.width || 0) >= BREAKPOINTS_SIZES.from.sm)

  const renderSearchFieldAndResults = () => (
    <>
      <SearchField
        ref={searchRef}
        {...(mode === 'search-only' ? htmlProps : {})}
        placeholder={t('app:countries_list.search_input')}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          setSearchText(event.target.value)
          debouncedSearchCallback(event.target.value)
        }}
        tabIndex={1}
        onReset={() => {
          setSearchText('')
          setSearchResults(undefined)
        }}
        value={searchText}
        name="countries"
      />
      {searchResults && (
        <StyledResultsWrapper>
          <StyledResults>
            {searchResults.length > 0 && (
              <ul>
                {searchResults.map((country) => (
                  <Box py={8} key={country.countryISOCode}>
                    <CountryItem
                      country={country}
                      clickHandler={(countryISOCode) =>
                        onCountryClick(countryISOCode)
                      }
                    />
                  </Box>
                ))}
              </ul>
            )}
          </StyledResults>
        </StyledResultsWrapper>
      )}
    </>
  )

  if (mode === 'search-only') return renderSearchFieldAndResults()
  if (mode === 'form-field') {
    return (
      <StyledPopoverSelector
        buttonSize={ButtonSize.REGULAR}
        hasError={hasError}
        style={{ left: 0, zIndex: 102 }}
        maxContainerDesktopWidth="100%"
        maxContainerDesktopHeight="300px"
        usePortal={usePortal}
        isOpen={isSelectOpen}
        onClose={() => {
          setIsSelectOpen(false)
          setSearchText('')
          setSearchResults(undefined)
        }}
        onOpen={() => {
          setIsSelectOpen(true)
        }}
        value={
          <Box pt={3} pb={3}>
            {selectedCountry ? (
              <Flex align="center">
                <StyledFlagImage
                  aria-label={selectedCountryName}
                  $imgFile={FlagSprite}
                  $countryISOCode={selectedCountry}
                />
                <Typography>{selectedCountryName}</Typography>
              </Flex>
            ) : (
              <Typography color="inactive">
                {t('app:countries_list.select_country')}
              </Typography>
            )}
          </Box>
        }
        title={t('app:countries_list.title')}
        {...htmlProps}
      >
        <StyledInput>
          <Box mb={24}>{renderSearchFieldAndResults()}</Box>
        </StyledInput>
        {countriesList.map((country) => {
          return (
            <Box mb={16} key={country.countryISOCode}>
              <CountryItem
                country={country}
                clickHandler={(countryISOCode) =>
                  onCountryClick(countryISOCode)
                }
              />
            </Box>
          )
        })}
      </StyledPopoverSelector>
    )
  }
  if (mode === 'list')
    return (
      <StyledPopoverSelector
        usePortal={usePortal}
        isOpen={isSelectOpen}
        onClose={() => {
          setIsSelectOpen(false)
          setSearchText('')
          setSearchResults(undefined)
        }}
        onOpen={() => {
          setIsSelectOpen(true)
        }}
        value={
          <>
            <StyledFlagImage
              aria-label={selectedCountryName}
              $imgFile={FlagSprite}
              $countryISOCode={selectedCountry}
            />
            {selectedCountryName}
          </>
        }
        title={t('app:countries_list.title')}
        {...htmlProps}
      >
        <StyledInput>
          <Box mb={24}>{renderSearchFieldAndResults()}</Box>
        </StyledInput>
        <Box mb={24}>
          <Typography variant="subTitle" color="inactive">
            {t('app:countries_list.countries.popular')}
          </Typography>
        </Box>
        {Localisation.getCountriesList({
          countriesISOCodes: FEATURED_COUNTRIES,
        }).map((country) => {
          return (
            <Box mb={16} key={country.countryISOCode}>
              <CountryItem
                country={country}
                clickHandler={(countryISOCode) =>
                  onCountryClick(countryISOCode)
                }
              />
            </Box>
          )
        })}
        <StyledLine />
        <Box my={24}>
          <Typography variant="subTitle" color="inactive">
            {t('app:countries_list.countries.all')}
          </Typography>
        </Box>
        {countriesList.map((country) => {
          return (
            <Box mb={16} key={country.countryISOCode}>
              <CountryItem
                country={country}
                clickHandler={(countryISOCode) =>
                  onCountryClick(countryISOCode)
                }
              />
            </Box>
          )
        })}
      </StyledPopoverSelector>
    )

  return null
}
